import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types';

const BaseButton = (props) => {
  // computed
  const hasIconClass = () => {
    return (props.iconComponent && !props.iconToRight) && 'button--has-icon'
  }

  const iconToRightClass = () => {
    return props.iconToRight && 'button--icon-to-right'
  }

  const iconOnlyClass = () => {
    return props.text === undefined && 'button--icon-only'
  }

  const backgroundColorClass = () => { 
    return props.backgroundColor && `button--background-${props.backgroundColor}`
  }

  const alignTextClass = () => {
    return props.textToLeft && 'button--text-to-left'
  }

  // methods
  const handleSubmit = (event) => { props.handleSubmit(event) }

  return (
    <button
      type={props.type}
      disabled={props.disabled}
      className={
        classnames(
          'button', 
          props.className, 
          backgroundColorClass(), 
          hasIconClass(), 
          iconToRightClass(), 
          iconOnlyClass(), 
          alignTextClass()
        )}
      onClick={handleSubmit}
      style={props.style}>
      { props.iconComponent }

      <span className="button__text">
        { props.text }
      </span>
    </button>
  )
}

BaseButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  textToLeft: PropTypes.bool,
  iconToRight: PropTypes.bool,
  iconComponent: PropTypes.object,
  handleSubmit: PropTypes.func
}

BaseButton.defaultProps = {
  backgroundColor: 'green',
  textToLeft: false,
  iconToRight: false
}

export default BaseButton
