import {
  POPULATE_FILTERS,
  TOGGLE_FILTER_GROUP_IS_OPEN,
  SEARCH_FILTER_ITEM
} from 'redux/types'
import initialState from 'redux/initialState'

export default function filterReducer(state = initialState.filters, action) {
  switch (action.type) {
    case POPULATE_FILTERS:
      let filterGroups = {};
      Object.keys(action.payload).map(groupName => {
        filterGroups[groupName] = {
          isOpen: state.filterGroups[groupName] ? state.filterGroups[groupName].isOpen : false,
          items: [
            ...filterFilters(action.payload[groupName].unapplied)
              .map(value => ({ value, isSelected: false })),
            ...filterFilters(action.payload[groupName].applied)
              .map(value => ({ value, isSelected: true })),
          ]
        }
      })

      if (Object.keys(action.payload).length > 0) {
        filterGroups = {
          ...filterGroups,
          date: { 
            isOpen: state.filterGroups.date
              ? state.filterGroups.date.isOpen
              : false
          }
        }
      }
      return Object.assign({}, state, { filterGroups })

    case TOGGLE_FILTER_GROUP_IS_OPEN:
      return Object.assign({}, state, {
        filterGroups: {
          ...state.filterGroups,
          [action.payload]: {
            ...state.filterGroups[action.payload],
            isOpen: !state.filterGroups[action.payload].isOpen
          }
        }
      })

    case SEARCH_FILTER_ITEM:
      return Object.assign({}, state, {
        filterGroups: {
          ...state.filterGroups,
          [action.groupName]: {
            ...state.filterGroups[action.groupName],
            searchValue: action.searchValue,
            items: state.filterGroups[action.groupName].items
              .map(item => ({
                ...item,
                hide: action.searchValue.trim().length > 0
                  && !item.value.toLowerCase().includes(action.searchValue.toLowerCase())
              }))
          }
        }
      })

    default:
      return state
  }
}

function filterFilters(filters) {
  return filters.filter(f => f !== "approvalEdited");
 }