const InspectionConfig = [
  {
    key: 'reportDetails.reportDate',
    isRequired: false,
    sortOrder: 0
  },
  {
    key: 'reportDetails.reportTitle',
    isRequired: true,
    sortOrder: 1
  },
  {
    key: 'reportDetails.skfCaseNumber',
    isRequired: false,
    sortOrder: 2
  },
  {
    key: 'reportDetails.terNumber',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'reportAuthor',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'coReportAuthor',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'reportDetails.numberOfBearingsInvestigated',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'reportDetails.inspectionDate',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'skfDetails.countryCompanyLocation',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'skfDetails.countryCompanyLocation.company',
    isRequired: false,
    sortOrder: 4
  },
  {
    key: 'skfDetails.countryCompanyLocation.location',
    isRequired: false,
    sortOrder: 5
  },
  {
    key: 'skfDetails.reportAuthor',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.coReportAuthor',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.approver.personalInfo',
    isRequired: false,
    sortOrder: 13
  },
  {
    key: 'skfDetails.reportNeedsApproval.solutionFactoryName',
    isRequired: false,
    sortOrder: 12
  },
  {
    key: 'skfDetails.reportNeedsApproval',
    isRequired: false,
    sortOrder: 13
  },
  {
    key: 'skfDetails.distributionListHeader',
    isRequired: false,
    sortOrder: 14
  },
  {
    key: 'skfDetails.distributionList',
    isRequired: false,
    sortOrder: 15
  },
  {
    key: 'skfDetails.delegationListHeader',
    isRequired: false,
    sortOrder: 16
  },
  {
    key: 'skfDetails.delegationList',
    isRequired: false,
    sortOrder: 17
  },
  {
    key: 'customerDetails.customer',
    isRequired: true,
    sortOrder: 17
  },
  {
    key: 'customerDetails.sectorSegment.sector',
    isRequired: false,
    sortOrder: 34
  },
  {
    key: 'customerDetails.sectorSegment.segment',
    isRequired: false,
    sortOrder: 35
  },
  {
    key: 'customerDetails.sectorSegment.industry',
    isRequired: false,
    sortOrder: 36
  },
  {
    key: 'customerDetails.customerAddress',
    isRequired: false,
    sortOrder: 18
  },
  {
    key: 'customerDetails.customerCityStateTerritory',
    isRequired: false,
    sortOrder: 19
  },
  {
    key: 'customerDetails.customerPostCode',
    isRequired: false,
    sortOrder: 20
  },
  {
    key: 'customerDetails.customerCountry',
    isRequired: false,
    sortOrder: 21
  },
  {
    key: 'customerDetails.site',
    isRequired: true,
    sortOrder: 22
  },
  {
    key: 'customerDetails.plantOrTrainOrShipName',
    isRequired: false,
    sortOrder: 23
  },
  {
    key: 'customerDetails.customerContact.name',
    isRequired: false,
    sortOrder: 25
  },
  {
    key: 'customerDetails.customerContact.jobTitle',
    isRequired: false,
    sortOrder: 26
  },
  {
    key: 'customerDetails.customerContact.email',
    isRequired: false,
    sortOrder: 27
  },
  {
    key: 'customerDetails.customerContact.phone',
    isRequired: false,
    sortOrder: 28
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.machineOrAssetCode',
    isRequired: true,
    sortOrder: 29
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.assetDescription',
    isRequired: true,
    sortOrder: 29
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.assetFigure',
    isRequired: true,
    sortOrder: 29
  },
  {
    key: "assetDetails.assetTypeOrFunctionalAreaOrSystem.analysisImages",
    isRequired: false,
    sortOrder: 41,
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.positionOfBearingInAsset',
    isRequired: false,
    sortOrder: 30
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.functionalAreaName',
    isRequired: true,
    sortOrder: 31
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.systemNameWhereAssetIsUsed',
    isRequired: false,
    sortOrder: 32
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.purposeOfAsset',
    isRequired: false,
    sortOrder: 33
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.oemName',
    isRequired: false,
    sortOrder: 34
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.OemModelNumber',
    isRequired: false,
    sortOrder: 35
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.serialNumber',
    isRequired: false,
    sortOrder: 36
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.activeSince',
    isRequired: false,
    sortOrder: 37
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.endUserTagNumber',
    isRequired: false,
    sortOrder: 38
  },
  {
    key: 'summary.summaryOfInspection',
    isRequired: false,
    isFullWidth: true,
    sortOrder: 39,
    isRichText: true
  },
  {
    key: "bearingInvestigations.bearingType.bearingTypeSelect",
    isRequired: false,
    sortOrder: 0
  },
  {
    key: "bearingInvestigations.bearingType.bearingSkfCustomerDescription",
    isRequired: false,
    sortOrder: 1,
    isRichText:true
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent",
    isRequired: false,
    sortOrder: 2
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.manufacturer",
    isRequired: false,
    sortOrder: 0
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.SKFManufacturer",
    isRequired: false,
    sortOrder: 3
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.skfBearingDesignation",
    isRequired: false,
    sortOrder: 4
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.competitorDesignationAndMarkings",
    isRequired: false,
    sortOrder: 5
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.skfEquivalent",
    isRequired: false,
    sortOrder: 5
  },
  {
    key: "bearingInvestigations.bearingType.skfSerialNumber",
    isRequired: false,
    sortOrder: 6
  },
  {
    key: "bearingInvestigations.bearingType.allPartsAvailable",
    isRequired: false,
    sortOrder: 7
  },
  {
    key: "bearingInvestigations.manufacturing.skfCompany",
    isRequired: false,
    sortOrder: 8
  },
  {
    key: "bearingInvestigations.manufacturing.skfCompany.company",
    isRequired: false,
    sortOrder: 9
  },
  {
    key: "bearingInvestigations.manufacturing.skfCompany.location",
    isRequired: false,
    sortOrder: 10
  },
  {
    key: "bearingInvestigations.manufacturing.manufacturingDateCode",
    isRequired: false,
    sortOrder: 11
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType",
    isRequired: false,
    sortOrder: 12
  },
  {
    key: "bearingInvestigations.operatingData.lubricantManufacturerName",
    isRequired: false,
    sortOrder: 13
  },
  {
    key: "bearingInvestigations.operatingData.lubricantModelDesignation",
    isRequired: false,
    sortOrder: 13
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.greaseType",
    isRequired: false,
    sortOrder: 13
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.oilType",
    isRequired: false,
    sortOrder: 14
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.greaseTypeGrams",
    isRequired: false,
    sortOrder: 15
  },
  {
    key: "bearingInvestigations.operatingData.ringRotation",
    isRequired: false,
    sortOrder: 16
  },
  {
    key: "bearingInvestigations.operatingData.lengthOfBearingOperated",
    isRequired: false,
    sortOrder: 16
  },
  {
    key: "bearingInvestigations.operatingData.shaftOrientation",
    isRequired: false,
    sortOrder: 17
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.racewayFailureAndCause",
    isRequired: false,
    sortOrder: 18
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.diameterFailureAndCause",
    isRequired: false,
    sortOrder: 19
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.sideFaceMarkedFailureAndCause",
    isRequired: false,
    sortOrder: 20
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.sideFaceOppositeFailureAndCause",
    isRequired: false,
    sortOrder: 21
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.outerRingFlangeFailureAndCause",
    isRequired: false,
    sortOrder: 21
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.racewayFailureAndCause",
    isRequired: false,
    sortOrder: 22
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.boreFailureAndCause",
    isRequired: false,
    sortOrder: 23
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.sideFaceMarkedFailureAndCause",
    isRequired: false,
    sortOrder: 24
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.sideFaceOppositeFailureAndCause",
    isRequired: false,
    sortOrder: 25
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.innerRingFlangeFailureAndCause",
    isRequired: false,
    sortOrder: 25
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowOneFailureAndCause",
    isRequired: false,
    sortOrder: 26
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowTwoFailureAndCause",
    isRequired: false,
    sortOrder: 27
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowThreeFailureAndCause",
    isRequired: false,
    sortOrder: 28
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowFourFailureAndCause",
    isRequired: false,
    sortOrder: 29
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageOneFailureAndCause",
    isRequired: false,
    sortOrder: 30
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageTwoFailureAndCause",
    isRequired: false,
    sortOrder: 31
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageThreeFailureAndCause",
    isRequired: false,
    sortOrder: 32
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageFourFailureAndCause",
    isRequired: false,
    sortOrder: 33
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.guideRing.guideRingFailureAndCause",
    isRequired: false,
    sortOrder: 34
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.sealsOrShields.sealOrShieldOneFailureAndCause",
    isRequired: false,
    sortOrder: 35
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.sealsOrShields.sealOrShieldTwoFailureAndCause",
    isRequired: false,
    sortOrder: 36
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.otherComponent.otherFailureAndCause",
    isRequired: false,
    sortOrder: 37
  },
  {
    key: "bearingInvestigations.bearingType.customerReferencePartNumber",
    isRequired: false,
    sortOrder: 38
  },
  {
    key: "bearingInvestigations.measuredInternalClearence.measuredInternalClearance",
    isRequired: false,
    sortOrder: 39
  },
  {
    key: "bearingInvestigations.analysis.analysisSummary",
    isRequired: false,
    sortOrder: 40,
    imageIsNext: true,
    isFullWidth: true
  },
  {
    key: "bearingInvestigations.analysis.analysisImages",
    isRequired: false,
    sortOrder: 41,
  },
  {
    key: "bearingInvestigations.analysis.primaryFailureAndCause",
    isRequired: false,
    sortOrder: 42
  },
  {
    key: "bearingInvestigations.analysis.additionalFailureAndCause",
    isRequired: false,
    sortOrder: 43
  },
  {
    key: "bearingInvestigations.disposition.dispositionContainer",
    isRequired: false,
    sortOrder: 44
  },
  {
    key: "bearingInvestigations.disposition.dispositionContainer.scrapDate",
    isRequired: false,
    sortOrder: 45
  },
  {
    key: "bearingInvestigations.recommendations.recommendations",
    isRequired: false,
    sortOrder: 46
  }
]

export default InspectionConfig
