import React from "react";

const SvgPhone = props => {
  return <svg width="12px" height="10px" viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <polygon id="path-1" points="0.087 0.2191 21.9134 0.2191 21.9134 7.8636 0.087 7.8636"></polygon>
      <polygon id="path-3" points="0.906 0.0467 19.094 0.0467 19.094 12.778 0.906 12.778"></polygon>
    </defs>
    <g id="Page-24" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SKF-icon-finder---full---v2_2" transform="translate(-292.000000, -49.000000)">
        <g id="Group-6123" transform="translate(292.000000, 49.000000)">
          <g id="Group-6119">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-6118"></g>
            <path d="M21.9134,4.2256 L21.9134,5.5896 C21.9134,6.8426 20.8934,7.8626 19.6394,7.8636 L16.9114,7.8636 C15.6584,7.8636 14.4674,6.7676 14.6374,5.0946 C12.2444,4.8826 9.7574,4.8826 7.3624,5.0946 C7.5334,6.7676 6.3414,7.8636 5.0894,7.8636 L2.3604,7.8636 C1.1074,7.8626 0.0863993534,6.8426 0.0863993534,5.5896 L0.0863993534,4.2256 C0.0854,2.7476 1.2444,1.3546 2.7244,1.0516 C8.1704,-0.0584 13.8244,-0.0584 19.2764,1.0516 C20.7544,1.3546 21.9134,2.7476 21.9134,4.2256" id="Fill-6117" fill="#515557" mask="url(#mask-2)"></path>
          </g>
          <g id="Group-6122" transform="translate(1.000000, 6.000000)">
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3"></use>
            </mask>
            <g id="Clip-6121"></g>
            <path d="M10,10.9597 C7.994,10.9597 6.362,9.3277 6.362,7.3217 C6.362,5.3157 7.994,3.6837 10,3.6837 C12.006,3.6837 13.638,5.3157 13.638,7.3217 C13.638,9.3277 12.006,10.9597 10,10.9597 M11.791,1.9227 L11.791,0.5007 C11.791,0.2497 11.587,0.0467 11.336,0.0467 C11.085,0.0467 10.881,0.2497 10.881,0.5007 L10.881,1.8657 L9.091,1.8657 L9.091,0.5007 C9.091,0.2497 8.887,0.0467 8.636,0.0467 C8.385,0.0467 8.181,0.2497 8.181,0.5007 L8.181,1.8877 C4.13,2.1257 0.906,5.4857 0.906,9.5957 L0.906,12.3237 C0.906,12.5747 1.109,12.7787 1.36,12.7787 L18.64,12.7787 C18.891,12.7787 19.094,12.5747 19.094,12.3237 L19.094,9.5957 C19.066,5.4967 16.338,1.8587 11.791,1.9227" id="Fill-6120" fill="#515557" mask="url(#mask-4)"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
}

export default SvgPhone;
