import {svgEmail, svgPhone} from "./Icons";
import _ from 'lodash';
import config from '../../../config';

export function isConfidential( data ){
  const answer = data["reportDetails.ConfidentialCheckBox"]
  return answer === true;
}
export function extractConfigFor(value, config){
  return config.filter(item => { return item.key.includes(value) })
}

export const transformPersonalInfo = (authorPrefix, data) => {
  const name = data[authorPrefix + '.name'];
  const email = data[authorPrefix + '.email'];
  const phone = data[authorPrefix + '.phone'];

  const emailString = email ? `\n${svgEmail} ${email}` : '';
  const phoneString = phone ? `\n${svgPhone} ${phone}` : '';

  const key = authorPrefix + ".personalInfo";
  const authorData = {}

  if (name) {
    authorData[key] = `${name}${emailString}${phoneString}`;
  }

  return {
    config: [{
      key: key,
      isRequired: false,
      noLabel: true,
    }],
    data: authorData
  }
}

export function transformCustomerDetailsData(data, totalConfig){
  let answerObject = {}
  let answer = data["customerDetails.customer"]
  let config = extractConfigFor('customerDetails', totalConfig)

  if (config !== undefined) {
    config.forEach(element => {
      if (typeof(data[element.key]) === "object") {
        if (data[element.key]?.hasOwnProperty("label")) {
          answerObject[element.key] = data[element.key].label
        }
      } else {
        answerObject[element.key] = data[element.key]
      }
    });
    if (answer !== undefined ) {
      if ( answer.companyName !== undefined && answer.companyName.hasOwnProperty("value")) {
        answerObject["customerDetails.customer"] = answer.companyName.value.AddressLine2
      }
      answerObject["customerDetails.customerAddress"] = answer.addressValue
      answerObject["customerDetails.customerPostCode"] = answer.postcodeValue
      answerObject["customerDetails.customerCityStateTerritory"] = answer.cityAndStateOrTerritoryValue
      answerObject["customerDetails.customerCountry"] = answer.countryValue
    }
  }
  return answerObject
}

export function formatSectorSegment(customPath, index, answerValue, answerObject, key) {
  if (answerValue) {
    const parts = customPath.split("/");
    answerObject[key] = parts.length > index && parts[index] != "-" && answerValue.indexOf(":") == -1 ? parts[index] + ": " + answerValue : answerValue;
  }
}

export function transformAssetDetails(data, totalConfig){
  let answerObject = {}
  let config = extractConfigFor('assetDetails', totalConfig)

  if (config !== undefined) {
    config.forEach(element => {
      let answer = data[element.key]
      if (typeof(answer) === "object" && answer !== null) {
        if (answer.hasOwnProperty("imageUrls")) {
          answerObject[element.key] = data[element.key]
        }
        if ( answer.hasOwnProperty("date")) {
          answerObject[element.key] = answer.date
        } else if (answer.hasOwnProperty("label") && answer.label) {
          answerObject[element.key] = answer.label
        } else if (answer.hasOwnProperty("unitSelected")) {
          if ( answer.value !== undefined && answer.unitSelected.label !== undefined) {
            answerObject[element.key] = `${answer.value} ${answer.unitSelected.label}`
          }
        }
      } else {
        answerObject[element.key] = data[element.key]
      }
    })
  }
  return answerObject
}

export function transformSummaryData(data, totalConfig){
  let answerObject = {}
  let answer = data["summary.recommendationsOptions"]
  let config = extractConfigFor('summary', totalConfig)
  if (config !== undefined) {
    config.forEach(element => {
      const answer = data[element.key];
      if (typeof answer === "object" && answer !== null) {
        if (element.key === "summary.partAction") {
          if (answer.label) {
            answerObject[element.key] = answer.label;
          }
        } else if (element.key === "summary.partActionTimeLine") {
          if (answer.date) {
            answerObject[element.key] = answer.date;
          }
        } else {
          answerObject[element.key] = data[element.key];
        }
      } else {
        answerObject[element.key] = data[element.key];  
      }
    });
    if ( answer !== undefined && answer !== null  && answer !== "") {
      answerObject["summary.recommendationsOptions"] = answer.map( object => object.label).join(", ")
    }
  }
  return answerObject
}

export function transformIndustryAssetTypeData(data, totalConfig){
  let answerObject = {}
  let config = extractConfigFor('industryAssetType', totalConfig)

  if (config !== undefined) {
    config.forEach(element => {
      let answer = data[element.key]
      if (answer !== undefined) {
        if (typeof(answer) === "object" && answer !== null) {
          if ( answer.hasOwnProperty("label")) {
            answerObject[element.key] = answer.label
          }
          if ( answer.hasOwnProperty("windFunctionalLocation")) {
            answerObject[element.key] = answer.windFunctionalLocation.label
            if (answer.hasOwnProperty("nacelleType")) {
              answerObject[`${element.key}.nacelleType`] = answer.nacelleType.label
              if (answer.hasOwnProperty("nainShaftArrangementType")) {
                answerObject[`${element.key}.nainShaftArrangementType`] = answer.nainShaftArrangementType.label
              }
            }
            if (answer.hasOwnProperty("rotorType")) {
              answerObject[`${element.key}.rotorType`] = answer.rotorType.label
            }
            if (answer.hasOwnProperty("towerType")) {
              answerObject[`${element.key}.towerType`] = answer.towerType.label
            }
            if (answer.hasOwnProperty("electricalType")) {
              answerObject[`${element.key}.electricalType`] = answer.electricalType.label
            }
          }
        } else {
          answerObject[element.key] = data[element.key]
        }

      }
    })
  }
  return answerObject
}

export function getInvestigationDetailsData(config, data){
  let answerObject = {}

  if (config !== undefined) {
    config.forEach(element => {
      let answer = data[element.key]
        if (typeof(answer) === "object" && answer !== null) {
          if ( answer.hasOwnProperty("label")) {
            answerObject[element.key] = answer.label
          }
        } else {
          answerObject[element.key] = data[element.key]
        }
    })
  }
  return answerObject
}

export function checkIfInvestigationDetailsExits(config, data) {
  let exists = false
  if (config !== undefined) {
    config.forEach(element => {
      let answer = data[element.key]
      if (answer) {
        exists = true
      }
    })
    return exists
  }
  return exists
}

export function getOtherSKFdetailsData(config, data){
  let answerObject = {}
  if (config !== undefined) {
    config.forEach(element => {
      let answer = data[element.key]

        if (typeof(answer) === "object") {
          if (answer?.hasOwnProperty("country")) {
            answerObject[element.key] = answer.country.label
            if (answer.company !== undefined && answer.company !== "") {
              answerObject[`${element.key}.company`] = answer.company.label
            }
            if (answer.location !== undefined && answer.location !== "") {
              answerObject[`${element.key}.location`] = answer.location.label
            }
          } else if (answer?.hasOwnProperty("needsApproval")) {
              if (answer.approversName.hasOwnProperty("value") ) {
                answerObject[element.key] = answer.approversName.label
              } else {
                answerObject[element.key] = answer.approversName
              }
          } else if (answer?.hasOwnProperty("diameterOverTwoHundred")) {
            if (answer.approversName.hasOwnProperty("value") ) {
              answerObject[element.key] = answer.approversName.value
            } else {
              answerObject[element.key] = answer.approversName
            }
            if (answer.solutionFactoryName !== "" && answer.solutionFactoryName !== undefined) {
              answerObject[`${element.key}.solutionFactoryName`] = answer.solutionFactoryName.label
            }
          } else {
            // Is distr list or deleg list
            if (element.key.includes("distribution") || element.key.includes("delegation")) {
              answerObject[element.key] = answer.map( object => object.label).join(", ")
            }
          }
        } else {
          if (!element.key.includes("countryCompanyLocation.company") && !element.key.includes("countryCompanyLocation.location")) {
            answerObject[element.key] = data[element.key]
          }
        }
    })
  }
  return answerObject
}

export function fixReportDate(config, data){
  let answerObject = {}

  if (config !== undefined) {
    config.forEach(element => {
      let answer = data[element.key]
      if (typeof(answer) === "object" && answer !== null) {
        if (answer.hasOwnProperty("date")) {
          data[element.key] = answer.date
        }
      }
    })
  }
  return data
}

export function formatRollingElementsHeader(data, index, headerName, scrollTarget) {
  if (!headerName.includes('Rolling elements - Row')) {
    return headerName
  }

  const rollingElementsRows = new Set()

  const { fieldTarget } = scrollTarget

  for (const [key, value] of Object.entries(data)) {
    if (fieldTarget?.includes(`bearingInvestigations.bearingInvestigation[${index}].componentPartsInvestigation.rollingElements`)) {
      rollingElementsRows.add(fieldTarget)
    }

    if (!key.includes(`bearingInvestigations.bearingInvestigation[${index}].componentPartsInvestigation.rollingElements`)) {
      continue
    }

    if (_.some(value, (el, k) => !_.isEmpty(el) && k !== 'severity')) {
      rollingElementsRows.add(key)
    }
  }

  return rollingElementsRows.size <= 1 ? 'Rolling elements' : headerName
}

export function findDomainFromTemplateId(templateId) {
  for (const key in config) {
    if (config[key] === templateId) {
      if (key.startsWith('BART')) {
        return 'BDA';
      }
      if (key.startsWith('WIND')) {
        return 'Wind';
      }
      if (key.startsWith('GBI')) {
        return 'GBI';
      }
      if (key.startsWith('US')) {
        return 'NAMInspection'
      }
      if (key.startsWith('INSPECTION')) {
        return 'Inspection';
      }
    }
  }
  return null;
}