import React from "react";

const SvgLock = props => (
  <svg width={10} height={12} viewBox="0 0 10 12" {...props}>
    <path
      d="M7.5 4h-5v-.5C2.5 2.122 3.622 1 5 1s2.5 1.122 2.5 2.5V4zm-2 3.847V9.5a.5.5 0 01-1 0V7.847A.989.989 0 014 7a1 1 0 012 0 .99.99 0 01-.5.848zM9.5 4h-1v-.5C8.5 1.57 6.93 0 5 0S1.5 1.57 1.5 3.5V4h-1a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5z"
      fill={props.svgColor !== undefined ? props.svgColor : 'currentColor'}
      fillRule="evenodd"
    />
  </svg>
);

export default SvgLock;
