import {
  POPULATE_FILTERS,
  TOGGLE_FILTER_GROUP_IS_OPEN,
  SEARCH_FILTER_ITEM
} from 'redux/types'

const populateFilters = payload => ({
  type: POPULATE_FILTERS,
  payload 
})

const toggleFilterGroupIsOpen = payload => ({
  type: TOGGLE_FILTER_GROUP_IS_OPEN,
  payload
})

const searchFilterItem = (groupName, searchValue) => ({
  type: SEARCH_FILTER_ITEM,
  groupName,
  searchValue
}) 

export {
  populateFilters,
  toggleFilterGroupIsOpen,
  searchFilterItem
}
