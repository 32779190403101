import React, { useEffect, useState } from "react";
import getComponent from "../../_Case/ComponentsList";
import _ from "lodash";

import { removeRequiredListKeys } from "redux/actions/requiredListActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux"

const OtherInvestigationDetailsComponent = ({ myName, getValue, setValue, formComponentData }) => { 
  const dispatch = useDispatch();
  const defaultState = { skfCaseNumber: "", purpose: "", scopeAndPurpose: "", investigationDetailsText: "", investigationDetailsFigure: "", customerRequestedDate: " ", describePurposeOfBearing: "", country: "", customer: "", site: "", plantOrTrainOrShipName: "", customerType: "", customerContactName: "", customerContactJobTitle: "", customerContactEmail: "", customerContactPhone: "" };
  const [state, setState] = useState(defaultState);
  const [isOther, setIsOther] = useState(false);
  const gbiTypeAnswer = useSelector(state => state.saveComponentValue.components.find(a => a.name === "TERorAMFRorOther"), shallowEqual);

  useEffect(() => { 
    if (gbiTypeAnswer !== undefined) {
      let parsedResult = JSON.parse(gbiTypeAnswer.value)
      if (parsedResult.GBIReportType.value === "Other") {
        setIsOther(true);
      } else {
        setIsOther(false);
        dispatch(removeRequiredListKeys([`${myName}.customer`]));
      }
    } else {
      setIsOther(false);
      dispatch(removeRequiredListKeys([`${myName}.customer`]));
    }
  }, [gbiTypeAnswer]);

  useEffect(() => { 
    let result = getValue(myName);
    if (result) {
      result = {
        ...result,
        investigationDetailsText: result.investigationDetailsText ? result.investigationDetailsText : "",
        investigationDetailsFigure: result.investigationDetailsFigure ? result.investigationDetailsFigure : ""
      }
      setState(result);
    } else { 
      setState(defaultState);
    }
  }, [getValue, myName]);

  const handleChange = (value, name) => { 
    let resultName = name.split(".").pop();
    let objectToSave = { ...state };
    objectToSave[resultName] = value;
    setState(objectToSave);
    setValue(objectToSave, myName);
  }

  const renderOther = (formComponent, index) => { 
    return <React.Fragment key={index}>{getComponent(
      formComponent,
      `${myName}.${formComponent.key}`,
      handleChange,
      state[formComponent.key]
    )}</React.Fragment>
  }

  return (
    isOther ? 
    <>
      {formComponentData.args.map((formComponent, index) => {
        return renderOther(formComponent, index);
      })}
    </>
    : null
  );

}

export default OtherInvestigationDetailsComponent;

