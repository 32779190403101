import { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { populateCases, toggleCasesLoading } from 'redux/actions/casesActions'
import { populateFilters } from 'redux/actions/filterActions';
import { pageSize } from 'components/common/Pagination';
import queryString from 'query-string'
import repository from 'core/repository';
import useBus from 'use-bus'

let prevSearch = ''

const useCaseSearch = (URLSearchQuery, showOnlyMyCases) => {
  const [hasExecutedInitialSearch, setHasExecutedInitialSearch] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [iterations, setIterations] = useState(0)

  useBus(
    '@@ui/MANUAL_DATA_GET',
      () => setIterations(iterations + 1),
    [iterations],
  )

  const dispatch = useDispatch()

  useEffect(() => {
    const isNewSearchQuery = URLSearchQuery !== prevSearch
    
    if (isNewSearchQuery || !hasExecutedInitialSearch || iterations > 0) {
      const URLSearch = queryString.parse(URLSearchQuery)
      let params = { limit: pageSize, order: URLSearch.order || '-updated' }

      if (params.order.includes('id')) { // Api breaks if order on id, order on created instead
        params = {
          ...params,
          order: params.order.slice(0, 1) === '-' ? '-created' : 'created'
        }
      }
      
      if (URLSearch.filters) {
        params = {
          ...params,
          filters: decodeURI(URLSearch.filters)
        }
      }
      
      if (URLSearch.q) {
        params = {
          ...params,
          q: decodeURI(URLSearch.q)
        }
      }

      if (typeof showOnlyMyCases !== 'undefined') {
        params = {
          ...params,
          showonlymycases: showOnlyMyCases
        }
      }
      
      const URLSearchPage = URLSearch.page ? Number(URLSearch.page) : 1
      if (URLSearchPage > 1 && URLSearchPage !== currentPage) {
        params = {
          ...params,
          from: (URLSearchPage * pageSize) - pageSize 
        }
      }
      
      if (!hasExecutedInitialSearch) {
        setHasExecutedInitialSearch(true)
      }

      setCurrentPage(URLSearchPage)
      prevSearch = URLSearchQuery

      if (iterations > 0) {
        setTimeout(() => {
          searchForCases(params)
        }, 1000)
      } else {
        searchForCases(params)
      }
    }
  }, [URLSearchQuery, iterations])

  const searchForCases = params => {
    dispatch(toggleCasesLoading(true))

    repository.getCaseSearch(params)
      .then(response => {
        dispatch(populateCases(response.data.hits, response.data.total))
        dispatch(populateFilters(response.data.filters))
      })
      .catch(error => {
        // TODO: Exchange alert with error modal
        console.log('Could not fetch reports succesfully')
        dispatch(populateCases([], 0))
        dispatch(populateFilters({}))
      })
  }

  return currentPage
}

export default useCaseSearch

