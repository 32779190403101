import React from 'react'
import SkfLogo from 'components/svg/SkfLogoLivePreview'

const LivePreviewHeader = () => {
  return (
    <header className="live-preview-header">
      <SkfLogo className="live-preview-header__logo" />
    </header>

  )
}

export default LivePreviewHeader
