import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import classnames from "classnames";

import SvgButtonBarShareImages from "../../svg/ButtonBarShareImages"
import shareImages, { getImageUrlsFromAnswers } from "../ButtonActions/shareImages";
import { isIOS, isAndroid } from "react-device-detect";

class ButtonDownloadImages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: [],
            filestoDownload: [],
            case: {},
            isDownloadingImages: false,
        }
    }

    componentDidMount() {
        this.setState({
            answers: this.props.answers,
            case: this.props.currentCase,

        });
        this.setStateFromProps();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCase != this.props.currentCase) {
            this.setStateFromProps();
        }
        if (prevProps.currentCase || this.props.currentCase) {
            if (prevProps.currentCase.revision_number != this.props.currentCase.revision_number) {
                this.setStateFromProps();
            }
        }
        if (prevProps.answers != this.props.answers) {
            this.setStateFromProps();
        }
        if (prevProps.excludeISOAppendix != this.props.excludeISOAppendix) {
            this.setStateFromProps();
        }
    }

    hasImages = (answers) => {
        const result = getImageUrlsFromAnswers(answers);
        return result.length > 0;
    }

   
    setStateFromProps() {
        this.setState({
            case: this.props.currentCase,
            answers: this.props.answers,
            revisionNumber: this.props.currentCase.revision_number,
            excludeISOAppendix: this.props.excludeISOAppendix
        });
    }

    onDownloadImages = () => {
        this.setState({ isDownloadingImages: true })
        shareImages(this.state.answers, this.state.case, this.props.intl).then(res => {
            console.log(res)
            this.setState({ isDownloadingImages: false });
        }).catch(err => {
            this.setState({ isDownloadingImages: false });
            console.log(err)
        });
    }

    isVisible = () => !(isIOS || isAndroid);
    isLoading = () => this.state.isDownloadingImages;
    isDisabled = () => !this.props.connection || !this.hasImages(this.props.answers);


    render() {
        return this.isVisible() && (
            <>
                <button
                    id="buttonDownloadPdf"
                    type="button"
                    onClick={() => this.onDownloadImages()}
                    className={classnames("button", "button--icon-only", { "button--icon-saving": this.isLoading() }, { "button--icon-disabled": this.isDisabled() })}
                    disabled={this.isDisabled()}>
                    <SvgButtonBarShareImages size={this.props.buttonSize} title={this.props.intl.formatMessage({id: "formRenderer.downloadImages"}) } />
                </button>
            </>
        ) 
    }
}

function mapStateToProps(state) {
    return {
        currentCase: state.currentCase.case,
        answers: state.saveComponentValue.components,
        connection: state.connection,
        language: state.language,
        excludeISOAppendix: state.excludeISOAppendix
    };
}

export default injectIntl(connect(mapStateToProps)(ButtonDownloadImages));