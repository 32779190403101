import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

const ReportGeneralText = props => {
  const generalText = `
  ${props.intl.formatMessage({
    id: "reportGeneralText.skfGeneralConditions"
  })}<br><p class="live-preview__general-paragraph">
  ${props.intl.formatMessage({
    id: "reportGeneralText.skfGeneralConditionsText1"
  })}<a href="${props.intl.formatMessage({
    id: "reportGeneralText.skfGeneralConditionsText2"
  })} "target=_blank ">${props.intl.formatMessage({
    id: "reportGeneralText.skfGeneralConditionsText3"
  })}</a>${props.intl.formatMessage({
    id: "reportGeneralText.skfGeneralConditionsText4"
  })}</p>
  ${props.intl.formatMessage({
    id: "reportGeneralText.trademarkText"
  })}<br>© ${new Date().getFullYear()} 
  ${props.intl.formatMessage({
    id: "reportGeneralText.trademarkSkfGroup"
  })}<br><p class="live-preview__general-paragraph">
  ${props.intl.formatMessage({
    id: "reportGeneralText.theContentsOfThisReport"
  })}</p>
  ${props.intl.formatMessage({
    id: "reportGeneralText.noticeOfConfidentiality"
  })}<br><p class="live-preview__general-paragraph">
  ${props.intl.formatMessage({
    id: "reportGeneralText.noticeOfConfidentialityText"
  })}</p>`;
  return (
    <div
      className="live-preview__general-text"
      dangerouslySetInnerHTML={{ __html: generalText }}
    ></div>
  );
};

ReportGeneralText.propTypes = {
  data: PropTypes.string
};

export default injectIntl(ReportGeneralText);
