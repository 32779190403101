import React from "react";

const SvgSettings = props => (
  <svg width={props.size || 24} height={props.size || 24} viewBox={`0 0 ${props.size || 24} ${props.size || 24}`} {...props}>
    <path
      d="M11.5 15.95a4.366 4.366 0 01-4.36-4.362A4.365 4.365 0 0111.5 7.23a4.365 4.365 0 014.36 4.358 4.366 4.366 0 01-4.36 4.362m10.188-2.075l-1.581-.914a8.647 8.647 0 00-.003-2.742l1.585-.917a.625.625 0 00.229-.85l-2.492-4.315a.624.624 0 00-.85-.229l-1.577.912a8.648 8.648 0 00-2.385-1.368V1.623A.624.624 0 0013.991 1H9.007a.624.624 0 00-.623.623v1.829a8.723 8.723 0 00-2.378 1.371L4.424 3.91a.62.62 0 00-.85.23l-2.49 4.314a.624.624 0 00.227.85l1.573.91a8.674 8.674 0 00.011 2.747l-1.584.915a.626.626 0 00-.228.85l2.492 4.317c.171.3.553.4.85.228l1.579-.91a8.552 8.552 0 002.38 1.368v1.828c0 .344.28.622.623.622h4.984a.623.623 0 00.623-.622v-1.83a8.588 8.588 0 002.37-1.372l1.59.918c.295.17.678.07.85-.23l2.492-4.315a.627.627 0 00-.228-.852"
      stroke={"#FFF"}
      fill={"none"}
    />
  </svg>
);

export default SvgSettings;
