import React, { Fragment, useEffect, useState } from "react";
import FeatureDetector from './FeatureDetector';


const featureToggles = require("./../../Configs/featureToggles.json");

{/* 
    Example of usage
    <FeatureToggle feature="feature1">
        <FeatureOn>
            <h1>Feature 1 On</h1>
        </FeatureOn>
        <FeatureOff>
            <h1>Feature 1 Off</h1>
        </FeatureOff>
    </FeatureToggle>
    <FeatureToggle feature="feature2">
        <FeatureOn>
            <h1>Feature 2 On</h1>
        </FeatureOn>
        <FeatureOff>
            <h1>Feature 2 Off</h1>
        </FeatureOff>
    </FeatureToggle> 

    with config

    {
    "features":[
            {
                "name": "feature1",
                "featureEnabled": false
            },
            {
                "name": "feature2",
                "featureEnabled": true
            }
        ]
    }
*/}

export const FeatureToggle = (props) => {
    const { children, feature } = props;
    const [featureToggleEnabled, setFeatureToggleEnabled] = useState("");

    // this FeatureToggle used to cause an infinite loop because of calling `setFeatureToggleEnabled` inside the component's body
    // added this useEffect for handling this side effect
    useEffect(() => {
        const featureDetector = new FeatureDetector(featureToggles)

        featureDetector.isFeatureEnabled(feature).then((res) => {
            if (res) {
                console.log("Feature " + feature + " is enabled")
                setFeatureToggleEnabled(res.every((i) => i))
            } else {
                console.log("Feature " + feature + " is disabled")
            }
        })
    }, [feature])

    const toggledChildren = React.Children.map(children, child =>
        React.cloneElement(child, { featureToggleEnabled })
    );
    
    return (
        featureToggleEnabled !== undefined ?
            <Fragment>
                {toggledChildren}
            </Fragment>
        : null
    );
}

export const FeatureOn = ({ children, featureToggleEnabled }) => (
    <Fragment>
        {featureToggleEnabled && children}
    </Fragment>
);
   
export const FeatureOff = ({ children, featureToggleEnabled }) => (
    <Fragment>
        {!featureToggleEnabled && children}
    </Fragment>
);

