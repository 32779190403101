import React, { useState } from "react";
import PropTypes from "prop-types";
import BaseButton from "components/common/BaseButton";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import { withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setIsSaving } from "redux/actions/isSavingActions";
import { getCaseDataRepositoryWithoutOffline } from "core/caseRepository/caseRepositoryFactory";
import DuplicateCaseModal from "./DuplicateCaseModal";
import ReactModal from "react-modal";

const TableCellOverlay = ({ caseId, history, status, rowPrefix }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [duplicatedCaseId, setDuplicatedCaseId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  ReactModal.setAppElement("#app");

  
  const closeOverlay = event => {
    if (event !== undefined) {
      event.stopPropagation();
    }

    const row = document.querySelector(`[data-row-id="${!rowPrefix ? caseId : (rowPrefix + caseId)}"]`);
    row.classList.remove("table__body-row--is-active");
  };

  const clearOverlays = () => {
    const overlays = document.querySelectorAll(".table__body-row--is-active");

    overlays.forEach(row => {
      row.classList.remove("table__body-row--is-active");
    });
  };

  const formatURL = (url, clonedCaseId) => {
    if (!url || !clonedCaseId) return url;

    let formattedUrl = url;

    const regexPatterns = [
      { pattern: /\/my-reports(\?.*)?$/, replacement: `/case/${clonedCaseId}` },
      { pattern: /\/all-reports(\?.*)?$/, replacement: `/case/${clonedCaseId}` },
      { pattern: /\/dashboard(\?.*)?$/, replacement: `/case/${clonedCaseId}` },
    ];

    regexPatterns.forEach(({ pattern, replacement }) => {
      if (url.match(pattern)) {
        formattedUrl = url.replace(pattern, replacement);
      }
    });

    return formattedUrl;
  }

  const duplicateCase = async (event, id) => {
    event.stopPropagation();

    const repository = getCaseDataRepositoryWithoutOffline();
    try {
      dispatch(setIsSaving(true))
      const clonedCaseResponse = await repository.cloneCase(id);
      setDuplicatedCaseId(clonedCaseResponse.data.id);
      clearOverlays();
    } catch (e) {
      console.log('There was an error duplicating case ' + id, e);
      alert('There was an error duplicating case:\n' + e);
    } finally {
      dispatch(setIsSaving(false));
    }
  };

  const redirectToCase = event => {
    event.stopPropagation();

    history.push("/case/" + caseId);
  };

  return (
    <div className="table__body-overlay-element">
      {showModal && duplicatedCaseId && (
        <DuplicateCaseModal
          duplicatedCaseId={duplicatedCaseId}
          setShowModal={setShowModal}
          showModal={showModal}
          formatURL={formatURL}
        />
      )}
      <BaseButton
        text={intl.formatMessage({
          id: "tableCellOverlay.openReport"
        })}
        backgroundColor="green"
        handleSubmit={event => {
          redirectToCase(event);
        }}
      />

      <BaseButton
        text={intl.formatMessage({
          id: "tableCellOverlay.duplicate"
        })}
        backgroundColor="gray"
        handleSubmit={(e) => {
          setShowModal(true);
          duplicateCase(e, caseId);
        }}
      />

      <BaseButton
        className="table__body-overlay-element-close"
        iconComponent={<CircleCloseFilterSvg />}
        handleSubmit={event => closeOverlay(event)}
      />
    </div>
  );
};

TableCellOverlay.propTypes = {
  caseId: PropTypes.string,
  status: PropTypes.string
};

export default withRouter(TableCellOverlay);
