import React, { Component } from "react";
import { fabric } from "fabric";
import ReactModal from "react-modal";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import SvgCanvasZoom from "components/svg/CanvasZoom";
class FailurePredictionCanvas extends Component { 
    constructor(props) {
        super(props);
        this.canvas = "";
        this.zoomCanvas = "";
        this.includeCanvas = ""

        this.canvasRef = React.createRef();
        this.zoomCanvasRef = React.createRef();
        this.includeCanvasRef = React.createRef();
        
        this.canvasWidth = 277.5;
        this.state = {
            showZoom: false
        }
    }

    componentDidMount() { 
        this.createPreviewCanvas();
        this.createZoomCanvas();
        this.createIncludeCanvas();
    }

    componentDidUpdate(prevProps) { 
        if (prevProps.includePredictions && this.props.includePredictions) { 
            if (prevProps.includePredictions.length !== this.props.includePredictions.length) { 
                this.createIncludeCanvas();
            }
        }
    }

    createIncludeCanvas = () => {
        this.createCanvasWithText(this.includeCanvasRef, this.includeCanvas, this.props.includePredictions, this.props.onZoomCanvas);
    }

    createZoomCanvas = () => {
        this.createCanvasWithText(this.zoomCanvasRef, this.zoomCanvas, this.props.predictions);
    }

    createCanvasWithText = (canvasRef, canvas, predictions, canvasEvent) => { 
        const isMobile = window.matchMedia("( max-width:  768px )").matches;

        const height = Math.round(window.innerHeight * 0.8);
        const width = Math.round(window.innerWidth * 0.8);
        canvas = new fabric.Canvas(canvasRef.current);

        let imgObj = new Image();
        imgObj.crossOrigin = "anonymous";
        imgObj.src = this.props.imageUrl + "?1";
        
        imgObj.onerror = function (e) {
            console.log(e);
        };

        imgObj.onload = function () {
            let image = new fabric.Image(imgObj);
            let scale = 1;
             const aspectRatio = image.width / image.height;
             if (aspectRatio > 1.4 || isMobile) {
                 //IS landscape
                 scale = this.scaleBgImageToWidthAndGetScale(image, canvas, width);
             } else {
                 //IS portrait
                 scale = this.scaleBgImageToHeightAndGetScale(image, canvas, height);
            }

            predictions.forEach(prediction => this.addBoundingBoxRectToCanvas(prediction, scale, canvas, true));

            if (canvasEvent) {
                canvasEvent(canvas);
            }

        }.bind(this);
    }

    createPreviewCanvas = () => {
        const isMobile = window.matchMedia("( max-width:  768px )").matches;

        let width = this.canvasWidth;
        if (isMobile) { 
            width = Math.round(window.innerWidth * 0.6);
        }

        this.canvas = new fabric.Canvas(this.canvasRef.current, {
            width: width
        });

        this.canvas.selection = false;
        let imgObj = new Image();
        imgObj.crossOrigin = "anonymous";
        imgObj.src = this.props.imageUrl + "?1";
        imgObj.onerror = function(e) {
            console.log(e);
        };
        imgObj.onload = function() {
            /* Add image to canvas */
            var image = new fabric.Image(imgObj);
            image.crossOrigin = "anonymous";
            const scale = this.scaleBgImageToWidthAndGetScale(image, this.canvas, width);
            this.props.predictions.forEach(prediction => this.addBoundingBoxRectToCanvas(prediction, scale, this.canvas, false));
            this.canvas.renderAll();
        }.bind(this);
    }

    scaleBgImageToHeightAndGetScale = (image, canvas, height) => {
        canvas.setHeight(height);
        image.scaleToHeight(height);

        const aspectRatio = image.width / image.height;
        const newWidth = height * aspectRatio;

        image.scaleToWidth(Math.round(newWidth));
        canvas.setWidth(Math.round(newWidth));
        canvas.setBackgroundImage(image);

        return  newWidth / image.width;;
    }
    scaleBgImageToWidthAndGetScale = (image, canvas, width) => {
        canvas.setWidth(width);
        image.scaleToWidth(width);

        const aspectRatio = image.width / image.height;
        const newHeight = width / aspectRatio;

        image.scaleToHeight(Math.round(newHeight));
        canvas.setHeight(Math.round(newHeight));
        canvas.setBackgroundImage(image);

        return newHeight / image.height;
    }

    isRelativeBoundingBoxes = boundingBox => {
        return boundingBox.height < 1 && boundingBox.width < 1 && boundingBox.x < 1 && boundingBox.y < 1;
    }

    addBoundingBoxRectToCanvas = (prediction, scale, canvas, addText) => {
        //This allows us to handle both relative and non-relative bounding boxes.
        let widthScale = scale, heightScale = scale;
        if (this.isRelativeBoundingBoxes(prediction.boundingBox)) {
            widthScale = canvas.width;
            heightScale = canvas.height;
        } 

        const top = prediction.boundingBox.y * heightScale;
        const left = prediction.boundingBox.x * widthScale;
        const width = prediction.boundingBox.width * widthScale;

        const rect = new fabric.Rect({
            top : top,
            left : left,
            width : width,
            height : prediction.boundingBox.height * heightScale,
            stroke: prediction.color,
            strokeWidth: 4,
            fill: '',
            selectable: false
        });
        canvas.add(rect);

        if (addText) {
            const padding = 5;
            const offset = 5;
            const fontsize = 24;
            const indicatorWidth = 5;

            let text = new fabric.Text(prediction.failureText, {
                left: left + padding + indicatorWidth,
                top: (top - fontsize - offset - padding),
                fontFamily: 'skf_sans_light',
                fontSize: fontsize,
                stroke: "#000000",
                selectable: false
            });

            const textWidth = text.width;
            const rightPoint = text.left + text.width + padding;
            if (rightPoint > canvas.width) {
                //Is outside of canvas to the right
                let text = new fabric.Text(prediction.failureText, {
                    left: (left + width) - textWidth,
                    top: (top - fontsize - offset - padding),
                    fontFamily: 'skf_sans_light',
                    fontSize: fontsize,
                    stroke: "#000000",
                    selectable: false
                });

                const foregroundRect = new fabric.Rect({
                    left: (text.left + text.width),
                    top: (top - fontsize - padding * 2 - offset),
                    width: indicatorWidth,
                    height: text.height + padding * 2,
                    fill: prediction.color,
                    selectable: false
                });

                const backgroundRect = new fabric.Rect({
                    left: text.left - padding,
                    top : (top - fontsize - padding * 2 - offset),
                    width: text.width + padding,
                    height: text.height + padding * 2,
                    fill: "#ffffff",
                    selectable: false
                })

                canvas.add(backgroundRect);
                canvas.add(foregroundRect);
                canvas.add(text);

            } else {
                const foregroundRect = new fabric.Rect({
                    left: left,
                    top: (top - fontsize - padding * 2 - offset),
                    width: indicatorWidth,
                    height: text.height + padding * 2,
                    fill: prediction.color,
                    selectable: false
                });
    
                const backgroundRect = new fabric.Rect({
                    left: left + indicatorWidth,
                    top : (top - fontsize - padding * 2 - offset),
                    width: text.width + padding * 2,
                    height: text.height + padding * 2,
                    fill: "#ffffff",
                    selectable: false
                })
    
                canvas.add(foregroundRect);
                canvas.add(backgroundRect);
                canvas.add(text);
            }

           
        }
    }

    openZoom = () => {
        this.setState({ showZoom: true });
    }

    closeZoom = () => {
        this.setState({ showZoom: false });
    }

    render() {
        return (
            <>
                <div style={{ "position": "relative" }}>
                    {this.props.predictions ? (
                        <>
                            <canvas ref={this.canvasRef} width={this.canvasWidth} />
                            
                        </>
                    ) : null}
                    <div onClick={() => this.openZoom()} className="overlay" style={window.matchMedia("( max-width:  768px )").matches ? { width: `${Math.round(window.innerWidth * 0.6)}px`, left: 'auto' } : {} }>
                        <SvgCanvasZoom />
                    </div>
                    <div className="ai-canvas-include">
                        <canvas ref={this.includeCanvasRef} height={Math.round(window.innerHeight * 0.8)} />
                    </div>
                    
                </div>
                <ReactModal
                    isOpen={this.state.showZoom}
                    className="react-modal ai-modal-zoom-container"
                    overlayClassName="react-modal-overlay" onAfterOpen={ this.createZoomCanvas}>
                    <div className="react-modal__body ai-modal-zoom__body">
                        <div className="image">
                            <div>
                                {this.props.predictions ? (
                                    <canvas ref={this.zoomCanvasRef} height={Math.round(window.innerHeight * 0.8)} />
                                ) : null}

                            </div>
                            <button className="react-modal__close-button" onClick={() => { this.closeZoom(); }}>
                                <CircleCloseFilterSvg />
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </>
        );
      }
}

export default FailurePredictionCanvas;