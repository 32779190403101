import React from "react";

const SvgCheck = props => {
  const computedProps = { ...props }
  delete computedProps['strokeColor']
  delete computedProps['fillColor']
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox={`0 0 ${props.size || 24} ${props.size || 24}`} {...computedProps}>
      <path d="M29.135 33.2981H3.59741C1.67412 33.2981 0.115021 31.739 0.115021 29.8157V4.27817C0.115021 2.35487 1.67412 0.795776 3.59741 0.795776H29.135C31.0583 0.795776 32.6174 2.35487 32.6174 4.27817V29.8157C32.6174 31.739 31.0583 33.2981 29.135 33.2981Z"
        fill={props.fillColor || "#88C008"} />
      <path d="M14.285 25.3133L27.6342 11.9641C28.0875 11.5108 28.0875 10.7758 27.6342 10.3226L25.9926 8.68097C25.5393 8.22768 24.8043 8.2276 24.351 8.68097L13.4642 19.5677L8.38142 14.4849C7.92813 14.0316 7.19313 14.0316 6.73976 14.4849L5.09818 16.1265C4.64489 16.5798 4.64489 17.3148 5.09818 17.7681L12.6434 25.3132C13.0967 25.7667 13.8317 25.7667 14.285 25.3133Z"
        fill={props.strokeColor || "#FFF"} />
    </svg>
  )
}

export default SvgCheck;
