const BartConfig = [
  {
    key: 'reportDetails.reportDate',
    isRequired: false,
    sortOrder: 0
  },
  {
    key: 'reportDetails.reportTitle',
    isRequired: true,
    sortOrder: 1
  },
  {
    key: 'reportDetails.skfCaseNumber',
    isRequired: false,
    sortOrder: 2
  },
  {
    key: 'reportDetails.terNumber',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'reportAuthor',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'coReportAuthor',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'investigationDetails.rmaNumber',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'investigationDetails.purpose',
    isRequired: false,
    sortOrder: 5
  },
  {
    key: 'investigationDetails.scopeOfInvestigation',
    isRequired: false,
    isFullWidth: true,
    sortOrder: 6,
    isRichText: true
  },
  {
    key: 'investigationDetails.overallBackgroundInformation',
    isRequired: false,
    isFullWidth: true,
    sortOrder: 7,
    isRichText: true
  },
  {
    key: 'investigationDetails.numberOfBearingsInvestigated',
    isRequired: false,
    sortOrder: 8
  },
  {
    key: 'skfDetails.countryCompanyLocation',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'skfDetails.countryCompanyLocation.company',
    isRequired: false,
    sortOrder: 8
  },
  {
    key: 'skfDetails.countryCompanyLocation.location',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'skfDetails.reportAuthor',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.coReportAuthor',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.approver.personalInfo',
    isRequired: false,
    sortOrder: 13
  },
  {
    key: 'skfDetails.reportNeedsApproval',
    isRequired: false,
    sortOrder: 16
  },
  {
    key: 'skfDetails.approversName',
    isRequired: false,
    sortOrder: 17
  },
  {
    key: 'skfDetails.distributionListHeader',
    isRequired: false,
    sortOrder: 18
  },
  {
    key: 'skfDetails.distributionList',
    isRequired: false,
    sortOrder: 19
  },
  {
    key: 'skfDetails.delegationListHeader',
    isRequired: false,
    sortOrder: 20
  },
  {
    key: 'skfDetails.delegationList',
    isRequired: false,
    sortOrder: 21
  },
  {
    key: 'customerDetails.customer',
    isRequired: true,
    sortOrder: 22
  },
  {
    key: 'customerDetails.sectorSegment.sector',
    isRequired: false,
    sortOrder: 34
  },
  {
    key: 'customerDetails.sectorSegment.segment',
    isRequired: false,
    sortOrder: 35
  },
  {
    key: 'customerDetails.sectorSegment.industry',
    isRequired: false,
    sortOrder: 36
  },
  {
    key: 'customerDetails.customerAddress',
    isRequired: false,
    sortOrder: 23
  },
  {
    key: 'customerDetails.customerCityStateTerritory',
    isRequired: false,
    sortOrder: 24
  },
  {
    key: 'customerDetails.customerPostCode',
    isRequired: false,
    sortOrder: 25
  },
  {
    key: 'customerDetails.customerCountry',
    isRequired: false,
    sortOrder: 26
  },
  {
    key: 'customerDetails.site',
    isRequired: true,
    sortOrder: 27
  },
  {
    key: 'customerDetails.plantOrTrainOrShipName',
    isRequired: false,
    sortOrder: 28
  },
  {
    key: 'customerDetails.customerContact.name',
    isRequired: false,
    sortOrder: 30
  },
  {
    key: 'customerDetails.customerContact.jobTitle',
    isRequired: false,
    sortOrder: 31
  },
  {
    key: 'customerDetails.customerContact.email',
    isRequired: false,
    sortOrder: 32
  },
  {
    key: 'customerDetails.customerContact.phone',
    isRequired: false,
    sortOrder: 33
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.machineOrAssetCode',
    isRequired: true,
    sortOrder: 34
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.assetDescription',
    isRequired: true,
    sortOrder: 29
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.assetFigure',
    isRequired: true,
    sortOrder: 29
  },
  {
    key: "assetDetails.assetTypeOrFunctionalAreaOrSystem.analysisImages",
    isRequired: false,
    sortOrder: 41,
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.functionalAreaName',
    isRequired: true,
    sortOrder: 35
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.systemNameWhereAssetIsUsed',
    isRequired: true,
    sortOrder: 36
  },
  {
    key: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.purposeOfAsset',
    isRequired: false,
    sortOrder: 37
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.oemName',
    isRequired: false,
    sortOrder: 38
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.OemModelNumber',
    isRequired: false,
    sortOrder: 39
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.serialNumber',
    isRequired: false,
    sortOrder: 40
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.activeSince',
    isRequired: false,
    sortOrder: 41
  },
  {
    key: 'assetDetails.MachineOrAssetDetails.endUserTagNumber',
    isRequired: false,
    sortOrder: 42
  },
  {
    key: 'summary.conclusions',
    isRequired: false,
    imageIsNext: true,
    sortOrder: 43,
    isRichText: true
  },
  {
    key: 'summary.conclusionImages',
    isRequired: false,
    sortOrder: 44,
    isRichText: true
  },
  {
    key: 'summary.recommendations',
    isRequired: true,
    isFullWidth: true,
    sortOrder: 45,
    isRichText: true
  },
  {
    key: 'summary.recommendationsOptions',
    isRequired: false,
    sortOrder: 46
  },
  {
    key: 'summary.equipmentUsed',
    isRequired: false,
    sortOrder: 47
  },
  {
    key: "bearingInvestigations.bearingType.bearingTypeSelect",
    isRequired: false,
    sortOrder: 0
  },
  {
    key: "bearingInvestigations.bearingType.bearingSkfCustomerDescription",
    isRequired: false,
    sortOrder: 1,
    isRichText: true
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent",
    isRequired: false,
    sortOrder: 2
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.manufacturer",
    isRequired: false,
    sortOrder: 3
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.skfBearingDesignation",
    isRequired: false,
    sortOrder: 3
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.SKFManufacturer",
    isRequired: false,
    sortOrder: 4
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.competitorDesignationAndMarkings",
    isRequired: false,
    sortOrder: 5
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.skfSN",
    isRequired: false,
    sortOrder: 6
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.skfEquivalent",
    isRequired: false,
    sortOrder: 6
  },
  {
    key: "bearingInvestigations.bearingType.customerReferencePartNumber",
    isRequired: false,
    sortOrder: 7
  },
  {
    key: "bearingInvestigations.bearingType.allPartsAvailable",
    isRequired: false,
    sortOrder: 8
  },
  {
    key: "bearingInvestigations.manufacturing.skfCompany",
    isRequired: false,
    sortOrder: 9
  },
  {
    key: "bearingInvestigations.manufacturing.skfCompany.company",
    isRequired: false,
    sortOrder: 10
  },
  {
    key: "bearingInvestigations.manufacturing.skfCompany.location",
    isRequired: false,
    sortOrder: 11
  },
  {
    key: "bearingInvestigations.manufacturing.manufacturingDateCode",
    isRequired: false,
    sortOrder: 12
  },

  {
    key: "bearingInvestigations.backgroundInformation.backgroundInformationText",
    isRequired: false,
    isFullWidth: true,
    sortOrder: 13,
    isRichText: true
  },
  {
    key: "bearingInvestigations.backgroundInformation.backgroundInformationImages",
    isRequired: false,
    sortOrder: 14,
  },
  {
    key: "bearingInvestigations.backgroundInformation.recurringFailure",
    isRequired: false,
    sortOrder: 15
  },
  {
    key: "bearingInvestigations.backgroundInformation.lengthOfBearingOperated",
    isRequired: false,
    sortOrder: 16
  },
  {
    key: "bearingInvestigations.backgroundInformation.operation",
    isRequired: false,
    sortOrder: 17
  },
  {
    key: "bearingInvestigations.backgroundInformation.operation.operationNumber",
    isRequired: false,
    sortOrder: 18
  },
  {
    key: "bearingInvestigations.backgroundInformation.expectedLife",
    isRequired: false,
    sortOrder: 19
  },
  {
    key: "bearingInvestigations.backgroundInformation.costOfFailure",
    isRequired: false,
    sortOrder: 20
  },
  {
    key: "bearingInvestigations.backgroundInformation.reasonWasRemoved",
    isRequired: false,
    sortOrder: 21
  },
  {
    key: "bearingInvestigations.operatingData.railwayWheelsetBearing",
    isRequired: false,
    sortOrder: 21
  },
  {
    key: "bearingInvestigations.operatingData.rotationalSpeed",
    isRequired: false,
    sortOrder: 22
  },
  {
    key: "bearingInvestigations.operatingData.ringRotation",
    isRequired: false,
    sortOrder: 23
  },
  {
    key: "bearingInvestigations.operatingData.temperature",
    isRequired: false,
    sortOrder: 24
  },
  {
    key: "bearingInvestigations.operatingData.radialLoad",
    isRequired: false,
    sortOrder: 25
  },
  {
    key: "bearingInvestigations.operatingData.axialLoad",
    isRequired: false,
    sortOrder: 26
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType",
    isRequired: false,
    sortOrder: 27
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.greaseType",
    isRequired: false,
    sortOrder: 28
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.oilType",
    isRequired: false,
    sortOrder: 29
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.circulatingOilFlowRate",
    isRequired: false,
    sortOrder: 29
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.greaseTypeGrams",
    isRequired: false,
    sortOrder: 30
  },
  {
    key: "bearingInvestigations.operatingData.viscosityForty",
    isRequired: false,
    sortOrder: 31
  },
  {
    key: "bearingInvestigations.operatingData.viscosityHundred",
    isRequired: false,
    sortOrder: 32
  },
  {
    key: "bearingInvestigations.operatingData.lubricantHaveAWorEPproperties",
    isRequired: false,
    sortOrder: 33
  },
  {
    key: "bearingInvestigations.operatingData.lubricantManufacturerName",
    isRequired: false,
    sortOrder: 34
  },
  {
    key: "bearingInvestigations.operatingData.lubricantModelDesignation",
    isRequired: false,
    sortOrder: 35
  },
  {
    key: "bearingInvestigations.interface.bearingMountingOnShaft",
    isRequired: false,
    sortOrder: 36
  },
  {
    key: "bearingInvestigations.interface.shaftOrientation",
    isRequired: false,
    sortOrder: 37
  },
  {
    key: "bearingInvestigations.interface.locationOrNonPosition",
    isRequired: false,
    sortOrder: 38
  },
  {
    key: "bearingInvestigations.interface.locationOfBearing",
    isRequired: false,
    sortOrder: 39
  },
  {
    key: "bearingInvestigations.interface.shaftFitOnShaft",
    isRequired: false,
    sortOrder: 40
  },
  {
    key: "bearingInvestigations.interface.isoFitToleranceShaft",
    isRequired: false,
    sortOrder: 41
  },
  {
    key: "bearingInvestigations.interface.isoFitToleranceHousing",
    isRequired: false,
    sortOrder: 42
  },
  {
    key: "bearingInvestigations.interface.shaftMaterial",
    isRequired: false,
    sortOrder: 43
  },
  {
    key: "bearingInvestigations.interface.shaftMaterial.stainlessSteelType",
    isRequired: false,
    sortOrder: 44
  },
  {
    key: "bearingInvestigations.interface.housingType",
    isRequired: false,
    sortOrder: 45
  },
  {
    key: "bearingInvestigations.interface.housingMaterial",
    isRequired: false,
    sortOrder: 46
  },
  {
    key: "bearingInvestigations.interface.shaftOrHousingSealing",
    isRequired: false,
    sortOrder: 47
  },
  {
    key: "bearingInvestigations.environment.contaminationLevel",
    isRequired: false,
    sortOrder: 48
  },
  {
    key: "bearingInvestigations.environment.bearingMountingOnShaft",
    isRequired: false,
    sortOrder: 49
  },
  {
    key: "bearingInvestigations.environment.cleanlinessOfOil",
    isRequired: false,
    sortOrder: 50
  },
  {
    key: "bearingInvestigations.environment.ambientTemperature",
    isRequired: false,
    sortOrder: 51
  },
  {
    key: "bearingInvestigations.environment.vibrationLevel",
    isRequired: false,
    sortOrder: 52
  },
  {
    key: "bearingInvestigations.environment.humidityMoistureLevel",
    isRequired: false,
    sortOrder: 53
  },
  {
    key: "bearingInvestigations.drives.power",
    isRequired: false,
    sortOrder: 54
  },
  {
    key: "bearingInvestigations.drives.directDrive",
    isRequired: false,
    sortOrder: 55
  },
  {
    key: "bearingInvestigations.drives.gearDrive",
    isRequired: false,
    sortOrder: 56
  },
  {
    key: "bearingInvestigations.drives.beltDrive",
    isRequired: false,
    sortOrder: 57
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.racewayFailureAndCause",
    isRequired: false,
    sortOrder: 58
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.diameterFailureAndCause",
    isRequired: false,
    sortOrder: 59
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.sideFaceMarkedFailureAndCause",
    isRequired: false,
    sortOrder: 60
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.sideFaceOppositeFailureAndCause",
    isRequired: false,
    sortOrder: 61
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.outerRingFlangeFailureAndCause",
    isRequired: false,
    sortOrder: 62
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.racewayFailureAndCause",
    isRequired: false,
    sortOrder: 62
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.boreFailureAndCause",
    isRequired: false,
    sortOrder: 63
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.sideFaceMarkedFailureAndCause",
    isRequired: false,
    sortOrder: 64
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.sideFaceOppositeFailureAndCause",
    isRequired: false,
    sortOrder: 65
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.innerRingFlangeFailureAndCause",
    isRequired: false,
    sortOrder: 66
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowOneFailureAndCause",
    isRequired: false,
    sortOrder: 66
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowTwoFailureAndCause",
    isRequired: false,
    sortOrder: 67
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowThreeFailureAndCause",
    isRequired: false,
    sortOrder: 68
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowFourFailureAndCause",
    isRequired: false,
    sortOrder: 69
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageOneFailureAndCause",
    isRequired: false,
    sortOrder: 70
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageTwoFailureAndCause",
    isRequired: false,
    sortOrder: 71
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageThreeFailureAndCause",
    isRequired: false,
    sortOrder: 72
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageFourFailureAndCause",
    isRequired: false,
    sortOrder: 73
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.guideRing.guideRingFailureAndCause",
    isRequired: false,
    sortOrder: 74
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.sealsOrShields.sealOrShieldOneFailureAndCause",
    isRequired: false,
    sortOrder: 75
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.sealsOrShields.sealOrShieldTwoFailureAndCause",
    isRequired: false,
    sortOrder: 76
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.otherComponent.otherFailureAndCause",
    isRequired: false,
    sortOrder: 77
  },
  {
    key: "bearingInvestigations.measuredInternalClearance.measuredInternalClearance",
    isRequired: false,
    sortOrder: 79
  },
  {
    key: "bearingInvestigations.analysis.analysisSummary",
    isRequired: false,
    sortOrder: 80,
    isFullWidth: true
  },
  {
    key: "bearingInvestigations.analysis.analysisImages",
    isRequired: false,
    sortOrder: 81,
  },
  {
    key: "bearingInvestigations.analysis.primaryFailureAndCause",
    isRequired: false,
    sortOrder: 82
  },
  {
    key: "bearingInvestigations.analysis.additionalFailureAndCause",
    isRequired: false,
    sortOrder: 83
  },
  {
    key: "bearingInvestigations.analysis.primaryFailureAndCauseIso",
    isRequired: false,
    sortOrder: 84
  },
  {
    key: "bearingInvestigations.analysis.additionalFailureAndCauseIso",
    isRequired: false,
    sortOrder: 85
  },
  {
    key: "bearingInvestigations.disposition.dispositionContainer",
    isRequired: false,
    sortOrder: 86
  },
  {
    key: "bearingInvestigations.disposition.dispositionContainer.scrapDate",
    isRequired: false,
    sortOrder: 87
  }
]

export default BartConfig
