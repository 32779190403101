import React from "react";

const RemoveSvg = props => (
  <svg width={26} height={26} viewBox="0 0 26 26" {...props}>
    <title>{props.title}</title>
    <g
      transform="translate(1 1)"
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
    >
      <circle cx={12} cy={12} r={12} />
      <path
        d="M7 7l9.428 9.428m0-9.428L7 16.428"
        fillRule="nonzero"
        strokeWidth={2}
      />
    </g>
  </svg>
);

export default RemoveSvg;
