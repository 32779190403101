import * as dotenv from 'dotenv'
dotenv.config()

const dev = {
  API_URL: "https://mrhtxc0nka.execute-api.eu-west-1.amazonaws.com/Prod",
  API_KEY: process.env.API_KEY_DEV,
  FORMDATA_API_URL: "https://cykungwitc.execute-api.eu-west-1.amazonaws.com/dev",
  FORMDATA_API_KEY: process.env.FORMDATA_API_KEY_DEV,
  BUCKET: "bart-dev-attachments",
  BUCKET_PDFS: "bart-dev-pdfs",
  COGNITO_AUTH: {
    userPoolWebClientId: "7dkvund567nd755t7qokog62hs",
    userPoolId: "eu-west-1_8uFN9LQ1w", // Amazon Cognito Identity Pool ID
    region: "eu-west-1", // Amazon Cognito Region
    identityPoolId: "eu-west-1:9ce25704-8c72-4256-ae0e-1c3bcd04e66b"
  },
  BART_TEMPLATE_ID : 56,
  GBI_TEMPLATE_ID : 133,
  INSPECTION_TEMPLATE_ID: 170,
  US_INSPECTION_TEMPLATE_ID : 218,
  WIND_TEMPLATE_ID: 200,
  BI_BART_TEMPLATE_ID : 66,
  BI_GBI_TEMPLATE_ID : 114,
  BI_INSPECTION_TEMPLATE_ID: 156,
  BI_US_INSPECTION_TEMPLATE_ID : 217,
  BI_WIND_TEMPLATE_ID: 185
};

const local_dev = {
  ...dev,
  FORMDATA_API_URL: "http://localhost:3001"
}

const stage = {
  API_URL: "https://iu2iy8kk9e.execute-api.eu-west-1.amazonaws.com/Prod",
  API_KEY: "jcDHeg6RYQ8s7LR69E8ba3dlaJodZ0VZ75tRXAqv",
  FORMDATA_API_URL: "https://tnq9xxter5.execute-api.eu-west-1.amazonaws.com/dev",
  FORMDATA_API_KEY: "KdmWb33iqI5h3YqHFM5go2QyBSFWs1xB1sQsaaSR",
  BUCKET: "bart-stage-attachments",
  BUCKET_PDFS: "bart-stage-pdfs",
  COGNITO_AUTH: {
    userPoolWebClientId: "5958b3mtq5a4to28qjd7rdvr0j",
    userPoolId: "eu-west-1_lZlmfKwED", // Amazon Cognito Identity Pool ID
    region: "eu-west-1", // Amazon Cognito Region
    identityPoolId: "eu-west-1:4afc7dd4-30ef-49e1-b693-1312c02dbb69"
  },
  BART_TEMPLATE_ID : 44,
  GBI_TEMPLATE_ID : 32,
  INSPECTION_TEMPLATE_ID: 87,
  US_INSPECTION_TEMPLATE_ID : 131,
  WIND_TEMPLATE_ID : 99,
  BI_BART_TEMPLATE_ID : 1,
  BI_GBI_TEMPLATE_ID : 2,
  BI_INSPECTION_TEMPLATE_ID: 57,
  BI_US_INSPECTION_TEMPLATE_ID : 131,
  BI_WIND_TEMPLATE_ID : 72,
};

const prod = {
  API_URL: "https://vek00uwc5b.execute-api.eu-west-1.amazonaws.com/Prod",
  API_KEY: process.env.API_KEY_PROD,
  FORMDATA_API_URL: "https://s784dmgqcl.execute-api.eu-west-1.amazonaws.com/dev",
  FORMDATA_API_KEY: process.env.FORMDATA_API_KEY_PROD,
  BUCKET: "bartattachments",
  BUCKET_PDFS: "bartpdfs",
  COGNITO_AUTH: {
    userPoolWebClientId: "5uoto19cl0rj0ftdmns9tal948",
    userPoolId: "eu-west-1_h7aVUCmUq", // Amazon Cognito Identity Pool ID
    region: "eu-west-1", // Amazon Cognito Region
    identityPoolId: "eu-west-1:082e5f9e-1f01-4669-b2bb-13bc79e50691"
  },
  BART_TEMPLATE_ID : 20,
  GBI_TEMPLATE_ID : 46,
  INSPECTION_TEMPLATE_ID: 73,
  US_INSPECTION_TEMPLATE_ID : 128,
  WIND_TEMPLATE_ID : 101,
  BI_BART_TEMPLATE_ID : 1,
  BI_GBI_TEMPLATE_ID : 34,
  BI_INSPECTION_TEMPLATE_ID: 58,
  BI_US_INSPECTION_TEMPLATE_ID : 113,
  BI_WIND_TEMPLATE_ID : 86
};

let config;

switch (process.env.REACT_APP_STAGE) {
  case "prod":
    config = prod;
    break;
  case "stage":
    config = stage;
    break;
  default:
    if (process.env.REACT_APP_CONFIG_LOCAL === "local") {
      config = local_dev;
    } else { 
      config = dev;
    }
}

export default {
  ...config
};
