import {
  SAVE_COMPONENT_VALUE,
  SET_ALL_ANSWERS,
  SET_ALL_ANSWERS_BEFORE_SAVE,
  REMOVE_COMPONENT_VALUE,
  USE_AUTOMATIC_FIGURENUMBERING,
  SELECT_QUICKLINK,
  RECORD_QUICKLINK_POSITION,
  SET_SCROLL_TARGET,
  SET_DISABLE_AUTOSCROLL,
  SET_VALIDATION_ERRORS,
  REMOVE_VALIDATION_ERRORS_BEARING,
  REMOVE_VALIDATION_ERROR,
  ADD_VALIDATION_ERROR,
  SET_CURRENTCASE_FORMTEMPLATE,
  DISMISS_ANSWER_ERROR,
  SAVE_COMPONENT_VALUES,
  SET_ANSWER_TO_SAVE,
  REMOVE_ANSWER_TO_SAVE,
  REMOVE_ANSWERS_TO_SAVE,
  CLEAR_ANSWERS_TO_SAVE,
  TRIGGER_SAVE_ON_ACCORDIAN,
  SET_LIVEPREVIEW_TARGET,
  SET_SELECTED_BEARING,
  SET_IMAGE_TO_DELETE,
  SET_CONVERTED_TO_BDA_CASEID,
  SET_MOVED_BEARINGS,
} from 'redux/types'

export function saveComponentValue(payload){
  return { type: SAVE_COMPONENT_VALUE, payload }
}
export function saveComponentValues(payload) { 
  return { type: SAVE_COMPONENT_VALUES, payload }
}

export function removeComponentValue(payload){
  return { type: REMOVE_COMPONENT_VALUE, payload }
}

export function setUseAutomaticFigureNumbering(payload) { 
  return { type: USE_AUTOMATIC_FIGURENUMBERING, payload }
}

export function setAllAnswersBeforeSave(payload){
  return { type: SET_ALL_ANSWERS_BEFORE_SAVE, payload }
}

export function setAllAnswers(payload){
  return { type: SET_ALL_ANSWERS, payload }
}

export function selectQuickLink(payload) {
  return { type: SELECT_QUICKLINK, payload }
}

export function recordQuickLinkPosition(payload) {
  return { type: RECORD_QUICKLINK_POSITION, payload }
}

export function setScrollTarget(payload) {
  return { type: SET_SCROLL_TARGET, payload }
}

export function setDisableAutoscroll(payload) {
  return { type: SET_DISABLE_AUTOSCROLL, payload }
}

export function setValidationErrors(payload) {
  return { type: SET_VALIDATION_ERRORS, payload }
}

export function removeValidationErrorsForBearing(payload) {
  return { type: REMOVE_VALIDATION_ERRORS_BEARING, payload }
}

export function removeValidationError(payload) {
  return { type: REMOVE_VALIDATION_ERROR, payload}
}

export function addValidationError(payload) {
  return { type: ADD_VALIDATION_ERROR, payload}
}

export function setCurrentCaseFormTemplate(payload) {
  return { type: SET_CURRENTCASE_FORMTEMPLATE, payload}
}

export function dismissAnswerError(payload) { 
  return { type: DISMISS_ANSWER_ERROR, payload} 
}

export function setAnswerToSave(answer, intl) { 
  return { type: SET_ANSWER_TO_SAVE, payload: { answer, intl } };
}

export function removeAnswerToSave(answerName) { 
  return { type: REMOVE_ANSWER_TO_SAVE, payload: answerName };
}

export function removeAnswersToSave(answerName) { 
  return { type: REMOVE_ANSWERS_TO_SAVE, payload: answerName };
}

export function clearAnswersToSave() { 
  return { type: CLEAR_ANSWERS_TO_SAVE };
}

export function triggerSaveOnAccordian(accoridanName) { 
  return { type: TRIGGER_SAVE_ON_ACCORDIAN, payload: accoridanName };
}

export function setLivePreviewTarget(target) {
  return { type: SET_LIVEPREVIEW_TARGET, payload: target }
}

export function setSelectedBearing(bearingIndex) {
  return { type: SET_SELECTED_BEARING, payload: bearingIndex }
}

export function setImageToDelete(imageToDelete) {
  return { type: SET_IMAGE_TO_DELETE, payload: imageToDelete }
}

export function setConvertToBDACaseId(payload) {
  return { type: SET_CONVERTED_TO_BDA_CASEID, payload: payload }
}

export function setMovedBearings(payload) {
  return { type: SET_MOVED_BEARINGS, payload: payload }
}
