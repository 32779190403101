import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import ImageItem from "./ImageItem";

const ImageContainer = ({ imageObjects, imageLayout, useAutomaticFigureNumbering, datakey }) => {


  const groupImages = (imageObjects) => {
    switch (imageLayout.value) {
      case "live-preview-images__container--one-figure":
        return [imageObjects];
      case "live-preview-images__container--50-50":
      case "live-preview-images__container--70-30":
        return imageObjects.reduce(function(result, value, index, array) {
          if (index % 2 === 0) result.push(array.slice(index, index + 2));
          return result;
        }, []);
      case "live-preview-images__container--3":
        return imageObjects.reduce(function(result, value, index, array) {
          if (index % 3 === 0) result.push(array.slice(index, index + 3));
          return result;
        }, []);
      case "live-preview-images__container--one-plus-three":
        return [imageObjects];
      default:
        return [imageObjects];
    }
  };

  const containerType = (imageObjects) => {
    switch (imageLayout.value) {
      case "live-preview-images__container--one-figure":
        return "one-figure";
      case "live-preview-images__container--50-50":
        return "50-50";
      case "live-preview-images__container--70-30":
        return "70-30";
      case "live-preview-images__container--3":
        return "3";
      case "live-preview-images__container--one-plus-three":
        return "one-plus-three";
      default:
        return "3";
    }
  };

  const oddLayoutClassesInit = (imageObjects) => {
    switch (imageLayout.value) {
      case "live-preview-images__container--70-30":
        return "layout_30";
      case "live-preview-images__container--one-plus-three":
        return "layout_one-plus-three_4";
      default:
        return "";
    }
  };

  const oddLayoutClassesSwitch = (imageObjects, layout) => {
    switch (imageLayout.value) {
      case "live-preview-images__container--70-30":
        return layout === "layout_30" ? "layout_70" : "layout_30";
      case "live-preview-images__container--one-plus-three":
        switch (layout) {
          case "layout_one-plus-three_1":
            return "layout_one-plus-three_2";
          case "layout_one-plus-three_2":
            return "layout_one-plus-three_3";
          case "layout_one-plus-three_3":
            return "layout_one-plus-three_4";
          default:
            return "layout_one-plus-three_1";
        }
      default:
        return "";
    }
  };

  let layout = oddLayoutClassesInit(imageObjects);

  return (
    <div className={`live-preview-images__container`} data-key-id={datakey}>
      <div className={"live-preview-outercontainer--" + containerType(imageObjects)}>
        {groupImages(imageObjects).map((group, gIndex) => {
          return (
            <div key={gIndex} className={imageLayout.value}>
              {group.map((imgObj, imgIndex) => {
                const imgNumber = useAutomaticFigureNumbering ? String(imgObj.autoNumber) : imgObj.number;
                layout = oddLayoutClassesSwitch(imageObjects, layout);
                return (
                  <ImageItem
                    key={imgIndex}
                    imageUrl={imgObj.url}
                    imageNumber={imgNumber}
                    imageCaption={imgObj.caption}
                    layout={layout}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ImageContainer.propTypes = {
  imageObjects: PropTypes.array,
  imageLayout: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    useAutomaticFigureNumbering: state.saveComponentValue.useAutomaticFigureNumbering,
  };
}

export default injectIntl(connect(mapStateToProps)(ImageContainer));
