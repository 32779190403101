import React from "react";

const SvgUndo = ({ title, size = "26px", fill = "#F8F8F8", className }) => (
    <svg width={size} height={size} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={className}>
        <title>{title}</title>
        <g id="Icons/icon_undo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icons/Actions/Undo" fill={ fill }>
                <path d="M11.2452509,2.00310242 C6.73084399,1.87865496 3.03240057,5.51429859 3.03240057,10.0032962 L1.44481934,10.0032962 C1.04570674,10.0032962 0.850585026,10.4833078 1.13439843,10.7588701 L3.60889654,13.2389301 C3.78627992,13.4167122 4.06122416,13.4167122 4.23860753,13.2389301 L6.71310565,10.7588701 C6.98804988,10.4833078 6.79292817,10.0032962 6.39381557,10.0032962 L4.80623434,10.0032962 C4.80623434,6.53654557 7.62663004,3.73647774 11.1033442,3.78092326 C14.4026751,3.82536879 17.1787249,6.6076584 17.2230708,9.91440517 C17.2674166,13.3900449 14.4736284,16.2256692 11.0146526,16.2256692 C9.90600644,16.2256692 8.86831369,15.9234396 7.96365846,15.4167607 C7.61776087,15.2212004 7.19204077,15.2923132 6.91709653,15.5767646 C6.50911477,15.9856633 6.58893729,16.6879026 7.09447991,16.9723539 C8.25634103,17.6212585 9.58671636,18.00349 11.0146526,18.00349 C15.4935828,18.00349 19.1210729,14.2967335 18.9969045,9.7721795 C18.8816053,5.60318963 15.4048911,2.11866078 11.2452509,2.00310242 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
);

export default SvgUndo;
