const endpoints = {
  CASESEARCH: 'casesearch',
  QC_CASESEARCH: 'casesearch/qc',
  PDF_BART: 'pdf',
  PUBLISH_PDF_BART: 'publishpdf',
  LISTS_USERS: 'lists/users',
  LISTS_CUSTOMERS: 'lists/customers',
  DATAEXPORT: 'dataexport/',
  AIPREDICTION: 'predictions/'
}

export default endpoints
