export function loadCookiesScript() {
  const stage = process.env.REACT_APP_STAGE
  if (!stage) return

  const inlineScript = document.createElement('script')
  inlineScript.type = 'text/javascript'
  inlineScript.setAttribute('data-cmp-ab', '1')
  inlineScript.innerHTML = `
    window.cmp_customlanguages = [{"l":"JA","i":"jp","r":0,"t":"JA"},{"l":"KO","i":"ke","r":0,"t":""},{"l":"MK","i":"mk","r":0,"t":"MK"},{"l":"TH","i":"th","r":0,"t":"TH"},{"l":"TW","i":"tw","r":0,"t":"TW"},{"l":"VI","i":"vi","r":0,"t":"VI"},{"l":"FR-CA","i":"fr","r":0,"t":"FR-ca"},{"l":"FR-CH","i":"fr","r":0,"t":"FR-ch"},{"l":"FR-HR","i":"fr","r":0,"t":"FR-hr"},{"l":"FR-AFRICA","i":"fr","r":0,"t":"FR-africa"},{"l":"FR-BENELUX","i":"fr","r":0,"t":"FR-benelux"},{"l":"PT-BR","i":"pt","r":0,"t":"PT-br"},{"l":"ES-AR","i":"es","r":0,"t":"ES-ar"},{"l":"ES-CL","i":"es","r":0,"t":"ES-cl"},{"l":"ES-CO","i":"es","r":0,"t":"ES-co"},{"l":"ES-MX","i":"es","r":0,"t":"ES-mx"},{"l":"ES-PE","i":"es","r":0,"t":"ES-pe"},{"l":"ES-UY","i":"es","r":0,"t":"ES-uy"},{"l":"ES-VEN","i":"es","r":0,"t":"ES-ven"},{"l":"DE-AT","i":"de","r":0,"t":"DE-at"},{"l":"DE-CH","i":"de","r":0,"t":"DE-ch"},{"l":"DE-SPANDAUPUMPEN","i":"de","r":0,"t":"DE-spandaupumpen"},{"l":"EN-GROUP","i":"en","r":0,"t":"EN-group"},{"l":"EN-IL","i":"en","r":0,"t":"EN-il"},{"l":"EN-KE","i":"en","r":0,"t":"EN-ke"},{"l":"EN-NG","i":"en","r":0,"t":"EN-ng"},{"l":"EN-AFRICA","i":"en","r":0,"t":"EN-africa"},{"l":"EN-AU","i":"en","r":0,"t":"EN-au"},{"l":"EN-CARIBBEAN","i":"en","r":0,"t":"EN-caribbean"},{"l":"EN-IN","i":"en","r":0,"t":"EN-in"},{"l":"EN-ID","i":"en","r":0,"t":"EN-id"},{"l":"EN-MY","i":"en","r":0,"t":"EN-my"},{"l":"EN-MENA","i":"en","r":0,"t":"EN-mena"},{"l":"EN-PH","i":"en","r":0,"t":"EN-ph"},{"l":"EN-SG","i":"en","r":0,"t":"EN-sg"},{"l":"EN-UK","i":"en","r":0,"t":"EN-uk"},{"l":"EN-US","i":"en","r":0,"t":"EN-us"},{"l":"EN-ALEMITE","i":"en","r":0,"t":"EN-alemite"},{"l":"EN-KAYDON","i":"en","r":0,"t":"EN-kaydon"},{"l":"EN-MITYVAC","i":"en","r":0,"t":"EN-mityvac"},{"l":"EN-SPANDAUPUMPEN","i":"en","r":0,"t":"EN-spandaupumpen"}]
  `
  document.head.appendChild(inlineScript)

  const externalScript = document.createElement('script')
  externalScript.type = 'text/javascript'
  externalScript.setAttribute('data-cmp-ab', '1')
  externalScript.setAttribute('data-cmp-cdn', 'cdn.consentmanager.net')
  externalScript.setAttribute('data-cmp-codesrc', '0')

  if (stage === 'prod') {
    externalScript.src = 'https://cdn.consentmanager.net/delivery/autoblocking/3c5c3fbbc12b8.js'
    externalScript.setAttribute('data-cmp-host', 'd.delivery.consentmanager.net')
    document.head.appendChild(externalScript)
  } else {
    externalScript.src = 'https://cdn.consentmanager.net/delivery/autoblocking/abcc3f2b91302.js'
    externalScript.setAttribute('data-cmp-host', 'c.delivery.consentmanager.net')
    document.head.appendChild(externalScript)
  }
}
