import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from 'react-router-dom';
import { renderBearingDesignationHeader } from '../Shared/GetAndRenderBearingDesignation';
import {
  transformBearingInvestigationData,
  extractBearingInvestigationData,
  transformBearingInvestigationKeys,
  imageAnalysisNames,
  getImageObjects,
  getImageLayoutObject,
  fixBearingConfig,
  isValuePresent,
} from "../Shared/BIHelperFunctions";

import PropTypes from "prop-types";
import classnames from "classnames";

import KeyValueTable from "components/_LivePreview/Shared/KeyValueTable";
import ImageContainer from "components/_LivePreview/Shared/ImageContainer";
import ObservationsAndFailuremode from "components/_LivePreview/Shared/ObservationsAndFailuremode";
import UsRecommendations from './UsRecommendations';
import InspectionComponentPartsInspection from "../Shared/InspectionComponentPartsInspection";

const UsInspectionBearingInvestigation = (props) => {
  const intl = useIntl()
  const [bearingsConfig, setBearingConfig] = useState([]);

  const pageBreakEachBearing = useSelector(state => state.pageBreakEachBearing)
  const scrollTarget = useSelector(state => state.saveComponentValue.scrollTarget)
  const caseWasSaved = useSelector(state => state.saveComponentValue.caseWasSaved)
  const currentCase = useSelector(state => state.currentCase.case)
  const bearingDesignationAnswers = useSelector(state => state.saveComponentValue.components.filter(c => c.name.endsWith('bearingType.BearingDesignationComponent')));

  useEffect(() => {
    const configs = fixBearingConfig(props.config, props.bearingsCount);
    setBearingConfig(configs);
  }, [props.data]);

  const extractConfigsFor = (values, config) => {
    return config.filter(item => { return values.some(v => item.key.includes(v)) });
  }

  const hasScrollTarget = key => {
    if (!scrollTarget) return false

    if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
      return (scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key)) && !caseWasSaved
    }
  }

  const renderAnalysis = (i, bearingsConfig) => {
    const renderedFailures = renderFailureImages(i, bearingsConfig);
    const renderedAnalysis = renderAnalysisImages(i);

    return (renderedAnalysis != null || renderedFailures.some(f => f !== null) ?
      (<div className="live-preview-list-structure" data-section-id={`bearingInvestigations.bearingInvestigation[${i}].analysis`}>
        <div className="live-preview-key-value">
          <div className="live-preview-key-value__header__without_background_color">{intl.formatMessage({ id: "analysis" })}</div>
        </div>
        {renderedAnalysis}
        {renderedFailures}
      </div>) : null
    );
  }

  const renderAnalysisImages = i => {
    const imageObjectKey = "bearingInvestigations.bearingInvestigation[" + i + "].analysis.analysisImages";
    const imageLayoutKey = "bearingInvestigations.bearingInvestigation[" + i + "].analysis.analysisImages";
    const summaryKey = "bearingInvestigations.bearingInvestigation[" + i + "].analysis.analysisSummary";

    const analysisImages = {
      imageObjects: getImageObjects(imageObjectKey, props.data),
      imageLayouts: getImageLayoutObject(props.data[imageLayoutKey])
    };
    const analysisSummary = props.data[summaryKey];

    return (
      isValuePresent(analysisSummary) || analysisImages.imageObjects.length > 0 || hasScrollTarget(summaryKey) || hasScrollTarget(imageLayoutKey) || hasScrollTarget(imageObjectKey) ?
        (<div className="live-preview-key-value">
          <div className="live-preview-images">
            <div data-key-id={summaryKey} key={"bearingInvestigations.bearingInvestigation[" + i + "].analysis.analysisSummary"} className="live-preview-key-value__row">
              <div className="live-preview-key-value__cell">
                <div className="live-preview-key-value__key">
                  {intl.formatMessage({ id: "analysisSummary" })}
                </div>
                <div className="live-preview-key-value__value" dangerouslySetInnerHTML={{ __html: analysisSummary }}></div>
              </div>
            </div>
            <ImageContainer
              datakey={imageObjectKey + ".failureImage"}
              imageObjects={analysisImages.imageObjects}
              imageLayout={analysisImages.imageLayouts} />
          </div>
        </div>) : null)
  };

  const showPredictionImage = data => {
    if (data && data.prediction && data.prediction.showBoundryBox && data.prediction.boundryBoxImage) {
      return true;
    } else {
      return false;
    }
  }

  const showAnalysis = data => {
    if (data) {
      if (data.cause || data.failureCode || data.imageLayout || (data.imageUrls && data.imageUrls.length > 0) || data.observations || showPredictionImage(data)) {
        return true;
      }
    }
    return false;
  }

  const renderFailureImages = (i, bearingsConfig) => {
    return imageAnalysisNames(i, bearingsConfig).map((name) => {
      let key = name.key;
      return showAnalysis(props.data[key]) || hasScrollTarget(key) ? (
        <div key={key + ".whole"}>
          <div className="live-preview-images">
            <ObservationsAndFailuremode
              answer={props.data[key]}
              configKey={key}
            />
            {showPredictionImage(props.data[key]) && (
              <ImageContainer
                datakey={name.key + ".predictionImage"}
                imageObjects={[props.data[key].prediction.boundryBoxImage]}
                imageLayout={{ value: "live-preview-images__container--one-figure" }} />)}
          </div>
        </div>
      ) : null;
    });
  };

  const fixRemanDefaults = (data, row) => {
    [1, 2, 3].forEach(remanNumber => {
      const remanConfig = row.find(r => r.key.includes(`remanId${remanNumber}`));
      const dateCodeConfig = row.find(r => r.key.includes(`dateCode${remanNumber}`));
      if (remanConfig && dateCodeConfig) {
        const remanValue = data[remanConfig.key]
        const dateValue = data[dateCodeConfig.key];
        if (dateValue && !remanValue) {
          data[remanConfig.key] = "-";
        } else if (!dateValue && remanValue) {
          data[dateCodeConfig.key] = "-";
        }
      }
    });
    return data;
  }

  const formatMovedBearingInfo = () => (
    <div className='live-preview__ref__inspection__id'>
      <Link to={`/case/${currentCase.convertedBDAID}`}>
        Ref. BDA No. {currentCase?.convertedBDAID}
      </Link>
    </div>
  );

  const isBearingTransferred = (index) => {
    const currentBearingDesignation = bearingDesignationAnswers.find(c => c.name === `bearingInvestigations.bearingInvestigation[${index}].bearingType.BearingDesignationComponent`)?.value;
    if (!currentBearingDesignation) return false;
    try {
      const value = JSON.parse(currentBearingDesignation);
      if (!value) return false;

      return value?.isTransferred === 'true' || value.isTransferred === true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  return (
    <div className="live-preview-key-values live-preview-key-value--bearing" data-section-id="bearingInvestigations">
      {bearingsConfig.map((row, i) => (
        <div key={`something-${i}`} className="live-preview-list-structure">
          <h3 className="live-preview-list-structure__header" data-section-id={`bearingInvestigation[${i}]`}>
            <div className={classnames("live-preview-key-value__header live-preview-key-value__bearingheader",
              { "live-preview-key-value-with-page-break-if-print__header": pageBreakEachBearing })}>
              {renderBearingDesignationHeader(intl.formatMessage({ id: "bearingNumber" }), intl.formatMessage({ id: "customerReferenceNumber.header" }), i, props.data)}
            </div>
          </h3>
          {(isBearingTransferred(i) && currentCase?.convertedBDAID) && formatMovedBearingInfo()}

          <KeyValueTable
            config={[
              ...extractConfigsFor(["bearingType.bearingSkfCustomerDescription",
                "bearingType.skfEndUserDescription", "bearingType.customerReferenceNumber"], row),
              ...transformBearingInvestigationKeys(
                extractConfigsFor(
                  ["backgroundInformation", "manufacturing", "operatingData", "interface", "environment", "drives", "measuredInternalClearance"],
                  row))]}
            data={
              fixRemanDefaults(
                transformBearingInvestigationData(
                  row,
                  ["bearingType.bearingSkfCustomerDescription", "bearingType.skfEndUserDescription",
                    "backgroundInformation", "manufacturing", "operatingData", "interface", "environment",
                    "drives", "measuredInternalClearance", "bearingType.customerReferenceNumber"],
                  props.data),
                row)}
          />
          <InspectionComponentPartsInspection
            index={i}
            bearingsConfig={bearingsConfig}
            data={props.data}
            hasScrollTarget={hasScrollTarget}
            scrollTarget={scrollTarget}
          />

          <KeyValueTable
            config={extractConfigsFor(["allPartsAvailable"], row)}
            data={transformBearingInvestigationData(
              row,
              ["allPartsAvailable"],
              props.data
            )}
          />
          {renderAnalysis(i, bearingsConfig)}
          <UsRecommendations
            config={extractConfigsFor(["recommendations", "skfEquivalent"], row)}
            data={extractBearingInvestigationData(
              row,
              ["recommendations", "BearingDesignationComponent"],
              props.data
            )}
          />
        </div>
      ))}
    </div>
  );
};

UsInspectionBearingInvestigation.propTypes = {
  header: PropTypes.string,
  config: PropTypes.array,
  extract: PropTypes.func,
}

export default UsInspectionBearingInvestigation
