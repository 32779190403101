import React from "react";
import SvgEmail from "components/svg/Email";
import SvgPhone from "components/svg/Phone";

const PersonCell = ({ config, data }) => {


    const renderName = (value) => {
        return <>
            {value ? <>
                {value}<br />
            </> : null}
        </>
    }

    const renderPhone = (value) => {
        return <>
            {value ? <>
                <SvgPhone />{" " + value}<br />
            </> : null}
        </>
    }

    const renderEmail = (value) => {
        if (!value) {
            return null;
        }
        if (value.includes(",")) {
            return value.split(",").map((e,i) => <React.Fragment key={i}>{renderEmail(e.trim())}<br/></React.Fragment>);
        } else {
            return <>
                {value ? <>
                    <SvgEmail />{" " + value}
                </> : null}
            </>
        }
    }

    return data ? <>
        {renderName(data.name)}
        {renderPhone(data.phone)}
        {renderEmail(data.email)}
    </> : null;
}

export default PersonCell;