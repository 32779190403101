import React from "react";

const SvgEmail = props => {
  return <svg width="12px" height="10px" viewBox="0 0 23 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <polygon id="path-email" points="0.887 0.9466 22.7132 0.9466 22.7132 14.8006 0.887 14.8006"></polygon>
    </defs>
    <g id="Page-12" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SKF-icon-finder---full---v2_2" transform="translate(-500.000000, -50.000000)">
        <g id="Group-2176" transform="translate(500.000000, 50.000000)">
          <path d="M11.802,9.2132 L21.818,1.2462 C21.505,1.0412 21.132,0.9192 20.73,0.9192 L2.872,0.9192 C2.488,0.9192 2.132,1.0332 1.827,1.2232 L11.802,9.2132 Z" id="Fill-2171" fill="#515557"></path>
          <g id="Group-2175" transform="translate(0.000000, 1.000000)">
            <mask id="mask-email" fill="white">
              <use xlinkHref="#path-email"></use>
            </mask>
            <g id="Clip-2174"></g>
            <path d="M22.4792,0.9866 L12.0932,9.2366 C11.9172,9.3686 11.6812,9.3686 11.5042,9.2356 L1.1442,0.9466 C0.9862,1.2316 0.8872,1.5546 0.8862,1.9036 L0.8862,12.8166 C0.8872,13.9106 1.7772,14.8006 2.8722,14.8006 L20.7282,14.8006 C21.8222,14.8006 22.7122,13.9106 22.7132,12.8166 L22.7132,1.9036 C22.7132,1.5706 22.6242,1.2616 22.4792,0.9866" id="Fill-2173" fill="#515557" mask="url(#mask-email)"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
}

export default SvgEmail;
