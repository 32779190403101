
import { CaseDataRepository } from "./CaseDataRepository";
import { CaseDataOfflineRepository } from "./CaseDataOfflineRepository";
import { CaseDataOfflineRepositoryEmpty } from "./CaseDataOfflineRepositoryEmpty";
import { OfflineTrackingRepositoryEmpty, OfflineTrackingRepository } from "./OfflineTrackingRepository";

export function getCaseDataRepository(state, forceConnection) { 
    if (state.connection || forceConnection) {
        return new CaseDataRepository(new CaseDataOfflineRepository(new OfflineTrackingRepositoryEmpty()));
    } else { 
        return new CaseDataOfflineRepository(new OfflineTrackingRepository());
    }
}

export function getCaseDataRepositoryWithoutOffline() { 
    return new CaseDataRepository(new CaseDataOfflineRepositoryEmpty());
}