import localforage from "localforage";
import uuid from "uuid";
import { removeBearingFromAnswers, duplicateBearingAnswers, moveBearingAnswers } from "components/formcomponents/utils/BearingUtils";

export class CaseDataOfflineRepository { 
    constructor(trackingRepository) { 
        this.trackingRepository = trackingRepository;
    }

    getCase(caseId) { 
        return localforage.getItem(`case/${caseId}`)
    }

    async deleteCase(caseId, updatedAt) { 
        await localforage.removeItem(`case/${caseId}`)
        await localforage.removeItem(`casedata/${caseId}`)
        await localforage.removeItem(`caseaccess/${caseId}`)
        await this.trackingRepository.deleteCase(caseId, updatedAt);
    }

    async addCase(templateId, answers, confidential = false, language = 'en', domain) { 
        const id = templateId + "-" + uuid.v4() + "-offlineCase";
        const response = await this.addOrUpdateCase(id, {
            id: id,
            title: "",
            author: "",
            status: "draft",
            template: templateId,
            domain,
            revision_number: 0,
            createdAt: new Date().toISOString(),
            updatedAt: new Date(),
            formtemplateId: templateId,
            confidential: confidential,
            language: language
        });
        await this.setCaseAccess(id, { data: { read: true, edit: true } });
        await localforage.setItem(`casedata/${id}`, { data: [] });
        for (const answer of answers) { 
            await this.addAnswer(response.data.id, answer);
        }
        return response;
    }

    async getCaseData(caseId) { 
        return await localforage.getItem(`casedata/${caseId}`)
    }

    async setCaseData(caseId, answers) { 
        return await localforage.setItem(`casedata/${caseId}`, { data: answers.data });
    }

    async getCaseAccess(caseId) {
        return await localforage.getItem(`caseaccess/${caseId}`)
    }

    async setCaseAccess(caseId, access) { 
        return await localforage.setItem(`caseaccess/${caseId}`, { data: access.data });
    }
    
    async addAnswer(caseId, answer) { 
        return await this.addOrUpdateAnswer(caseId,  uuid.v4(), answer);
    }

    async updateAnswer(caseId, answerId, answer) { 
        return await this.addOrUpdateAnswer(caseId, answerId, answer);
    }

    async updateMultipleAnswers(caseId, answersToSave) {
        let caseData = await localforage.getItem(`casedata/${caseId}`);
        if (caseData === null) {
            caseData = { data: [] };
        }
        
        const tracking = {
            answersToUpdate: [],
            answersToAdd: []
        };
        const allAnswers = [...answersToSave.answersToUpdate.map(a => a.answer), ...answersToSave.answersToAdd];
        const updatedAnswers = [];
        for (let i = 0; i <allAnswers.length; i++) {
            const answer = allAnswers[i];
            const answerId = answer.id ? answer.id : uuid.v4();

            const answertoUpdate = caseData.data.find(a => a.id === answerId);
            if (answertoUpdate) {
                answertoUpdate.value = answer.value;
                tracking.answersToUpdate.push(answertoUpdate);
                updatedAnswers.push(answertoUpdate);
            } else { 
                let answerToSave = { ...answer, id: answerId, isSaving: undefined, isError: undefined, error: undefined };
                caseData.data.push(answerToSave);
                updatedAnswers.push(answerToSave);
                tracking.answersToAdd.push(answer);
                
            }
        }
        await this.trackingRepository.updateAnswers(caseId, tracking.answersToUpdate, tracking.answersToAdd);
        await localforage.setItem(`casedata/${caseId}`, caseData);
        return { data: updatedAnswers.map(a => ({answer: a})) };
    }

    async updateCaseData(caseId, caseData) { 
        return await this.addOrUpdateCase(caseId, caseData);
    }

    async addOrUpdateCase(caseId, caseData) { 
        const caseDataToUpdate = await localforage.getItem(`case/${caseId}`);
        if (caseDataToUpdate === null) {
            const caseDataToAdd = { data: { ...caseData, id: caseId } };
            await localforage.setItem(`case/${caseData.id}`, caseDataToAdd);
            await this.trackingRepository.addCase(caseId, caseDataToAdd);
            return caseDataToAdd;
        } else { 
            const caseDataToSave = { data: { ...caseDataToUpdate.data, ...caseData } };
            await localforage.setItem(`case/${caseId}`, caseDataToSave);
            await this.trackingRepository.updateCase(caseId, caseDataToSave);
            return caseDataToSave;
        }
    }

    async addOrUpdateAnswer(caseId, answerId, answer) { 
        let caseData = await localforage.getItem(`casedata/${caseId}`);
        if (caseData === null) { 
            caseData = { data: [] };
        }
        const answertoUpdate = caseData.data.find(a => a.id === answerId);
        if (answertoUpdate) {
            answertoUpdate.value = answer.value;
            await localforage.setItem(`casedata/${caseId}`, caseData);
            await this.trackingRepository.updateAnswer(caseId, answerId, answertoUpdate);
            return { data: answertoUpdate };
        } else { 
            let answerToSave = { ...answer, id: answerId, isSaving: undefined, isError: undefined, error: undefined };
            caseData.data.push(answerToSave);
            await localforage.setItem(`casedata/${caseId}`, caseData);
            await this.trackingRepository.addAnswer(caseId, answerId, answer);
            return { data: answerToSave };
        }
    }

    getCasesDraftForUser(numberOfCases) { 
        throw Error("Function not implemented");
    }

    async getFormTemplate(templateId) { 
        return await localforage.getItem(`formtemplate/${templateId}`)
    }

    getFormTemplates(templateIds) { 
        throw Error("Function not implemented");
    }
    
    async moveBearing(caseId, currentIndex, newIndex, count) { 
        let caseData = await localforage.getItem(`casedata/${caseId}`);
        const answers = moveBearingAnswers(caseData.data, currentIndex, newIndex, count);
        await localforage.setItem(`casedata/${caseId}`, { data: answers });
        await this.trackingRepository.moveBearing(caseId, currentIndex, newIndex, count);
        return { data: answers };
    }

    async removeBearing(caseId, bearingIndex, newCount) { 
        let caseData = await localforage.getItem(`casedata/${caseId}`);
        const answers = removeBearingFromAnswers(caseData.data, bearingIndex, newCount);
        await localforage.setItem(`casedata/${caseId}`, { data: answers });
        await this.trackingRepository.removeBearing(caseId, bearingIndex, newCount);
        return { data: answers };
    }
    
    async duplicateBearing(caseId, bearingIndex, newCount) { 
        let caseData = await localforage.getItem(`casedata/${caseId}`);
        const answers = duplicateBearingAnswers(caseData.data, bearingIndex, newCount);

        await localforage.setItem(`casedata/${caseId}`, { data: answers.updatedAnswers });
        for (let i = 0; i < answers.newAnswers.length; i++) {
            const answer = answers.newAnswers[i];
            await this.trackingRepository.addAnswer(caseId, answer.id, answer);
        }
        return { data: answers.updatedAnswers };
    }

    getList(endpoint) { 
        throw Error("Function not implemented");
    }
}