import React from "react";

const SvgCanvasTrashcan = props => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Icons/icon_trashcan" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icons/icon_delete" fill="#F8F8F8">
                <path d="M4,17 C4,18.1 4.9,19 6,19 L14,19 C15.1,19 16,18.1 16,17 L16,7 C16,5.9 15.1,5 14,5 L6,5 C4.9,5 4,5.9 4,7 L4,17 Z M16,2 L13.5,2 L12.79,1.29 C12.61,1.11 12.35,1 12.09,1 L7.91,1 C7.65,1 7.39,1.11 7.21,1.29 L6.5,2 L4,2 C3.45,2 3,2.45 3,3 C3,3.55 3.45,4 4,4 L16,4 C16.55,4 17,3.55 17,3 C17,2.45 16.55,2 16,2 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
);

export default SvgCanvasTrashcan;
