import React from 'react'
import PropTypes from 'prop-types'

const ReportHeader = (props) => {
  return (
    <h3 className="live-preview__header">
      {props.data}
    </h3>
  );
}

ReportHeader.propTypes = {
  data: PropTypes.string
}

export default ReportHeader
