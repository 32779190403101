import { SET_CURRENT_CASE, SET_CURRENT_CASE_STATUS, SET_CURRENT_ADD_CASE_TO_SAVE, SET_CURRENT_REMOVE_CASE_TO_SAVE } from "redux/types";

const setCurrentCase = (payload) => {
    return { type: SET_CURRENT_CASE, payload };
};

const setCurrentCaseStatus = (payload) => {
    return { type: SET_CURRENT_CASE_STATUS, payload };
};

const addCaseToSave = (payload) => {
    return { type: SET_CURRENT_ADD_CASE_TO_SAVE, payload };
};

const removeCaseToSave = (payload) => {
    return { type: SET_CURRENT_REMOVE_CASE_TO_SAVE, payload };
};

export { setCurrentCase, setCurrentCaseStatus, addCaseToSave, removeCaseToSave};
