import localforage from "localforage";
import { getCaseDataRepositoryWithoutOffline } from "core/caseRepository/caseRepositoryFactory";


export async function findAndUploadOfflineImages(events, caseId) {
  const imageResult = [];
  const offlineImages = await findOfflineImageUrls();
  for (const offlineUrl of offlineImages) {
    if (isImageInEvents(offlineUrl, events)) {
      const res = await localforage.getItem("offline-img-" + offlineUrl);
      const response = await postImage(caseId, res);
      imageResult.push({
        offlineUrl,
        syncedImage: response?.data
      });
    }
  }
  return imageResult;
}

export function findOfflineImageUrls() {
    let offlineImgUrls = [];
    return new Promise((resolve, reject) => {
      localforage
        .iterate((value, key) => {
          if (key.indexOf("offline-img") > -1) {
            key = key.slice(key.indexOf("img-") + 4);
            offlineImgUrls.push(key);
          }
        })
        .then(() => {
          resolve(offlineImgUrls);
        })
        .catch((err) => {
          console.log(err);
        });
    });
}

async function postImage(caseId, base64Value) {
  const repository = getCaseDataRepositoryWithoutOffline();
  return await repository.postImage(caseId, base64Value);
}

function isImageInEvents(imageUrl, events) { 
  const searchText = `url":"${imageUrl}","originalUrl":null,"hasOriginal":false`
  return events.some(event => { 
    if (event.type === "addAnswer" || event.type === "updateAnswer") { 
      return event.payload.answer.value?.includes(searchText);
    }
  });
}