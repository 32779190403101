import { SET_REQUIRED_LIST, REMOVE_REQUIRED_LIST_BEARING, REMOVE_REQUIREDLIST_KEYS } from 'redux/types'
import initialState from 'redux/initialState'

export default function requiredListReducer(state = initialState.requiredList, action) {
  switch (action.type) {
    case SET_REQUIRED_LIST:
      return action.payload;
    case REMOVE_REQUIRED_LIST_BEARING:
      return removeBearingFromRequiredList(state, action.payload);
    case REMOVE_REQUIREDLIST_KEYS:
      return removeBearingFromRequiredListByKeys(state, action.payload);
    default:
      return state
  }
}

function removeBearingFromRequiredList(requiredList, bearingIndex) {
  return requiredList.filter(r => !r.key.startsWith(`bearingInvestigations.bearingInvestigation[${bearingIndex}]`));
}

function removeBearingFromRequiredListByKeys(requiredList, keys) { 
  return requiredList.filter(r => !keys.includes(r.key));
}