import React from "react";

const SvgCheck2 = props => (
    <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" style={{ background: "transparent" }} {...props}>
        <g id="icon_check" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="transparent" x="0" y="0" width="28" height="28"></rect>
            <path d="M5,-1.77635684e-15 L23,-1.77635684e-15 C25.7614237,1.69014829e-16 28,2.23857625 28,5 L28,23 C28,25.7614237 25.7614237,28 23,28 L5,28 C2.23857625,28 8.11988438e-15,25.7614237 5.32907052e-15,23 L5.32907052e-15,5 C4.99089364e-15,2.23857625 2.23857625,-1.26909153e-15 5,-1.77635684e-15 Z" id="Rectangle-5" fill="#88C008"></path>
            <g id="Group" transform="translate(3.999998, 6.500002)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M8.20716122,14.6213263 L19.7070935,3.12127459 C20.0976006,2.73082548 20.0976006,2.0976348 19.7070935,1.70709194 L18.2929802,0.29290076 C17.9024731,-0.0975994929 17.2692881,-0.0976676787 16.8786947,0.29290076 L7.49997539,9.67154642 L3.12131573,5.29282283 C2.73080856,4.90237372 2.09762353,4.90237372 1.70706467,5.29282283 L0.292873918,6.70708219 C-0.0976246393,7.09753131 -0.0976246393,7.73072198 0.292873918,8.12125632 L6.7928757,14.6212411 C7.18346902,15.0118607 7.8165679,15.0118607 8.20716122,14.6213263 Z" id="Path"></path>
            </g>
        </g>
    </svg>
);
    
export default SvgCheck2;



