import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from "react-intl"
import KeyValueTable from 'components/_LivePreview/Shared/KeyValueTable'
import NewKeyValueTable from 'components/_LivePreview/Shared/NewKeyValueTable'
import BearingTable from 'components/_LivePreview/Shared/BearingTable'
import {
    getInvestigationDetailsData,
    fixReportDate,
    getOtherSKFdetailsData,
    transformPersonalInfo,
    transformCustomerDetailsData,
    checkIfInvestigationDetailsExits
} from "../Shared/LivepreviewHelperFunctions"
import { checkIfAnyBearingHasDesignationBrandOrRecommendations, } from "../Shared/GetAndRenderBearingDesignation";


const BartReportInformation = (props) => {
  const data = { ...props.data };
  
  var bearingTable;
  var header;
  var purposeOfInvestigation;
  var detailsHeaderTitle;

  const checkIfDataExsists = (data, config) => {
    let cleanedData = {}
    config.forEach(configElement => {
       let answer = data[configElement.key]
       if (answer !== "" && answer !== undefined){
        cleanedData[configElement.key] = answer
       }
    });
    return cleanedData
  }

  if (data["skfDetails.reportNeedsApproval"]) {
    data["skfDetails.approver.email"] = typeof data["skfDetails.reportNeedsApproval"].approversName === 'object'
      ? data["skfDetails.reportNeedsApproval"].approversName.value
      : data["skfDetails.reportNeedsApproval"].approversName;
    data["skfDetails.approver.name"] = typeof data["skfDetails.reportNeedsApproval"].approversName === 'object'
      ? data["skfDetails.reportNeedsApproval"].approversName.label
      : data["skfDetails.reportNeedsApproval"].approversName;
    const approverPersonalInfo = transformPersonalInfo("skfDetails.approver", data);

    if (approverPersonalInfo.data["skfDetails.approver.personalInfo"] !== undefined) {
      data["skfDetails.reportNeedsApproval"] = approverPersonalInfo.data["skfDetails.approver.personalInfo"]
    }
  }

  props.prefixes.map ((prefix => {
    const personalInfo = transformPersonalInfo(prefix, data);
    data[prefix] = personalInfo.data[`${prefix}.personalInfo`]
  }))


  props.config.reportDetails.map((element => {
    const key = element.key
    if (element.attributeType === "hour" ) {
      if (data[key]) {
        if (parseFloat(data[key]) > 1) {
          data[key] = `${data[key]} ${props.intl.formatMessage({id: "hours"})}`
        } else {
          data[key] = `${data[key]} ${props.intl.formatMessage({id: "hour"})}`
        }
      }
    } 
  }))
    

  if (props.isBearingTableShown) {
    if (checkIfAnyBearingHasDesignationBrandOrRecommendations(data)) {
      bearingTable = <BearingTable 
      data={data}
      numberOfBearings= {data["bearingInvestigations.count"]}
      isGBI= {false}
      />
    }
    if (checkIfInvestigationDetailsExits(props.config.investigationDetails, data) || checkIfAnyBearingHasDesignationBrandOrRecommendations(data)) {
      header = <div className="live-preview-key-value__header">{props.intl.formatMessage({ id: "skfDetails.livePreview.purpose.header"})}</div>
      purposeOfInvestigation = <KeyValueTable config={props.config.investigationDetails} data={getInvestigationDetailsData(props.config.investigationDetails, data)} sectionId="investigationDetails" />
    }
  }

  if (props.isUserExternal || props.isUserExternal == "TRUE") {
    detailsHeaderTitle = props.intl.formatMessage({ id: "skfDetailsExternal"})
  } else {
    detailsHeaderTitle = props.intl.formatMessage({ id: "skfDetails.livePreview.header"})
  }

  return (
    <div className="live-preview-report-information">
      <NewKeyValueTable config={props.config.reportDetails} sectionId="reportDetails" data={fixReportDate(props.config.reportDetails, data)} />

        {header}
        {purposeOfInvestigation}
        {bearingTable}
        {
          props.config.customerDetails && <KeyValueTable 
          header={props.intl.formatMessage({ id: "customerDetails.livePreview.header"})} config={props.config.customerDetails} sectionId="customerDetails" data={transformCustomerDetailsData(data, props.config.customerDetails)} />
        }  
        {
          props.config.skfDetailsOther && <KeyValueTable
          header={detailsHeaderTitle}
          config={props.config.skfDetailsOther} sectionId="skfDetails"
          data={getOtherSKFdetailsData(props.config.skfDetailsOther, data)} />
        }
    </div>
  )
}

BartReportInformation.propTypes = {
  reportDetails: PropTypes.object,
  investigationsDetails: PropTypes.object,
  reportedBy: PropTypes.object
}

export default injectIntl(BartReportInformation)
