import React from 'react'
import PropTypes from 'prop-types'
import LockSvg from 'components/svg/Lock'
import TableCellOverlay from "components/common/TableCellOverlay";
import { FormattedMessage } from "react-intl";

const TableCellBasic = props => {
  return (
    <td className="table__body-cell">
      <div className="table__body-inset">
        {props.cellValue && 
          <React.Fragment>
            <LockSvg className="table__body-lock-svg" svgcolor="#C1C9CC" /><FormattedMessage id="tableCellConfidential.yes" />

          </React.Fragment>
        }
      </div>
      <TableCellOverlay caseId={props.caseId} status={props.status} rowPrefix={props.rowPrefix } />
    </td>
  )
}

TableCellBasic.propTypes = {
  cellValue: PropTypes.bool,
  caseId: PropTypes.string,
  status: PropTypes.string
}

export default TableCellBasic
