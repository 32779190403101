import React from "react";

const SvgCanvasColorSwatch = ({strokeColor, fillColor, size = "48px", title = ""}) => (
  <svg width={size} height={size} viewBox="3 3 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>{title}</title>
    <g id="Icons/icon_colorswatch" stroke={strokeColor ? strokeColor : "none"} strokeWidth="3" fill="none" fillRule="evenodd">
        <path d="M9.39449665,9.39847527 C3.54267242,15.2631088 3.52766775,24.7575152 9.39449665,30.6071497 C15.2613255,36.4567842 24.7593075,36.4717832 30.6111318,30.6071497 C36.462956,24.7425162 36.462956,15.2481098 30.6111318,9.39847527 C24.7443029,3.53384174 15.2463209,3.53384174 9.39449665,9.39847527 Z" id="Vector" fill={fillColor ? fillColor : "#FFFFFF"}></path>
    </g>
</svg>
);

export default SvgCanvasColorSwatch;
