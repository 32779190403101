import React, { Component } from "react";
import Select, { components } from "react-select";
import { injectIntl } from "react-intl";
import { SvgImageLayoutThree, SvgImageLayoutTwo5050, SvgImageLayoutTwo7030, SvgImageLayoutOneThree, SvgImageLayoutOne } from "./../../svg/ImageLayout";

const originalOptions = [{
    "label": "Three figures (Default)",
    "value": "live-preview-images__container--3"
},
{
    "label": "Two figures",
    "value": "live-preview-images__container--50-50"
},
{
    "label": "Two figures (landscape and square)",
    "value": "live-preview-images__container--70-30"
},
{
    "label": "One + three figures",
    "value": "live-preview-images__container--one-plus-three"
},
{
    "label": "One figure",
    "value": "live-preview-images__container--one-figure"
}];
class ImageLayoutSelectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            options:[]
        };
    }

    componentDidMount() {
        if (this.props.selectedValue && this.props.selectedValue.value) {
            this.setState({
                value: this.translateOptions([this.props.selectedValue])[0]
            });
        }
        this.loadOptions();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedValue !== this.props.selectedValue) {
            if (this.props.selectedValue) {
                this.setState({
                    value:  this.translateOptions([this.props.selectedValue])[0]
                });
            }
        }
    }

    loadOptions() {
        this.setState({
            options: this.translateOptions(originalOptions)
        });
    }

    translateOptions = options => options.map(opt => ({ label: opt.value ? this.props.intl.formatMessage({ id: `${opt.value}` }) : "", value: opt.value }));

    render() {
        return (
            <Select
                onChange={event => {
                    if (event !== undefined && event !== null) {
                        this.setState({ value: event });
                        this.props.onChange(event);
                    } else {
                        this.setState({ value: "" });
                        this.props.onChange("");
                    }
                }}
                value={this.state.value}
                options={this.state.options}
                styles={imageLayoutStyles}
                placeholder={this.props.intl.formatMessage({id : "customComponents.select"})}
                isClearable={false}
                components={{ Option: IconOption, SingleValue: IconValue, Placeholder: IconPlaceholder }}
                classNamePrefix="imageLayout-select"
            />
            //Use defaultMenuIsOpen={true} in select to force open state for development purposes
        )
    }
}

const { Option } = components;
const IconOption = props => (
    <Option {...props}>
        {renderIconSvgFromValue(props.data.value, props.data.label)}
    </Option>
);
  
const IconValue = props => {
    return renderIconSvgFromValue(props.data.value, props.data.label);
}

const IconPlaceholder = props => {
    return renderIconSvgFromValue( "live-preview-images__container--3", props.children);
}

const renderIconSvgFromValue = (value, label) => {
    switch (value) {
        case "live-preview-images__container--3":
            return <SvgImageLayoutThree label={ label } fillColor="#88C008" />
        case "live-preview-images__container--50-50":
            return <SvgImageLayoutTwo5050 label={ label } fillColor="#88C008" />
        case "live-preview-images__container--70-30":
            return <SvgImageLayoutTwo7030 label={ label } fillColor="#88C008" />
        case "live-preview-images__container--one-plus-three":
            return <SvgImageLayoutOneThree label={ label } fillColor="#88C008" />
        case "live-preview-images__container--one-figure":
            return <SvgImageLayoutOne label={ label } fillColor="#88C008" />
        default:
            return <SvgImageLayoutThree label={ label } fillColor="#88C008" />
    }

}

const imageLayoutStyles = {
    control: () => ({
        borderRadius: 0,
        backgroundColor: "#88C008",
        marginTop: "5px",
        display: 'flex',
        alignItems: 'center',
        '> div': {
            padding: 0
        }
    }),
    menu: (provided) => ({
        ...provided,
        top: 'none',
        margin: 0,
        boxShadow: 'none',
        borderTop: 0,
        borderRadius: 0
    }),
    menuList: (provided) => ({
        ...provided,
        borderRadius: 0,
        backgroundColor: '#ffffff',
        padding: 0
    }),
    option: (provided, state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.25rem 0',
        borderBottom: '0',
        backgroundColor: state.isSelected ? '#FFFFFF' : state.isFocused ? '#FFFFFF' : '#88C008',
        '&:last-child': {
            borderBottom: 0
        },
        '&:hover': {
            cursor: 'pointer'
        }
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        color: "#fff"
    })
};

export default injectIntl(ImageLayoutSelectComponent);