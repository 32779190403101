const GbiConfig = [
  {
    key: 'reportDetails.reportDate',
    isRequired: false,
    sortOrder: 0
  },
  {
    key: 'reportDetails.reportTitle',
    isRequired: false,
    sortOrder: 1
  },
  {
    key: 'reportDetails.ConfidentialCheckBox',
    isRequired: false,
    sortOrder: 2
  },
  {
    key: 'investigationDetails.terInvestigationDetails.localReferenceNumber',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'investigationDetails.terInvestigationDetails.customerReference',
    isRequired: false,
    sortOrder: 4
  },
  {
    key: 'investigationDetails.terInvestigationDetails.investigationDetailsText',
    isRequired: false,
    sortOrder: 7,
    isFigureText: true,
    imageKey: "investigationDetails.terInvestigationDetails.investigationDetailsFigure"
  },
  {
    key: 'investigationDetails.terInvestigationDetails.investigationDetailsFigure',
    isRequired: false,
    sortOrder: 7,
    textKey: 'investigationDetails.terInvestigationDetails.investigationDetailsText'
  },
  {
    key: 'investigationDetails.terInvestigationDetails.customerName',
    isRequired: false,
    sortOrder: 5
  },
  {
    key: 'investigationDetails.terInvestigationDetails.customerIDReference',
    isRequired: false,
    sortOrder: 6
  },
  {
    key: 'investigationDetails.terInvestigationDetails.customerRequestedDate',
    isRequired: false,
    sortOrder: 7
  },
  {
    key: 'investigationDetails.terInvestigationDetails.X1Coordinator',
    isRequired: false,
    sortOrder: 8
  },
  {
    key: 'investigationDetails.terInvestigationDetails.productLine',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'investigationDetails.terInvestigationDetails.GBICenter',
    isRequired: false,
    sortOrder: 10
  },
  {
    key: 'investigationDetails.terInvestigationDetails.GBIDistributionList',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'investigationDetails.terInvestigationDetails.peopleInvolved',
    isRequired: false,
    sortOrder: 12
  },
  {
    key: 'investigationDetails.terInvestigationDetails.quantity',
    isRequired: false,
    sortOrder: 13
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.localReferenceNumber',
    isRequired: false,
    sortOrder: 13
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.customerReference',
    isRequired: false,
    sortOrder: 14
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.X1Coordinator',
    isRequired: false,
    sortOrder: 15
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.typeOfReturn',
    isRequired: false,
    sortOrder: 16
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.investigationDetailsText',
    isRequired: false,
    sortOrder: 7,
    isFigureText: true,
    imageKey: "investigationDetails.amfrInvestigationDetails.investigationDetailsFigure"
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.investigationDetailsFigure',
    isRequired: false,
    sortOrder: 7,
    textKey: 'investigationDetails.amfrInvestigationDetails.investigationDetailsText'
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.customerName',
    isRequired: false,
    sortOrder: 17
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.customerRequestedDate',
    isRequired: false,
    sortOrder: 18
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.productLine',
    isRequired: false,
    sortOrder: 19
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.GBICenter',
    isRequired: false,
    sortOrder: 20
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.GBIDistributionList',
    isRequired: false,
    sortOrder: 21
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.peopleInvolved',
    isRequired: false,
    sortOrder: 22
  },
  {
    key: 'investigationDetails.amfrInvestigationDetails.quantity',
    isRequired: false,
    sortOrder: 23
  },
  {
    key: 'investigationDetails.OtherInvestigationDetails.skfCaseNumber',
    isRequired: false,
    sortOrder: 23
  },
  {
    key: 'investigationDetails.OtherInvestigationDetails.purpose',
    isRequired: false,
    sortOrder: 24
  },
  {
    key: 'investigationDetails.OtherInvestigationDetails.scopeAndPurpose',
    isRequired: false,
    sortOrder: 25
  },
  {
    key: 'investigationDetails.OtherInvestigationDetails.investigationDetailsText',
    isRequired: false,
    sortOrder: 7,
    isFigureText: true,
    imageKey: "investigationDetails.OtherInvestigationDetails.investigationDetailsFigure"
  },
  {
    key: 'investigationDetails.OtherInvestigationDetails.investigationDetailsFigure',
    isRequired: false,
    sortOrder: 7,
    textKey: 'investigationDetails.OtherInvestigationDetails.investigationDetailsText'
  },
  {
    key: 'investigationDetails.OtherInvestigationDetails.country',
    isRequired: false,
    sortOrder: 26
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.customer',
    isRequired: false,
    sortOrder: 27
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.customer.addressValue',
    isRequired: false,
    sortOrder: 28
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.customer.cityAndStateOrTerritoryValue',
    isRequired: false,
    sortOrder: 29
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.customer.postcodeValue',
    isRequired: false,
    sortOrder: 30
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.customer.countryValue',
    isRequired: false,
    sortOrder: 31
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.site',
    isRequired: false,
    sortOrder: 32
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.plantOrTrainOrShipName',
    isRequired: false,
    sortOrder: 33
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.customerContactName',
    isRequired: false,
    sortOrder: 35
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.customerContactJobTitle',
    isRequired: false,
    sortOrder: 36
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.customerContactEmail',
    isRequired: false,
    sortOrder: 37
  },
  {
    key: 'customerDetails.OtherInvestigationDetails.customerContactPhone',
    isRequired: false,
    sortOrder: 38
  },
  {
    key: 'investigationDetails.OtherInvestigationDetails.customerRequestedDate',
    isRequired: false,
    sortOrder: 39
  },
  {
    key: 'investigationDetails.OtherInvestigationDetails.describePurposeOfBearing',
    isRequired: false,
    sortOrder: 40
  },
  {
    key: 'skfDetails.countryCompanyLocation',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'skfDetails.countryCompanyLocation.company',
    isRequired: false,
    sortOrder: 8
  },
  {
    key: 'skfDetails.countryCompanyLocation.location',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'skfDetails.reportAnalysisBy',
    isRequired: false,
    sortOrder: 41
  },
  {
    key: 'skfDetails.reportAuthorPersonalInfo',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.coReportAuthorPersonalInfo',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.coReportAuthorTwo',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.coReportAuthorThree',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.coReportAuthorFour',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'customerDetails.customerAddress',
    isRequired: false,
    sortOrder: 23
  },
  {
    key: 'customerDetails.customerCityStateTerritory',
    isRequired: false,
    sortOrder: 24
  },
  {
    key: 'customerDetails.customerPostCode',
    isRequired: false,
    sortOrder: 25
  },
  {
    key: 'customerDetails.customerCountry',
    isRequired: false,
    sortOrder: 26
  },
  {
    key: 'customerDetails.site',
    isRequired: true,
    sortOrder: 27
  },
  {
    key: 'customerDetails.plantOrTrainOrShipName',
    isRequired: false,
    sortOrder: 28
  },
  {
    key: 'customerDetails.customerContact.name',
    isRequired: false,
    sortOrder: 31
  },
  {
    key: 'customerDetails.customerContact.jobTitle',
    isRequired: false,
    sortOrder: 32
  },
  {
    key: 'customerDetails.customerContact.email',
    isRequired: false,
    sortOrder: 33
  },
  {
    key: 'customerDetails.customerContact.phone',
    isRequired: false,
    sortOrder: 34
  },
  {
    key: 'coReportAuthor',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'coReportAuthorTwo',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'coReportAuthorThree',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'coReportAuthorFour',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'skfDetails.reportApprovedBy',
    isRequired: false,
    sortOrder: 42
  },
  {
    key: 'skfDetails.reportNeedsApproval',
    isRequired: true,
    sortOrder: 61
  },
  {
    key: 'summary.conclusions',
    isRequired: false,
    isFullWidth: true,
    sortOrder: 62,
    isRichText: true
  },
  {
    key: 'summary.partAction',
    sortOrder: 62,
    isRichText: false
  },
  {
    key: 'summary.partActionTimeLine',
    sortOrder: 62,
    isRichText: false
  },
  {
    key: 'summary.equipmentUsed',
    isRequired: false,
    sortOrder: 63
  },
  {
    key: 'summary.testEquipment',
    isRequired: false,
    isFullWidth: true,
    isContainer: true,
    sortOrder: 63,
    cellType: 'equipmentTable'
  },
  {
    key: 'attachments.attachments',
    isRequired: false,
    sortOrder: 64
  },
  {
    key: 'bearingInvestigations.skfBearingTypeGBI',
    isRequired: false,
    sortOrder: 0
  },
  {
    key: 'bearingInvestigations.bearingSkfCustomerDescription',
    isRequired: false,
    sortOrder: 1,
    isRichText: true
  },
  {
    key: 'bearingInvestigations.dateOfReciept',
    isRequired: false,
    sortOrder: 1,
  },
  {
    key: 'bearingInvestigations.terBearingInvestigation',
    isRequired: false,
    sortOrder: 2
  },
  {
    key: 'bearingInvestigations.terBearingInvestigation.productServiceDesignation',
    isRequired: false,
    sortOrder: 2
  },
  {
    key: 'bearingInvestigations.terBearingInvestigation.quantity',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'bearingInvestigations.terBearingInvestigation.application',
    isRequired: false,
    sortOrder: 4
  },
  {
    key: 'bearingInvestigations.terBearingInvestigation.applicationDetails',
    isRequired: false,
    sortOrder: 5
  },
  {
    key: 'bearingInvestigations.terBearingInvestigation.typeOfReturn',
    isRequired: false,
    sortOrder: 6
  },
  {
    key: 'bearingInvestigations.terBearingInvestigation.customerReferencePartNumber',
    isRequired: false,
    sortOrder: 7
  },
  {
    key: 'bearingInvestigations.terBearingInvestigation.problemDescription',
    isRequired: false,
    sortOrder: 8
  },
  {
    key: 'bearingInvestigations.terBearingInvestigation.problemDetail',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation',
    isRequired: false,
    sortOrder: 10
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.productDesignation',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.quantity',
    isRequired: false,
    sortOrder: 12
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.skfBearingDesignation',
    isRequired: false,
    sortOrder: 13
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.customerIdRefrenceNumber',
    isRequired: false,
    sortOrder: 14
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.competitorBearing',
    isRequired: false,
    sortOrder: 15
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.application',
    isRequired: false,
    sortOrder: 16
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.vehicleNumber',
    isRequired: false,
    sortOrder: 17
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.carSalesDate',
    isRequired: false,
    sortOrder: 18
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.carRepairDate',
    isRequired: false,
    sortOrder: 19
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.carType',
    isRequired: false,
    sortOrder: 20
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.duration',
    isRequired: false,
    sortOrder: 21
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.problemDetails',
    isRequired: false,
    sortOrder: 22
  },
  {
    key: 'bearingInvestigations.amfrBearingInvestigation.problemDescription',
    isRequired: false,
    sortOrder: 23
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation',
    isRequired: false,
    sortOrder: 24
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.bearingDesignation',
    isRequired: false,
    sortOrder: 25
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.bearingDesignation.manufacturer',
    isRequired: false,
    sortOrder: 26
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.bearingDesignation.SKFManufacturer',
    isRequired: false,
    sortOrder: 27
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.bearingDesignation.skfBearingDesignation',
    isRequired: false,
    sortOrder: 28
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.bearingDesignation.competitorDesignationAndMarkings',
    isRequired: false,
    sortOrder: 29
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.backgroundInformationText',
    isRequired: false,
    isFullWidth: true,
    sortOrder: 30,
    isRichText: true
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.recurringFailure',
    isRequired: false,
    sortOrder: 31
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.lengthOfBearingOperated',
    isRequired: false,
    sortOrder: 32
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.operation.continuousOrIntermittent',
    isRequired: false,
    sortOrder: 34
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.expectedLife',
    isRequired: false,
    sortOrder: 35
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.costOfFailure',
    isRequired: false,
    sortOrder: 36
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.reasonWasRemoved',
    isRequired: false,
    sortOrder: 37
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.machineOrAssetCode',
    isRequired: false,
    sortOrder: 38
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.functionalAreaName',
    isRequired: false,
    sortOrder: 39
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.systemNameWhereAssetIsUsed',
    isRequired: false,
    sortOrder: 40
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.purposeOfAsset',
    isRequired: false,
    sortOrder: 41
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.rotationalSpeed',
    isRequired: false,
    sortOrder: 42
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.temperature',
    isRequired: false,
    sortOrder: 43
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.lubricationType',
    isRequired: false,
    sortOrder: 44
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.lubricationType.lubricationBrand',
    isRequired: false,
    sortOrder: 44
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.lubricationType.oilType',
    isRequired: false,
    sortOrder: 45
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.lubricationType.oilBathBelowShaft',
    isRequired: false,
    sortOrder: 46
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.lubricationType.circulatingOilFlowRate',
    isRequired: false,
    sortOrder: 47
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.lubricationType.greaseType',
    isRequired: false,
    sortOrder: 46
  },
  {
    key: 'bearingInvestigations.otherBearingInvestigation.lubricationType.greaseTypeGrams',
    isRequired: false,
    sortOrder: 47
  },
  {
    key: 'bearingInvestigations.allPartsAvailable',
    isRequired: false,
    sortOrder: 48
  },
  {
    key: 'bearingInvestigations.skfCompany',
    isRequired: false,
    sortOrder: 49
  },
  {
    key: 'bearingInvestigations.skfCompany.company',
    isRequired: false,
    sortOrder: 50
  },
  {
    key: 'bearingInvestigations.skfCompany.location',
    isRequired: false,
    sortOrder: 51
  },
  {
    key: 'bearingInvestigations.manufacturingDateCode',
    isRequired: false,
    sortOrder: 52
  },
  {
    key: 'bearingInvestigations.testEquipment',
    isRequired: false,
    isFullWidth: true,
    isContainer: true,
    cellType: 'equipmentTable',
    sortOrder: 53
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.arrival.arrivalFailureAndCause',
    isRequired: false,
    sortOrder: 50
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.arrival.greasedFailureAndCause',
    isRequired: false,
    sortOrder: 50
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.outerRing.racewayFailureAndCause',
    isRequired: false,
    sortOrder: 51
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.outerRing.diameterFailureAndCause',
    isRequired: false,
    sortOrder: 52
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.outerRing.sideFaceMarkedFailureAndCause',
    isRequired: false,
    sortOrder: 53
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.outerRing.sideFaceOppositeFailureAndCause',
    isRequired: false,
    sortOrder: 54
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.outerRing.outerRingFlangeFailureAndCause',
    isRequired: false,
    sortOrder: 55
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.innerRing.racewayFailureAndCause',
    isRequired: false,
    sortOrder: 55
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.innerRing.boreFailureAndCause',
    isRequired: false,
    sortOrder: 56
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.innerRing.sideFaceMarkedFailureAndCause',
    isRequired: false,
    sortOrder: 57
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.innerRing.sideFaceOppositeFailureAndCause',
    isRequired: false,
    sortOrder: 58
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.innerRing.innerRingFlangeFailureAndCause',
    isRequired: false,
    sortOrder: 59
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.rollingElements.rowOneFailureAndCause',
    isRequired: false,
    sortOrder: 59
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.rollingElements.rowTwoFailureAndCause',
    isRequired: false,
    sortOrder: 60
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.rollingElements.rowThreeFailureAndCause',
    isRequired: false,
    sortOrder: 61
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.rollingElements.rowFourFailureAndCause',
    isRequired: false,
    sortOrder: 62
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.cage.cageOneFailureAndCause',
    isRequired: false,
    sortOrder: 63
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.cage.cageTwoFailureAndCause',
    isRequired: false,
    sortOrder: 64
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.cage.cageThreeFailureAndCause',
    isRequired: false,
    sortOrder: 65
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.cage.cageFourFailureAndCause',
    isRequired: false,
    sortOrder: 66
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.guideRing.guideRingFailureAndCause',
    isRequired: false,
    sortOrder: 67
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.sealsOrShields.sealOrShieldOneFailureAndCause',
    isRequired: false,
    sortOrder: 68
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.sealsOrShields.sealOrShieldTwoFailureAndCause',
    isRequired: false,
    sortOrder: 69
  },
  {
    key: 'bearingInvestigations.componentPartsInvestigation.otherComponent.otherFailureAndCause',
    isRequired: false,
    sortOrder: 70
  },
  {
    key: 'bearingInvestigations.analysis.findings',
    isRequired: false,
    isFullWidth: true,
    sortOrder: 71,
    isRichText: true
  },
  {
    key: 'bearingInvestigations.analysis.primaryFailureAndCause',
    isRequired: false,
    sortOrder: 75
  },
  {
    key: 'bearingInvestigations.analysis.additionalFailureAndCause',
    isRequired: false,
    sortOrder: 79
  },
  {
    key: 'bearingInvestigations.GBI-ForgingDieCode.forgingDieCodeInnerRing',
    isRequired: false,
    sortOrder: 80
  },
  {
    key: 'bearingInvestigations.GBI-ForgingDieCode.forgingDieCodeOuterRing',
    isRequired: false,
    sortOrder: 81
  }
]

export default GbiConfig
