
import React from 'react'
import { useIntl } from 'react-intl';
import renderHTML from "react-render-html";

const TestEquipmentTable = ({ data, config }) => { 
    const intl = useIntl();

    const headers = [
        intl.formatMessage({ id: "testEquipment.test" }),
        intl.formatMessage({ id: "testEquipment.testMethod" }),
        intl.formatMessage({ id: "testEquipment.operator" }),
        intl.formatMessage({ id: "testEquipment.instrument" }),
        intl.formatMessage({ id: "testEquipment.date" }),
    ]

    return data && data.length > 0 ? <div className="live-preview-images live-preview-equipment-table">
        <div className="live-preview-images__headline" >{intl.formatMessage({ id: "preview.testEquipment" })}</div>
        <div className="live-preview-images__container">
            <table data-key-id={config.key}>
                <tr>
                    {headers.map((header, index) => <th key={index} className='live-preview-key-value__key'>{header}</th>)}
                </tr>
                {data.map((row, index) => <tr key={index}>
                    {Object.keys(row).map((key, index) => <td key={index} className='live-preview-key-value__value'>{renderHTML(row[key])}</td>)}
                </tr>)}
            </table>
        </div>
    </div> : null;
}

export default TestEquipmentTable;