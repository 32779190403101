import React from "react";
import SkfLogo from "components/svg/SkfLogo";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import DeployFormtemplates from "components/formcomponents/deployFormtemplates";
import { useSelector } from "react-redux";

const isLocal = process.env.NODE_ENV === 'development';

const TheFooter = ({ isUserExternal }) => {
  const connection = useSelector(state => state.connection);
  const user = useSelector(state => state.currentUser);

  const renderPublicFooterLinks = () => (
    <>
      <a href="https://www.skf.com/group/footer/terms-and-conditions" className="footer__link" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="footer.termsOfUse" />
      </a>
      <a href="https://www.skf.com/group/footer/privacy-policy" className="footer__link" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="footer.privacypolicy" />
      </a>
      <LicenseAgreementLink />
      <a href="https://www.skf.com/group/footer/site-ownership" className="footer__link" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="footer.siteownership" />
      </a>
      <a href="https://www.skf.com/group/footer/general-conditions-of-sales" className="footer__link" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="footer.generalconditionsofsales" />
      </a>
      <a href="?cmpscreen" className="footer__link">Privacy settings</a>
    </>
  );

  const renderLoggedInFooterLinks = () => (
    <>
      <Link to={"/about"} className="footer__link">
        <FormattedMessage id="footer.about" />
      </Link>
      <LicenseAgreementLink />
      {connection && !isUserExternal ? <>
        <a href="https://skfgroup.sharepoint.com/sites/BART-Public/Shared%20Documents/Forms/AllItems.aspx?viewpath=%2Fsites%2FBART-Public%2FShared%20Documents%2FForms%2FAllItems.aspx" className="footer__link" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="footer.openUserGuide" />
        </a>
        <a href="https://www.yammer.com/skf.com/#/threads/inGroup?type=in_group&feedId=7941463&view=all" className="footer__link" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="footer.addQuestionsOnYammer" />
        </a>
        <a href="?cmpscreen" className="footer__link">Privacy settings</a>
      </> : null}
      {isLocal ? <DeployFormtemplates /> : null}
    </>
  );

  const renderLinks = () => {
    if (user.sub) {
      return renderLoggedInFooterLinks();
    } else {
      return renderPublicFooterLinks();
    }
  }

  return (
    <footer className="footer">
      <div className="footer__container">
        <SkfLogo className="footer__logo" />
        <div key="footer__copyright" className="footer__copyright">
          <span>&copy;</span>
          <FormattedMessage id="footer.copyright" />
        </div>
        {renderLinks()}
      </div>
    </footer>
  );
};

const LicenseAgreementLink = () => (
  <Link to={"/license-agreement"} className="footer__link">
    <FormattedMessage id="footer.license-agreement" />
  </Link>
);

export default TheFooter;
