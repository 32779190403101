import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { setConfidential } from "../../../redux/actions/isConfidentialActions";
import { setExcludeISOAppendix } from "../../../redux/actions/excludeISOAppendixActions";
import { setIsRailwayWheelsetBearing } from '../../../redux/actions/isRailwayWheelsetBearingActions';
import { getBearingIndexFromName } from "../utils/BearingUtils"
import { setUseAutomaticFigureNumbering, setDisableAutoscroll } from '../../../redux/actions/saveComponentValueAction';
import { setPageBreakEachBearing } from "../../../redux/actions/pageBreakEachBearingActions";
import { showSummaryAtTop } from "../../../redux/actions/showSummaryAtTopAction";
import config from "config";
import repository from "./../../../core/repository";
import { intlFormat } from "date-fns";

class CheckboxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    let value = false;
    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      value = this.props.parentSetValue;
    } else {
      value = this.props.getValue(this.props.myName);
    }
    if (value !== undefined) {
      this.setState({ checked: value });
    } else {
      this.setState({ checked: false });
    }

    if (this.isAutomaticFigureNumbering()) { 
      this.props.dispatch(setUseAutomaticFigureNumbering(value));
    }

    if (this.isExcludeISOAppendixComp() && value !== undefined) {
      this.props.dispatch(setExcludeISOAppendix(value));
    }

    if (this.isPageBreakEachBearingComp() && value !== undefined) {
      this.props.dispatch(setPageBreakEachBearing(value))
    }

    if (this.isDisableAutoScroll()) {
      repository.getUserProperties().then(res => {
        if (res && res.disableAutoscroll !== undefined) {
          this.setState({ checked: res.disableAutoscroll });
          this.props.dispatch(setDisableAutoscroll(res.disableAutoscroll));
        } else {
          this.setState({ checked: false });
        }
      });
    }

    if (this.isShowSummaryAtTop()) {
      this.props.dispatch(showSummaryAtTop(value));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isConfidential !== this.props.isConfidential &&
      this.isConfidentialComp()
    ) {
      this.handleChange(this.props.isConfidential);
    }

    if (
      prevProps.excludeISOAppendix !== this.props.excludeISOAppendix &&
      this.isExcludeISOAppendixComp()
    ) {
      this.handleChange(this.props.excludeISOAppendix);
    }

    if (
        prevProps.pageBreakEachBearing !== this.props.pageBreakEachBearing &&
        this.isPageBreakEachBearingComp()
    ) {
      this.handleChange(this.props.pageBreakEachBearing);
    }
  }

  handleChange = (checked) => {
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(checked, this.props.myName);
    } else {
      this.props.setValue(checked, this.props.myName);
    }

    this.setState({ checked: checked });
    if (this.isDisableAutoScroll()) {
      repository.updateUserProperties({ disableAutoscroll: checked })
        .then(res => this.props.dispatch(setDisableAutoscroll(checked)))
        .catch(err => console.error(err));
    }
    if (this.isConfidentialComp()) {
      this.props.dispatch(setConfidential(checked));
    }
    if (this.isRailwayWheelsetBearingComp()) {
      this.props.dispatch(
        setIsRailwayWheelsetBearing({ isRailwayBearing: checked, bearingIndex: getBearingIndexFromName(this.props.myName) })
      );
    }
    if (this.isAutomaticFigureNumbering()) { 
      this.props.dispatch(setUseAutomaticFigureNumbering(checked));
    }
    if (this.isExcludeISOAppendixComp()) {
      this.props.dispatch(setExcludeISOAppendix(checked));
    }
    if (this.isPageBreakEachBearingComp()) {
      this.props.dispatch(setPageBreakEachBearing(checked));
    }
    if (this.isShowSummaryAtTop()) {
      this.props.dispatch(showSummaryAtTop(checked));
    }
  };

  isShowSummaryAtTop = () =>
    this.props.myName === "reportDetails.ShowSummaryAtTop" || this.props.myName === "reportDetails.ShowInspectionSummaryAtTop"
  
  isConfidentialComp = () =>
    this.props.myName === "reportDetails.ConfidentialCheckBox";

  isRailwayWheelsetBearingComp = () =>
    this.props.myName.includes(
      "operatingData.railwayWheelsetBearing.isRailwayWheelsetBearing"
    );
  
  isAutomaticFigureNumbering = () => 
      this.props.myName === "reportDetails.AutoFigureNumbering";

  isExcludeISOAppendixComp = () =>
    this.props.myName === "reportDetails.ExcludeISOAppendixInReportCheckBox";

  isPageBreakEachBearingComp = () =>
      this.props.myName === "reportDetails.PageBreakEachBearing";

  isDisableAutoScroll = () =>
    this.props.myName === "reportDetails.DisableAutoScroll";

  showDescription = (name, description) => { 
    if (name === "reportDetails.ShowInspectionSummaryAtTop" && this.props.formTemplate === config.US_INSPECTION_TEMPLATE_ID) {
      return this.props.intl.formatMessage({id: "ShowUsInspectionSummaryAtTop"})
    } else { 
      return description;
    }
  }
  render() {
    return (
      <div
        className={
          "input-shell checkbox"
        }
      >
        <input
          id={this.props.myName}
          type="checkbox"
          checked={this.state.checked}
          onChange={(e) => this.handleChange(e.target.checked)}
          className="checkbox__hidden"
        />

        <label
          htmlFor={this.props.myName}
          className="checkbox__label checkbox__label--dark"
          tabIndex="0"
        >
          <span className="checkbox__label-span">
            {this.showDescription(this.props.myName, this.props.formComponentData.description)}
          </span>
        </label>
        {this.props.formComponentData.required && (
          <div className="input-shell__message">
            <div className="input-shell__message-hint">
              {" "}
              <FormattedMessage id="formComponents.required" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formTemplate: state.formTemplate,
    isConfidential: state.isConfidential,
    excludeISOAppendix: state.excludeISOAppendix,
    pageBreakEachBearing: state.pageBreakEachBearing,
  };
}

export default injectIntl(connect(mapStateToProps)(CheckboxComponent));
