import React, { Component, useEffect, useState } from "react";
import BartPreview from "components/_LivePreview/Bart/Bart";
import InspectionPreview from "components/_LivePreview/Inspection/Inspection";
import UsInspectionPreview from "components/_LivePreview/UsInspection/UsInspection";
import GbiPreview from "components/_LivePreview/Gbi/Gbi";
import WindPreview from "components/_LivePreview/Wind/Wind";
import config from "config"
import classnames from 'classnames'
import { connect, shallowEqual, useSelector } from "react-redux";

const LivePreview = ({ classNames }) => { 

  const answers = useSelector(state => state.saveComponentValue.components, shallowEqual);
  const answersToSave = useSelector(state => state.saveComponentValue.componentsToSave, shallowEqual);
  const isUserExternal = useSelector(state => state.currentUser.isExternal, shallowEqual);
  const currentCase = useSelector(state => state.currentCase.case, shallowEqual);

  const [previewAnswers, setPreviewAnswers] = useState();

  useEffect(() => {
    const convertedAnswers = answers.reduce((acc, answer) => {
      try {
        const answerToSave = answersToSave.find(a => a.answer.name === answer.name);
        if (answerToSave && answerToSave.answer.value) {
          acc[answer.name] = JSON.parse(answerToSave.answer.value);
        } else { 
          if (answer.value) {
            acc[answer.name] = JSON.parse(answer.value);
          }
        }
      } catch (error) {
        console.log(answer);
        console.log(error);
      }
      return acc;
    }, {});

    answersToSave.forEach(answerToSave => {
      const existingAnswer = Object.keys(convertedAnswers).find(key => key === answerToSave.answer.name);
      if (!existingAnswer) {
        if (answerToSave.answer.value) {
          convertedAnswers[answerToSave.answer.name] = JSON.parse(answerToSave.answer.value);
        }
      }
    });

    setPreviewAnswers(convertedAnswers);
   }, [answers, answersToSave]);


  const selectPreview = (id) => {
    const props = {
      data: previewAnswers,
      isUserExternal,
      revisionNumber: currentCase.revision_number,
      reportNumber: currentCase.id,
      caseStatus: currentCase.status
    }
    if (id == config.BART_TEMPLATE_ID) {
      return <BartPreview {...props} />
    } else if (id == config.GBI_TEMPLATE_ID) {
      return <GbiPreview {...props} />
    } else if (id == config.INSPECTION_TEMPLATE_ID) {
      return <InspectionPreview {...props} />
    } else if (id == config.US_INSPECTION_TEMPLATE_ID) {
      return <UsInspectionPreview {...props} />
    } else if ( id == config.WIND_TEMPLATE_ID ) {
      return <WindPreview {...props}/>
    }
  }

  return <div id="livePreview" className={classnames('page-layout__content page-layout__content--live-preview', classNames)}>
    { currentCase !== null && previewAnswers ? selectPreview(currentCase.formtemplateId) :  <div className="element--is-loading-after"></div> }
  </div>
  
}

function mapStateToProps(state) {
  return {
    answers: state.saveComponentValue.components,
    isUserExternal: state.currentUser.isExternal,
    currentCase: state.currentCase
  };
}

export default connect(mapStateToProps)(LivePreview);
