
import repository from "core/repository";

import { approveCase as approveCaseThunk } from "redux/thunks/caseThunks";

export default function approveCase(caseId, answers, user, dispatchF) {
    return new Promise((resolve, reject) => {
        dispatchF(approveCaseThunk(caseId, (response) => { 
            if (hasAiAnswers(answers)) {
                approvePredictionFeedback(answers, user);
            }
            resolve({ noApprover: false, notValid: false, caseStatus: response.data });
        }));
    });
}

function approvePredictionFeedback(answers, user) {
    const promises = getAiAnswers(answers)
        .filter(a => a.prediction && a.prediction.resourceId)
        .map(failureanswer => repository.approveFeedback(failureanswer.prediction.resourceId, failureanswer.failureCode.value, user.email));
    Promise.all(promises);
}

export function hasAiAnswers(answers) {
    return answers.some(a => isAiAnswer(a));
}

function getAiAnswers(answers){
    return answers.filter(a => isAiAnswer(a)).map(answer => JSON.parse(answer.value));
}

function isAiAnswer(answer){
    if (answer.name.endsWith("analysis.primaryFailureAndCause") || answer.name.endsWith("analysis.additionalFailureAndCause")) {
        return answer.value.includes("prediction\":{");
    }
    return false;
}