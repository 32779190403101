import React from 'react'
import { injectIntl } from "react-intl"
import { connect } from "react-redux";
import classnames from "classnames";

const ObservationsAndFailuremode = (props) => {

  const getAnalysis = (name) => {
    if ( name !== undefined && name !== "" && name !== null) {
      if (name.endsWith("primaryFailureAndCause")) {
        return translated.failureCodePrimaryTranslated;
      } else if (name.endsWith("primaryFailureAndCauseIso")) {
        return translated.failureCodePrimaryTranslatedIso;
      } else if (name.endsWith("additionalFailureAndCause")) {
        return translated.failureCodeAdditionalTranslated;
      } else if (name.endsWith("additionalFailureAndCauseIso")) {
        return translated.failureCodeAdditionalTranslatedIso;
      } else {
          return translated.failureCodeTranslated;
      }
    }
  }

  const isAnalysis = (name) => {
    if ( name !== undefined && name !== "" && name !== null) {
      if (name.endsWith("primaryFailureAndCause")) {
        return true;
      } else if (name.endsWith("primaryFailureAndCauseIso")) {
        return true;
      } else if (name.endsWith("additionalFailureAndCause")) {
        return true;
      } else if (name.endsWith("additionalFailureAndCauseIso")) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  const hasScrollTarget = key => {
    if (props.scrollTarget) {
      if (props.scrollTarget.fieldTarget || props.scrollTarget.scrollTarget) {
        return props.scrollTarget.fieldTarget === key || props.scrollTarget.scrollTarget.includes(key);
      }
    }
    return false;
  }

  const translated = {
    failureCodeTranslated : props.intl.formatMessage({ id: "failureCode"}),
    failureCodePrimaryTranslated : props.intl.formatMessage({ id: "failureCodePrimary"}),
    failureCodePrimaryTranslatedIso : props.intl.formatMessage({ id: "failureCodePrimaryIso"}),
    failureCodeAdditionalTranslated : props.intl.formatMessage({ id: "failureCodeAdditional"}),
    failureCodeAdditionalTranslatedIso : props.intl.formatMessage({ id: "failureCodeAdditionalIso"}),
    causeTranslated : props.intl.formatMessage({ id: "cause"}),
    observationsTranslated: props.intl.formatMessage({ id: "observations" }),
    hasAFABeenUsedTranslated: props.intl.formatMessage({ id: "customComponents.failurePrediction.failurePost" })
  }

  let hasObservations = false
  let hasFailure = false
  let hasCause = false
  let hasAFABeenUsed = false;
  let failureModeNameId = getAnalysis(props.configKey)

  if ( props.answer !== undefined) {
    if (props.answer.observations !== "" && props.answer.observations !== undefined){
      hasObservations = true
    }
    if (props.answer.cause !== "" && props.answer.cause !== undefined){
      hasCause = true
    }
    if (props.answer.failureCode !== "" && props.answer.failureCode !== undefined){
      hasFailure = true
      if (props.answer.prediction !== undefined && props.answer.prediction !== null) {
        const predictions = props.answer.prediction.approvePredictions && props.answer.prediction.approvePredictions.length > 0 ? props.answer.prediction.approvePredictions : props.answer.prediction.draftPredictions;
        if (predictions) {
          const selectedPrediction = predictions.find(p => p.feedback.isFailureSelected);
          if (selectedPrediction) {
            hasAFABeenUsed = selectedPrediction.failureCode === props.answer.failureCode.value;
          }  
        }
      }
    }
  }

  const failureCodeText = answer => {
    if (answer) {
      if (answer.failureCode && answer.failureCode.label) {
        if (hasAFABeenUsed) {
          return answer.failureCode.label + " " + translated.hasAFABeenUsedTranslated
        } else {
          return answer.failureCode.label  
        }
      }
    }
    return "";
  }
  
  const getSkipPageBreakClass = (content) => {
      return content.length > 1000 ? "skip-break" : "";
  }

  return (
    <React.Fragment>
      {(hasFailure || hasCause || hasObservations || hasScrollTarget(props.configKey)) && <div data-key-id={props.configKey} key={props.configKey} className="live-preview-key-value__container">
        {
          (hasFailure || hasCause || (hasScrollTarget(props.configKey) && isAnalysis(props.configKey))) &&
          <div key={props.configKey + ".failure"}  className="live-preview-key-value__row">
            {(hasFailure || (hasScrollTarget(props.configKey) && isAnalysis(props.configKey))) && <div className="live-preview-key-value__cell">
              <div className="live-preview-key-value__key">{failureModeNameId}:</div>
              <div className="live-preview-key-value__value">{failureCodeText(props.answer)}</div>
            </div>}

            {(hasCause || (hasScrollTarget(props.configKey) && isAnalysis(props.configKey))) && <div className="live-preview-key-value__cell">
              <div className="live-preview-key-value__key">{translated.causeTranslated}:</div>
              <div className="live-preview-key-value__value">{props.answer ? props.answer.cause ? props.answer.cause.label : "" : ""}</div>
            </div>}
          </div>
        }
        {
          (hasObservations || hasScrollTarget(props.configKey)) &&
          <div key={props.configKey + ".observations"} className={classnames("live-preview-key-value__row", getSkipPageBreakClass(props.answer ? props.answer.observations : "") )}>
            <div className="live-preview-key-value__cell">
              <div className="live-preview-key-value__key">{translated.observationsTranslated}:</div>
              <div className="live-preview-key-value__value" dangerouslySetInnerHTML={props.answer ? { __html: props.answer.observations } : { __html: "" }}></div>
            </div>
          </div>
        }
      </div>}
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    scrollTarget: state.saveComponentValue.scrollTarget
  };
};

export default connect(mapStateToProps)(injectIntl(ObservationsAndFailuremode));
