import { getBearingIndexFromName } from "../../../components/formcomponents/utils/BearingUtils";
import imageOrder from "../../../Configs/image-order.json"
import { addAnswerToSave } from "./answersToSaveEffects";

export function getImageUrlsFromState(answers) {
  return answers
    .reduce((acc, item) => {
      const urls = getImageUrlsFromAnswer(item.value, item.name)
        .filter(imageToAdd => !acc.find(image => image.url === imageToAdd.url));
      return [...acc, ...urls];
    }, []);
}

function getImageUrlsFromAnswer(answervalue, answername) {
  if (answervalue?.includes("imageUrls")) {
    const value = JSON.parse(answervalue);
    if (value.imageUrls) {
      return value.imageUrls.filter(image => image.url && image.url !== "")
        .map(image => ({ ...image, name: answername }));
    }
  }
  return [];
}

export function combineImagesFromComponentTypSave(components, componentsToSave, images, payload) { 
  if (payload.answer.value?.includes("imageUrls")) {
    return [...getImageUrlsFromState(componentsToSave.map(a => a.answer), ...getImageUrlsFromState(components.filter(a => a.name !== payload.answer.name)))].reduce((urls, url) => { 
      if (!urls.find(u => u.url === url.url)) {
        urls = [...urls, url];
      }
      return urls;
    }, []);
  } else { 
    return images;
  }
}

export function addImageNumberingToComponentsToSave(components, componentsToSave, payload) {
  if (hasImages(payload.answer.value) || isAutomaticFigureNumbering(componentsToSave.map(c => c.answer))) {
    const imageComponentsToSave = componentsToSave.map(a => a.answer).filter(c => hasImages(c.value));
    const existingImageComponents = components.filter(a => !componentsToSave.some(c => c.answer.name === a.name)).filter(c => hasImages(c.value));

    const componentToSaveIndex = imageComponentsToSave.findIndex(component => component.isSaving);

    let imagesFound;
    let newImageComponentsToSave = [...imageComponentsToSave];
    if (componentToSaveIndex > -1) {
      imagesFound = imageComponentsToSave[componentToSaveIndex];
      newImageComponentsToSave.splice(componentToSaveIndex, 1);
    }

    if (imagesFound) {
      newImageComponentsToSave.push(imagesFound);
    }

    let allComponents = [...existingImageComponents, ...newImageComponentsToSave];

    const gbiReport = components.find(cmp => cmp.value?.includes('GBIReportType'));
    if (gbiReport) {
      try {
        const gbiValue = JSON.parse(gbiReport.value);
        allComponents = [...allComponents, { gbiReportType: gbiValue.GBIReportType.value }];
      } catch (e) {
        console.error(`There was a problem parsing the report: ${e}`);
      }
    }

    const numberedImages = addImageNumbering({ components: allComponents }).components;

    numberedImages.forEach(imageComponent => {
      if (hasImages(imageComponent.value)) {
        componentsToSave = addAnswerToSave(componentsToSave, imageComponent)
      }
    });
  }
  return componentsToSave;
}

export function addImageNumbering(state) {
  const counter = { count: 0 };
  var selectedGBIReportType = ""

  const isGbiReport = state.components.some(cmp => cmp.gbiReportType);
  if (isGbiReport) {
    //if it is a gbi report, it will always be in the end of the components
    selectedGBIReportType = state.components[state.components.length - 1].gbiReportType;
    state.components = [...state.components.filter(cmp => !cmp.gbiReportType)];
  }

  let components = [...state.components];
  
  const assetItem = state.components.find(component => component.name.includes('assetDetails'));
  components = components.filter(cmp => cmp.index !== null);

  if (assetItem) {
    components = [{ ...assetItem }, ...components.filter(cmp => cmp.id !== assetItem.id)];
  }

  components = components.map(component => {
    return hasImages(component.value)
      ? {
        ...component,
        imageIndex: getImageIndex(imageOrder, component.name), 
        bearingIndex: getBearingIndexFromName(component.name)
      }
      : { ...component }
  });

  console.log("Given index", components);

  components = sortImages(components);
  
  components = components.map(component => {
    return hasImages(component.value)
      ? {
        ...component,
        value: isNotFailureImages(component.name)
          ? setImageNumbers(component, counter, isSelectedGBIReportType(selectedGBIReportType, component))
          : component.value
      }
      : { ...component }
  });
  
  console.log("result", components);
  return { components };
}

export function isAutomaticFigureNumbering(answers) {
  const answer = answers.find(a => a.name === "reportDetails.AutoFigureNumbering");
  if (answer) {
    return answer.value === "true";
  }
  return false;
}

function getImageIndex(imageOrder, componentName) {
  const orderItem = imageOrder.find(o => componentName.includes(o.key));
  if (!orderItem) {
    return -1;
  } else {
    return orderItem.index;
  }
}

function sortImages(images) {
  images = images.sort((a, b)=> {
    if (a.bearingIndex > -1 && b.bearingIndex > -1) {
      if (a.bearingIndex == b.bearingIndex) {
        return a.imageIndex - b.imageIndex;
      } else {
        return a.bearingIndex - b.bearingIndex;
      }
    } else {
      return a.imageIndex - b.imageIndex;
    }
  });
  return images;
}

function hasImages(componentValue) {
  return componentValue?.includes("imageUrls") 
}

function isSelectedGBIReportType(selectedGBIReportType, component) {
  if (component.name.includes("Other") || component.name.includes("amfr") || component.name.includes("ter")) {
    var type = component.name.substring(
      component.name.indexOf(".") + 1,
      component.name.lastIndexOf("InvestigationDetails")
    );
    return type.toLowerCase() === selectedGBIReportType.toLowerCase() ? true : false
  }
}

function isNotFailureImages(name) {
  //return !name.includes("primaryFailureAndCause") && !name.includes("additionalFailureAndCause");
  return true;
}

function setImageNumbers(component, counterRef, isSelectedGBIReportType) {
  var value = JSON.parse(component.value);

  var tempValue = value
  if (!value.imageUrls && isSelectedGBIReportType) {
    for (var key in tempValue) {
      if (tempValue[key].hasOwnProperty("imageUrls")) {
        var imageUrls = tempValue[key].imageUrls
        value[key].imageUrls = imageUrls.map(image => {
          if (image.url && image.url != "") {
            counterRef.count++;
            return { ...image, autoNumber: counterRef.count }
          } else {
            return image;
          }
        })
      }
    }
    return JSON.stringify(value);
  } else if ((value.imageUrls || value.investigationDetailsFigure.imageUrls) 
            && !component.name.includes("primaryFailureAndCause") 
            && !component.name.includes("additionalFailureAndCause")) {

    value.imageUrls = value.imageUrls?.map(image => {
      if (image.url && image.url != "") {
        counterRef.count++;
        return { ...image, autoNumber: counterRef.count }
      } else {
        return image;
      }

    })
    return JSON.stringify(value);
  } else if (value.prediction && value.prediction.boundryBoxImage && value.prediction.showBoundryBox) {
    if (value.prediction.boundryBoxImage.url && value.prediction.boundryBoxImage.url != "") {
      counterRef.count++;
      value.prediction.boundryBoxImage = { ...value.prediction.boundryBoxImage, autoNumber: counterRef.count };
    }
    return JSON.stringify(value);
  } else {
    return component.value;
  }
}