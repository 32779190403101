
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { injectIntl } from "react-intl";
import repository from "core/repository";
import ReactModal from "react-modal";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import BaseButton from "../../common/BaseButton";
import FailurePredictionCanvas from "./FailurePredictionCanvas";
import Select from "react-select";
import { isUserAdmin } from "../utils/userUtils";

const aicolors = ["#6E90F7", "#1AFF1A", "#C83B7D", "#EA6B2D", "#F2B33E"];
const reasons = [{ value: "doNoAccept", label: "Do not accept AI result" }, { value: "surfaceOfIntrest", label: "Not on surface of intrest" }, { value: "notADamage", label: "Not a damage" }, { value: "misidentifiedDamage", label: "Misidentified damage" }];
class FailurePredictionComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resourceId: "",
            imageUrl: "",
            predictionLoading: false,
            predictionResult: null,
            predictionMessage: "",
            normalizedPredictions: [],
            isModalOpen: false,
            isThankYouModalOpened: false,
            selectHeader: "",
            selectFailureText: "",
            isSaving: false,
            reasons: [],
            addBoundryBoxToReport: false,
            canvasToSave: null
        };
    }

    componentDidMount() {
        if (this.isPrimaryFailure()) {
            this.setState({ "selectHeader": this.props.intl.formatMessage({ id: "customComponents.failurePrediction.selectPrimaryFailure" }) });
            this.setState({ "selectFailureText": this.props.intl.formatMessage({ id: "customComponents.failurePrediction.primaryFailureMode" }) });
        } else {
            this.setState({ "selectHeader": this.props.intl.formatMessage({ id: "customComponents.failurePrediction.selectAdditionalFailure" }) });
            this.setState({ "selectFailureText": this.props.intl.formatMessage({ id: "customComponents.failurePrediction.additionalFailureMode" }) });
        }
        this.setState({ "reasons": this.translateReasons(reasons) });
    };

    translateReasons = reasons => reasons.map(r => ({ value: r.value, label: this.props.intl.formatMessage({ id: r.value }) }));

    isPrimaryFailure = () => {
        return (this.props.myName.includes("primaryFailureAndCause"));
    };

    getImage = () => {
        if (this.props.formComponentData.imageUrl?.length > 0) {
            if (this.props.formComponentData.imageUrl[0].uneditedUrl) {
                return this.props.formComponentData.imageUrl[0].uneditedUrl
            } else {
                return this.props.formComponentData.imageUrl[0].url
            }
        } else {
            return "";
        }
    }

    performPrediction = () => {
        const imageUrl = this.getImage();
        this.setState({ "predictionLoading": true });
        //Only get predictions if we have resourceId and have not changed image
        if (this.isTheSamePrediction(imageUrl)) {
            repository.getPrediction(this.props.formComponentData.prediction.resourceId)
                .then(result => {
                    this.setState({ "predictionResult": result.data });
                    this.setState({ "resourceId": this.props.formComponentData.prediction.resourceId });
                    this.setState({ "imageUrl": imageUrl });
                    this.setCreatePrediction(result);
                })
                .catch(err => this.handleError(err))
        } else {
            repository.createPrediction(this.getImage())
                .then(result => {
                    this.setState({ "predictionResult": result.data });
                    this.setState({ "resourceId": result.data.id });
                    this.setState({ "imageUrl": imageUrl });
                    this.props.parentHandleSave({ resourceId: result.data.id, imageUrl: imageUrl }, this.props.myName);
                    this.setCreatePrediction(result);
                })
                .catch(err => this.handleError(err));
        }
    }

    isTheSamePrediction = (imageUrl) => {
        if (this.state.resourceId && this.state.imageUrl) {
            //We have already done a prediction in current session
            return this.state.imageUrl === imageUrl
        } else {
            //New session
            return this.props.formComponentData.prediction &&
                this.props.formComponentData.prediction.resourceId &&
                //Have we changed image
                this.props.formComponentData.prediction.imageUrl === imageUrl
        }
    }

    waitForPrediction = () => {
        setTimeout(() => {
            repository.getPrediction(this.state.predictionResult.id)
                .then(result => this.waitHandler(result))
                .catch(err => this.handleError(err))
        }, 500);
    }

    handleError = error => {
        this.setState({ "predictionLoading": false });
        alert(this.props.intl.formatMessage({ id: 'customComponents.failurePrediction.communicationError' }));
        this.setState({ "predictionMessage": "Error getting prediction" });
    }

    waitHandler = result => {
        console.log(result);
        this.setState({ "predictionResult": result.data });
        if (result.data.status === "pending") {
            console.log("waiting");
            this.waitForPrediction(result.data)
        } else {
            console.log("done");
            this.setCreatePrediction(result);
        }
    }

    setCreatePrediction = result => {
        this.setState({ "predictionLoading": false });
        this.setState({ "normalizedPredictions": result.data.predictions ? this.normalizePredictions(result.data) : [] });
        this.setState({ "isModalOpen": true });
        this.setState({ "addBoundryBoxToReport": this.props.formComponentData.prediction.showBoundryBox });
    }

    //No predictions below 30%
    //Round to nearset 10% (but never 100)
    //Only who 5 with highest confidence
    normalizePredictions = data => this.setFailureText(data.predictions
        .filter(p => p.p > 0.3)
        .slice(0, 5)
        .map((item, i) => ({
            ...item,
            index: i,
            color: aicolors[i],
            confidence: this.roundTo10(item.p * 100),
            failureText: this.getFailureText(item.iso),
            failureCode: this.getFailureCode(item.iso),
            setFailureText: "",
            feedback: this.getFeedback(item, data.feedback)
        })));

    setFailureText = predictions => predictions.map(p => ({ ...p, setFailureText: p.feedback.isFailureSelected ? this.state.selectFailureText : "" }));

    getFeedback = (prediction, feedback) => {
        if (feedback && feedback !== null) {
            const feedbackForPrediction = feedback.feedback.find(p => p.predictionRef === prediction.predictionRef);
            if (feedbackForPrediction) {
                return {
                    isFailureSelected: feedbackForPrediction.isFailureSelected,
                    isAccepted: feedbackForPrediction.isAccepted,
                    isRejected: feedbackForPrediction.isRejected,
                    rejectReason: this.getReasonOption(feedbackForPrediction.rejectReason),
                    rejectFailureCode: this.getFailureOption(feedbackForPrediction.rejectFailureCode)
                };
            } else {
                return this.defaultFeedback();
            }
        } else {
            return this.defaultFeedback();
        }
    }

    showAddBoundryBoxToReport = () => this.state.normalizedPredictions.some(p => p.feedback.isFailureSelected);

    getReasonOption = (rejectReason) => {
        const reason = this.state.reasons.find(r => r.value == rejectReason);
        return reason ? reason : "";
    }

    getFailureOption = (rejectFailureCode) => {
        const failure = this.props.formComponentData.failureOptions.find(r => r.value == rejectFailureCode);
        return failure ? failure : "";
    }

    defaultFeedback = () => ({
        isFailureSelected: false,
        isAccepted: null,
        isRejected: null,
        rejectReason: "",
        rejectFailureCode: ""
    });

    roundTo10 = (x) => {
        const res = Math.round(x / 10) * 10;
        if (res == 100)
            return 90;
        else
            return res;
    }

    getFailureText = iso => {
        const failureCode = this.getFailureCode(iso);
        const item = this.props.formComponentData.failureOptions.find(i => i.value === failureCode);
        if (item) {
            return item.label;
        } else {
            return iso;
        }
    }

    getFailureCode = iso => `M${iso.split(".").join("")}`;

    isButtonEnabled = () => {
        return this.props.formComponentData.imageUrl?.length > 0 && this.props.formComponentData.imageUrl[0]?.url;
    }

    closeModal = () => this.setState({ "isModalOpen": false });

    toggleFailure = (index) => {
        this.setState(
            {
                "normalizedPredictions": this.state.normalizedPredictions.map((item, i) => {
                    if (i === index) {
                        item.setFailureText = this.state.selectFailureText;
                        item.feedback.isFailureSelected = !item.feedback.isFailureSelected
                        if (item.feedback.isFailureSelected) {
                            item.feedback.isAccepted = true;
                            item.feedback.isRejected = false;
                        } else {
                            item.feedback.isAccepted = false;
                            item.feedback.isRejected = false;
                        }

                    } else {
                        item.setFailureText = "";
                        item.feedback.isFailureSelected = false;
                    }
                    return item;
                })
            });
    }

    restartButtonAction = () => {
        this.toggleFailure(0);
        this.setState({
            normalizedPredictions: this.state.normalizedPredictions.map(prediction => ({ ...prediction, feedback: this.defaultFeedback() }))
        });
        this.scrollToTopOfPredictions();
    }

    isAltered = () => {
        return this.state.normalizedPredictions.some(p =>
            p.feedback.isFailureSelected ||
            p.feedback.isAccepted ||
            p.feedback.isRejected)
    }

    scrollToTopOfPredictions = () => {
        const container = document.querySelector('.ai-predictions');
        container.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    doneButtonAction = () => {
        const resourceId = this.props.formComponentData.prediction.resourceId;

        const feedback = {
            reportNumber: this.props.currentCase.id,
            reportStatus: this.props.currentCase.status,
            user: this.props.currentUser.email,
            predictions: this.state.normalizedPredictions
        }

        this.setState({ isSaving: true });

        Promise.all([this.saveDataToParent(), repository.addUpdatePredictionFeedback(resourceId, feedback)]).then(res => {
            const foundSelectedFailure = this.state.normalizedPredictions.find(p => p.feedback.isFailureSelected == true);
            if (foundSelectedFailure) {
                this.props.parentHandleSave({ value: foundSelectedFailure.failureCode, label: foundSelectedFailure.failureText }, ".failureMode");
            } else {
                this.props.parentHandleSave("", ".failureMode");
            }
            this.setState({ isSaving: false });
            this.closeModal();
            if (feedback.predictions.some(p => p.feedback.isRejected || p.feedback.isAccepted || p.feedback.isFailureSelected)) {
                this.openThankYouModal();
            }
        });
    }

    openThankYouModal = () => {
        this.setState({ isThankYouModalOpened: true });
    }

    closeThankYouModal = () => {
        this.setState({ isThankYouModalOpened: false });
    }

    gotZoomCanvas = canvas => {
        this.setState({ canvasToSave: canvas });
    }

    saveDataToParent = () => {
        return new Promise((resolve, reject) => {
            let dataToSave = {
                resourceId: this.state.resourceId,
                imageUrl: this.state.imageUrl,
                showBoundryBox: this.props.formComponentData.prediction.showBoundryBox,
                boundryBoxImage: this.props.formComponentData.prediction.boundryBoxImage,
                draftPredictions: this.props.currentCase.status !== "approvalEdited" ? {
                    predictions: this.state.normalizedPredictions,
                    caseStatus: this.props.currentCase.status,
                    date: new Date()
                } : this.props.formComponentData.prediction.draftPredictions,
                approvePredictions: this.props.currentCase.status === "approvalEdited" ? {
                    predictions: this.state.normalizedPredictions,
                    caseStatus: this.props.currentCase.status,
                    date: new Date()
                } : this.state.normalizedPredictions
            };


            if (this.showAddBoundryBoxToReport()) {
                dataToSave = {
                    ...dataToSave,
                    showBoundryBox: this.state.addBoundryBoxToReport
                }

                if (this.state.addBoundryBoxToReport) {
                    if (this.state.canvasToSave) {
                        const imageData = this.state.canvasToSave.toDataURL({ format: "jpeg" });
                        repository.postImage(this.props.currentCase.id, imageData).then(response => {
                            dataToSave = {
                                ...dataToSave,
                                showBoundryBox: this.state.addBoundryBoxToReport,
                                boundryBoxImage: {
                                    url: response.data.url,
                                    caption: this.props.intl.formatMessage({ id: "customComponents.failurePrediction.header" }),
                                    number: ""
                                }
                            };
                            this.props.parentHandleSave(dataToSave, this.props.myName);
                            resolve();
                        }).catch(err => reject(err));
                    }
                } else {
                    dataToSave = { ...dataToSave, boundryBoxImage: "" }
                    this.props.parentHandleSave(dataToSave, this.props.myName);
                    resolve();
                }
            } else {
                dataToSave = { ...dataToSave, showBoundryBox: false, boundryBoxImage: "" };
                this.props.parentHandleSave(dataToSave, this.props.myName);
                resolve();
            }
        });
    }

    hasPredictions = () => this.state.normalizedPredictions.length > 0;

    handleAcceptAction = prediction => {
        prediction.feedback.isAccepted = true;
        prediction.feedback.isRejected = false;
        this.setState({ "normalizedPredictions": this.state.normalizedPredictions });
    }

    handleRejectAction = prediction => {
        prediction.feedback.isAccepted = false;
        prediction.feedback.isRejected = true;
        this.setState({ "normalizedPredictions": this.state.normalizedPredictions });
    }

    handleRejectReason = (event, prediction) => {
        prediction.feedback.rejectReason = event != null ? event : "";
        this.setState({ "normalizedPredictions": this.state.normalizedPredictions });
    }

    handleRejectFailureCode = (event, prediction) => {
        prediction.feedback.rejectFailureCode = event != null ? event : "";
        this.setState({ "normalizedPredictions": this.state.normalizedPredictions });
    }

    renderIncludePredictionBoundryBox = () => {
        return this.showAddBoundryBoxToReport() ? (
            <div className="input-shell checkbox">
                <input id="addBoundryToReport" type="checkbox" checked={this.state.addBoundryBoxToReport} onChange={(e) => this.setState({ addBoundryBoxToReport: e.target.checked })} className="checkbox__hidden" />
                <label htmlFor="addBoundryToReport" className="checkbox__label checkbox__label--dark" tabIndex="0">
                    <span className="checkbox__label-span">
                        {this.props.intl.formatMessage({ id: "customComponents.failurePrediction.addBoundryBox" })}
                    </span>
                </label>
            </div>) : <div></div>
    };

    renderPredictionRejection = (prediction, isFirst) => {
        return prediction.feedback.isRejected && (
            <div className="prediction-item-feedback" >
                <div>
                    <Select
                        onChange={event => { this.handleRejectReason(event, prediction) }}
                        value={prediction.feedback.rejectReason}
                        options={this.state.reasons}
                        placeholder={"Select reason..."}
                        styles={reactSelectStyles}
                        isClearable={true}
                    />
                </div>
                {prediction.feedback.rejectReason !== "" && prediction.feedback.rejectReason.value === "misidentifiedDamage" && (
                    <div>
                        <Select
                            onChange={event => { this.handleRejectFailureCode(event, prediction) }}
                            value={prediction.feedback.rejectFailureCode}
                            options={this.props.formComponentData.failureOptions}
                            placeholder={"Select failure mode..."}
                            styles={reactSelectStyles}
                            isClearable={true}
                            menuPortalTarget={document.body}
                        />
                    </div>

                )}
            </div>
        );
    }

    renderPrediction = (prediction, isFirst) => {
        return <div className={"ai-predictions__row " + (prediction.feedback.isFailureSelected ? "selected" : "")} key={prediction.index} prediction-item-key={prediction.index}>
            <div className="ai-predictions__column prediction-item__color" style={{ borderColor: prediction.color }}>{prediction.failureText}</div>
            <div className="ai-predictions__column ai-center-column">{prediction.confidence}%</div>
            <div className="ai-predictions__column ai-center-column">{!prediction.feedback.isRejected && <CheckBox checked={prediction.feedback.isFailureSelected} handleChange={checked => this.toggleFailure(prediction.index)} id={"failure" + prediction.index} />}</div>
            <div className="ai-predictions__column ai-center-column ai-center-confirmation">{!prediction.feedback.isFailureSelected &&
                <>
                    <Radio id={"correct" + prediction.index} handleChange={() => this.handleAcceptAction(prediction)} checked={prediction.feedback.isAccepted} label="Correct" />
                    <Radio id={"incorrect" + prediction.index} handleChange={() => this.handleRejectAction(prediction)} checked={prediction.feedback.isRejected} label="Incorrect" />
                    {this.renderPredictionRejection(prediction, isFirst)}
                </>
            }
            </div>
        </div>;
    }

    isInternalUser = () => {
        const userRole = this.props.currentUser['custom:external']
        if (typeof userRole === 'string') {
            return userRole.toString().toLowerCase() === 'false'
        }
        if (typeof userRole === 'boolean') {
            return !userRole
        }
        return true
    }

    isNAMUser = () => this.props.currentUser['custom:region'] === 'NAM'

    render() {

        return (
            <React.Fragment>
                <div className="input-shell">
                    <BaseButton
                        text={this.props.intl.formatMessage({ id: "customComponents.failurePrediction.button" })}
                        className={classnames("input-shell", { "element--is-loading element--is-loading-after": this.state.predictionLoading })}
                        disabled={!this.isButtonEnabled()}
                        handleSubmit={() => this.performPrediction()}
                    />
                </div>
                <ReactModal
                    isOpen={this.state.isModalOpen}
                    className="react-modal ai-modal-container"
                    overlayClassName="react-modal-overlay">
                    <div className="react-modal__header ai-modal__header">
                        <h2>{this.props.intl.formatMessage({ id: "customComponents.failurePrediction.header" })}</h2>
                        <button className="react-modal__close-button" onClick={() => { this.closeModal(); }}>
                            <SvgClose />
                        </button>
                    </div>
                    <div className="react-modal__body ai-modal__body" prediction-items="true">
                        {this.hasPredictions() && <div className="ai-header">
                            <p>{this.state.selectHeader}</p>
                        </div>}
                        <div className="ai-container">
                            <div className="ai-image">
                                <FailurePredictionCanvas imageUrl={this.getImage()} onZoomCanvas={this.gotZoomCanvas} predictions={this.state.normalizedPredictions} includePredictions={this.state.normalizedPredictions.filter(p => p.feedback.isAccepted)} />
                            </div>
                            <div className="ai-predictions" prediction-items="true" >
                                {this.hasPredictions() && <>
                                    <div className="ai-predictions__row">
                                        <div className="ai-predictions__column ai-header-column">{this.props.intl.formatMessage({ id: "customComponents.failurePrediction.identifiedFailure" })}</div>
                                        <div className="ai-predictions__column ai-header-column ai-center-column ai-center-column">{this.props.intl.formatMessage({ id: "customComponents.failurePrediction.confidence" })}</div>
                                        <div className="ai-predictions__column ai-header-column ai-center-column">{this.props.intl.formatMessage({ id: "customComponents.failurePrediction.primaryFailureMode" })}</div>
                                        <div className="ai-predictions__column ai-header-column ai-center-column ai-center-confirmation">{this.props.intl.formatMessage({ id: "customComponents.failurePrediction.confirmAFAidentification" })}</div>
                                    </div>
                                    {this.state.normalizedPredictions.map((p, index) => this.renderPrediction(p, index === 0))}
                                    <ResetButton onClick={() => this.restartButtonAction()} label={this.props.intl.formatMessage({ id: "customComponents.failurePrediction.resetConfirmation" })} disabled={!this.isAltered()} />
                                </>}
                                {!this.hasPredictions() && (<>
                                    <div className="no-predictions-headers">
                                        <p>{this.props.intl.formatMessage({ id: "customComponents.failurePrediction.noPredictions" })}</p>
                                    </div>
                                </>)}
                            </div>
                        </div>
                        <div className="ai-actions">
                            {this.renderIncludePredictionBoundryBox()}
                            <div>
                                {this.hasPredictions() && (
                                    <>
                                        <BaseButton text={this.props.intl.formatMessage({ id: "customComponents.failurePrediction.done" })} className={classnames('button--background-green', { "element--is-loading element--is-loading-after": this.state.isSaving })} handleSubmit={() => this.doneButtonAction()}></BaseButton>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </ReactModal>
                <ReactModal
                    isOpen={this.state.isThankYouModalOpened}
                    className="react-modal update-modal-container"
                    overlayClassName="react-modal-overlay">
                    <div className="react-modal__header">
                        <button className="react-modal__close-button" onClick={() => this.closeThankYouModal()}>
                            <CircleCloseFilterSvg />
                        </button>
                    </div>
                    <div className="react-modal__body update-modal__body">
                        <h2>{this.props.intl.formatMessage({ id: "customComponents.failurePrediction.thankyou" })}</h2>
                        <BaseButton
                            handleSubmit={() => this.closeThankYouModal()}
                            text={this.props.intl.formatMessage({ id: "formRenderer.close" })} />
                    </div>
                </ReactModal>
            </React.Fragment>
        );
    }
}



const ResetButton = ({ disabled, onClick, label }) => {
    return <div className={"ai-predictions_reset" + (disabled ? " reset-disabled" : "")}>
        <button onClick={() => !disabled ? onClick() : null}><Undo />{label}</button>
    </div>
}

const SvgClose = props => (
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M19.523 18.11l7.15-7.15a1 1 0 011.414 1.414l-7.15 7.15 7.15 7.149a1 1 0 01-1.414 1.414l-7.15-7.15-7.15 7.15a1 1 0 11-1.413-1.414l7.15-7.15-7.15-7.15a1 1 0 111.414-1.413l7.15 7.15z"
                fill="#485A64"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

const Undo = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        focusable="false"
        height="100%"
        width="100%"
    >
        <path d="M11.245 2.003c-4.514-.124-8.213 3.511-8.213 8H1.445a.44.44 0 0 0-.31.756l2.474 2.48a.438.438 0 0 0 .63 0l2.474-2.48a.445.445 0 0 0-.32-.756H4.807a6.213 6.213 0 0 1 6.297-6.222c3.3.044 6.076 2.827 6.12 6.133.044 3.476-2.75 6.312-6.208 6.312a6.227 6.227 0 0 1-3.051-.81.861.861 0 0 0-1.047.16c-.408.41-.328 1.112.177 1.396a8.006 8.006 0 0 0 3.92 1.031c4.48 0 8.107-3.706 7.983-8.23-.115-4.17-3.592-7.654-7.752-7.77Z" />
    </svg>
)

const CheckBox = ({ id, checked, handleChange }) => {
    return <div className="checkbox">
        <input
            id={id}
            type="checkbox"
            checked={checked}
            onChange={(e) => handleChange(e.target.checked)}
            className="checkbox__hidden" />
        <label htmlFor={id} className="checkbox__label checkbox__label--dark" tabIndex="0">
            <span className="checkbox__label-span">
            </span>
        </label>
    </div>;
}

const Radio = ({ id, checked, handleChange, label }) => {
    const [localChecked, setLocalChecked] = useState(false);

    useEffect(() => {
        setLocalChecked(checked);
    }, [checked]);

    const localChanged = event => {
        if (handleChange) {
            handleChange(event.target.value);
        }
        setLocalChecked(event.target.value);
    }

    return <div className="radio">
        <input
            id={id}
            type="radio"
            checked={localChecked}
            onChange={(e) => localChanged(e)}
            className="radio__hidden"
        />
        <label htmlFor={id} className="radio__label radio__label--dark" tabIndex="0">
            <span className="radio__label-span">
                {label}
            </span>
        </label>
    </div>;
}

const reactSelectStyles = {
    control: () => ({
        borderRadius: 0,
        backgroundColor: '#ffffff',
        fontSize: '0.8rem',
        display: 'flex',
        alignItems: 'center',
        height: 'auto',
        border: '1px solid #DCE0E1',
        padding: '0.25rem 0.6375rem',

        '> div': {
            padding: 0
        }
    }),
    menuPortal: provided => ({ ...provided, zIndex: 28 }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        boxShadow: 'none',
        border: '1px solid #DCE0E1',
        borderTop: 0,
        borderRadius: 0,
        zIndex: 30
    }),
    menuList: (provided) => ({
        ...provided,
        borderRadius: 0,
        backgroundColor: '#ffffff',
        padding: 0
    }),
    option: (provided, state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        padding: '0.25rem 0.6375rem',
        borderBottom: '1px solid #DCE0E1',
        fontSize: '0.8rem',
        backgroundColor: state.isSelected ? '#0F58D6' : state.isFocused ? '#DCE0E1' : 'transparent',
        '&:last-child': {
            borderBottom: 0
        },
        '&:hover': {
            cursor: 'pointer'
        }
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: 0
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0
    }),
    input: (provided) => ({
        ...provided,
        padding: 0
    })
}

function mapStateToProps(state) {
    return {
        requiredList: state.requiredList,
        language: state.language,
        connection: state.connection,
        currentCase: state.currentCase.case,
        currentUser: state.currentUser
    };
}

export default injectIntl(connect(mapStateToProps)(FailurePredictionComponent));