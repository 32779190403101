import React from "react";

const SvgSkfLogoLivePreview = props => (
  <svg width={158} height={38} viewBox="0 0 158 38" {...props}>
    <defs>
      <path id="skf-logo-live-preview_svg__a" d="M.443.305h9.273v9.158H.443z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M44.992 37.129c.767 0 1.39-.625 1.39-1.394v-20.42a1.39 1.39 0 00-1.392-1.392H19.48a.928.928 0 01-.927-.928V5.569c0-.512.415-.928.928-.928h7.42c.513 0 .929.416.929.928V7.89c0 .768.622 1.392 1.391 1.392h11.132c.769 0 1.392-.624 1.392-1.392V1.392C41.745.624 41.12 0 40.353 0H6.027c-.768 0-1.392.624-1.392 1.393V17.17c0 .769.624 1.391 1.392 1.391H31.54a.93.93 0 01.928.929v12.067a.928.928 0 01-.928.929H14.843a.928.928 0 01-.928-.929v-6.961c0-.77-.623-1.392-1.392-1.392H1.39c-.768 0-1.391.623-1.391 1.392v11.138c0 .77.623 1.394 1.391 1.394h43.6zm24.583-13.336a.232.232 0 01.393-.167L83.056 36.73c.075.074.417.398.972.398h16.62c.77 0 1.392-.624 1.392-1.392V24.604c0-.768-.622-1.392-1.391-1.392H88.655c-.535 0-.873-.298-.96-.384l-8.726-8.742a.232.232 0 01-.069-.163c0-.067.025-.12.069-.163l4.061-4.08a1.39 1.39 0 01.975-.399H96.01c.77 0 1.392-.623 1.392-1.392V1.392C97.402.623 96.78 0 96.01 0H84.028c-.554 0-.895.32-.972.397L69.968 13.5a.232.232 0 01-.393-.167l-.001-11.942C69.574.623 68.95 0 68.182 0H54.736a1.39 1.39 0 00-1.39 1.392V12.53a1.39 1.39 0 001.39 1.392c.513 0 .928.415.928.928v7.426a.928.928 0 01-.928.928 1.39 1.39 0 00-1.39 1.392v11.138c0 .77.622 1.394 1.39 1.394h13.446c.768 0 1.392-.625 1.392-1.394V23.793zm54.26 13.336c.768 0 1.392-.625 1.392-1.394V24.597c0-.77-.624-1.392-1.392-1.392a.93.93 0 01-.928-.928v-2.784a.93.93 0 01.928-.93h5.095c.512 0 .927.416.927.93 0 .768.623 1.39 1.392 1.39h11.146c.768 0 1.392-.622 1.392-1.39v-6.498c0-.769-.624-1.392-1.392-1.392H131.25c-.769 0-1.392.623-1.392 1.392a.927.927 0 01-.927.928h-5.095a.93.93 0 01-.928-.928V5.569a.93.93 0 01.928-.928h14.383c.513 0 .928.416.928.928V7.89c0 .769.625 1.392 1.392 1.392h15.772c.768 0 1.392-.623 1.392-1.392V1.392c0-.768-.624-1.392-1.392-1.392h-45.92c-.769 0-1.392.624-1.392 1.392v11.14c0 .768.623 1.391 1.391 1.391.513 0 .928.416.928.928v7.426a.928.928 0 01-.928.928 1.39 1.39 0 00-1.39 1.392v11.138c0 .77.622 1.394 1.39 1.394h13.446z"
        fill="#0056DB"
      />
      <g transform="translate(147.986 27.666)">
        <mask id="skf-logo-live-preview_svg__b" fill="#fff">
          <use xlinkHref="#skf-logo-live-preview_svg__a" />
        </mask>
        <path
          d="M4.268 4.597v-1.44h.958c.49 0 1.013.107 1.013.683 0 .714-.532.757-1.13.757h-.841zm0 .597h.81l1.225 2.014h.788L5.769 5.162c.683-.086 1.204-.448 1.204-1.28 0-.916-.543-1.321-1.64-1.321H3.565v4.647h.703V5.194zm.823 4.27c2.494 0 4.625-1.94 4.625-4.592 0-2.628-2.13-4.567-4.625-4.567C2.574.305.443 2.244.443 4.872c0 2.653 2.13 4.591 4.648 4.591zM1.363 4.871c0-2.168 1.635-3.803 3.728-3.803 2.07 0 3.706 1.635 3.706 3.803 0 2.205-1.636 3.828-3.706 3.828-2.093 0-3.728-1.623-3.728-3.828z"
          fill="#0056DB"
          mask="url(#skf-logo-live-preview_svg__b)"
        />
      </g>
    </g>
  </svg>
);

export default SvgSkfLogoLivePreview;
