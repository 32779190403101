import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useCaseSearch from 'customHooks/useCaseSearch'
import BaseTableMobile from 'components/common/BaseTableMobile'
import queryString from 'query-string'
import BaseTable from './BaseTable'
import Pagination from './Pagination'
import { withRouter } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { TO_SMALL } from 'core/ui/breakpoints'

const CasesTable = ({ loading, showOnlyMyCases = false, showPagination = true, history }) => {
  const currentPage = useCaseSearch(history.location.search, showOnlyMyCases)
  const cases = useSelector(state => getFormattedCases(state.caseRelated.cases))

  const [sortedKey, setSortedKey] = useState('')
  const [sortedOrder, setSortedOrder] = useState('')

  const URLSearch = queryString.parse(history.location.search)

  useEffect(() => {
    const URLOrder = URLSearch.order

    if (!URLOrder) {
      setSortedKey('')
      setSortedOrder('')
      return
    }

    const isDesc = URLOrder.slice(0, 1) === '-'
    setSortedKey(isDesc ? URLOrder.slice(1) : URLOrder)
    setSortedOrder(isDesc ? 'desc' : 'asc')
  }, [URLSearch.order])

  const isMobile = useMediaQuery({
    query: TO_SMALL
  })

  const handleSortTable = sortKey => {
    let newURLSearch = { ...URLSearch }
    const prevKey = URLSearch.order

    if (!prevKey || !prevKey.includes(sortKey)) {
      newURLSearch = {
        ...newURLSearch,
        order: sortKey
      }
    } else {
      newURLSearch = {
        ...newURLSearch,
        order: prevKey.slice(0, 1) === '-' ? sortKey : '-' + sortKey
      }
    }

    history.push('?' + queryString.stringify(newURLSearch))
  }

  return (
    <>
      {isMobile ? (
        <BaseTableMobile
          className={loading ? 'element--is-loading element--is-loading-after' : ''}
          tableData={cases}
        />
      ) : (
        <BaseTable
          className={loading ? 'element--is-loading element--is-loading-after' : ''}
          tableData={cases}
          sortedKey={sortedKey}
          sortedOrder={sortedOrder}
          onSortTable={handleSortTable}
        />
      )}
      {showPagination && (
        <Pagination
          className={loading ? 'element--is-loading' : ''}
          currentPage={currentPage}
        />
      )}
    </>
  )
}

CasesTable.propTypes = {
  loading: PropTypes.bool,
  showOnlyMyCases: PropTypes.bool,
  showPagination: PropTypes.bool,
  cases: PropTypes.array,
}

function getFormattedCases(cases = []) {
  return cases.map(report => {
    const reportDate = new Date(report.updated)
    const updated = reportDate instanceof Date ? formatDateString(reportDate) : 'N/A'

    return { ...report, updated }
  })
}

function formatDateString(date) {
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)

  return `${year}-${month}-${day}`
}

export default withRouter(CasesTable)
