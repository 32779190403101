import repository from "../../core/repository";
import React, { Component } from "react";
import { createConfiguration } from "Configs/config-builder";
import bartConfig from "Configs/BearingDamageAnalysis/root.json";
import gbiConfig from "Configs/GBI/root.json";
import inspectionConfig from "Configs/Inspection/root.json";
import usInspectionConfig from "Configs/USInspection/root.json";
import windConfig from "Configs/Wind/root.json";

import bartBearingInvestigationConfig from "../../Configs/BearingDamageAnalysis/BearingInvestigation.json";
import GBIBearingInvestigationConfig from "../../Configs/GBI/GBI-BearingInvestigation.json";
import inspectionBearingInvestigationConfig from "../../Configs/Inspection/Inspection-BearingInvestigation.json";
import usInspectionBearingInvestigationConfig from "./../../Configs/USInspection/USInspection-BearingInvestigation.json";
import windBearingInvestigationConfig from "../../Configs/Wind/Wind-BearingInvestigation.json";
import config from "config";

class DeployFormtemplates extends Component {
  constructor(props) {
    super(props);
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
  }

  changeKeyValueInFormConfig = formConfig => {
    let string = JSON.stringify(formConfig);
    string = string.replace(/"key":/g, '"name":');
    let object = JSON.parse(string);
    return object;
  };

  fixGBIBEaringinvestigation = formConfig => {
    return formConfig.reduce((acc, c) => {
      if (c.name === "bearingDetails" && c.skipParentName) {
        acc = [...acc, ...c.components];
      } else {
        acc = [...acc, c];
      }
      return acc;
    }, []);
  }

  buttonClick = () => {
    // Make BART config
    let compiledBARTConfig = createConfiguration(bartConfig);
    // Make GBI Config
    let compiledGBIConfig = createConfiguration(gbiConfig);
    // Make Inspection Config
    let compiledInspectionConfig = createConfiguration(inspectionConfig);
    // MAke US Inspection Config
    let compiledUsInspectionConfig = createConfiguration(usInspectionConfig);
    // Make Inspection Config
    let compiledWindConfig = createConfiguration(windConfig);
    // Make bearing config
    let compiledBARTBearingInvestigationConfig = createConfiguration(
      bartBearingInvestigationConfig
    );
    let compiledGBIBearingInvestigationConfig = createConfiguration(
      GBIBearingInvestigationConfig
    );
    let compiledInspectionBearingInvestigationConfig = createConfiguration(
      inspectionBearingInvestigationConfig
    );
    let compiledUsInspectionBearingInvestigationConfig = createConfiguration(
      usInspectionBearingInvestigationConfig
    );
    let compiledWindBearingInvestigationConfig = createConfiguration(
      windBearingInvestigationConfig
    );

    // Replace key with name
    let translatedBARTConfig = this.changeKeyValueInFormConfig(
      compiledBARTConfig
    );
    let translatedGBIConfig = this.changeKeyValueInFormConfig(
      compiledGBIConfig
    );
    let translatedInspectionConfig = this.changeKeyValueInFormConfig(
      compiledInspectionConfig
    );
    let translatedUsInspectionConfig = this.changeKeyValueInFormConfig(
      compiledUsInspectionConfig
    );
    let translatedWindConfig = this.changeKeyValueInFormConfig(
      compiledWindConfig
    );
    let translatedBARTBearingInvestigationConfig = this.changeKeyValueInFormConfig(
      compiledBARTBearingInvestigationConfig
    );
    let translatedGBIBearingInvestigationConfig = this.changeKeyValueInFormConfig(
      compiledGBIBearingInvestigationConfig
    );
    let translatedInspectionBearingInvestigationConfig = this.changeKeyValueInFormConfig(
      compiledInspectionBearingInvestigationConfig
    );
    let translatedUsInspectionBearingInvestigationConfig = this.changeKeyValueInFormConfig(
      compiledUsInspectionBearingInvestigationConfig
    );
    let translatedWindBearingInvestigationConfig = this.changeKeyValueInFormConfig(
      compiledWindBearingInvestigationConfig
    );

    let BART = {
      displayName: "BART",
      formTemplateData: translatedBARTConfig
    };
    let GBI = {
      displayName: "GBI",
      formTemplateData: translatedGBIConfig
    };
    let INSPECTION = {
      displayName: "INSPECTION",
      formTemplateData: translatedInspectionConfig
    };
    let US_INSPECTION = {
      displayName: "US_INSPECTION",
      formTemplateData: translatedUsInspectionConfig
    };
    let WIND = {
      displayName: "WIND",
      formTemplateData: translatedWindConfig
    };
    let BI_BART = {
      displayName: "BI_BART",
      formTemplateData: translatedBARTBearingInvestigationConfig
    };
    let BI_GBI = {
      displayName: "BI_GBI",
      formTemplateData: this.fixGBIBEaringinvestigation(translatedGBIBearingInvestigationConfig)
    };
    let BI_INSPECTION = {
      displayName: "BI_INSPECTION",
      formTemplateData: translatedInspectionBearingInvestigationConfig
    };
    let BI_US_INSPECTION = {
      displayName: "BI_US_INSPECTION",
      formTemplateData: translatedUsInspectionBearingInvestigationConfig
    };
    let BI_WIND = {
      displayName: "BI_WIND",
      formTemplateData: translatedWindBearingInvestigationConfig
    };

    repository
      .postFormTemplateWithId(config.BI_BART_TEMPLATE_ID, BI_BART)
      .then(response => {
        console.log("BART BI", response.data.id);
        BART.formTemplateData.forEach(configObject => {
          if (configObject.name == "bearingInvestigations") {
            configObject.subTemplateId = response.data.id;
          }
        });
        repository
          .postFormTemplateWithId(config.BART_TEMPLATE_ID, BART)
          .then(innerResponse => {
            console.log(
              "Uploaded formtemplates for BART, BART has id =",
              innerResponse.data.id
            );
          });
      });

    repository
      .postFormTemplateWithId(config.BI_GBI_TEMPLATE_ID, BI_GBI)
      .then(response => {
        console.log("GBI BI", response.data.id);
        GBI.formTemplateData.forEach(configObject => {
          if (configObject.name == "bearingInvestigations") {
            configObject.subTemplateId = response.data.id;
          }
        });
        repository
          .postFormTemplateWithId(config.GBI_TEMPLATE_ID, GBI)
          .then(innerResponse => {
            console.log(
              "Uploaded formtemplates for GBI, GBI has id =",
              innerResponse.data.id
            );
          });
      });
    repository
      .postFormTemplateWithId(config.BI_INSPECTION_TEMPLATE_ID, BI_INSPECTION)
      .then(response => {
        console.log("INSPECTION BI", response.data.id);
        INSPECTION.formTemplateData.forEach(configObject => {
          if (configObject.name == "bearingInvestigations") {
            configObject.subTemplateId = response.data.id;
          }
        });
        repository
          .postFormTemplateWithId(config.INSPECTION_TEMPLATE_ID, INSPECTION)
          .then(innerResponse => {
            console.log(
              "Uploaded formtemplates for INSPECTIOn, INSPECTION has id =",
              innerResponse.data.id
            );
          });
      });

      repository
      .postFormTemplateWithId(config.BI_US_INSPECTION_TEMPLATE_ID, BI_US_INSPECTION)
      .then(response => {
        console.log("INSPECTION BI", response.data.id);
        US_INSPECTION.formTemplateData.forEach(configObject => {
          if (configObject.name == "bearingInvestigations") {
            configObject.subTemplateId = response.data.id;
          }
        });
        repository
          .postFormTemplateWithId(config.US_INSPECTION_TEMPLATE_ID, US_INSPECTION)
          .then(innerResponse => {
            console.log(
              "Uploaded formtemplates for US INSPECTIOn, INSPECTION has id =",
              innerResponse.data.id
            );
          });
      });
    
    repository
      .postFormTemplateWithId(config.BI_WIND_TEMPLATE_ID, BI_WIND)
      .then(response => {
        console.log("Wind BI", response.data.id);
        WIND.formTemplateData.forEach(configObject => {
          if (configObject.name == "bearingInvestigations") {
            configObject.subTemplateId = response.data.id;
          }
        });
        repository
          .postFormTemplateWithId(config.WIND_TEMPLATE_ID, WIND)
          .then(innerResponse => {
            console.log(
              "Uploaded formtemplates for WIND, WIND has id =",
              innerResponse.data.id
            );
          });
      });
  };

  handleButtonPress() {
    this.buttonPressTimer = setTimeout(() => this.buttonClick(), 1500);
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }

  render() {
    if (process.env.REACT_APP_CONFIG_LOCAL === "local") {
      return (
        <div className="footer__link"
          onTouchStart={this.handleButtonPress}
          onTouchEnd={this.handleButtonRelease}
          onMouseDown={this.handleButtonPress}
          onMouseUp={this.handleButtonRelease}
          onMouseLeave={this.handleButtonRelease}
        >
          Upload formtemplates
        </div>
      );
    } else {
      return null;
    }
  }
}

export default DeployFormtemplates;
