import { addOrUpdateAnswer } from "./setReportTitle"
import config from "config"

export const setSystemTypeMachineNumber = (templateId, components, componentsToSave) => {
  
  if (templateId !== config.US_INSPECTION_TEMPLATE_ID) return componentsToSave

  const hasChangedValue = componentsToSave.some(c => fields.some(field => field.name === c.answer.name))
  if (!hasChangedValue) return componentsToSave

  const generatedValue = generateTitle(components, componentsToSave)
  const component = {
    name: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.systemNameMachineNumber',
    value: JSON.stringify(generatedValue),
  }

  return addOrUpdateAnswer(componentsToSave, components, component)
}

const generateTitle = (components, componentsToSave) => {
  const result = []
  for (let i = 0; i < fields.length; i++) {
    const { name } = fields[i]
    const isSystemName = name.endsWith('systemName')
    if (isSystemName) {
      let component = componentsToSave.find(c => c.answer.name === name)
      const parsedValueToSave = component?.answer?.value ? JSON.parse(component.answer.value) : null
      if (component) {
        if (parsedValueToSave) {
          const systemNameValue = typeof parsedValueToSave === 'object' && parsedValueToSave
            ? parsedValueToSave?.value : parsedValueToSave ? parsedValueToSave : ''
          result.push(systemNameValue)
        }
        continue
      }
      component = components.find(c => c.name === name)
      if (!component) return

      const parsedValue = JSON.parse(component.value)
      if (!parsedValue) return

      const systemNameValue = typeof parsedValue === 'object' && parsedValue
        ? parsedValue?.value : parsedValue ? parsedValue : ''

      result.push(systemNameValue)
    } else {
      if (result.length === 0 || !result[0]) break
      let component = componentsToSave.find(c => c.answer.name === name)
      const parsedValueToSave = component?.answer?.value ? JSON.parse(component.answer.value) : null
      if (component) {
        result.push(parsedValueToSave?.value || '')
        continue
      }
      component = components.find(c => c.name === name)
      if (!component) break

      const parsedValue = JSON.parse(component?.value)
      if (parsedValue?.value) {
        result.push(parsedValue?.value || '')
      }
    }
  }

  return result.filter(e => e).join('/')
}

const fields = [
  {
    name: "assetDetails.assetTypeOrFunctionalAreaOrSystem.systemName",
    getTitle: value => typeof value?.systemName === 'object' ? value.systemName?.value : value?.systemName ? value.systemName : '',
  },
  {
    name: 'assetDetails.assetTypeOrFunctionalAreaOrSystem.machineNumber',
    getTitle: value => value?.label,
  },
]