import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

const WelcomeInformation = (props) => {
  return (
    <div className="welcome-information">
      <div className="welcome-information__container">
        <h1 className="welcome-information__header">
          <div className="welcome-information__page-title">
            <FormattedMessage id="dashboard.bart" />
          </div>

          <div className="welcome-information__user">
            <FormattedMessage id="dashboard.welcome" /> {props.userName}!
          </div>
        </h1>
        {props.connection &&
          (!props.isUserExternal ? (
            <div className="welcome-information__help">
              <FormattedMessage id="dashboard.doYouNeedHelp" />
              <a
                href="https://skfgroup.sharepoint.com/sites/BART-Public/Shared%20Documents/Forms/AllItems.aspx?viewpath=%2Fsites%2FBART-Public%2FShared%20Documents%2FForms%2FAllItems.aspx"
                className="welcome-information__help-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="dashboard.openUserGuide" />
              </a>
              <a
                href="https://www.yammer.com/skf.com/#/threads/inGroup?type=in_group&feedId=7941463&view=all"
                className="welcome-information__help-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="dashboard.askQuestionsOnYammer" />
              </a>
            </div>
          ) : null)}
      </div>
    </div>
  );
};

WelcomeInformation.propTypes = {
  userName: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    connection: state.connection,
  };
};

export default connect(mapStateToProps)(WelcomeInformation);
