import { getBearingIndexFromName } from "../../../components/formcomponents/utils/BearingUtils";

export function getQuicklinkPositions(existingPositions, newPosition) {
    //only allow position for bearings
    if (newPosition.name.includes("bearingInvestigations.bearingInvestigation[")) {
      //Only allow one position per bearing
      const existingPosition = existingPositions.find(position => position.index === getBearingIndexFromName(newPosition.name));
      if (existingPosition) {
        return existingPositions.map(p => p.name === existingPosition.name ? { ...newPosition, current: true, index: getBearingIndexFromName(newPosition.name) } : { ...p, current: false });
      } else {
        
        return [...existingPositions.map(p => ({ ...p, current: false })), { ...newPosition, current: true, index: getBearingIndexFromName(newPosition.name) }];
      }
    } else {
      return [...existingPositions];
    }
  }
  
export function getQuickLinkTarget(payload, existingPositions) {
  const currentPosition = existingPositions.find(p => p.current);
  const targets = {};

  if (payload.accordionTarget) {
    targets.accordionTarget = payload.accordionTarget;
  }
  if (currentPosition) {
    const firstSplit = currentPosition.name.split("[");
    if (firstSplit.length > 1) {
      const secondSplit = firstSplit[1].split("]");
      if (secondSplit.length > 1) {
        targets.name = `${firstSplit[0]}[${payload.index}]${secondSplit[1]}`;
        return targets;
      }
    }
    return { name: "" };
  }
  targets.name = `bearingInvestigations.bearingInvestigation[${payload.index}]`;
  return targets;
}