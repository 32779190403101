import React, { useEffect, useState } from "react";
import Select from "react-select";
import httpService from "core/http-service";
import classnames from "classnames";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const AutoCompleteComponent = (props) => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.parentSetValue !== undefined && props.parentSetValue !== null) {
      setValue(props.parentSetValue);
    } else {
      setValue(props.getValue(props.myName));
    }
  }, [props, props.parentSetValue]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    let hasApi = false;
    let apiPath = "";
    let configOptions = [];
    if (props.formComponentData.args !== undefined) {
      props.formComponentData.args.forEach((arg) => {
        if (arg.hasOwnProperty("apiPath")) {
          setIsLoading(true);
          hasApi = true;
          apiPath = arg.apiPath;
          httpService
            .get(apiPath, { lang: props.language })
            .then((response) => {
              setOptions(
                response.data.map((option) => {
                  return {
                    value: {
                      email: option.email,
                      name: option.name,
                      phone: option.phone_number,
                    },
                    label: option.name,
                  };
                })
              );
              setIsLoading(false);
            });
        }
        if (arg.hasOwnProperty("options")) {
          configOptions = arg.options;
        }
      });
      if (!hasApi) {
        setOptions(
          configOptions.map((item) => {
            return { value: item.value, label: item.label };
          })
        );
      }
    }
  }, [props.formComponentData.args]);

  function getCurrentValue(value, options, parentSetValue) {
    if (value == null || value == "") {
      return "";
    }
    
    let currentValue = options.filter(
      (option) => option.value.name === value.name
    );
    if (
      currentValue.length == 0 ||
      currentValue[0].label != value ||
      currentValue[0].label != value.name
    ) {
      currentValue = options.filter(
        (option) =>
          option.value.name === value || option.value.name === value.name
      );
    }
    return currentValue[0];
  }

  return (
    <div className="input-shell">
      {props.formComponentData.description && (
        <label
          htmlFor={props.formComponentData.description}
          className={classnames(
            "input-shell__label",
            "input-shell__label--dark",
            { "input-shell__label--required": props.formComponentData.required }
          )}
        >
          {props.formComponentData.description}
        </label>
      )}

      <div
        className="input-shell__container"
        data-id={props.formComponentData.myName}
      >
        <Select
          onChange={(event) => {
            if (event !== null) {
              if (props.parentHandleSave !== undefined) {
                props.parentHandleSave(event.value, props.myName);
              } else {
                props.setValue(event.value, props.myName);
                setValue(event.value);
              }
            } else {
              if (props.parentHandleSave !== undefined) {
                props.parentHandleSave("", props.myName);
                setValue("");
              } else {
                props.setValue("", props.myName);
                setValue("");
              }
            }
          }}
          className="select__element"
          value={getCurrentValue(value, options, props.parentSetValue)}
          options={options}
          placeholder={props.intl.formatMessage({
            id: "customComponents.select",
          })}
          styles={ReactSelectStyles}
          isClearable={true}
          isLoading={isLoading}
        />
      </div>

      {props.formComponentData.required && (
        <div className="input-shell__message">
          <div className="input-shell__message-hint">
            <FormattedMessage id="formComponents.required" />
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    language: state.language,
  };
}

export default connect(mapStateToProps)(injectIntl(AutoCompleteComponent));
