import React, { Component } from "react";
import getComponent from '../../_Case/ComponentsList'

class ShaftMaterialComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shaftMaterialType: "",
      stainlessSteelType: ""
    }
  }

  componentDidMount() {
    let values = this.props.getValue(this.props.myName)
    if (values !== undefined) {
      this.setState({
        shaftMaterialType: values.shaftMaterialType,
        stainlessSteelType: values.stainlessSteelType
      })
    }
  }

  handleChange = (value, name) => {
    var parts = name.split(".");
    var resultName = parts[parts.length - 1];
    let objectToSave = { shaftMaterialType: this.state.shaftMaterialType, stainlessSteelType: this.state.stainlessSteelType }
    if (resultName === "shaftMaterialSelect") {
      this.setState({
        shaftMaterialType: value,
        stainlessSteelType: ""
      });

      if (value !== "Stainless steel") {
        objectToSave.stainlessSteelType = ""
      }
      objectToSave.shaftMaterialType = value
      this.props.setValue(objectToSave, this.props.myName)
    } else if (resultName === "stainlessSteelType") {
      this.setState({
        stainlessSteelType: value
      });
      objectToSave.stainlessSteelType = value
      this.props.setValue(objectToSave, this.props.myName)
    }
  };

  renderStainlessSteelOptions = () => {
    if (this.state.shaftMaterialType === "Stainless steel") {
      return getComponent(
        {
          "key": "stainlessSteelType",
          "type": "Text",
          "description": "Stainless Steel Type"
        }, `${this.props.myName}.stainlessSteelType`,
        this.handleChange,
        this.state.stainlessSteelType)
    } else {
      return null
    }
  }

  renderShaftMaterial = (formComponent, index) => {
    if (formComponent !== undefined) {
      return <React.Fragment key={index}>{getComponent(formComponent, `${this.props.myName}.${formComponent.key}`, this.handleChange, this.state.shaftMaterialType)}</React.Fragment>
    }
  }

  render() {
    return (
      <React.Fragment key={this.state.shaftMaterialType}>
        {this.props.formComponentData.args.map((formComponent, index) => {
          return this.renderShaftMaterial(formComponent, index)
        })}
        {this.renderStainlessSteelOptions()}
      </React.Fragment>
    );
  }
}

export default ShaftMaterialComponent;