export function validateComponents(validationErrors, components) {
    if (validationErrors.length > 0) {
        return validationErrors.map(ve => {
            if (ve.key.includes("primaryFailureAndCause")) {
                return handleFailureMode(ve, components);
            } else if (ve.key === "skfDetails.reportNeedsApproval.needsApproval") {
                return handleRequiresApproval(ve, components);
            } else if (ve.key === "skfDetails.reportNeedsApproval.approversName") {
                return handleRequiresApprovalName(ve, components);
            } else if (ve.key === "skfDetails.reportNeedsApproval.diameterOverTwoHundred") {
                return handleInspectionRequiresApproval(ve, components);
            } else if (ve.key === "investigationDetails.OtherInvestigationDetails.customer") {
                return handleOtherInvestigationDetailsCustomer(ve, components);
            } else {
                const answer = components.find(a => a.name === ve.key);
                if (answer) {
                    return { ...ve, valid: ve.isValid(answer.value) };
                } else {
                    return ve;
                }
            }
            
        });
    } else { 
        return validationErrors;
    }
    
}
function handleOtherInvestigationDetailsCustomer(validationError, components) {
    const answer = components.find(a => a.name === "investigationDetails.OtherInvestigationDetails");
    if (answer) {
        return { ...validationError, valid: validationError.isValid(answer.value) };
    }
    return validationError;
}
function handleRequiresApprovalName(validationError, components) {
    const answer = components.find(a => a.name === "skfDetails.reportNeedsApproval");
    if (answer) {
        const answerValue = JSON.parse(answer.value);
        if (answerValue.needsApproval || answerValue.needsApproval === "false") {
            return { ...validationError, valid: true};
        }
    }
    return validationError;
}

function handleInspectionRequiresApproval(validationError, components) {
    const answer = components.find(a => a.name === "skfDetails.reportNeedsApproval");
    if (answer) {
        const answerValue = JSON.parse(answer.value);
        if (answerValue.diameterOverTwoHundred && answerValue.diameterOverTwoHundred === "false") {
            if (answerValue.approversName !== "") {
                return { ...validationError, valid: true };
            } else {
                return { ...validationError, valid: false };    
            }
        } else if (answerValue.diameterOverTwoHundred) {
            if (answerValue.solutionFactoryName !== "") {
                return { ...validationError, valid: true };
            } else {
                return { ...validationError, valid: false };    
            }
        } else {
            return { ...validationError, valid: false };
        }
    }
    return validationError;
}

function handleRequiresApproval(validationError, components) {
    const answer = components.find(a => a.name === "skfDetails.reportNeedsApproval");
    if (answer) {
        const answerValue = JSON.parse(answer.value);
        if (answerValue.needsApproval && answerValue.needsApproval === "false") {
            return { ...validationError, valid: true };
        } else if (answerValue.needsApproval) {
            if (answerValue.approversName !== "") {
                return { ...validationError, valid: true };
            } else {
                return { ...validationError, valid: false };    
            }
        } else {
            return { ...validationError, valid: false };
        }
    }
    return validationError;
}

function handleFailureMode(validationError, components) {
    const parts = validationError.key.split(".");
    const newKey = parts.slice(0, parts.length - 1).join(".");
    const field = parts[parts.length - 1];
    const answer = components.find(a => a.name === newKey);
    if (answer) {
        let answerValue = JSON.parse(answer.value);
        let skipImageCauseValidation = false;
        if (answerValue.failureCode) { 
            skipImageCauseValidation = answerValue.failureCode.value === "M000" || answerValue.failureCode.value === "M001";
        }
        switch (field) {
            case "failureMode":
                return { ...validationError, valid: validationError.isValid(answerValue.failureCode) };
            case "failureImage" && !skipImageCauseValidation:
                return { ...validationError, valid: validationError.isValid(answerValue.imageUrls) };
            case "cause" && !skipImageCauseValidation:
                return { ...validationError, valid: validationError.isValid(answerValue.cause) };
            default:
                return validationError;
        }
    } else {
        return validationError;
    }
}

export function removeValidationErrorsForBearing(validationErrors, bearingIndex) {
    return validationErrors.filter(r => !r.key.startsWith(`bearingInvestigations.bearingInvestigation[${bearingIndex}]`));
}

export function removeValidationError(validationErrors, key) {
    return validationErrors.filter(r => r.key !== key);
}

export function addValidationError(validationErrors, error) {
    const errors = validationErrors.filter(r => r.key !== error.key);
    errors.push(error);
    return errors;
}