import React from "react";

const style = { background: "#F6F7F7" };
const styleRotate = {background: "#F6F7F7", transform: "rotateX(180deg)"}

const SvgTreeEditEndExpanded = ({ rotate }) => (
    <svg width="38px" height="31px" viewBox="0 0 38 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={rotate ? styleRotate : style}>  
        <g id="Tree/tree_edit_end_expanded" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="#F6F7F7" x="0" y="0" width="38" height="31"></rect>
            <path d="M30.5,13 C31.8807119,13 33,14.1192881 33,15.5 C33,16.8807119 31.8807119,18 30.5,18 C29.2903182,18 28.2813059,17.1408321 28.0498926,15.999426 L1,16 L0,16 L0,14 L1,14 L1,15.008 L28.0496883,15.0015827 C28.2701672,13.911793 29.1994548,13.0790623 30.3356243,13.0053177 Z M1,10 L1,12 L0,12 L0,10 L1,10 Z M1,6 L1,8 L0,8 L0,6 L1,6 Z M1,2 L1,4 L0,4 L0,2 L1,2 Z M1,0 L1,1 L0,1 L0,0 L1,0 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
);

const SvgTreeInward = ({ rotate }) => (
    <svg width="38px" height="31px" viewBox="0 0 38 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={rotate ? styleRotate : style}>
        <g id="Tree/tree_inward" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="#F6F7F7" x="0" y="0" width="38" height="31"></rect>
            <path d="M1,-7.10542736e-15 L1,13.9474402 L28.0498926,14.000574 C28.2813059,12.8591679 29.2903182,12 30.5,12 C31.8807119,12 33,13.1192881 33,14.5 C33,15.7093254 32.1413382,16.7180997 31.0004345,16.9499027 L31,31 L30,31 L30.000574,16.9501074 C29.0208811,16.7514805 28.2491173,15.9799847 28.0500973,15.0004345 L0,14.9474402 L0,-7.10542736e-15 L1,-7.10542736e-15 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
);

const SvgTreeOutward = ({ rotate }) => (
    <svg width="38px" height="31px" viewBox="0 0 38 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={rotate ? styleRotate : style}>
        <g id="Tree/tree_outward" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="#F6F7F7" x="0" y="0" width="38" height="31"></rect>
            <path d="M31,0 L31.0004345,13.0500973 C32.1413382,13.2819003 33,14.2906746 33,15.5 C33,16.8807119 31.8807119,18 30.5,18 C29.2931687,18 28.2860623,17.1448764 28.0515415,16.0074901 L1,16.0604334 L1,31 L0,31 L0,15.0611526 L28.0482697,15.0086476 C28.2443521,14.0247864 29.0178553,13.249133 30.000574,13.0498926 L30,0 L31,0 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
);

const SvgTreeRootBranch = ({ rotate }) => (
    <svg width="38px" height="31px" viewBox="0 0 38 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={rotate ? styleRotate : style}>
        <g id="Tree/tree_root_branch" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="#F6F7F7" x="0" y="0" width="38" height="31"></rect>
            <path d="M0,0 L1,0 L1,15 L28.5498926,15.000574 C28.7813059,13.8591679 29.7903182,13 31,13 C32.3807119,13 33.5,14.1192881 33.5,15.5 C33.5,16.8807119 32.3807119,18 31,18 C29.794594,18 28.7884413,17.146895 28.5523756,16.0115186 L1,16.0660977 L1,31 L0,31 L0,0 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
);

const SvgTreeRootBranchExpandedEnd = ({ rotate }) => (
    <svg width="38px" height="31px" viewBox="0 0 38 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={rotate ? styleRotate : style}>
        <g id="Tree/tree_root_branch_exp_end" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="#F6F7F7" x="0" y="0" width="38" height="31"></rect>
            <path d="M1,30 L1,31 L0,31 L0,30 L1,30 Z M1,26 L1,28 L0,28 L0,26 L1,26 Z M1,22 L1,24 L0,24 L0,22 L1,22 Z M1,18 L1,20 L0,20 L0,18 L1,18 Z M30.5,13 C31.8807119,13 33,14.1192881 33,15.5 C33,16.8807119 31.8807119,18 30.5,18 C29.2931687,18 28.2860623,17.1448764 28.0515415,16.0074901 L1,16.0604334 L1,17 L0,17 L0,15.0086306 L28.0496883,15.0015827 C28.2807115,13.8596741 29.2899619,13 30.5,13 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
);

const SvgTreeRootBranchExpandedStart = ({ rotate }) => (
    <svg width="38px" height="31px" viewBox="0 0 38 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={rotate ? styleRotate : style}>
        <g id="Tree/tree_root_branch_exp_start" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="#F6F7F7" x="0" y="0" width="38" height="31"></rect>
            <path d="M1,30 L1,31 L0,31 L0,30 L1,30 Z M1,26 L1,28 L0,28 L0,26 L1,26 Z M1,22 L1,24 L0,24 L0,22 L1,22 Z M1,18 L1,20 L0,20 L0,18 L1,18 Z M1,0 L1,13.9474402 L28.0498926,14.000574 C28.2813059,12.8591679 29.2903182,12 30.5,12 C31.8807119,12 33,13.1192881 33,14.5 C33,15.8807119 31.8807119,17 30.5,17 C29.2931687,17 28.2860623,16.1448764 28.0515415,15.0074901 L1,15 L1,16 L0,16 L0,0 L1,0 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
);

const SvgTreeRootEnd = ({ rotate }) => (
    <svg width="38px" height="31px" viewBox="0 0 38 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={rotate ? styleRotate : style}>
        <g id="Tree/tree_root_end" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="#F6F7F7" x="0" y="0" width="38" height="31"></rect>
            <path d="M1,0 L1,13.9474402 L28.5496883,14.0015827 C28.7807115,12.8596741 29.7899619,12 31,12 C32.3807119,12 33.5,13.1192881 33.5,14.5 C33.5,15.8807119 32.3807119,17 31,17 C29.7910309,17 28.7824948,16.1418441 28.5503024,15.0014429 L0,14.9474402 L0,0 L1,0 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
);

const SvgTreeRootFirst = ({ rotate }) => (
    <svg width="38px" height="31px" viewBox="0 0 38 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={rotate ? styleRotate : style}>
        <g id="Tree/tree_root_first" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="#F6F7F7" x="0" y="0" width="38" height="31"></rect>
            <path d="M32,13 C33.3807119,13 34.5,14.1192881 34.5,15.5 C34.5,16.8807119 33.3807119,18 32,18 C30.8149038,18 29.8224031,17.1754007 29.5649611,16.0686652 L1,16.125 L1,31 L0,31 L0,15.125 L29.5371639,15.0681366 C29.7417559,13.8932417 30.7665641,13 32,13 Z" id="Combined-Shape" fill="#000000"></path>
        </g>
    </svg>
);

export {
    SvgTreeEditEndExpanded,
    SvgTreeInward,
    SvgTreeOutward,
    SvgTreeRootBranch,
    SvgTreeRootBranchExpandedEnd,
    SvgTreeRootBranchExpandedStart,
    SvgTreeRootEnd,
    SvgTreeRootFirst
};
