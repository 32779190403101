import React, { useState, useEffect } from "react";
import { injectIntl } from 'react-intl';
import classnames from 'classnames'
import SvgCanvasColorSwatch from "components/svg/CanvasColorSwatch";

const colors = [
    {
        value: "green",
        colorCode: "rgb(0, 0, 0, 0.3)",
        colorCodeSelected: "rgb(6, 186, 91)",
        text: "minor"
    },
    {
        value: "yellow",
        colorCode: "rgb(0, 0, 0, 0.3)",
        colorCodeSelected: "rgb(255, 189, 0)",
        text: "moderate"
    },
    {
        value: "red",
        colorCode: "rgb(0, 0, 0, 0.3)",
        colorCodeSelected: "rgb(240, 0, 0)",
        text: "severe"
    }
]

const TrafficLightComponent = ({ formComponentData, myName, parentSetValue, parentHandleSave, setValue, getValue, className, intl}) => {
    const [color, setColor] = useState('');

    useEffect(() => {
        const value = getCurrentData();
        if (value !== undefined) {
            setColor(value);
        }
    }, [getValue, parentSetValue]);

    const getCurrentData = () => {
        let values = getValue(myName);
        if (values !== undefined) {
            return values;
        } else if (parentSetValue) {
            return parentSetValue
        }
    }

    const saveValues = objectToSave => {
        if (parentSetValue !== undefined) {
            parentHandleSave(objectToSave, myName)
        } else {
            setValue(objectToSave, myName)
        }
    }

    const handleChange = value => {
        if (value.value === color.value) {
            value = '';
        }
        setColor(value);
        saveValues(value);
    }

    const renderLight = (c, index) => {
        return <div key={index} className="light" onClick={() => handleChange(c)}>
            <SvgCanvasColorSwatch
                strokeColor="#485A64"
                fillColor={c.value === color.value || color.value === undefined ? c.colorCodeSelected : c.colorCode}
                size="38px"
                title={intl.formatMessage({id: c.text}) }/>
        </div>
    }

    return <div className={classnames('input-shell', className)}>
        {formComponentData.description && (<label
            htmlFor={formComponentData.name}
            className={classnames('input-shell__label', 'input-shell__label--dark', { 'input-shell__label--required': formComponentData.required })}>
            {formComponentData.description}
        </label>)}
        <div className="input-shell__container traffic-light" data-id={myName}>
            { colors.map((c, index) => renderLight(c, index)) }
        </div>
    </div>;
    
}

export default injectIntl(TrafficLightComponent);