import { SET_CONNECTION } from 'redux/types'
import initialState from 'redux/initialState'

export default function connectionReducer(state = initialState.connection, action) {
  let newState = '';
  switch (action.type) {
      case SET_CONNECTION:
        return newState = action.payload;
    default:
      return state
  }
}