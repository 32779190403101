import React, { useEffect, useState } from "react";
import classnames from "classnames";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import Select from "react-select";
import { useIntl } from "react-intl";

const SelectValueUnitComponent = ({ parentSetValue, parentHandleSave, myName, getValue, setValue, formComponentData }) => { 
  const intl = useIntl();

  const [value, setCurrentValue] = useState("");
  const [unitSelected, setUnitSelected] = useState("");
  const [units, setUnits] = useState([]);

  const translateOptions = options => {
    if (options) {
      return options.map(option => ({
        value: option.value,
        label: intl.formatMessage({ id: `${option.value.toLocaleLowerCase()}` })
      }));
    } else { 
      return null;
    }
  };

  useEffect(() => { 
    let values;

    if (parentSetValue) {
      values = parentSetValue
    } else { 
      values = getValue(myName);
    }
    if (values) {
      setCurrentValue(values.value);
      setUnitSelected(values.unitSelected);
    } else { 
      setCurrentValue("");
      setUnitSelected("");
    }
    setUnits(translateOptions(formComponentData.args[0].options))
  }, [parentSetValue, myName, getValue, formComponentData]);

  const handleChange = (event, select) => { 
    if (select !== undefined) {
      setUnitSelected(event);
      saveValue({ value, unitSelected: event });
    } else if (event.target.type === "number") {
      setCurrentValue(event.target.value)
    }
  }

  const _onBlur = () => {
    saveValue({ value, unitSelected });
  };

  const saveValue = (currentValue) => { 
    if (parentHandleSave !== undefined) {
      parentHandleSave(currentValue, myName);
    } else {
      setValue(currentValue, myName);
    }
  }

  return (
    <div className="input-shell">
      {formComponentData.description && (
        <label
          htmlFor={myName}
          className={classnames("input-shell__label", "input-shell__label--dark", {"input-shell__label--required": formComponentData.required})}>
          {formComponentData.description}
        </label>
      )}
      <div className="input-shell__container input-shell__container--double-inputs">
        <input
          onChange={event => handleChange(event)}
          onBlur={_onBlur}
          type="number"
          className="input__element"
          placeholder={formComponentData.hasOwnProperty("placeholder") ? formComponentData.placeholder : ""}
          value={value !== null ? value : ""}
        />
        <Select
          onChange={value => {
            if (value !== null) {
              handleChange(value, "select");
            } else {
              setValue("");
              setUnitSelected("");
              handleChange("", "select");
            }
          }}
          className="select__element"
          styles={ReactSelectStyles}
          placeholder={intl.formatMessage({id: "customComponents.select"})}
          value={unitSelected}
          options={units}
          isClearable={true}
        />
      </div>
      {(formComponentData.required || formComponentData.showrequired) && (
        <div className="input-shell__message">
          <div className="input-shell__message-hint">
            {intl.formatMessage({ id: "formComponents.required" })}
          </div>
        </div>
      )}
    </div>
  );

}

export default SelectValueUnitComponent;
