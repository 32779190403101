import React from "react";

const SvgSkfLogo = props => (
  <svg
    width={88}
    height={21}
    viewBox="0 0 88 21"
    aria-hidden="true"
    className="skf-logo_svg__svg"
    {...props}
  >
    <g className="skf-logo_svg__svg__color" fillRule="evenodd">
      <defs>
        <path id="skf-logo_svg__a" d="M27.396.093H.275v20.598h27.12V.093z" />
        <path
          id="skf-logo_svg__c"
          d="M13.848 20.691h13.563V.093H.285v20.598z"
        />
        <path id="skf-logo_svg__e" d="M5.411 5.25V.17H.247v5.08H5.41z" />
      </defs>
      <g fill="none">
        <path
          d="M25.059 20.907a.774.774 0 00.774-.773V8.805a.773.773 0 00-.775-.772H10.85a.516.516 0 01-.517-.515v-4.12c0-.284.232-.514.517-.514h4.133c.286 0 .517.23.517.514v1.288c0 .426.347.773.775.773h6.2a.774.774 0 00.775-.773V1.081a.774.774 0 00-.775-.772H3.357a.774.774 0 00-.775.773v8.753c0 .426.347.772.775.772h14.21c.285 0 .516.23.516.515v6.695a.516.516 0 01-.516.515h-9.3a.516.516 0 01-.517-.515v-3.862a.774.774 0 00-.775-.772h-6.2a.773.773 0 00-.775.772v6.18c0 .426.347.772.775.772h24.284z"
          fill="#FEFEFE"
        />
        <g transform="translate(29.437 .216)">
          <mask id="skf-logo_svg__b" fill="#fff">
            <use xlinkHref="#skf-logo_svg__a" />
          </mask>
          <path
            d="M9.314 13.293a.13.13 0 01.13-.129.13.13 0 01.089.036l7.29 7.27a.769.769 0 00.541.221h9.257a.773.773 0 00.775-.772v-6.176a.773.773 0 00-.775-.772h-6.68a.773.773 0 01-.534-.214l-4.861-4.85a.129.129 0 01-.038-.09c0-.037.014-.066.038-.09l2.262-2.263a.774.774 0 01.544-.221h6.686a.774.774 0 00.775-.773V.865a.773.773 0 00-.775-.772h-6.674a.777.777 0 00-.542.22l-7.289 7.27a.13.13 0 01-.22-.093V.865a.774.774 0 00-.775-.772H1.049a.774.774 0 00-.774.772v6.18c0 .426.347.772.774.772.286 0 .517.23.517.515v4.12a.516.516 0 01-.517.515.773.773 0 00-.774.772v6.18c0 .426.347.772.774.772h7.489a.775.775 0 00.775-.773v-6.625z"
            fill="#FEFEFE"
            mask="url(#skf-logo_svg__b)"
          />
        </g>
        <g transform="translate(60.423 .216)">
          <mask id="skf-logo_svg__d" fill="#fff">
            <use xlinkHref="#skf-logo_svg__c" />
          </mask>
          <path
            d="M8.549 20.691a.775.775 0 00.775-.773v-6.18a.774.774 0 00-.775-.771.517.517 0 01-.517-.515v-1.545c0-.285.232-.515.517-.515h2.838c.285 0 .516.23.516.515 0 .426.347.772.775.772h6.208a.774.774 0 00.775-.772V7.302a.774.774 0 00-.775-.772h-6.208a.774.774 0 00-.775.772.515.515 0 01-.516.515H8.549a.516.516 0 01-.517-.515v-4.12c0-.283.232-.514.517-.514h8.01c.287 0 .517.23.517.514V4.47c0 .427.348.773.776.773h8.784a.774.774 0 00.775-.773V.865a.774.774 0 00-.775-.772H1.06a.773.773 0 00-.775.772v6.18c0 .426.347.772.775.772.286 0 .517.23.517.515v4.12a.516.516 0 01-.517.515.773.773 0 00-.775.772v6.18c0 .426.347.772.775.772h7.49z"
            fill="#FEFEFE"
            mask="url(#skf-logo_svg__d)"
          />
        </g>
        <g transform="translate(82.423 15.657)">
          <mask id="skf-logo_svg__f" fill="#fff">
            <use xlinkHref="#skf-logo_svg__e" />
          </mask>
          <path
            d="M2.377 2.55v-.798h.534c.273 0 .564.059.564.379 0 .395-.297.42-.63.42h-.468zm0 .331h.45L3.51 4h.44l-.737-1.135c.38-.048.67-.249.67-.71 0-.509-.302-.733-.913-.733h-.985V4h.392V2.88zm.459 2.369c1.389 0 2.575-1.075 2.575-2.547C5.411 1.245 4.225.17 2.836.17 1.434.17.246 1.245.246 2.703c0 1.472 1.188 2.547 2.59 2.547zM.759 2.703c0-1.203.91-2.11 2.077-2.11C3.989.593 4.9 1.5 4.9 2.703c0 1.223-.911 2.124-2.064 2.124-1.166 0-2.077-.901-2.077-2.124z"
            fill="#FEFEFE"
            mask="url(#skf-logo_svg__f)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgSkfLogo;
