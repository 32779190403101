import React, { useState } from "react";
import classnames from "classnames";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SvgSavingIndicator from "../../svg/SavingIndicator"
import restoreDeletedCase from "./../ButtonActions/restoreCase";
import { useIntl } from "react-intl";
import { isUserAdmin } from "components/formcomponents/utils/userUtils";

const ButtonRestore = () => { 
    const dispatch = useDispatch();
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);
    const connection = useSelector(state => state.connection, shallowEqual);
    const user = useSelector(state => state.currentUser, shallowEqual);

    const onRestore = () => {
        setIsLoading(true);
        restoreDeletedCase(currentCaseId, dispatch).then(() => {
            setIsLoading(false);
        });
    }

    const isAdmin = isUserAdmin(user["custom:administrator"]);

    return isAdmin && (
        <button
            id="buttonReload"
            type="button"
            onClick={() => onRestore()}
            className={classnames("button", "button--icon-only", { "button--icon-saving": isLoading }, { "button--icon-disabled": !connection })}
        >
            <SvgSavingIndicator color="#F8F8F8" title={intl.formatMessage({ id: "restoreReport" })} />
        </button>
    )
}

export default ButtonRestore;