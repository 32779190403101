import React from 'react'
import { getImageLayoutObject, getImageObjects, imageSectionNames, isImageEmpty } from './BIHelperFunctions'
import { useIntl } from 'react-intl'
import { formatRollingElementsHeader } from './LivepreviewHelperFunctions'
import ImageContainer from './ImageContainer'
import TrafficLight from '../UsInspection/TrafficLight'

const InspectionComponentPartsInspection = ({
  index,
  data,
  hasScrollTarget,
  scrollTarget,
  bearingsConfig,
}) => {
  const intl = useIntl()
  let currentName = ''

  const renderHeaderName = (partsOfName, key) => {
    const componentPartsInvestigationType = partsOfName[3]
    const componentPartsInvestigationSubtype = partsOfName[4]

    let headerName = intl.formatMessage({ id: `${componentPartsInvestigationSubtype}` })
    if (key.includes('innerRing') || key.includes('outerRing')) {
      headerName = `${intl.formatMessage({ id: `${componentPartsInvestigationType}` })} - ${intl.formatMessage({ id: `${componentPartsInvestigationSubtype}` })}`
    }
    return headerName
  }

  const hasObservations = (key) => data[key]?.observations?.length > 0
  const hasSeverity = (key) => data[key]?.severity?.value

  const renderObservations = (data) => {
    if (!data) return ''

    if (typeof data === 'string') {
      const text = data.replace(/<[^>]*>/g, '')
      return text
    } else if (typeof data === 'object') {
      return data?.map(({ label }) => label).join(', ')
    }

    return ''
  }

  return (
    <div data-section-id={`bearingInvestigations.bearingInvestigation[${index}].componentPartsInvestigation`}>
      {imageSectionNames(index, bearingsConfig).map((name) => {
        const key = name.key
        const partsOfName = key.split('.')
        const componentPartsInvestigationType = partsOfName[3]

        if (partsOfName[partsOfName.length - 1] === 'allPartsAvailable') return null

        let scrollHook = ''
        if (currentName !== componentPartsInvestigationType) {
          currentName = componentPartsInvestigationType
          scrollHook = `${partsOfName[0]}.${partsOfName[1]}.${partsOfName[2]}.${currentName}`
        }

        if (isImageEmpty(data[key]) && !hasScrollTarget(key)) return null

        const headerName = formatRollingElementsHeader(data, index, renderHeaderName(partsOfName, key), scrollTarget)

        return (data[key] || hasScrollTarget(key)) ? (
          <div key={key + '.whole'} data-section-id={scrollHook}>
            <div className='live-preview-images' data-key-id={key}>
              <h6 className='live-preview-images__headline live-preview-images__severity'>
                <span>{headerName}</span>
                {(hasSeverity(key) || hasScrollTarget(key)) && (
                  <div key={key + '.severity'} className='severity'>
                    <div className='severity__header'>{intl.formatMessage({ id: 'severity' })}</div>
                    <TrafficLight scrollKey={key} severity={data[key]?.severity} />
                  </div>
                )}
              </h6>

              {(hasObservations(key) || hasScrollTarget(key)) && (
                <div key={key + '.observations'} className='live-preview-key-value__row'>
                  <div className='live-preview-key-value__cell'>
                    <div className='live-preview-key-value__key'>
                      {intl.formatMessage({ id: 'observations' })}:
                    </div>
                    <div className='live-preview-key-value__value'>
                      {hasObservations(key) && renderObservations(data[key]?.observations)}
                    </div>
                  </div>
                </div>
              )}

              <ImageContainer
                imageObjects={getImageObjects(key, data)}
                imageLayout={getImageLayoutObject(data[key])}
              />
            </div>
          </div>
        ) : null
      })}
    </div>
  )
}

export default InspectionComponentPartsInspection
