import React from "react";

const SvgButtonBarDistribute = ({ title, size = "26px" }) => (
    <svg width={size} height={size} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{title}</title>
        <g id="Icons/icon_envelope" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M18,2 C19.1,2 20,2.9 20,4 L20,4 L20,16 C20,17.1 19.1,18 18,18 L18,18 L2,18 C0.9,18 0,17.1 0,16 L0,16 L0,4 C0,2.9 0.9,2 2,2 L2,2 L18,2 Z M1.5,4.84940479 C1.5,4.17940479 2.275625,3.77940479 2.88125,4.12940479 L2.88125,4.12940479 L10,8.31940479 L17.11875,4.12940479 L17.2210994,4.0779336 C17.8081617,3.82468257 18.5,4.21662701 18.5,4.84940479 C18.5,5.13940479 18.340625,5.40940479 18.075,5.56940479 L18.075,5.56940479 L14.4,7.732 L18.5696774,13.0741573 C18.9401286,13.5526961 18.7527829,14.2615074 18.2420361,14.4204416 L18.1425385,14.4436074 C17.8894822,14.4836875 17.6306697,14.374472 17.4523719,14.1523658 L17.4523719,14.1523658 L13.034,8.536 L11.12625,9.65940479 L10.9638331,9.74674207 C10.3026553,10.0669788 9.51125,10.0378663 8.87375,9.65940479 L8.87375,9.65940479 L7.388,8.785 L3.16587623,13.9952716 C2.7844316,14.4620455 2.05586721,14.4409527 1.78357627,13.9842686 L1.73543977,13.8871394 C1.63946156,13.649585 1.68765781,13.3728377 1.8639631,13.1491467 L1.8639631,13.1491467 L6.021,7.98 L1.925,5.56940479 L1.83047485,5.50442432 C1.62202148,5.34159229 1.5,5.10315479 1.5,4.84940479 Z" id="Combined-Shape" fill="#F8F8F8"></path>
        </g>
    </svg>
);

export default SvgButtonBarDistribute;
