import { SET_ISBEARINGS_EXPANDED, SET_BEARINGS_EXPANDEDSTATE, SET_RESETBEARINGS_EXPANDED, SET_ISBEARINGS_SAVING  } from "redux/types";
import initialState from "redux/initialState";

export default function isBearingsExpandedReducer(
  state = initialState.bearingsExpanded,
  action
) {
  switch (action.type) {
    case SET_ISBEARINGS_EXPANDED:
      return { ...state, isBearingsExpanded: action.payload };
    case SET_RESETBEARINGS_EXPANDED:
      return { isBearingsExpanded: false, bearingsExpandedState: "" };
    case SET_BEARINGS_EXPANDEDSTATE:
      return { isBearingsExpanded: true, bearingsExpandedState: action.payload };
    case SET_ISBEARINGS_SAVING:
      return { ...state, isBearingsSaving: action.payload };
    default:
      return state;
  }
}
