import React from "react";

export function checkIfAnyBearingHasDesignationBrandOrRecommendations(data) {
    for (var i = 0; i < data["bearingInvestigations.count"]; i++) {
        let bearingDesignationComponent = data[`bearingInvestigations.bearingInvestigation[${i}].bearingType.BearingDesignationComponent`]
        if (bearingDesignationComponent) {
            if (bearingDesignationComponent.skfOrCompetitor.value == "skf") {
                if (bearingDesignationComponent.SKFManufacturer.label !== undefined || bearingDesignationComponent.skfBearingDesignation.label !== undefined) {
                    return true
                }
            } else if (bearingDesignationComponent.skfOrCompetitor.value == "competitor") {
                if (bearingDesignationComponent.manufacturer.label !== undefined || bearingDesignationComponent.competitorDesignationAndMarkings !== undefined) {
                    return true
                }
            }
        }
        if (data[`bearingInvestigations.bearingInvestigation[${i}].recommendations.recommendations`]) {
            if (data[`bearingInvestigations.bearingInvestigation[${i}].recommendations.recommendations`].value) {
                return true
            }
        }
    } 
    return false;
}

export function renderCustomerPartReferenceNumber(i, data) {
    const customerReferencePartNumber = data["bearingInvestigations.bearingInvestigation[" + i + "].bearingType.customerReferencePartNumber"]
    if (customerReferencePartNumber) {
        return customerReferencePartNumber;
    }
    const customerReferenceNumber = data["bearingInvestigations.bearingInvestigation[" + i + "].bearingType.customerReferenceNumber"];
    if (customerReferenceNumber) {
        return customerReferenceNumber;
    }
    return null;
}

export function getBearingData(i, data, includeReferencePartNumber = true) { 
    const bearingDesignationObject = data["bearingInvestigations.bearingInvestigation[" + i + "].bearingType.BearingDesignationComponent"];

    const bearingData = {
        manufacturer: "",
        designation: "",
        customerReferenceNumber: "",
        skfEquivalent: "",
    }

    if (bearingDesignationObject && bearingDesignationObject.skfOrCompetitor) {
        if (bearingDesignationObject.skfOrCompetitor.value == "skf") {
            bearingData.manufacturer = bearingDesignationObject.SKFManufacturer.label;
            bearingData.designation = bearingDesignationObject.skfBearingDesignation.label;
        } else if(bearingDesignationObject.skfOrCompetitor.value == "competitor") {
            bearingData.manufacturer = bearingDesignationObject.manufacturer.label;
            bearingData.designation = typeof bearingDesignationObject.competitorDesignationAndMarkings === "object"
                ? bearingDesignationObject.competitorDesignationAndMarkings.label
                : bearingDesignationObject.competitorDesignationAndMarkings;
            bearingData.skfEquivalent = bearingDesignationObject.skfEquivalent || "";
        }  
    } 
    if (includeReferencePartNumber) { 
        const customerReferencePartNumber = data["bearingInvestigations.bearingInvestigation[" + i + "].bearingType.customerReferencePartNumber"]
        const customerReferenceNumber = data["bearingInvestigations.bearingInvestigation[" + i + "].bearingType.customerReferenceNumber"];
        if (customerReferencePartNumber) {
            bearingData.customerReferenceNumber = customerReferencePartNumber;
        } else if (customerReferenceNumber) {
            bearingData.customerReferenceNumber = customerReferenceNumber;
        }
    } 
    return bearingData;
}
export function renderBearingDesignation(i, data, includeReferencePartNumber = true) {
    const bearingData = getBearingData(i, data, includeReferencePartNumber);

    const result = Object.keys(bearingData).map(k => bearingData[k]).filter(s => s).join(" ");

    if (result) {
        return (<span>{result}</span>)
    } else {
        return null
    }
}

export function renderBearingDesignationHeader (bearingText, customerRefNumberText, i, data, includeReferencePartNumber = true) {
    const bearingData = getBearingData(i, data, includeReferencePartNumber);
    return renderBearingData(bearingText, customerRefNumberText, i, bearingData);
    
}

function renderBearingData(bearingText, customerRefNumberText, i, bearingData) {
    const parts = [<span key={i + "first"} className="first">{`${bearingText} ${i + 1}`}</span>]
    if (bearingData.manufacturer || bearingData.customerReferenceNumber) {
        parts.push(<span key={i + "middle"}>{[bearingData.manufacturer, bearingData.designation].filter(s => s).join(" ")}</span>)
    }
    if (bearingData.customerReferenceNumber) {
        parts.push(<span key={i + "last"} className="last">{customerRefNumberText} {bearingData.customerReferenceNumber}</span>)
    }
    return parts;
}

export function getBearingDesignationGBI(i, data, includeReferencePartNumber = true) { 
    const type = data["TERorAMFRorOther"];
    if (type) {
        const bearingData = {
            manufacturer: "",
            designation: "",
            customerReferenceNumber: ""
        }

        if (type.GBIReportType.value === "Other") {
            const bearingDesignationObject = data["bearingInvestigations.bearingInvestigation[" + i + "].otherBearingInvestigation"];
            if (bearingDesignationObject && bearingDesignationObject.bearingDesignation) {
                if (bearingDesignationObject.bearingDesignation.skfOrCompetitor.value == "skf") {
                    bearingData.manufacturer = bearingDesignationObject.bearingDesignation.SKFManufacturer ? bearingDesignationObject.bearingDesignation.SKFManufacturer.label : "";
                    bearingData.designation = bearingDesignationObject.bearingDesignation.skfBearingDesignation ? bearingDesignationObject.bearingDesignation.skfBearingDesignation.label : "";
                } else if (bearingDesignationObject.bearingDesignation.skfOrCompetitor.value == "competitor") {
                    bearingData.manufacturer = bearingDesignationObject.bearingDesignation.manufacturer ? bearingDesignationObject.bearingDesignation.manufacturer.label : "";
                    bearingData.designation = bearingDesignationObject.bearingDesignation.competitorDesignationAndMarkings;
                }
            }
        } else if (type.GBIReportType.value === "AMFR") {
            const bearingDesignationObject = data["bearingInvestigations.bearingInvestigation[" + i + "].amfrBearingInvestigation"];
            if (bearingDesignationObject && bearingDesignationObject.skfBearingDesignation) {
                bearingData.designation = bearingDesignationObject.skfBearingDesignation;
            }
        } else if (type.GBIReportType.value === "TER") { 
            if (includeReferencePartNumber) { 
                const terBearingInvestigation = data["bearingInvestigations.bearingInvestigation[" + i + "].terBearingInvestigation"]
                if (terBearingInvestigation && terBearingInvestigation.customerReferencePartNumber) { 
                    bearingData.customerReferenceNumber = terBearingInvestigation.customerReferencePartNumber;
                }
            }
        }

        return bearingData;
    }
}

export function renderBearingDesignationHeaderGBI(bearingText, customerRefNumberText, i, data, includeReferencePartNumber = true) {
    const bearingData = getBearingDesignationGBI(i, data, includeReferencePartNumber);
    if (bearingData) {
        return renderBearingData(bearingText, customerRefNumberText, i, bearingData);
    } else { 
        return <span className="first">{bearingText} {i + 1}</span>;
    }
}

export function renderBearingDesignationGBI(i, data, includeReferencePartNumber = true) {
    const bearingData = getBearingDesignationGBI(i, data, includeReferencePartNumber);
    if (bearingData) { 
        const result = Object.keys(bearingData).map(k => bearingData[k]).filter(s => s).join(" ");

        if (result) {
            return (<span>{result}</span>)
        } else {
            return null
        }
    }
}


export function renderCustomerPartReferenceNumberGBI(i, data) {
    const type = data["TERorAMFRorOther"];
    if (type) { 
        if (type.GBIReportType.value === "TER") { 
            const terBearingInvestigation = data["bearingInvestigations.bearingInvestigation[" + i + "].terBearingInvestigation"]
            if (terBearingInvestigation && terBearingInvestigation.customerReferencePartNumber) { 
                return terBearingInvestigation.customerReferencePartNumber;
            }
        }
    }
    return null;
}

export default renderBearingDesignation;