import { SET_IS_RAILWAY_WHEELSET_BEARING, RESET_IS_RAILWAY_WHEELSET_BEARING } from "redux/types";
import initialState from "redux/initialState";

export default function isRailwayWheelsetBearingReducer(
  state = initialState.isRailwayWheelsetBearing,
  action
) {
  switch (action.type) {
    case SET_IS_RAILWAY_WHEELSET_BEARING: {
      const newState = [...state.map(i => ({bearingIndex: i.bearingIndex, isRailwayBearing: i.isRailwayBearing}))];
      const bearingValue = newState.find(bi => bi.bearingIndex == action.payload.bearingIndex);
      if (bearingValue) {
        bearingValue.isRailwayBearing = action.payload.isRailwayBearing
      } else { 
        newState.push(action.payload)
      }
      return newState;
    }
    case RESET_IS_RAILWAY_WHEELSET_BEARING: { 
      return [];
    }
    default:
      return state;
  }
}
