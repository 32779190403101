import React from "react";

const SvgButtonBarShareImages = ({ title, size = "26px" }) => (
    <svg width={ size } height={ size } viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{title}</title>
        <g id="Icons/icon_download_images" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M14.59,8.0925 L13,8.0925 L13,13.0925 C13,13.6425 12.55,14.0925 12,14.0925 L8,14.0925 C7.45,14.0925 7,13.6425 7,13.0925 L7,8.0925 L5.41,8.0925 C4.52,8.0925 4.07,7.0125 4.7,6.3825 L9.29,1.7925 C9.68,1.4025 10.31,1.4025 10.7,1.7925 L15.29,6.3825 C15.92,7.0125 15.48,8.0925 14.59,8.0925 Z M3,17.5 C3,16.95 3.45,16.5 4,16.5 L16,16.5 C16.55,16.5 17,16.95 17,17.5 C17,18.05 16.55,18.5 16,18.5 L4,18.5 C3.45,18.5 3,18.05 3,17.5 Z" id="Shape" fill="#F8F8F8" transform="translate(10.000000, 10.000000) rotate(-180.000000) translate(-10.000000, -10.000000) "></path>
        </g>
    </svg>
);

export default SvgButtonBarShareImages;
