import React from "react";
import CaseSearchBox from "components/common/CaseSearchBox";
import { connect } from "react-redux";

const DashboardSearch = props => (
  props.connection && <div className="dashboard-search">
    <CaseSearchBox isUserExternal={props.isUserExternal} />
  </div>
);

const mapStateToProps = state => {
  return {
    connection: state.connection
  };
};

export default connect(mapStateToProps)(DashboardSearch);
