import { SET_ISBEARINGS_EXPANDED, SET_BEARINGS_EXPANDEDSTATE, SET_RESETBEARINGS_EXPANDED, SET_ISBEARINGS_SAVING } from "redux/types";

const setIsBearingsExpanded = (payload) => {
  return { type: SET_ISBEARINGS_EXPANDED, payload };
};

const setBearingsExpandedState = (payload) => {
  return { type: SET_BEARINGS_EXPANDEDSTATE, payload };
};

const setBearingsIsSaving = (payload) => {
  return { type: SET_ISBEARINGS_SAVING, payload };
};

const resetBearingsExpandedState = (payload) => {
  return { type: SET_RESETBEARINGS_EXPANDED, payload };
};

export { setIsBearingsExpanded, setBearingsExpandedState, resetBearingsExpandedState, setBearingsIsSaving };
