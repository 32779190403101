import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import getComponent from "components/_Case/ComponentsList"
import _ from 'lodash'

export default function FunctionalAreaComponent(props) {
  const [segmentType, setSegmentType] = useState(null)
  const [functionalArea, setFunctionalArea] = useState('')

  const segmentToSave = useSelector(state => state.saveComponentValue.componentsToSave.find(c => c?.answer?.name === 'assetDetails.assetTypeOrFunctionalAreaOrSystem.segment'))
  const savedSegment = useSelector(state => state.saveComponentValue.components.find(c => c.name === 'assetDetails.assetTypeOrFunctionalAreaOrSystem.segment'))

  useEffect(() => {
    setInitialSegmentValue(segmentToSave, savedSegment)
    setInitialFunctionalAreaValue()
  }, [])

  useEffect(() => {
    segmentChangeHandler()
  }, [segmentToSave])

  const segmentChangeHandler = () => {
    if (!segmentToSave?.answer?.value) return

    const parsedValue = JSON.parse(segmentToSave.answer.value)

    if (_.isEqual(parsedValue, segmentType)) {
      return
    }

    setSegmentType(parsedValue)

    if (_.isEmpty(parsedValue)) {
      setFunctionalArea('')
      saveValues('', props.myName)
      return
    }

    if (!isCustomSegmentValue(segmentTypes, parsedValue?.value)) {
      setFunctionalArea('')
      saveValues('', props.myName)
      return
    }

    const isCustomFunctionalArea = isCustomFunctionalAreaValue([...pulpPaperOptions, ...metalsOptions, ...miningOptions], functionalArea?.value)

    if (!isCustomSegmentValue(segmentTypes, parsedValue) && !isCustomFunctionalArea) {
      setFunctionalArea('')
      saveValues('', props.myName)
      return
    }

    if (isCustomSegmentValue(segmentTypes, parsedValue) && !isCustomFunctionalArea) {
      setFunctionalArea('')
      saveValues('', props.myName)
    }

    if (isCustomSegmentValue(segmentTypes, parsedValue?.value) && isCustomFunctionalArea) {
      setFunctionalArea(functionalArea)
      saveValues(functionalArea, props.myName)
    }
  }

  const setInitialFunctionalAreaValue = () => {
    let values
    if (props.parentSetValue) {
      values = props.parentSetValue(props.myName)
    } else {
      values = props.getValue(props.myName)
    }
    setFunctionalArea(values)
  }

  const setInitialSegmentValue = (newSegment, segmentFromState) => {
    if (newSegment) {
      if (newSegment?.answer.value) {
        const parsedValue = JSON.parse(newSegment.answer.value)
        setSegmentType(parsedValue)
        return
      }
    }
    if (segmentFromState) {
      if (segmentFromState?.value) {
        const parsedValue = JSON.parse(segmentFromState.value)
        const segmentValue = getCurrentValue(parsedValue)
        setSegmentType(segmentValue)
      }
    }
  }

  const changeHandler = (value, name) => {
    if (_.isEqual(value, functionalArea)) return

    if (isCustomFunctionalAreaValue([...pulpPaperOptions, ...metalsOptions, ...miningOptions], value)) {
      setFunctionalArea(value)
      saveValues(value, props.myName)
    } else {
      setFunctionalArea(value ? { ...value } : '')
      saveValues(value, props.myName)
    }
  }

  const saveValues = (value, name) => {
    if (props.parentHandleSave) {
      props.parentHandleSave(value, name)
    } else {
      props.setValue(value, name)
    }
  }

  const renderFunctionalAreaOptions = () => {
    const currentSegmentType = segmentType?.value ? segmentType.value : segmentType ? segmentType : ''
    switch (currentSegmentType) {
      case segmentTypes.MINING: {
        return getComponent(
          {
            "key": "mining",
            "type": "AutoCompleteCreatable",
            "description": "Mining",
            "required": true,
            "args": [{ "options": miningOptions }]
          },
          props.myName,
          changeHandler,
          functionalArea,
        )
      }
      case segmentTypes.METALS: {
        return getComponent(
          {
            "key": "metals",
            "type": "AutoCompleteCreatable",
            "description": "Metals",
            "required": true,
            "args": [{ "options": metalsOptions }]
          },
          props.myName,
          changeHandler,
          functionalArea,
        )
      }
      case segmentTypes.PULP_PAPER: {
        return getComponent(
          {
            "key": "pulp&paper",
            "type": "AutoCompleteCreatable",
            "description": "Pulp & Paper",
            "required": true,
            "args": [{ "options": pulpPaperOptions }]
          },
          props.myName,
          changeHandler,
          functionalArea,
        )
      }
      default: {
        const currentValue = getCurrentValue(functionalArea)
        return getComponent(
          {
            "key": "other",
            "type": "Text",
            "description": "Functional Area",
            "required": true,
          },
          props.myName,
          changeHandler,
          currentValue,
        )
      }
    }
  }

  return <>{renderFunctionalAreaOptions()}</>
}

const getCurrentValue = currentValue => currentValue && typeof currentValue === 'object' ? currentValue?.value : currentValue ? currentValue : ''
const isCustomSegmentValue = (data, value) => !_.some(data, type => type === value)
const isCustomFunctionalAreaValue = (data, value) => !data.some(e => e?.value === value?.value || e?.value === value)

const segmentTypes = {
  METALS: 'Metals',
  MINING: 'Mining',
  PULP_PAPER: 'Pulp & Paper',
}

const miningOptions = [
  { "label": "screening", "value": "Screening" },
  { "label": "crushing", "value": "Crushing" },
  { "label": "grinding", "value": "Grinding" },
  { "label": "flotation", "value": "Flotation" },
  { "label": "pelletizing", "value": "Pelletizing" },
]

const metalsOptions = [
  { "label": "ladleTurret", "value": "Ladle turret" },
  { "label": "footRoller", "value": "Foot roller" },
  { "label": "lowerSegmentRoll", "value": "Lower segment roll" },
  { "label": "rollOutTable", "value": "Roll out table" },
  { "label": "hotMill", "value": "Hot mill" },
  { "label": "coldMill", "value": "Cold mill" },
  { "label": "barWireMill", "value": "Bar, wire mill" },
  { "label": "driveline", "value": "Driveline" },
]

const pulpPaperOptions = [
  { "label": "formingSection", "value": "Forming section" },
  { "label": "pressSection", "value": "Press section" },
  { "label": "dryerSection", "value": "Dryer section" },
  { "label": "calenders", "value": "Calenders" },
  { "label": "reeler", "value": "Reeler" },
]