import React from "react";

const SvgButtonBarReject = ({ title, size = "26px" }) => (
    <svg width={ size } height={ size } viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{ title }</title>
        <g id="Icons/icon_reject" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M10.979,1.0002 L13.688,1.8112 L13.688,10.5322 C13.688,10.5322 13.2445671,11.0613796 12.7758185,11.6345865 L12.5412703,11.9226128 C12.1920907,12.3534174 11.8678696,12.7635043 11.745,12.9482 L11.745,12.9482 L10.006,15.5522 L9.131,18.8522 C8.646,19.5782 7.683,19.7612 6.979,19.2612 C6.275,18.7612 6.429,15.4812 6.812,14.7912 C7.333,13.8512 7.657,12.8412 7.745,12.3322 C7.779,12.1372 7.762,11.9502 7.723,11.7742 L7.723,11.7742 L2.547,11.7742 C1.692,11.7742 1,11.0592 1,10.1772 C1,9.4432 1.482,8.8322 2.135,8.6442 C1.822,8.3522 1.622,7.9342 1.622,7.4642 C1.622,6.6882 2.159,6.0432 2.87,5.8982 C2.562,5.6062 2.367,5.1922 2.367,4.7262 C2.367,3.9742 2.872,3.3492 3.549,3.1792 C3.48,2.9982 3.439,2.8032 3.439,2.5972 C3.439,1.7152 4.132,1.0002 4.986,1.0002 L4.986,1.0002 L10.979,1.0002 Z M17.7749,1 C18.2659,1 18.6649,1.398 18.6649,1.89 L18.6649,1.89 L18.6649,10.403 C18.6649,10.895 18.2659,11.293 17.7749,11.293 L17.7749,11.293 L15.5109,11.293 C15.0199,11.293 14.6219,10.895 14.6219,10.403 L14.6219,10.403 L14.6219,1.89 C14.6219,1.398 15.0199,1 15.5109,1 L15.5109,1 Z" id="Combined-Shape" fill="#F8F8F8"></path>
        </g>
    </svg>
);

export default SvgButtonBarReject;
