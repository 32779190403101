import { SET_ACTIVE_TAB } from 'redux/types'
import initialState from 'redux/initialState'

export default function activeTabReducer(state = initialState.activeTab, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return action.payload;
    default:
      return state
  }
}
