import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ContentContainer = (props) => {
  return (
    <div className="content-container">
      {props.header && <h2 className="content-container__header">
        { props.header }
      </h2>}

      <div className={ classnames('content-container__content', { 'content-container__content--frame': props.useFrame }, { 'content-container__content--has-link': props.hasLink }, { 'content-container__content--has-toggler': props.hasToggler })}>
        { props.children }

        { props.link }

        {props.showMoreComponent && <div className="content-container__toggle-container">
          { props.showMoreComponent }
        </div>}
      </div>
    </div>
  )
}

ContentContainer.propTypes = {
  header: PropTypes.string,
  useFrame: PropTypes.bool,
  link: PropTypes.object,
  hasToggler: PropTypes.object,
  showMore: PropTypes.bool,
  showMoreComponent: PropTypes.object
}

export default ContentContainer
