import React, { useState, useEffect } from "react";
import MainContainer from "components/common/MainContainer";
import PageHeader from "components/common/PageHeader";
import ContentContainer from "components/common/ContentContainer";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import BaseButton from "components/common/BaseButton";
import BaseInput from "components/common/BaseInput";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import httpService from "core/http-service";
import { isUserAdmin } from "components/formcomponents/utils/userUtils";

const DataExport = ({ loading, connection, ...props }) => {
  const history = useHistory();
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [fromWhere, setFromWhere] = useState(0);
  const [size, setSize] = useState(100);
  const [numberOfCases, setNumberOfCases] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlreadyRequested, setIsAlreadyRequested] = useState(false);

  useEffect(() => {
    async function getUser() {
      try {
        await Auth.currentSession();
        Auth.currentAuthenticatedUser().then((user) => {
          const isAdmin = isUserAdmin(user.attributes["custom:administrator"])
          if (isAdmin) {
            setUserIsAdmin(true);
            httpService.get('totalcasecount')
              .then(res => setNumberOfCases(res.data.count))
              .catch(err => console.log(err));
          } else {
            setUserIsAdmin(false);
            history.push("/dashboard");
          }
        });
      } catch (e) {
        history.push("/dashboard");
      }
    }
    getUser();
  }, []);

  function getDataExport() {
    if (userIsAdmin) {
      setIsLoading(true);
      httpService
        .get("dataexport", { from: fromWhere, size: size })
        .then((response) => {
          var a = document.createElement("a");
          var file = new Blob([response.data], {
            type: "data:text/csv;charset=utf-8,",
          });
          a.href = URL.createObjectURL(file);
          a.download = "dataexport.csv";
          a.click();
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          alert(error);
          console.log(error);
        });
    }
  }

  async function getDataExportAll() {
    if (userIsAdmin) {
      let number = 0;
      let size = 40;
      let left = numberOfCases;
      let csvString = "";
      setIsLoading(true);
      try {
        while (left > 0) {
          console.log("left", left);
          if (number === 0) {
            let result = await httpService.get("dataexport", {
              from: number,
              size: size,
            });
            csvString += result.data;
            number = number + size;
            left = left - size;
            setProgress((number / numberOfCases) * 100);
          } else {
            let result = await httpService.get("dataexport", {
              from: number,
              size: size,
            });
            let splitted = result.data.split("\n");
            let finalString;
            if (splitted[0] !== undefined && splitted[0] !== "") {
              splitted.shift();
              finalString = splitted.join("\n");
            }
            number = number + size;
            left = left - size;
            csvString += finalString;
            setProgress((number / numberOfCases) * 100);
          }
        }
        setIsLoading(false);
        var a = document.createElement("a");
        var file = new Blob([csvString], {
          type: "data:text/csv;charset=utf-8,",
        });
        a.href = URL.createObjectURL(file);
        a.download = "dataexport.csv";
        a.click();
        setProgress(0);
      } catch (error) {
        setIsLoading(false);
        setProgress(0);
        console.log(error);
        alert(error);
      }
    }
  }

  async function requestDataExportAll() {
    if (userIsAdmin) {
      let envStringForBucketName = process.env.REACT_APP_STAGE;
      console.log(envStringForBucketName)
      if (!envStringForBucketName) {
        return;
      }
      if (envStringForBucketName === 'stage') {
        // s3 bucket contains 'staging' instead of 'stage' in its name
        envStringForBucketName = 'staging';
      }

      try {
        setIsAlreadyRequested(true);
        alert("Export request sent to the server.");
        // request will timeout after 30s but lambda will continue to execute
        await httpService.get("dataexport", {
          isFullExport: true,
          env: envStringForBucketName,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <MainContainer>
      <PageHeader header={"Data export"} />
      <ContentContainer useFrame={true}>
        <h3>Press the button to get a data export from the database</h3>
        {numberOfCases !== 0 && <h4>{`Total number of cases ${numberOfCases}`}</h4>}
        <BaseButton
          id="dataexport"
          text={"Get data export"}
          className={classnames("login__button", {
            "element--is-loading element--is-loading-after": isLoading,
          })}
          handleSubmit={getDataExport}
          disabled={isLoading}
          backgroundColor="green"
        ></BaseButton>
        <BaseInput
          type="number"
          id="from"
          labelText={"From"}
          labelTextDark={true}
          value={fromWhere}
          handleChange={(e) => {
            if (e.target.value !== undefined)
              setFromWhere(parseInt(e.target.value));
          }}
        />
        <BaseInput
          type="number"
          id="size"
          labelText={"Size"}
          labelTextDark={true}
          value={size}
          handleChange={(e) => {
            if (e.target.value !== null) setSize(parseInt(e.target.value));
          }}
        />
        <div
          id="myProgress"
          style={{ width: "100%", backgroundColor: "grey", marginTop: "30px" }}
        >
          <div
            id="myBar"
            style={{
              width: `${progress}%`,
              height: "30px",
              backgroundColor: "green",
            }}
          ></div>
        </div>
        <BaseButton
          text={"Get data export for all cases (Longer time)"}
          className={classnames("login__button", {
            "element--is-loading element--is-loading-after": isLoading,
          })}
          disabled={isLoading}
          handleSubmit={getDataExportAll}
          backgroundColor="green"
        ></BaseButton>
        <BaseButton
          text={"Manually request full data export upload to snowflake"}
          className={classnames("login__button")}
          style={{ marginTop: "50px" }}
          disabled={isAlreadyRequested}
          handleSubmit={requestDataExportAll}
          backgroundColor="green"
        ></BaseButton>
      </ContentContainer>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  connection: state.connection,
});

export default connect(mapStateToProps)(DataExport);
