import React, { Component, useState } from "react";
import getComponent from "../../_Case/ComponentsList";
import classnames from "classnames";
import SubHeaderComponent from "./../SubHeaderComponent";
import httpService from "core/http-service";
import AsyncCreatableSelect from "react-select/async-creatable";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import { connect, shallowEqual, useSelector } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";


class CustomerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      companyPath: "",
      addressValue: "",
      cityAndStateOrTerritoryValue: "",
      companyName: "",
      postcodeValue: "",
      countryValue: "",
      isLoading: true
    };
  }

  componentDidMount() {
    let values = "";
    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      values = this.props.parentSetValue;
      if (values === "") {
        values = undefined;
      }
    } else {
      values = this.props.getValue(this.props.myName);
    }
    if (values !== undefined) {
      for (let key of Object.keys(values)) {
        if (values[key] !== undefined) {
          this.setState({ [key]: values[key] });
        }
      }
    }

    if (values !== undefined) {
      if (values.companyName !== undefined && values.companyName !== "") {
        this.getCustomerData(values.companyName, "/lists/customer", "cdm", {
          lang: this.props.language
        }).then(response => {
          if (response.data !== undefined && response.data.length !== 0) {
            this.setState({
              companyName: values.companyName
            });
          }
        });
      } else if (values.companyPath !== undefined) {
        this.setState({
          isLoading: false
        });
      }
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  getCustomerData = (customerName, apiPath, fromWhere, params) => {
    // Callback function when specific customer is selected.
    if (fromWhere !== undefined && fromWhere === "cdm") {
      if (apiPath !== undefined && customerName !== undefined) {
        return httpService
          .get(apiPath, {
            q: customerName.value.CompanyName.toLowerCase(),
            lang: params.language
          })
          .then(response => {
            this.setState({
              isLoading: false
            });
            return response;
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            return error;
          });
      }
    } else {
      if (apiPath !== undefined && customerName !== undefined) {
        return httpService
          .get(apiPath, {
            q: customerName.toLowerCase()
          })
          .then(response => {
            this.setState({
              isLoading: false
            });
            return response;
          })
          .catch(error => {
            this.setState({
              isLoading: false
            });
            return error;
          });
      }
    }
  };

  saveToStore(objectToSave, name) {
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(objectToSave, name);
    } else {
      this.props.setValue(objectToSave, name);
    }
  }

  handleChange = (value, name) => {
    let parts = name.split(".");
    let resultName = parts[parts.length - 1];

    let objectToSave = {
      companyName: this.state.companyName,
      companyPath: this.state.companyPath,
      addressValue: this.state.addressValue,
      cityAndStateOrTerritoryValue: this.state.cityAndStateOrTerritoryValue,
      postcodeValue: this.state.postcodeValue,
      countryValue: this.state.countryValue,
      sectorSegment: this.state.sectorSegment
    };

    if (resultName === "companyName") {
      if (value !== undefined) {
        let saveObject = {
          companyPath: this.padWithSlash(value.Path),
          addressValue: value.AddressLine3,
          cityAndStateOrTerritoryValue: value.AddressLine4
            ? value.AddressLine4
            : "",
          customerId: value.Id,
          companyName: {
            value: value,
            label: `${value.AddressLine2} , ${value.AddressLine4}`
          },
          countryValue: value.Country,
          postcodeValue: value.PostalCode ? value.PostalCode : ""
        };
        this.setState(
          saveObject,
          this.saveToStore(saveObject, this.props.myName)
        );
      }
    } else if (resultName === "address") {
      this.setState({
        addressValue: value
      });
      objectToSave.addressValue = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "cityAndStateOrTerritory") {
      this.setState({
        cityAndStateOrTerritoryValue: value
      });
      objectToSave.cityAndStateOrTerritoryValue = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "postcode") {
      this.setState({
        postcodeValue: value
      });
      objectToSave.postcodeValue = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "sectorSegmentIndustry") {
      this.setState({
        companyPath: value.customPath,
        sectorSegment: value
      });
      objectToSave.sectorSegment = value;
      objectToSave.companyPath = value.customPath;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "country") {
      this.setState({
        countryValue: value
      });
      objectToSave.countryValue = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else {
      return null;
    }
  };

  padWithSlash = value => { 
    if (!value.startsWith("/")) { 
      value = "/" + value;
    }
    if (!value.endsWith("/")) { 
      value = value + "/";
    }
    return value;
  }
  filterOptions = (options, inputValue) => {
    return options.map(option => {
      return {
        label: `${option.AddressLine2} , ${option.AddressLine4}`,
        value: option
      };
    });
  };

  promiseOptions = inputValue => {
    return new Promise((resolve, reject) => {
      if (inputValue !== undefined && inputValue !== "") {
        if (inputValue.length > 2) {
          this.getCustomerData(inputValue, "/lists/customer", "promise", {
            lang: this.props.language
          })
            .then(response => {
              resolve(this.filterOptions(response.data, inputValue));
            })
            .catch(error => reject(error));
        }
      } else {
        resolve([]);
      }
    });
  };

  onCreateOption = value => {
    let saveObject = {
      companyPath: this.state.companyPath,
      addressValue: this.state.addressValue,
      cityAndStateOrTerritoryValue: this.state.cityAndStateOrTerritoryValue,
      customerId: this.state.customerId,
      companyName: { value: { AddressLine2: value, CompanyName: value }, label: value },
      countryValue: this.state.countryValue,
      postcodeValue: this.state.postcodeValue
    };
    this.setState(saveObject, this.saveToStore(saveObject, this.props.myName));
  };

  renderCustomer = formComponent => {
    if (formComponent.key === "companyName") {
      return (
        <div className={classnames("group", "componentContainer-linebreak", {"invalid": !this.props.isvalid})}>
          <SubHeaderComponent formComponentData={{description: `${this.props.formComponentData.description}`}}></SubHeaderComponent>
          <div className="input-shell">
            <label className="input-shell__label input-shell__label--dark input-shell__label--required">
              <FormattedMessage id="customComponents.companyName" />
          </label>
            <div className="input-shell__container">
              <AsyncCreatableSelect
                onChange={event => {
                  if (event !== null) {
                    this.handleChange(event.value, "asd.companyName");
                    this.setState({
                      companyName: event
                    });
                  } else {
                    //is cleared
                    this.setState({
                      companyName: ""
                    });
                  }
                }}
                placeholder={
                  this.props.connection
                    ? this.props.intl.formatMessage({
                      id: "customComponents.typeThreeChars"
                    })
                    : this.props.intl.formatMessage({
                      id: "customComponents.disabled"
                    })
                }
                className="select__element"
                value={this.state.companyName}
                isDisabled={!this.props.connection}
                styles={ReactSelectStyles}
                cacheOptions
                defaultOptions
                loadOptions={this.promiseOptions}
                onCreateOption={this.onCreateOption}
                isClearable={true}
              />
            </div>
            <div className="input-shell__message">
              <div className="input-shell__message-hint">
                {this.props.intl.formatMessage({ id: 'formComponents.required' })}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (formComponent.key === "address") {
      return getComponent(
        formComponent,
        `${this.props.myName}.${formComponent.key}`,
        this.handleChange,
        this.state.addressValue
      );
    } else if (formComponent.key === "cityAndStateOrTerritory") {
      return getComponent(
        formComponent,
        `${this.props.myName}.${formComponent.key}`,
        this.handleChange,
        this.state.cityAndStateOrTerritoryValue
      );
    } else if (formComponent.key === "postcode") {
      return getComponent(
        formComponent,
        `${this.props.myName}.${formComponent.key}`,
        this.handleChange,
        this.state.postcodeValue
      );
    } else if (formComponent.key === "country") {
      return getComponent(
        formComponent,
        `${this.props.myName}.${formComponent.key}`,
        this.handleChange,
        this.state.countryValue
      );
    } else if (formComponent.key === "sectorSegmentIndustry") {
      return getComponent(
        { ...formComponent, responsive: "skipcontainer" },
        `${this.props.myName}.${formComponent.key}`,
        this.handleChange,
        this.state.companyPath, undefined, undefined, undefined, undefined, undefined, !this.props.isvalid
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        
        {this.state.isLoading
          ? null
          : this.props.formComponentData.args.map(formComponent => {
              return (
                <React.Fragment key={formComponent.key}>
                  {this.renderCustomer(formComponent)}
                </React.Fragment>
              );
            })}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    connection: state.connection,
    language: state.language
  };
}
export default injectIntl(connect(mapStateToProps)(CustomerComponent));