import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
import classnames from "classnames";
import SvgEllipsis from "components/svg/Ellipsis";
import useOuterClick from "components/formcomponents/utils/useOuterClick";
import ReactModal from "react-modal";
import BaseButton from "components/common/BaseButton";
import { moveBearing, duplicateBearing, removeBearing } from "redux/thunks/bearingThunks";
import { callBackThunkAsPromise } from "redux/thunks/caseThunks";
import { saveAllAnswersAndValidate } from "redux/thunks/answerThunk";

const BearingInvestigationMenu = ({ bearingIndex }) => {
  const [numberOfBearings, setNumberOfBearings] = useState(0);
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const isSaving = useSelector(state => state.isSaving, shallowEqual);
  const [isModalOpened, setIsModelOpen] = useState(false);
  const innerRef = useOuterClick(() => {
    setIsOpen(false)
  });
  const dispatch = useDispatch();

  // if you start doing actions(move, delete) with unsaved new bearings, bugs start appearing
  useEffect(() => {
    if (isOpen && !isSaving) {
      dispatch(saveAllAnswersAndValidate(()=>{}));
    }
  }, [isOpen])
  
  const bearingCountAnswer = useSelector(state => state.saveComponentValue.components.find(a => a.name === "bearingInvestigations.count"), shallowEqual);
  useEffect(() => {
    if (bearingCountAnswer) { 
      const bearingCount = JSON.parse(bearingCountAnswer.value);
      setNumberOfBearings(bearingCount);
    }
  }, [bearingCountAnswer]);
  const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);

  const moveUpAction = () => {
    dispatch(moveBearing(currentCaseId, bearingIndex, bearingIndex - 1, numberOfBearings));
  }
  const moveDownAction = () => { 
    dispatch(moveBearing(currentCaseId, bearingIndex, bearingIndex + 1, numberOfBearings));
  }
  const duplicateAction = () => { 
    callBackThunkAsPromise(dispatch, saveAllAnswersAndValidate).then(() => { 
      dispatch(duplicateBearing(currentCaseId, bearingIndex, numberOfBearings));
    })
  }
  const removeAction = () => {
    setIsModelOpen(true);
  }
  const cancelDelete = () => { 
    setIsModelOpen(false);
  }
  const closeConfirmDelete = () => { 
    setIsModelOpen(false);
    dispatch(removeBearing(currentCaseId, bearingIndex, numberOfBearings - 1));
  }
  
  const renderModal = () => {
    return <ReactModal isOpen={isModalOpened} className="react-modal" overlayClassName="react-modal-overlay">
      <div className="react-modal__header"></div>
      <div className="react-modal__body">
        <div className="react-modal__text">
          <FormattedMessage id="customComponents.removeABearingInvestigationConfirmation" />
        </div>
        <div className="react-modal__actions-container">
          <BaseButton handleSubmit={() => closeConfirmDelete()} text={intl.formatMessage({ id: "customComponents.yes" })} backgroundColor="gray" className="react-modal__action-button" />
          <BaseButton handleSubmit={() => cancelDelete()} text={intl.formatMessage({ id: "customComponents.no" })} backgroundColor="green" className="react-modal__action-button" />
        </div>
      </div>
    </ReactModal>;
  }
  
  return (
    <>
      <div className="accordion-menu">
        <button type="button" className={classnames("button", "bearing-add-button", { "is-open": isOpen })} onClick={event => setIsOpen(isOpen ? false : true)} ref={innerRef}>
          <SvgEllipsis />
        </button>
        {isOpen && !isSaving && (
          <div className="accordion-menu-content navigation__container">
            <a className={classnames("navigation__action", { "disabled": bearingIndex === 0 })}
              onClick={event => bearingIndex !== 0 ? moveUpAction() : null}>
              {intl.formatMessage({ id: "customComponents.bearingMenu.moveUp" })}
            </a>
            <a className={classnames("navigation__action", { "disabled": bearingIndex === (numberOfBearings - 1) })}
              onClick={event => bearingIndex !== (numberOfBearings - 1) ? moveDownAction() : null}>
              {intl.formatMessage({ id: "customComponents.bearingMenu.moveDown" })}
            </a>
            <a className="navigation__action"
              onClick={event => duplicateAction(bearingIndex)}>
              {intl.formatMessage({ id: "customComponents.bearingMenu.duplicate" })}
            </a>
            <a className={classnames("navigation__action", { "disabled": numberOfBearings === 1 })}
              onClick={event => numberOfBearings !== 1 ? removeAction(bearingIndex) : null}>
              {intl.formatMessage({ id: "customComponents.bearingMenu.delete" })}
            </a>
          </div>
        )}
      </div>
      { renderModal() }
    </>
  );
}

export default BearingInvestigationMenu;