import React, { useEffect, useState } from "react";
import getComponent from '../../_Case/ComponentsList'
import httpService from "core/http-service";
import classnames from 'classnames'
import { removeComponentValue } from "../../../redux/actions/saveComponentValueAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { FormattedMessage, useIntl } from "react-intl";

const TERorAMFRorOtherComponent = ({ myName, getValue, setValue, formComponentData }) => { 
  const intl = useIntl();
  const dispatch = useDispatch();

  const connection = useSelector(state => state.connection, shallowEqual);

  const [GBIReportType, setGBIReportType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let values = getValue(myName);
    if (values !== undefined) {
      if (values.GBIReportType !== undefined) {
        setGBIReportType(values.GBIReportType);
      }
      if (values.searchText !== undefined) {
        setSearchText(values.searchText);
      }
      if (values.searchData !== undefined) {
        setSearchData(values.searchData);
      }
    }
  }, [myName, getValue]);

  const handleChange = (value, name) => { 
    var resultName = name.split(".").pop();

    let objectToSave = { GBIReportType, searchText: searchText, searchData: searchData };

    if (resultName === "gbiTypeSelect") {
      setGBIReportType(value);
      objectToSave = { ...objectToSave, GBIReportType: value };
      if (value.value === "Other") {
        objectToSave = { ...objectToSave, searchText: "" };
        setSearchText("");
      } 
      removeAnswersForOther(GBIReportType, value);
      setValue(objectToSave, myName)
    } else if (resultName === "searchText") {
      setSearchText(value);
      objectToSave = { ...objectToSave, searchText: value };
      setValue(objectToSave, myName)
    } else if (resultName === "searchData") {
      setSearchData(value);
      objectToSave = { ...objectToSave, searchData: value };
      setValue(objectToSave, myName)
    } 
  }

  const removeAnswersForOther = (initial, value) => {
    if ( initial !== "" && initial !== undefined) {
      if ( value === 'TER') {
        //Clear AMFR
        dispatch(removeComponentValue(`bearingInvestigations.bearingInvestigation[${0}].amfrBearingInvestigation`));
        dispatch(removeComponentValue("investigationDetails.amfrInvestigationDetails"));
        dispatch(removeComponentValue("investigationDetails.OtherInvestigationDetails"));
        dispatch(removeComponentValue(`bearingInvestigations.bearingInvestigation[${0}].otherBearingInvestigation`));
        setValue( 1, "bearingInvestigations.count")
      } else if (value === 'AMFR') {
        //Clear answers for TER
        dispatch(removeComponentValue("bearingInvestigations.bearingInvestigation[0].terBearingInvestigation"));
        dispatch(removeComponentValue("investigationDetails.terInvestigationDetails"));
        //Clear answers for Other
        dispatch(removeComponentValue("investigationDetails.OtherInvestigationDetails"));
        dispatch(removeComponentValue(`bearingInvestigations.bearingInvestigation[${0}].otherBearingInvestigation`))
        
      } else if (value === "Other"){
        dispatch(removeComponentValue(`bearingInvestigations.bearingInvestigation[${0}].amfrBearingInvestigation`));
        dispatch(removeComponentValue("investigationDetails.amfrInvestigationDetails"));
        dispatch(removeComponentValue("bearingInvestigations.bearingInvestigation[0].terBearingInvestigation"));
        dispatch(removeComponentValue("investigationDetails.terInvestigationDetails"));
        
      }
    }
  }

  const searchAMFRorTERData = async () => { 
    setIsLoading(true);
    if (searchText) { 
      try {
        const response = await httpService.get(`lists/amfrter/${searchText}`);  
        const responseData = response.data;
        if (responseData) { 
          if ('TER' in responseData) {
            const data = responseData["TER"]
            const investingationDetailsObject = {
              localReferenceNumber: data.LocalRefNo,
              customerReference: data.CustomerRef,
              customerName: data.CustomerName,
              customerIDReference: data.CustNumber,
              customerRequestedDate: data.CustReqDate,
              X1Coordinator: data.CreatedBy,
              productLine: data.ProductLine,
              GBICenter: data.GbiCenter,
              GBIDistributionList: data.DistributionList.join(", "),
              quantity: data.SamplesQuantity
            }
            const bearingInvestigationsObject = {
              "productServiceDesignation": data.ProductDesignation,
              "application" : data.Application,
              "applicationDetails" : "",
              "typeOfReturn": data.TypeOfReturn ? data.TypeOfReturn : "" ,
              "customerReferencePartNumber": "",
              "problemDescription": data.ProblemDescription,
              "problemDetail": "",
            }
            setValue(investingationDetailsObject, "investigationDetails.terInvestigationDetails")
            setValue(bearingInvestigationsObject, "bearingInvestigations.bearingInvestigation[0].terBearingInvestigation")
          } else if ( 'AMFR' in responseData) {
            const data = responseData["AMFR"]
            const saveObject = {
                localReferenceNumber: data.LocalRefNo ? data.LocalRefNo : "",
                customerReference: data.CustomerRef,
                customerName: data.CustomerName,
                typeOfReturn : data.Type,
                customerRequestedDate: data.RequestDate,
                X1Coordinator: data.CreatedBy,
                productLine: data.ProductLine,
                GBICenter: data.GbiCenter,
                GBIDistributionList: data.DistributionList.join(", "),
            } 
            const numberOfProductFailures = data.Productfailures.length
  
            for (let index = 0; index < data.Productfailures.length; index++) {
              const productFailure = data.Productfailures[index];
              let bearingInvestigationsObject = {
                productDesignation : productFailure.ProductDesignation ,
                quantity: productFailure.SamplesReturned ,
                skfBearingDesignation: "" ,
                customerIdRefrenceNumber: productFailure.CustRefNumber,
                competitorBearing: "" ,
                application: productFailure.Application ,
                vehicleNumber: productFailure.VIN  ,
                //carSalesDate: this.fixDateFormatting(productFailure.CarSalesDate),
                //carRepairDate: this.fixDateFormatting(productFailure.CarRepairDate),
                carSalesDate: productFailure.CarSalesDate,
                carRepairDate: productFailure.CarRepairDate,
                carType: productFailure.CarType  ,
                duration: productFailure.Mileage  ,
                problemDetails: productFailure.ProblemDetails ,
                problemDescription: productFailure.ProblemDescription ,
              }
              setValue(bearingInvestigationsObject, `bearingInvestigations.bearingInvestigation[${index}].amfrBearingInvestigation`)
            }
            setValue(numberOfProductFailures, "bearingInvestigations.count")
            setValue(saveObject, "investigationDetails.amfrInvestigationDetails")
          } else {
            alert("Could not find AMFR or TER data with key ", searchText)
          }
          setIsLoading(false);
        } else { 
          setSearchData(undefined);
          setIsLoading(false);
        }
      } catch (error) {
        if ( error !== undefined && error.hasOwnProperty("response")&& error.response.hasOwnProperty("data")) {
          alert(error.response.data.error)
        }
        setSearchData(undefined);
        setIsLoading(false);
      }
    }
  }
    
  const renderGBIReportType = (formComponent) => {
    if ( formComponent !== undefined ) {
      return <React.Fragment key={myName}>{
        getComponent(
          formComponent,
          `${myName}.${formComponent.key}`,
          handleChange,
          GBIReportType)}
      </React.Fragment>;
    }
  }

  const renderTEROrAMFR = () => {
    if (GBIReportType.value === 'TER') {
      return renderTER()
    }  else if (GBIReportType.value === 'AMFR') {
      return renderAMFR()
    } else {
      return null
    }
  }

  const renderAMFR = () => {
    return (
    <>
      <React.Fragment key={myName + "searchAmfr"}>{getComponent(
        {
          "description" : "Search with AMFR number",
          "key" : "searchAmfr",
          "type": "Text",
          "placeholder": intl.formatMessage({ id: "placeholder.searchAmfr" })
        },
        `${myName}.searchText`,
        handleChange,
        searchText
      )}</React.Fragment>
        <button key={myName + "searchButton"}
          type="button"
          disabled={ !connection }
          className={classnames('button button--background-green', { 'element--is-loading element--is-loading-after': isLoading })}
          onClick={searchAMFRorTERData}>
          <FormattedMessage id="customComponents.searchAmfr" />
      </button>
    </>
    )
  }

  const renderTER = () => {
    return (
      <>
        <React.Fragment key={myName + "searchTer"}>{getComponent(
          {
            "description": "Search with TER number",
            "key": "searchTer",
            "type": "Text",
            "placeholder": intl.formatMessage({ id: "placeholder.searchTer" })
          },
          `${myName}.searchText`,
          handleChange,
          searchText
        )}</React.Fragment>
        <button key={myName + "searchButton2"}
          type="button"
          disabled={ !connection }
          className={classnames('button button--background-green', { 'element--is-loading element--is-loading-after': isLoading })}
          onClick={searchAMFRorTERData}>
          <FormattedMessage id="customComponents.searchTer" />
        </button>
      </>
    );
  }

 

  return <div className="search-template-container">
    {
      formComponentData.args.map(formComponent => {
        return renderGBIReportType(formComponent)
      })
    }
    {renderTEROrAMFR()}
  </div>;
}

export default TERorAMFRorOtherComponent;