import React, { useState } from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "redux/actions/activeTabActions";

import classnames from "classnames";

import ButtonApprove from "./ButtonApprove";
import ButtonApproveWithEdits from "./ButtonApproveWithEdits";
import ButtonClose from "./ButtonClose";
import ButtonDelete from "./ButtonDelete";
import ButtonDistribute from "./ButtonDistribute";
import ButtonDownloadImages from "./ButtonDownloadImages";
import ButtonDuplicate from "./ButtonDuplicate";
import ButtonEdit from "./ButtonEdit";
import ButtonExpandForm from "./ButtonExpandForm";
import ButtonExpandPreview from "./ButtonExpandPreview";
import ButtonPdf from "./ButtonPdf";
import ButtonReject from "./ButtonReject";
import ButtonReload from "./ButtonReload";
import ButtonRestore from "./ButtonRestore";
import ButtonSettings from "./ButtonSettings";
import ButtonSubmit from "./ButtonSubmit";
import ButtonConvert from "./ButtonConvert";

const BUTTON_SIZE = "24px";

const ButtonBar = ({ formConfig }) => {
    const dispatch = useDispatch();

    const [caseIsApproving, setCaseIsApproving] = useState(false)

    const connection = useSelector(state => state.connection, shallowEqual);
    const currentCaseStatus = useSelector(state => state.currentCase.case.status, shallowEqual);
    const currentCaseAccess = useSelector(state => state.currentCaseAccess, shallowEqual);
    const currentFormTemplateID = useSelector(state => state.currentCase.case.formtemplateId, shallowEqual);
    const activeTab = useSelector(state => state.activeTab, shallowEqual);

    const setCaseStatus = (status) => { 
        dispatch(setActiveTab(getActiveTabForCaseStatus(status)));
    }

    const getActiveTabForCaseStatus = caseStatus => {
        if (caseStatus == "approved" || caseStatus == "rejected" || caseStatus == "submitted") {
            return 1;
        } else {
            return activeTab;
        }
    }

    const getIsApproving = currentState => setCaseIsApproving(currentState)

    return (
        <div className={classnames("button-bar__container", { "noconnection": !connection })}>
            <div className="button-bar">
                {currentCaseStatus === "deleted" && (
                    <div className="button-bar__group">
                        <ButtonRestore />
                    </div>
                )}
                {currentCaseStatus !== "deleted" && (
                    <div className="button-bar__group">
                        <ButtonReload buttonSize={BUTTON_SIZE} />
                        <ButtonSubmit buttonSize={BUTTON_SIZE} currentFormTemplateID={currentFormTemplateID} setCaseStatus={setCaseStatus} userCanEdit={currentCaseAccess.edit} />
                        <ButtonConvert buttonSize={BUTTON_SIZE} />
                        <ButtonApprove setApprovingState={getIsApproving} buttonSize={BUTTON_SIZE} currentFormTemplateID={currentFormTemplateID} setCaseStatus={setCaseStatus} />
                        <ButtonEdit buttonSize={BUTTON_SIZE} currentFormTemplateID={currentFormTemplateID} setCaseStatus={setCaseStatus} userCanEdit={currentCaseAccess.edit} />
                        <ButtonApproveWithEdits buttonSize={BUTTON_SIZE} setCaseStatus={setCaseStatus} />
                        <ButtonReject approvingState={caseIsApproving} buttonSize={BUTTON_SIZE} currentFormTemplateID={currentFormTemplateID} setCaseStatus={setCaseStatus} />
                    </div>
                )}
                {currentCaseStatus !== "deleted" && (
                    <div className="button-bar__group">
                        <ButtonDuplicate buttonSize={BUTTON_SIZE} />
                        {/* <ButtonHistory buttonSize={ buttonSize } /> */}
                        <ButtonPdf approvingState={caseIsApproving} buttonSize={BUTTON_SIZE} currentFormTemplateID={currentFormTemplateID} />
                        <ButtonDistribute buttonSize={BUTTON_SIZE} currentFormTemplateID={currentFormTemplateID} />
                        <ButtonDownloadImages buttonSize={BUTTON_SIZE} />
                        <ButtonDelete buttonSize={BUTTON_SIZE} userCanEdit={currentCaseAccess.edit} />
                    </div>
                )}
                <div className="button-bar__group button-bar__right">
                    {currentCaseStatus !== "deleted" && (
                        <ButtonSettings buttonSize={BUTTON_SIZE} formConfig={formConfig} />
                    )}
                    <ButtonClose buttonSize={BUTTON_SIZE} />
                    <ButtonExpandForm buttonSize={BUTTON_SIZE} />
                    <ButtonExpandPreview buttonSize={BUTTON_SIZE} />
                </div>
            </div>
        </div>
    );
}

export default ButtonBar;