import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import ReactModal from "react-modal"

import { setCurrentCaseStatus } from "redux/actions/currentCaseActions";
import SvgButtonBarEdit from "./../../svg/ButtonBarEdit"
import BaseButton from "components/common/BaseButton";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import generatePdf from "./../ButtonActions/generatePdf";
import { setActiveTab } from "redux/actions/activeTabActions";
import { editCase } from "redux/thunks/caseThunks";
import compareUserEmail from "components/formcomponents/utils/userUtils";

const ButtonEdit = ({ buttonSize, userCanEdit, setCaseStatus, currentFormTemplateID }) => { 

    const excludeISOAppendix = useSelector(state => state.excludeISOAppendix, shallowEqual);
    const currentCaseStatus = useSelector(state => state.currentCase.case.status, shallowEqual);
    const currentCaseApprover = useSelector(state => state.currentCase.case.approver, shallowEqual);
    const currentCaseRevision = useSelector(state => state.currentCase.case.revision_number, shallowEqual);
    const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);
    const currentUser = useSelector(state => state.currentUser);
    const connection = useSelector(state => state.connection, shallowEqual);
    const language = useSelector(state => state.language, shallowEqual);
    const answers = useSelector(state => state.saveComponentValue.components, shallowEqual);

    const intl = useIntl();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmEditApprovedOpen, setIsConfirmEditApprovedOpen] = useState(false);

    const [isApprover, setIsApprover] = useState(false);
    useEffect(() => { 
        if (currentCaseApprover && currentUser) { 
            setIsApprover(compareUserEmail(currentCaseApprover, currentUser.email))
        }
    }, [currentCaseApprover, currentUser]);

    const onEditCase = () => {
        if (currentCaseStatus === "approved") {
            setIsConfirmEditApprovedOpen(true);
        } else if (currentCaseStatus=== "rejected") {
            setToDraft();
        }
    }

    const setToDraft = () => { 
        setIsLoading(true);
        dispatch(editCase(currentCaseId, (response) => { 
            dispatch(setCurrentCaseStatus(response.data));
            setIsLoading(false);
            setCaseStatus(response.data);
            dispatch(setActiveTab(0));
        }));
    }

    const onEditContinue = () => {
        setIsLoading(true);
        generatePdf(
            currentCaseId,
            currentCaseRevision,
            currentCaseStatus,
            currentFormTemplateID,
            excludeISOAppendix,
            language,
            answers,
            true,
            document.querySelector(".live-preview").outerHTML).then(() => { 
                
            });
            setToDraft();
            setIsConfirmEditApprovedOpen(false);
    }
    
    const closeConfirmEditApproved = () => {
        setIsConfirmEditApprovedOpen(false);
     }

    const isVisible = () =>
        (currentCaseStatus === "rejected" && userCanEdit) ||
        (currentCaseStatus === "approved" && (userCanEdit || isApprover));

    const isDisabled = () => !userCanEdit || !connection;

    return isVisible() && <>
        <button
            id="buttonEdit"
            type="button"
            onClick={() => onEditCase()}
            className={classnames("button", "button--icon-only", { "button--icon-saving": isLoading }, { "button--icon-disabled": isDisabled() })}
            disabled={isDisabled()}>
            <SvgButtonBarEdit size={buttonSize} title={intl.formatMessage({ id: "formRenderer.edit" })} />
        </button>
        <ReactModal isOpen={isConfirmEditApprovedOpen} className="react-modal react-modal-large" overlayClassName="react-modal-overlay" onRequestClose={closeConfirmEditApproved}>
            <div className="react-modal__header">
                <button className="react-modal__close-button" onClick={() => { closeConfirmEditApproved() }}>
                    <CircleCloseFilterSvg />
                </button>
            </div>

            <div className="react-modal__body">
                <div className="react-modal__text">
                    <FormattedMessage id="formRenderer.edit.areYouSureMessage" />
                </div>

                <div className="react-modal__actions-container">
                    <BaseButton handleSubmit={onEditContinue} text={intl.formatMessage({ id: "formRenderer.edit.continue", })} backgroundColor="gray" className="react-modal__action-button react-modal__action-button--wide" />
                    <BaseButton handleSubmit={closeConfirmEditApproved} text={intl.formatMessage({id: "formRenderer.edit.cancel"})} backgroundColor="green" className="react-modal__action-button" />
                </div>
            </div>
        </ReactModal>
    </>;
}

export default ButtonEdit;