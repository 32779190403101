import React, { useState, useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import classnames from "classnames";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import useOuterClick from "./../../formcomponents/utils/useOuterClick";

import getComponent from "./../ComponentsList";
import SvgButtonBarSettings from "../../svg/ButtonBarSettings"
import CheckboxComponent from "../../formcomponents/customformcomponents/CheckboxComponent";

const ButtonSettings = ({intl, formConfig, buttonSize}) => {
    
    const currentCaseStatus = useSelector(state => state.currentCase.case.status, shallowEqual);


    const [isOpen, setIsOpen] = useState(false);
    const [components, setComponents] = useState([]);

    const parentName = "reportDetails";

    useEffect(() => {
        if (formConfig) {
            const reportDetails = formConfig.find(c => c.key === "reportDetails");
            if (reportDetails) {
                const components = reportDetails.components.filter(c => c.type === "Setting").map(c => ({...c, type: "CheckBox"}));
                setComponents(components);
            }
        }
    }, [formConfig]);

   

    const innerRef = useOuterClick(() => {
        setIsOpen(false)
    });

    const openMenu = () => {
        setIsOpen(!isOpen);
    }

    const calculateName = formComponent => {
        return `${parentName}.${formComponent.key}`;    
    };

    const getFormComponent = (formComponent) => {
        return getComponent(
            formComponent,
            calculateName(formComponent),
            undefined,
            undefined,
            undefined,
            false,
            false,
            undefined,
            undefined
        );
      }

    const isVisible = () => currentCaseStatus !== "rejected" && currentCaseStatus !== "approved" && currentCaseStatus !== "submitted";
    
    return components.length > 0 && isVisible() && <div ref={innerRef}>
        <button
            id="buttonSettings"
            type="button"
            onClick={() => openMenu()}
            className={classnames("button", "button--icon-only")} >
            <SvgButtonBarSettings size={ buttonSize } title={intl.formatMessage({ id: "formRenderer.settings" })} />
        </button>
        {isOpen && (
            <div className="settings-menu">
                { components
                    .map(c => getFormComponent(c))
                    .map((item, index) => <div key={index} className="settings-menu__action">{item}</div>) }
            </div>
        )}
    </div>;
    
}

function mapStateToProps(state) {
    return {
        currentCase: state.currentCase
    };
}
  
export default injectIntl(connect(mapStateToProps)(ButtonSettings));