import { SET_PAGE_BREAK_EACH_BEARING } from "redux/types";
import initialState from "redux/initialState";

export default function pageBreakEachBearingReducers(
    state = initialState.pageBreakEachBearing,
    action
) {
    switch (action.type) {
        case SET_PAGE_BREAK_EACH_BEARING:
            return action.payload;
        default:
            return state;
    }
}
