import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import BaseButton from "components/common/BaseButton";
import ReactModal from "react-modal"; 

const ServiceWorkerUpdateModal = ({ serviceWorkerInitialized, serviceWorkerUpdated, serviceWorkerRegistration, ...props }) => {

    const updateServiceWorker = () => {
        const registrationWaiting = serviceWorkerRegistration?.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: "SKIP_WAITING" });

            registrationWaiting.addEventListener("statechange", (e) => {
                if (e.target.state === "activated") {
                    window.location.reload();
                }
            });
        }
        window.location.reload()
    };


    return (serviceWorkerUpdated && serviceWorkerRegistration) || serviceWorkerInitialized ? (
        <ReactModal
            isOpen={true}
            className="react-modal update-modal-container"
            overlayClassName="react-modal-overlay">
            <div className="react-modal__header ai-modal__header">
            </div>
            <div className="react-modal__body update-modal__body">
                <h2>{props.intl.formatMessage({ id: "applicationUpdate.description" })}</h2>
                <BaseButton
                    handleSubmit={updateServiceWorker}
                    text={props.intl.formatMessage({ id: "applicationUpdate.button" })} />
            </div>
        </ReactModal>
    ) : null;

}

function mapStateToProps(state) {
    return {
        serviceWorkerInitialized: state.swReducers.serviceWorkerInitialized,
        serviceWorkerUpdated: state.swReducers.serviceWorkerUpdated,
        serviceWorkerRegistration: state.swReducers.serviceWorkerRegistration
    };
  }
  
  export default injectIntl(connect(mapStateToProps)(ServiceWorkerUpdateModal));