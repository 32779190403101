import React, { Component } from "react";
import getComponent from "../../_Case/ComponentsList";
import { injectIntl } from 'react-intl';

class LubricationTypeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      greaseOrOil: "",
      greaseType: "",
      oilType: "",
      greaseTypeGrams: "",
      oilBathBelowShaft: "",
      circulatingOil: "",
      lubricationBrand: ""
    };
  }

  componentDidMount() {
    let values;
    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      values = this.props.parentSetValue;
    } else {
      values = this.props.getValue(this.props.myName);
    }
    if (values !== undefined) {
      this.setState({
        greaseOrOil: values.greaseOrOil,
        greaseType: values.greaseType ,
        oilType: values.oilType,
        greaseTypeGrams: values.greaseTypeGrams,
        oilBathBelowShaft: values.oilBathBelowShaft,
        circulatingOil: values.circulatingOil,
        lubricationBrand: values.lubricationBrand ? values.lubricationBrand : ""
      });
    }
  }

  saveToStore(objectToSave, name) {
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(objectToSave, name);
    } else {
      this.props.setValue(objectToSave, name);
    }
  }

  handleChange = (value, name) => {
    var parts = name.split(".");
    var resultName = parts[parts.length - 1];
    let objectToSave = {
      greaseOrOil: this.state.greaseOrOil,
      greaseType: this.state.greaseType,
      oilType: this.state.oilType,
      greaseTypeGrams: this.state.greaseTypeGrams,
      oilBathBelowShaft: this.state.oilBathBelowShaft,
      circulatingOil: this.state.circulatingOil,
      lubricationBrand: this.state.lubricationBrand
    };
    if (resultName === "greaseOrOil") {
      if (value.value === "grease") {
        this.setState({
          oilType: "",
          circulatingOil: "",
        });
        objectToSave.oilType = "";
        objectToSave.circulatingOil = "";
      } else {
        this.setState({
          greaseType: "",
          greaseTypeGrams: ""
        });
        objectToSave.greaseType = "";
        objectToSave.greaseTypeGrams = "";
      }

      this.setState({
        greaseOrOil: value
      });
      objectToSave.greaseOrOil = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "greaseType") {
      this.setState({
        greaseType: value
      });
      objectToSave.greaseType = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "oilType") {
      this.setState({
        oilType: value
      });
      objectToSave.oilType = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "greaseTypeGrams") {
      this.setState({
        greaseTypeGrams: value
      });
      objectToSave.greaseTypeGrams = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "oilBathBelowShaft") {
      this.setState({
        oilBathBelowShaft: value
      });
      objectToSave.oilBathBelowShaft = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "circulatingOil") {
      this.setState({
        circulatingOil: value
      });
      objectToSave.circulatingOil = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "lubricationBrand") {
      this.setState({
        lubricationBrand: value
      });
      objectToSave.lubricationBrand = value;
      this.saveToStore(objectToSave, this.props.myName);
    }
  };

  renderGrease = () => {
    return (
      <React.Fragment>
        {getComponent(
          {
            key: "greaseType",
            type: "AutoComplete",
            description: "Grease method",
            args: [
              {
                options: [
                  {
                    label: "factoryFillSealedOrShieldedBearing",
                    value: "factoryFillSealedOrShieldedBearing"
                  },
                  { label: "manualGun", value: "manualGun" },
                  { label: "automaticGun", value: "automaticGun" },
                  {
                    label: "singlePointLubricator",
                    value: "singlePointLubricator"
                  },
                  {
                    label: "multiPointLubricationSystems",
                    value: "multiPointLubricationSystems"
                  },
                  {
                    label: "automaticLubrication",
                    value: "automaticLubrication"
                  }
                ]
              }
            ]
          },
          `${this.props.myName}.greaseType`,
          this.handleChange,
          this.state.greaseType
        )}
        {getComponent(
          {
            key: "greaseTypeGrams",
            type: "ValueUnit",
            description: "Quantity (grams)",
            args: [
              {
                options: [
                  { label: "perHour", value: "perHour" },
                  { label: "perDay", value: "perDay" },
                  { label: "perWeek", value: "perWeek" },
                  { label: "perMonth", value: "perMonth" }
                ]
              }
            ]
          },
          `${this.props.myName}.greaseTypeGrams`,
          this.handleChange,
          this.state.greaseTypeGrams
        )}
      </React.Fragment>
    );
  };

  renderOilOptions = () => {
    if (this.state.oilType.value === "oilBath") {
      return (
        <React.Fragment key={this.state.oilType}>
          {getComponent(
            {
              key: "oilBathBelowShaft",
              type: "ValueUnit",
              description: "Oil bath level below shaft centerline",
              args: [
                {
                  options: [
                    { label: "inch", value: "inch" },
                    { label: "milliMeter", value: "milliMeter" }
                  ]
                }
              ]
            },
            `${this.props.myName}.oilBathBelowShaft`,
            this.handleChange,
            this.state.oilBathBelowShaft
          )}
        </React.Fragment>
      );
    } else if (this.state.oilType.value === "circulatingOil") {
      return (
        <React.Fragment key={this.state.oilType}>
          {getComponent(
            {
              key: "circulatingOilFlowRate",
              type: "ValueUnit",
              description: "Oil flow rate",
              args: [
                {
                  options: [{ label: "lmin", value: "lmin" }, { label: "galmin", value: "galmin" }]
                }
              ]
            },
            `${this.props.myName}.circulatingOil`,
            this.handleChange,
            this.state.circulatingOil
          )}
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  renderOil = () => {
    return getComponent(
      {
        key: "oilType",
        type: "AutoComplete",
        description: "Oil lubrication method",
        args: [
          {
            options: [
              { label: "oilBath", value: "oilBath" },
              { label: "oilPickUpRing", value: "oilPickUpRing" },
              { label: "circulatingOil", value: "circulatingOil" },
              { label: "oilJet", value: "oilJet" },
              { label: "oilSpot", value: "oilSpot" },
              { label: "oilMist", value: "oilMist" },
              { label: "oilWick", value: "oilWick" }
            ]
          }
        ]
      },
      `${this.props.myName}.oilType`,
      this.handleChange,
      this.state.oilType
    );
  };

  renderOilOrGrease = () => {
    if (this.state.greaseOrOil !== undefined) {
      if (this.state.greaseOrOil.value === "grease") {
        return this.renderGrease();
      } else if (this.state.greaseOrOil.value === "oil") {
        return this.renderOil();
      } else {
        return null;
      }
    }
  };

  renderLubrication = (formComponent, index) => {
    if (formComponent !== undefined) {
      if (formComponent.key === "greaseOrOil") {
        return (
          <div key={index}>
            {getComponent(
              formComponent,
              `${this.props.myName}.${formComponent.key}`,
              this.handleChange,
              this.state.greaseOrOil
            )}
          </div>
        );
      } else if (formComponent.key === "lubricationBrand") {
        return (<div key={index}>
          {getComponent(
            formComponent,
            `${this.props.myName}.${formComponent.key}`,
            this.handleChange,
            this.state.lubricationBrand
          )}
        </div>);
      }
    }
  };

  render() {
    return (
      <div>
        {this.props.formComponentData.args.map((formComponent, index) => {
          return this.renderLubrication(formComponent, index);
        })}
        {this.renderOilOrGrease()}
        { this.state.oilType &&  this.renderOilOptions()}
      </div>
    );
  }
}

export default injectIntl(LubricationTypeComponent);
