import React from "react";

const SvgButtonBarSubmit = ({ title, size = "26px" }) => (  
    <svg width={ size } height={ size } viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{title}</title>
        <g id="Icons/icon_submit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icons/Actions/Send" fill="#F8F8F8">
                <path d="M2.29513041,18.4149686 L18.4380059,10.9216394 C19.1873314,10.5710157 19.1873314,9.42898428 18.4380059,9.07836059 L2.29513041,1.58503143 C1.68456893,1.29451466 1.00925093,1.78538783 1.00925093,2.49665303 L1,7.11486792 C1,7.61575891 1.34228447,8.04652515 1.80483104,8.10663207 L14.8763973,10 L1.80483104,11.8833501 C1.34228447,11.9534748 1,12.3842411 1,12.8851321 L1.00925093,17.503347 C1.00925093,18.2146122 1.68456893,18.7054853 2.29513041,18.4149686 L2.29513041,18.4149686 Z" id="Path"></path>
            </g>
        </g>
    </svg>
);

export default SvgButtonBarSubmit;
