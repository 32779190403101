import React from "react";
import FormComponent from "./FormComponent";
import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import BearingQuickLinks from "./BearingQuickLinks";
import ButtonBar from "./ButtonBar/ButtonBar";

const FormRenderer = () => {
  const currentCaseStatus = useSelector((state) => state.currentCase.case.status, shallowEqual);
  const currentCaseIsFormtemplateId = useSelector((state) =>  state.currentCase.case ? state.currentCase.case.formtemplateId : null, shallowEqual);
  const currentCaseAccess = useSelector((state) => state.currentCaseAccess, shallowEqual);
  const currentUser = useSelector((state) => state.currentUser, shallowEqual);
  const currentFormConfig = useSelector((state) => state.currentFormConfig, shallowEqual);
  const hasComponents = useSelector((state) => state.saveComponentValue.components.length > 0, shallowEqual);

  const isFormDisplayed = () => {
    return (currentCaseStatus === "draft" || currentCaseStatus=== "approvalEdited")
      && currentCaseAccess.edit;
  }

  return currentCaseAccess && currentCaseStatus && currentFormConfig && hasComponents ?
    <>
      <ButtonBar formConfig={currentFormConfig} />
      <div className="form-container">
        {isFormDisplayed() &&
          <>
            <FormComponent
              formConfig={ currentFormConfig }
              isDisabled={false}
              isUserExternal={currentUser.isExternal}
              user={ currentUser }
              isRoot={ true }
          />
           <BearingQuickLinks formTemplateId={currentCaseIsFormtemplateId} userCanEdit={currentCaseAccess.edit} />
          </>}
        {!isFormDisplayed() && currentCaseAccess.edit === false &&
          <h3>
            <FormattedMessage id="formRenderer.noPermission" />
          </h3>}
        {!isFormDisplayed() && currentCaseStatus === "deleted" &&
          <h3>
            <FormattedMessage id="formRenderer.reportDeleted" />
          </h3>}
      </div>
    </> :
    <div className="element--is-loading-after"></div>;
}

export default FormRenderer;
