import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import MainContainer from 'components/common/MainContainer'
import PageHeader from 'components/common/PageHeader'
import Filter from 'components/common/Filter'
import CasesTable from 'components/common/CasesTable'
import { FormattedMessage } from 'react-intl'
import ExportMessage from "../common/ExportMessage";

const MyReports = ({ loading }) => (
  <MainContainer
    filter={<Filter loading={loading} className={'page-layout__filter'} />}
    pageTitle={(
      <PageHeader
        className="page-layout__heading-title"
        header={<FormattedMessage id="pageName.myReports"/>}
      />
    )}
  >
    <CasesTable loading={loading} showOnlyMyCases={true} />
    <ExportMessage />
  </MainContainer>
)

MyReports.propTypes = {
  loading: PropTypes.bool
}

const mapStateToProps = state => ({
  loading: state.caseRelated.loading
})

export default connect(mapStateToProps)(MyReports)
