import React, { Component, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import httpService from "core/http-service";
import classnames from "classnames";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import ReactSelectSearchIndicator from "components/formcomponents/ReactSelectSearchIndicator";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";


class AutoCompleteCreatableMultipleComponent extends Component {
  state = {
    values: "",
    options: [],
    newOption: "",
    isLoading: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.parentSetValue != this.props.parentSetValue) {
      this.setState({ values: this.props.parentSetValue });
    }
  }

  componentDidMount() {
    let savedvalues;
    
    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      savedvalues = this.props.parentSetValue;
    } else {
      savedvalues = this.props.getValue(this.props.myName);
    }

    let hasApi = false;
    let valueLabelParams = {};
    let configOptions = [];

    if (this.props.formComponentData.args !== undefined) {
      this.props.formComponentData.args.forEach(arg => {
        if (arg.hasOwnProperty("valueLabelParams")) {
          valueLabelParams = arg.valueLabelParams;
        }
      });
    }
    if (this.props.formComponentData.args !== undefined) {
      // If a apiPath is defined in the config, go fetch data from the API.
      this.props.formComponentData.args.forEach(arg => {
        if (arg.hasOwnProperty("apiPath")) {
          hasApi = true;

          this.setState({
            isLoading: true
          })

          this.getOptionsFromAPI(arg.apiPath, {"lang": this.props.language}).then(response => {
            if (response.data !== undefined) {
              let result = response.data.map(option => {
                // Use named value if exist otherwise default to option (fails if struct)
                const value = this._ensureNamedPropertyOrDefault(option, valueLabelParams.value);
                // If the label is missing show the value instead
                const label = option[valueLabelParams.label] || value;

                return { value, label };
              });
              if ( result !== undefined ) {
                this.setState({
                  options: result,
                  isLoading: false
                })
              }

              if (savedvalues !== undefined) {
                this.setState({ values: savedvalues }, () => {
                  this.checkIfExistsInArrayThenCreate(savedvalues, result);
                });
              }
            } else {
              return null;
            }
          });
        }
        if (arg.hasOwnProperty("options")) {
          configOptions = arg.options;
        }
      });
      if (!hasApi) {
        if (savedvalues !== undefined) {
          this.setState({
            values: savedvalues
          });
        }
        if (Array.isArray(configOptions)) {
          this.setState({
            options: configOptions.map(item => {
              return {
                value: item.value,
                label: this.props.intl.formatMessage({
                  id: `${item.value}`
                })
              };
            })
          });
        }
      }
    } else {
      if (savedvalues !== undefined && savedvalues !== "") {
        this.setState({ options: savedvalues }, () =>
          this.checkIfExistsInArrayThenCreate(savedvalues, [])
        );
      } else {
        this.setState({ options: [] });
      }
    }
  }

  /**
   * Ensure that we have got a usable named value from the data.
   * If data is of primitive type return it as is.
   */
  _ensureNamedPropertyOrDefault(data, name) {
    const value = data[name];
    if (value !== undefined) return value;

    if (typeof data === 'object') {
      throw new Error(`Failed to extract property '${name}' from data!`);
    }
    return data;
  }

  checkIfExistsInArrayThenCreate(values, options) {
    let isFound = false;
    if (Array.isArray(values) && Array.isArray(options)) {
      values.forEach(value => {
        isFound = false;
        options.forEach(option => {
          if (value.value === option.value) {
            isFound = true;
          }
        });
        if (!isFound) {
          this.handleCreateNewOption(value.value, options, values);
        }
        isFound = false;
      });
    }
  }

  getOptionsFromAPI = (apiPath, params) => {
    if (apiPath !== undefined) {
      return httpService
        .get(apiPath, params)
        .then(response => {
          return response;
        })
        .catch(error => {
          return error;
        });
    }
  };

  handleChange = e => {
    if (e !== undefined) {
      if (this.props.parentHandleSave !== undefined) {
        this.props.parentHandleSave(e, this.props.myName);
      } else {
        this.props.setValue(e, this.props.myName);
      }
      this.setState({ values: e });
      if (Array.isArray(e) && e.length === 0) {
        this.setState({ newOption: "" });
      }
    }
  };

  handleCreateNewOption = (inputValue, options, values) => {
    // If the user creates their own option, it's added to the options-list.
    if (inputValue !== undefined && inputValue !== "") {
      const newOption = { label: inputValue, value: inputValue };
      let valuesWithNewAdded = values;
      this.setState({
        options: [...options, newOption],
        values: valuesWithNewAdded
      });

      this.handleChange(valuesWithNewAdded);
    }
  };

  render() {
    return (
      <div className="input-shell">
        {this.props.formComponentData.description && (
          <label
            htmlFor={this.props.formComponentData.description}
            className={classnames(
              "input-shell__label",
              "input-shell__label--dark",
              {
                "input-shell__label--required": this.props.formComponentData
                  .required
              }
            )}
          >
            {this.props.formComponentData.description}
          </label>
        )}

        <div
          className="input-shell__container"
          data-id={this.props.formComponentData.myName}
        >
          <CreatableSelect
            isMulti={true}
            isLoading={this.state.isLoading}
            value={this.state.values}
            options={this.state.options}
            className="select__element"
            placeholder={
              this.props.intl.formatMessage({
                id: "formComponents.selectOrFreeText"
              })
            }
            onChange={e => {
              if (e !== undefined && e !== null) {
                this.handleChange(e);
              } else {
                if (this.props.parentHandleSave !== undefined) {
                  this.props.parentHandleSave("", this.props.myName);
                } else {
                  this.props.setValue("", this.props.myName);
                }
                this.setState({
                  values: [],
                  newOption: ""
                });
              }
            }}
            onCreateOption={inputValue => {
              let inputObject = { label: inputValue, value: inputValue };
              let valuesWithInputAdded = [...this.state.values, inputObject];
              this.checkIfExistsInArrayThenCreate(
                valuesWithInputAdded,
                this.state.options
              );
            }}
            styles={ReactSelectStyles}
            components={{ DropdownIndicator: ReactSelectSearchIndicator }}
            // defaultMenuIsOpen={true}
          />
        </div>

        {this.props.formComponentData.required && (
          <div className="input-shell__message">
            <div className="input-shell__message-hint">
              <FormattedMessage id="formComponents.required" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default injectIntl(connect(mapStateToProps)(AutoCompleteCreatableMultipleComponent))

