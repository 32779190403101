export default function (value) {
    if (!value) {
        return false;
    }
    const parsedValue = JSON.parse(value);
    if (!parsedValue) return false

    if (isValid(parsedValue)) {
        console.log("WindFunctionalLocation is valid", parsedValue)
        return true;
    }
    console.log("WindFunctionalLocation is invalid", parsedValue)
    return false;
}

function isValid(value) {
    const functionalLocationValue = value?.functionalLocation?.label
    if (!functionalLocationValue) return false

    const assetTypeValue = value?.assetType?.label

    switch (functionalLocationValue) {
        case 'Rotor':
        case 'Tower':
            return functionalLocationValue && assetTypeValue
        case 'Nacelle':
            const positionInAssetValue = value?.positionInAsset?.label
            return functionalLocationValue && assetTypeValue && positionInAssetValue
    }
}