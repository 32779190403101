import React from "react";

const SvgButtonBarEdit = ({ title, size = "26px", fill = "#F8F8F8", className }) => (
    <svg width={size} height={size} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={ className }>
        <title>{title}</title>
        <g id="Icons/icon_edit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M1,15.4625 L1,18.5025 C1,18.7825 1.22,19.0025 1.5,19.0025 L4.54,19.0025 C4.67,19.0025 4.8,18.9525 4.89,18.8525 L15.81,7.9425 L12.06,4.1925 L1.15,15.1025 C1.05,15.2025 1,15.3225 1,15.4625 Z M18.71,5.0425 C19.1,4.6525 19.1,4.0225 18.71,3.6325 L16.37,1.2925 C15.98,0.9025 15.35,0.9025 14.96,1.2925 L13.13,3.1225 L16.88,6.8725 L18.71,5.0425 Z" id="Shape" fill={ fill }></path>
        </g>
    </svg>
);

export default SvgButtonBarEdit;
