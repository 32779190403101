import { createPdfRequestUrl, addHtmlWrapper } from "./generatePdf";
import { isIOS, isIPad13, isMobileSafari } from "react-device-detect";
import config from "config";
import repository from "core/repository";

export default function distributeCase(currentCase, answers, currentFormTemplateID, language, previewHtml, intl) {
    const mailto = {
        to: "",
        cc: "",
        subject: "",
        body: ""
    };

    mailto.to = getRecipients(answers, currentFormTemplateID);
    mailto.cc = getCcRecipients(answers, currentFormTemplateID);
    mailto.subject = getSubject(currentCase.id, answers, intl);

    const requestUrl = createPdfRequestUrl(currentCase.id, currentCase.revision_number, currentCase.status, currentFormTemplateID, isExcludeISOAppendix(answers), language, answers);

    return new Promise((resolve, reject) => {
        repository.postPublishPdf(addHtmlWrapper(previewHtml), requestUrl).then(response => {
            getPdfUrl(response.data.filename).then(url => {
                generateEmailAndResolve(mailto, url, currentCase.id, currentCase.approver, intl, resolve);
            }).catch(err => reject(err));
        }).catch(err => reject(err));    
    });
}

function generateEmailAndResolve(mailto, url, currentCaseId, currentCaseApprover, intl, resolve) {
    mailto.body = getBody(url, currentCaseId, currentCaseApprover, intl);
    generateEmail(mailto);
    resolve();
}


function getPdfUrl(filename) {
    return new Promise(resolve => resolve(`https://${config.BUCKET_PDFS}.s3.eu-west-1.amazonaws.com/published/${encodeURIComponent(filename)}`));
}

function getRecipients(answers, currentFormTemplateID) {
    switch (currentFormTemplateID) {
        case config.US_INSPECTION_TEMPLATE_ID:
            return getRecipientsForUsInspection(answers, currentFormTemplateID);
        default:
            return getRecipientsDefault(answers, currentFormTemplateID);
    }
}

function getCcRecipients(answers, currentFormTemplateID) {
    switch (currentFormTemplateID) {
        case config.US_INSPECTION_TEMPLATE_ID:
            return getCcRecipientsForUsInspection(answers);
        default:
            return getCcRecipientsDefault(answers);
    }
}

function getSubject(currentCaseId, answers, intl) {
    const title = getReportTitle(answers);
    return (`${intl.formatMessage({ id: "distribute.subject.prenumber" })}${currentCaseId}${intl.formatMessage({ id: "distribute.subject.postnumber" })} ${title ? title : ""}`)
        .split("#")
        .join("")
        .split("/")
        .join("-");
}

function getBody(pdfUrl, currentCaseId, currentCaseApprover, intl) {
    let body = `${intl.formatMessage({ id: "distribute.body.preurl" })}\n${pdfUrl}\n${intl.formatMessage({ id: "distribute.body.posturl" })}`;
    body = body.replace("#number#", currentCaseId);
    body = body.replace("#approversName#", getApproverName(currentCaseApprover));
    return encodeURIComponent(body);
}

function isExcludeISOAppendix(answers) {
    const excludeAppendix = answers.find(a => a.name === "reportDetails.ExcludeISOAppendixInReportCheckBox");
    if (excludeAppendix) {
        return excludeAppendix.value === "true";
    }
}

function generateEmail(mailto) {
    
    const separator = getEmailSeparator();
    
    mailto.to = mailto.to.filter(m => m && m.length > 0);
    mailto.cc = mailto.cc.filter(m => m && m.length > 0);
    
    if (!mailto.to || mailto.to.length < 1) {
        mailto.to = mailto.cc;
        mailto.cc = [];
    }

    if (!mailto.cc) {
        mailto.cc = [];
    }

    if (isIOS || isIPad13 || isMobileSafari) {
        if (mailto.cc.length > 0) {
            window.location.href = `ms-outlook://compose?to=${getEmailString(mailto.to, separator)}&cc=${getEmailString(mailto.cc, separator)}&subject=${mailto.subject}&body=${mailto.body}`;    
        } else {
            window.location.href = `ms-outlook://compose?to=${getEmailString(mailto.to, separator)}&subject=${mailto.subject}&body=${mailto.body}`;
        }
    } else {
        if (mailto.cc.length > 0) {
            window.location.href = `mailto:${getEmailString(mailto.to, separator)}?cc=${getEmailString(mailto.cc, separator)}&subject=${mailto.subject}&body=${mailto.body}`;    
        } else {
            window.location.href = `mailto:${getEmailString(mailto.to, separator)}?subject=${mailto.subject}&body=${mailto.body}`;
        }
    }
}

function getEmailSeparator() {
    if (isIOS || isIPad13 || isMobileSafari) {
        if (navigator.languages.some(l => l.includes("US"))) {
            return ",";
        }
    }
    return ";";
}

function getRecipientsForUsInspection(answers, currentFormTemplateID) {
    let emails = [];
    emails.push(getAuthorEmail(answers, currentFormTemplateID));

    const parsedEndUser = answers.find(a => a.name === "skfDetails.endUser");
    if (parsedEndUser) {
        const { endUser, distributor, vendor } = JSON.parse(parsedEndUser.value);

        addValidEmail(endUser.email);
        addValidEmail(distributor?.email);
        addValidEmail(vendor?.email);
        addValidEmail(vendor?.value?.email);
        addValidEmail(endUser?.territoryManager?.email);
        addValidEmail(endUser?.industrySpecialist?.email);

        const recommendationAnswersPattern = /bearingInvestigations\.bearingInvestigation\[(\d+)\]\.recommendations\.recommendations/;
        const isPotentialForRemanufacturingSelected = answers.filter(answer => recommendationAnswersPattern.test(answer.name)).some(answer => {
            const parsedValue = JSON.parse(answer.value);
            return parsedValue.label === 'Potential for remanufacturing';
        });

        if (endUser.automatedRg === 1 && isPotentialForRemanufacturingSelected) {
            emails = [...emails, "skfbirmingham@skf.com"];
        }
    }

    function addValidEmail(email) {
        const rgx = new RegExp('^(?!(n\/a|none)$).*');

        if (email && rgx.test(email)) {
            emails.push(email);
        }
    }
    return emails;
}

function getCcRecipientsForUsInspection(answers) {
    let emails = [];
    emails = [...emails, ...getDistributionList(answers)];
    return emails;
}


function getRecipientsDefault(answers, currentFormTemplateID) {
    let emails = [];
    emails.push(getAuthorEmail(answers, currentFormTemplateID));
    emails = [...emails, ...getDistributionList(answers)];
    return emails;
}

function getCcRecipientsDefault(answers) {
    let emails = [];
    emails.push(getCoAuthorEmail(answers));
    emails = [...emails, ...getDelegationList(answers)];
    return emails;
}

function getAuthorEmail(answers, currentFormTemplateID) {
    let author;
    if (currentFormTemplateID === config.US_INSPECTION_TEMPLATE_ID) {
        author = answers.find(a => a.name === "reportDetails.inspectionMadeBy.email");
    } else {
        author = answers.find(a => a.name === "skfDetails.reportAuthor.email");    
    }
    if (author) {
        return author.value.split("\"").join("");
    }
}

function getCoAuthorEmail(answers) {
    const coAuthor = answers.find(a => a.name === "skfDetails.coReportAuthor.email");
    if (coAuthor) {
        return coAuthor.value.split("\"").join("");
    }
}

function getApproverName(currentCaseApprover) {
    const parts = currentCaseApprover.split("@");
    const nameparts = parts[0].split(".");
    return nameparts.map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ");
}

function getDistributionList(answers) {
    const distributors = answers.find(a => a.name === "skfDetails.distributionList");
    if (distributors) {
        const value = JSON.parse(distributors.value);
        if (value) {
            return value.map(d => d.value);    
        }
    }
    return [];
}

function getDelegationList(answers) {
    const delegations = answers.find(a => a.name === "skfDetails.delegationList");
    if (delegations) {
        const value = JSON.parse(delegations.value);
        if (value) {
            return value.map(d => d.value);    
        }
    }
    return [];
}

function getReportTitle(answers) {
    const title = answers.find(a => a.name === "reportDetails.reportTitle");
    if (title) {
        const value = JSON.parse(title.value);
        if (value) {
            return value;
        }
    }
}

function getEmailString(emailArray, separator) {
    emailArray = [...new Set(emailArray)];
    return emailArray.filter(e => e && e.length > 0).join(separator);
}