import localforage from "localforage";

export class OfflineTrackingRepository { 
    async addCase(caseId, caseData) { 
        await this.addEvent("addCase", { caseId, caseData: caseData.data });
    }
    async deleteCase(caseId, updatedAt) { 
        await this.addEvent("deleteCase", { caseId, caseData: {updatedAt} });
    }
    async updateCase(caseId, caseData) { 
        await this.addEvent("updateCase", { caseId, caseData: caseData.data });
    }
    async addAnswer(caseId, answerId, answer) { 
        await this.addEvent("addAnswer", { caseId, answerId, answer });
    }
    async updateAnswer(caseId, answerId, answer) { 
        await this.addEvent("updateAnswer", { caseId, answerId, answer });
    }
    async updateAnswers(caseId, answersToUpdate, answersToAdd) { 
        let events = await localforage.getItem(`offlineevents`);
        if (!events) { 
            events = [];
        }
        events = [
            ...events,
            ...answersToUpdate.map(a => ({
                type: "updateAnswer",
                payload: { caseId, answerId: a.id, answer: a },
                date: new Date()
            })),
            ...answersToAdd.map(a => ({
                type: "addAnswer",
                payload: { caseId, answerId: a.id, answer: a },
                date: new Date()
            }))
        ];
        await localforage.setItem(`offlineevents`, events);
    }
    async moveBearing(caseId, currentIndex, newIndex, count) { 
        await this.addEvent("moveBearing", { caseId, currentIndex, newIndex, count });
    }
    async removeBearing(caseId, bearingIndex, newCount) { 
        await this.addEvent("removeBearing", { caseId, bearingIndex, newCount });
    }
    async duplicateBearing(caseId, bearingIndex, newCount) { 
        await this.addEvent("duplicateBearing", { caseId, bearingIndex, newCount });
    }

    async addEvent(type, payload) { 
        let events = await localforage.getItem(`offlineevents`);
        if (!events) { 
            events = [];
        }
        const eventData = { type, payload, date: new Date() };
        events.push(eventData);
        await localforage.setItem(`offlineevents`, events);
    }

    async getEvents() { 
        let events = await localforage.getItem(`offlineevents`);
        if (!events) { 
            events = [];
        }
        return events;
    }

    async getEventsGroupedByCaseId() {
        const events = await this.getEvents();
        return events.reduce((groups, event) => {
            const group = groups.find(g => g.caseId === event.payload.caseId);
            if (group) {
                if (this.isAddCaseEvent(event)) {
                    group.addCaseEvent = event;
                } else { 
                    group.events.push(event);
                }
                return groups;
            } else {
                return [
                    ...groups, {
                        caseId: event.payload.caseId,
                        events: this.isAddCaseEvent(event) ? [] : [event],
                        addCaseEvent: this.isAddCaseEvent(event) ? event : null
                    }];
            }
        }, []);
    }

    normalizeEvents(eventGroups) { 
        return eventGroups.map(group => this.normalizeEventGroup(group));
    }

    normalizeEventGroup(eventGroup) { 
        return {
            ...eventGroup,
            events: eventGroup.events.reduce((events, event) => {
                if (this.isAnswerEvent(event)) {
                    return this.normalizeAnswer(events, event);
                } else if (this.isUpdateCaseEvent(event)) {
                    return this.normalizeUpdateCase(events, event);
                } else {
                    return [...events, event];
                }
            }, [])
        };
    }

    normalizeUpdateCase(events, event) { 
        const existingEvent = events.find(e => e.payload.caseId === event.payload.caseId && this.isUpdateCaseEvent(e));
        if (existingEvent) {
            existingEvent.payload.answer = event.payload.answer;
            return events;
        } else {
            return [...events, event];
        }
    }

    normalizeAnswer(events, event) { 
        const existingEvent = events.find(e => e.payload.answerId === event.payload.answerId && this.isAnswerEvent(e));
        if (existingEvent) {
            existingEvent.payload.answer = event.payload.answer;
            return events;
        } else {
            return [...events, event];
        }
    }

    isUpdateCaseEvent(event) { 
        return event.type === "updateCase";
    }
    
    isAddCaseEvent(event) { 
        return event.type === "addCase";
    }

    isAnswerEvent(event) { 
        return event.type === "addAnswer" || event.type === "updateAnswer";
    }

    async clearEvents() { 
        await localforage.setItem(`offlineevents`, []);
    }

    async getFailedCases() {
        return await localforage.getItem('failedcases') || []
    }

    async incrementSyncAttempts(caseId) {
        let failedCases = await this.getFailedCases()
        failedCases = failedCases
            .filter(failedCase => failedCase.caseId === caseId)
            .map(failedCase => ({ ...failedCase, syncAttempts: failedCase.syncAttempts + 1 }))
        await localforage.setItem('failedcases', failedCases)
    }

    async clearSyncedCaseFromEvents(caseId) {
        let allEvents = await localforage.getItem('offlineevents')
        const filteredEvents = allEvents.filter(e => e.payload.caseId !== caseId)
        await localforage.setItem('offlineevents', filteredEvents)

        // remove the case from failedcases as well
        const failedCases = await this.getFailedCases()
        const filteredFailedCases = failedCases.filter(failedCase => failedCase.caseId !== caseId)
        await localforage.setItem('failedcases', filteredFailedCases)

        // remove the case from localforage as well
        try {
            // remove uuids only
            if (typeof caseId === 'string' && !caseId.match(/^\d+$/)) {
                const keyToDelete = 'case/' + caseId
                await localforage.removeItem(keyToDelete)
            }
        } catch (e) {
            console.error('Failed to remove case with id', caseId)
        }
    }

    async resetSyncAttempts(caseId) {
        let allFailedCases = await localforage.getItem('failedcases') || []
        const foundCase = allFailedCases.find(failedCase => failedCase.caseId === caseId)
        if (foundCase) {
            allFailedCases = allFailedCases.filter(failedCase => failedCase.caseId !== caseId)
            await localforage.setItem('failedcases', [...allFailedCases, { ...foundCase, syncAttempts: 1 }])
        }
    }

    async getAttemptsToSync(caseId) {
        const cases = await localforage.getItem('failedcases')
        const foundCase = cases.find(failedCase => failedCase.caseId === caseId)
        return foundCase ? foundCase.syncAttempts : 1
    }

    async addFailedToSyncCase(caseId) {
        const currentFailedCases = await this.getFailedCases()

        const alreadyInFailedCases = currentFailedCases.some(c => c.caseId === caseId)
        if (!alreadyInFailedCases) {
            await localforage.setItem('failedcases', [...currentFailedCases, { caseId, syncAttempts: 1 }])
        } else {
            await this.incrementSyncAttempts(caseId)
        }
    }

    async isFailedToSyncCase(caseId) {
        const failedCases = await this.getFailedCases()
        return failedCases.some(failedCase => failedCase.caseId === caseId)
    }

    async removeOfflineImages() {
        const offlineEvents = await this.getEvents()
        if (offlineEvents.length > 0) {
            return
        }

        try {
            const allKeys = await localforage.keys()
            const imagesKeys = allKeys.filter(key => key.startsWith('offline-img-'))

            const promises = imagesKeys.map(key => localforage.removeItem(key))
            await Promise.all(promises)
        } catch (e) {
            console.log('There was an error while removing offline images:', e)
        }
    }
}

export class OfflineTrackingRepositoryEmpty { 
    async addCase(id, caseData) { 
        return await this.default();
    }
    async deleteCase(caseId, updatedAt) { 
        return await this.default();
    }
    async updateCase(id, caseData) { 
        return await this.default();
    }
    async addAnswer(caseId, id, answer) { 
        return await this.default();
    }
    async updateAnswer(caseId, id, answer) { 
        return await this.default();
    }
    async updateAnswers(caseId, answersToUpdate, answersToAdd) { 
        return await this.default();
    }
    async moveBearing(caseId, currentIndex, newIndex, count) { 
        return await this.default();
    }
    async removeBearing(caseId, bearingIndex, newCount) { 
        return await this.default();
    }
    async duplicateBearing(caseId, bearingIndex, newCount) { 
        return await this.default();
    }

    default() { 
        return new Promise(res => res());
    }
}