import React, { useState, useEffect } from "react";
import getComponent from "../../_Case/ComponentsList";

const usObservationOptions = [
    "surfaceGood", "noObservation", "abrasiveWear", "adhesiveWear", "brinelling", "chatterMarks",
    "crack", "discoloration", "etching", "falseBrinelling", "flutting", "forcedFracture", "frettingCorrosion",
    "indentation", "moistureCorrosion", "nick", "peeling", "pitting", "scratch", "spalling", "thermalCrack",
    "washboarding", "wear"];

const ObservationComponent = ({ formComponentData, myName, parentSetValue, parentHandleSave, setValue, getValue}) => {

    const [observations, setObservations] = useState("");
    const [imageUrls, setImageUrls] = useState([]);
    const [imageLayout, setImageLayout] = useState({});
    const [severity, setSeverity] = useState({});

    useEffect(() => {
        const currentData = getCurrentData();
        if (currentData) {
            setImageUrls(currentData.imageUrls);
            setObservations(currentData.observations);
            setImageLayout(currentData.imageLayout);
            setSeverity(currentData.severity)
        }
    }, [formComponentData, getValue]);

    const getCurrentData = () => {
        let values = getValue(myName);
        if (values !== undefined) {
            return values;
        } else if (parentSetValue) {
            return parentSetValue
        }
    }

    const saveValues = objectToSave => {
        if (parentSetValue !== undefined) {
            parentHandleSave(objectToSave, myName)
        } else {
            setValue(objectToSave, myName)
        }
    }

    const handleChange = (value, name) => {
        var parts = name.split(".");
        var resultName = parts[parts.length - 1];

        let objectToSave = getCurrentData();

        if (!objectToSave) {
            objectToSave = {
                observations: observations,
                imageUrls: imageUrls,
                imageLayout: imageLayout,
                severity: severity
            };
        }

        switch (resultName) {
            case "severity":
                objectToSave.severity = value;
                setSeverity(value);
                saveValues(objectToSave);
                break;
            case "observations":
                objectToSave.observations = value;
                setObservations(value);
                saveValues(objectToSave);
                break;
            case "failureImage":
                objectToSave.imageUrls = value;
                setImageUrls(value);
                saveValues(objectToSave);
                break;
            case "imageLayout":
                objectToSave.imageLayout = value;
                setImageLayout(value);
                saveValues(objectToSave);
                break;
            default:
                break;
        }
    };

    const renderSubHeader = (formComponent, index) => {
        return <React.Fragment key={index}>
            {getComponent(
                formComponent,
                `${myName}.${formComponent.key}`
            )}
        </React.Fragment>
    };

    const renderText = (formComponent, index) => {
        
        return (<React.Fragment key={index}>
            {getComponent(
                formComponent,
                `${myName}.${formComponent.key}`,
                handleChange,
                observations
            )}
        </React.Fragment>)
    };

    const renderUsObservationList = (formComponent, index) => {
        return (<React.Fragment key={index}>
            {getComponent(
                {
                    ...formComponent, args: [{
                        options: usObservationOptions.map(o => ({
                            label: o,
                            value: o
                        }))
                    }]
                },
                `${myName}.${formComponent.key}`,
                handleChange,
                observations
            )}
        </React.Fragment>)
    }

    const renderImage = (formComponent, index) => {
        return <React.Fragment key={index}>
            {getComponent(
                formComponent,
                `${myName}.${formComponent.key}`,
                handleChange,
                { urlItems: imageUrls, imageLayout: imageLayout }
            )}
        </React.Fragment>
    };

    const renderSeverity = (formComponent, index) => {
        return (<React.Fragment key={index}>
            {getComponent(
                formComponent,
                `${myName}.${formComponent.key}`,
                handleChange,
                severity
            )}
        </React.Fragment>)
    }

    const renderParts = (formComponent, index) => {
        if (formComponent !== undefined) {
            switch (formComponent.type) {
                case "SubHeader":
                    return renderSubHeader(formComponent, index)
                case "RichText":
                    return renderText(formComponent, index);
                case "AutoCompleteCreatableMultiple":
                    if (formComponent.args === "UsObservationList") {
                        return renderUsObservationList(formComponent, index);
                    } else {
                        return renderText(formComponent, index);    
                    }
                    
                case "TrafficLight":
                    return renderSeverity(formComponent, index)
                case "FileImage":
                    return renderImage(formComponent, index)
                default:
                    return null;
            }
        }
        return null;
    };

    return formComponentData.args.map((formComponent, index) => {
        return renderParts(formComponent, index);
    });
}


export default ObservationComponent;