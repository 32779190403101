import { SET_CURRENT_USER } from "redux/types";
import initialState from "redux/initialState";

export default function currentUserReducer(
  state = initialState.currentUser,
  action
) {
  switch (action.type) {
    case SET_CURRENT_USER:
      let user = action.payload;
      user = { ...user, ...getIsExternal(action.payload) };
      return user;
    default:
      return state;
  }
}

function getIsExternal(payload) { 
  if (payload["custom:external"]) {
    return { isExternal: payload["custom:external"].toLocaleString().toLocaleLowerCase() === "true" }
  } else { 
    return {};
  }
}