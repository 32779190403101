import React from "react";
import MainContainer from "components/common/MainContainer";
import PageHeader from "components/common/PageHeader";
import { FormattedMessage } from "react-intl";

const About = () => {
  return (
    <MainContainer
      pageTitle={
        <PageHeader
          className="page-layout__heading-title"
          header={<FormattedMessage id="pageName.about" />}
        />
      }
    >
      <h3>Bearing Analysis Reporting Tool (BART) version 6.0 – Release October 2024</h3>
      <p>Bearing Analysis Reporting Tool has four main purposes:</p>
      <ul>
        <li>Simplify and speed up the preparation of bearing investigation reporting</li>
        <li>Standardize and improve the quality of the reporting</li>
        <li>Improve the collection of the data available from the analysis</li>
        <li>Expand the role of BART beyond only bearing damage analysis</li>
      </ul>
      <p>
        BART can prepare four types of report: Bearing Inspection, Bearing Damage Analysis, Bearing Investigations–Wind, and Global Bearing Investigations.
      </p>
      <p>
        <strong>Bearing Inspection</strong> is used to document the condition of bearings in a Bearing investigation Level 0 and make initial inspections to identify bearings for potential remanufacturing. Bearing Inspection is used to help secure new bearing business and SKF remanufacturing. Bearing Inspection can be used by external SKF users.
      </p>
      <p>
        <strong>Bearing Damage Analysis</strong> (BDA) is similar to earlier versions of BART. BDA is an important SKF service provided to customers to help them improve the reliability of their bearings and assets and helps increase our bearing and remanufacturing business. BDA can assist the customer by providing them feedback on the performance of bearings in their assets, avoid failures, and reduce costs. Professional BDA helps differentiate SKF from its competitors and helps increase our bearing sales and can be an important part of our remanufacturing.  <strong>BART Bearing Damage Analysis</strong> is used to document the condition of the bearings, ISO failure mode(s), failures cause(s), analysis and recommendations for improvements to avoid future failures.  The BART BDA report includes recommendations of SKF product and services solutions. BART BDA is for Bearing investigations Level 1.
      </p>
      <p>
        <strong>Bearing Investigation - WIND</strong> is similar to BDA but has input fields and taxonomy that are specific to the SKF Renewable Energy – Wind business.
      </p>
      <p>
        <strong>Global Bearing Investigations</strong> is used by the Global Laboratories to make reports on Technical Error Reports (TER), Automotive Field Reports (AMFR) and other bearing investigations Level 1 and 2.
      </p>
      <p>
        BART is a cloud based application.  The complete report is prepared and approved within BART.  The output is a finished pdf document ready for distribution.  All the data used to prepare the report is captured in the database.  The information learned from the data will be periodically reported.
      </p>
    </MainContainer>
  );
};

export default About;
