import { POPULATE_CASES, TOGGLE_CASES_LOADING } from 'redux/types'
import initialState from 'redux/initialState'

export default function caseReducer(state = initialState.caseRelated, action) {
  switch (action.type) {
    case POPULATE_CASES:
      return Object.assign({}, state, {
        ...state,
        cases: action.cases ? [...action.cases] : [],
        total: action.total ? action.total : 0,
        loading: false
      })
    
    case TOGGLE_CASES_LOADING:
      return Object.assign({}, state, {
        ...state,
        loading: action.isLoading
      })
  
    default:
      return state
  }
}
