import React, { Component } from "react";
import { setScrollTarget } from "./../../redux/actions/saveComponentValueAction";
import { connect } from "react-redux";

let oldElement = null;

class ScrollOnFocus extends Component {
    constructor(props) {
        super(props);
    }

    focusEventListener = (event) => {
        const parent = event.target.closest('.componentContainer')
        if (parent !== null) {
            const id = parent.getAttribute("data-parent-id");
            if (id != null && id != "") {
                if (id != "bearingInvestigations") {
                    this.props.dispatch(setScrollTarget({ id: id, formTemplateId: this.props.currentFormTemplateID }));
                }
            }
        }
    }

    componentDidMount() {
        document.addEventListener('focusin', this.focusEventListener);
        document.addEventListener('click', this.focusEventListener);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.scrollTarget) {
            if (prevProps.scrollTarget.scrollTarget !== this.props.scrollTarget.scrollTarget || prevProps.scrollTarget.count !== this.props.scrollTarget.count) {
                this.scrollToTarget(this.props.scrollTarget);
            }
        } else {
            this.scrollToTarget(this.props.scrollTarget);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('focusin', this.focusEventListener)
    };

    getElementTarget = (target) => {
        if (target.scrollTarget) {
            if (target.scrollTarget.includes("[")) {
                if (
                    target.scrollTarget.includes("FailureAndCause") ||
                    target.scrollTarget.includes("skfCompany") ||
                    (
                        target.scrollTarget.includes("continuousOrIntermittent") &&
                        !target.scrollTarget.includes("otherBearingInvestigation")
                    ) ||
                    target.scrollTarget.includes("dispositionSelect") ||
                    target.scrollTarget.endsWith("greaseOrOil") ||
                    target.scrollTarget.endsWith("functionalLocation")
                ) {
                    return target.fieldTarget;
                } else {
                    return target.scrollTarget;
                }
            } else if (target.fieldTarget !== "") {
                return target.fieldTarget;
            } else {
                return target.scrollTarget;
            }
        } else {
            return "empty";
        }
    }

    getCurrentIndex = (target, datakey) => {
        //changing last target's index with the next target's index
        const nextIndex = datakey.substring(datakey.lastIndexOf('[') + 1, datakey.lastIndexOf(']'))

        const prevIndex = target.substring(target.lastIndexOf('[') + 1, target.lastIndexOf(']'))
        const result = datakey.replace(prevIndex, nextIndex)

        return result
    }

    scrollToTarget = (target, iteration = 0) => {
        const container = document.querySelector('.page-layout__content--live-preview')
        if (!container) {
            console.log("could not find container");
            return
        }

        if (target.oldScrollTarget) {
            if (oldElement != null) {
                oldElement.classList.remove("scroll-indicator");
            }
        }
        const datakey = this.getElementTarget(target);
        let targetElement = container.querySelector(`[data-key-id*="${datakey}"]`) || container.querySelector(`[data-section-id*="${datakey}"]`);
        if (!targetElement) {
            const alternativeTarget = target.scrollTarget?.split('.')[1]
            targetElement = container.querySelector(`[data-key-id*="${alternativeTarget}"]`) || container.querySelector(`[data-section-id*="${alternativeTarget}"]`)
        }

        let livePreviewElement;
        if (this.props.livePreviewTarget) {
            const livePreviewTarget = this.getCurrentIndex(this.props.livePreviewTarget, datakey);
            livePreviewElement = document.querySelector(`[data-key-id*="${livePreviewTarget}"]`)
        }

        if (targetElement !== null) {
            if (!this.props.disableAutoScroll) {
                container.scrollTo({
                    top: livePreviewElement ? livePreviewElement.offsetTop : targetElement.offsetTop - 60,
                    behavior: "smooth"
                });
            }

            if (datakey.endsWith("]")) {
                targetElement.classList.add("scroll-indicator");
                oldElement = targetElement;
            } else {
                targetElement.parentElement.classList.add("scroll-indicator");
                oldElement = targetElement.parentElement;
            }
        } else if (iteration === 0) {
            setTimeout(() => this.scrollToTarget(target, 1), 1000);
        } else {
            console.log("could not find", datakey);
        }
    }

    render() {
        return <></>;
    }
}

function mapStateToProps(state) {
    return {
        scrollTarget: state.saveComponentValue.scrollTarget,
        disableAutoScroll: state.saveComponentValue.disableAutoScroll,
        livePreviewTarget: state.saveComponentValue.livePreviewTarget,
    };
};

export default (connect(mapStateToProps)(ScrollOnFocus));