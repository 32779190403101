import React from 'react'
import { useIntl } from 'react-intl';
import classnames from 'classnames'
import PropTypes from 'prop-types';

const BaseInput = (props) => {
  const intl = useIntl()

  // computed
  const hasIcon = () => {
    return props.iconComponent !== undefined
  }

  // methods
  const handleChange = (event) => { props.handleChange(event) }

  const getKeyValue = () => {
    const parts = props?.myName.split('.')

    return parts[parts.length - 1]
  }

  return (
    <div className={classnames('input-shell', props.className)}>
      {props.labelText &&
        <label
          htmlFor={props.id}
          className={classnames('input-shell__label', { 'input-shell__label--dark': props.labelTextDark },
            { 'input-shell__label--required': props.isRequired })}
        >
          {props?.myName ? intl.formatMessage({ id: getKeyValue() }) : props.labelText}
        </label>
      }

      <div className="input-shell__container" data-id={props.myName}>
        {!props.isTextArea &&
          <input
            id={props.id}
            name={props.id}
            type={props.type}
            placeholder={props.placeholder}
            className={classnames('input__element', { 'input__element--use-icon': hasIcon() })}
            onChange={handleChange}
            value={typeof props.value === 'object' ? props.value.label : props.value}
            onBlur={props.onBlur}
            disabled={props.isDisabled}
            onKeyUp={props.onKeyUp || null}
          />
        }
        {props.isTextArea &&
          <textarea
            id={props.id}
            name={props.id}
            placeholder={props.placeholder}
            className="input__textarea"
            onChange={handleChange}
            onBlur={props.onBlur}
            onKeyUp={props.onKeyUp || null}
            disabled={props.isDisabled}
            value={props.value}
          />
        }
        {props.iconComponent && (props.iconComponent)}
      </div>

      {props.errorMessage || props.hintMessage &&
        <div className="input-shell__message">
          {props.errorMessage &&
            <div className={classnames({ 'input-shell__message-error': props.errorMessage })}>
              {props.errorMessage}
            </div>
          }
          {props.hintMessage &&
            <div className={classnames({ 'input-shell__message-hint': props.hintMessage })}>
              {props.hintMessage}
            </div>
          }
        </div>
      }
    </div>
  )
}

BaseInput.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  labelTextDark: PropTypes.bool,
  errorMessage: PropTypes.string,
  iconComponent: PropTypes.object,
  handleChange: PropTypes.func,
  isTextArea: PropTypes.bool,
  isRequired: PropTypes.bool,
  myName: PropTypes.string
}

BaseInput.defaultProps = {
  type: 'text',
  labelTextDark: false,
  isTextArea: false
}

export default BaseInput
