import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import distributeCase from "../ButtonActions/distributeReport";
import classnames from "classnames";
import SvgButtonBarDistribute from "../../svg/ButtonBarDistribute"
import ReactModal from "react-modal";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import BaseButton from "components/common/BaseButton";

const ButtonDistribute = ({ buttonSize, currentFormTemplateID }) => {
    const intl = useIntl();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const currentCase = useSelector(state => state.currentCase.case);
    const answers = useSelector(state => state.saveComponentValue.components);
    const language = useSelector(state => state.language);

    const onDistribute = async () => {
        try {
            setIsLoading(true);
            await distributeCase(
                currentCase,
                answers,
                currentFormTemplateID,
                language,
                document.querySelector('.live-preview').outerHTML,
                intl
            );
            setIsModalOpen(true);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    }

    const isDisabled = currentCase.status !== 'approved' || currentCase.confidential;

    return (
        <>
            <button
                id="buttonDistributePdf"
                type="button"
                onClick={() => onDistribute()}
                className={classnames("button", "button--icon-only", { "button--icon-saving": isLoading }, { "button--icon-disabled": isDisabled })}
                disabled={isDisabled}
            >
                <SvgButtonBarDistribute
                    size={buttonSize}
                    title={intl.formatMessage({ id: currentCase.confidential ? "formRenderer.distributePDFConfidential" : "formRenderer.distributePDF" })}
                />
            </button>
            <ReactModal
                isOpen={isModalOpen}
                className="react-modal"
                overlayClassName="react-modal-overlay"
                onRequestClose={() => setIsModalOpen(false)}
            >
                <div className="react-modal__header">
                    <button
                        className="react-modal__close-button"
                        onClick={() => setIsModalOpen(false)}
                    >
                        <CircleCloseFilterSvg />
                    </button>
                </div>
                <div className="react-modal__body">
                    <div className="react-modal__text">
                        <FormattedMessage id="formRenderer.distributeMessage" />
                    </div>
                    <div className="react-modal__actions-container">
                        <BaseButton
                            handleSubmit={() => setIsModalOpen(false)}
                            text={intl.formatMessage({ id: "formRenderer.close" })} backgroundColor="green" className="react-modal__action-button"
                        />
                    </div>
                </div>
            </ReactModal>
        </>
    );
}

export default ButtonDistribute;
