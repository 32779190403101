import config from "config";
import { addAnswerToSave } from "./answersToSaveEffects";

const approverFields = ["reportDetails.reportApprovedBy.name", "reportDetails.reportApprovedBy.email", "reportDetails.reportApprovedBy.phone"]

export function setApprover(formtemplateId, components, componentsToSave, payload) {
    if (formtemplateId === config.US_INSPECTION_TEMPLATE_ID) {
        if (shouldSetApprover(payload) && isApproverEmpty(components) && isApproverEmpty(componentsToSave.map(c => c.answer))) {
            const name = getApproverName(payload)
            const email = getApproverEmail(payload)
            const phone = getApproverPhone(payload);
            componentsToSave = AddOrUpdateAnswer(componentsToSave, components, { name: "reportDetails.reportApprovedBy.name", value: JSON.stringify(name) });
            componentsToSave = AddOrUpdateAnswer(componentsToSave, components, { name: "reportDetails.reportApprovedBy.email", value: JSON.stringify(email) });
            componentsToSave = AddOrUpdateAnswer(componentsToSave, components, { name: "reportDetails.reportApprovedBy.phone", value: JSON.stringify(phone) });
            return componentsToSave
        }   
    }
    return componentsToSave;
}

function AddOrUpdateAnswer(componentsToSave, components, component) { 
    const existingAnswer = components.find(c => c.name === component.name);
    if (existingAnswer) { 
        component = {
            ...existingAnswer,
            value: component.value
        };
    }
    return addAnswerToSave(componentsToSave, component);
}

function getApproverName(payload) { 
    return getEndUserField(payload, "name");
}

function getApproverEmail(payload) { 
    return getEndUserField(payload, "email");
}

function getApproverPhone(payload) { 
    return getEndUserField(payload, "phone_number");
}

function getEndUserField(payload, industrySpecialistField) { 
    const endUserValue = JSON.parse(payload.value);
    if (endUserValue && endUserValue.approver){ 
        return endUserValue.approver[industrySpecialistField];
    }
    return "";
}

function shouldSetApprover(payload) { 
    return payload.name === "skfDetails.endUser";
}

function isApproverEmpty(components) { 
    return !approverFields.map(field => components.find(c => c.name === field)).some(c => c && c.value && c.value !== JSON.stringify(""));
}