import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LockSvg from "components/svg/Lock";

const StatusConfidential = ({ caseStatus, isConfidential }) => {
  const refInspectionId = useSelector(state => state.currentCase.case?.inspectionID);

  const printHtmlHbs = "{{{printHtml}}}";
  if (caseStatus === "approvalEdited") { 
    caseStatus = "submitted";
  }

  const hasInspectionId = refInspectionId && refInspectionId !== 0;

  const renderInspectionRefId = () => !hasInspectionId ? null : (
    <div className="live-preview__ref__inspection__id">
      <Link to={`/case/${refInspectionId}`}>
        {`Ref. Inspection No. ${refInspectionId}`}
      </Link>
    </div>
  );

  return (
    <div className={`live-preview-status-confidential ${!hasInspectionId ? 'single-status' : ''}`}>
      {renderInspectionRefId()}
      <div className="print">
        { printHtmlHbs }
      </div>
      <div className="live-preview-status-confidential__status">
        <span><FormattedMessage id={ `preview.${caseStatus}` } /></span>
        {isConfidential && (
          <span className="live-preview-status-confidential__confidential">
            <LockSvg /> <FormattedMessage id="shared.confidential" />
          </span>
        )}
      </div>
    </div>
  );
};

export default StatusConfidential;
