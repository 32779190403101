import React from "react";
import SvgCanvasTrashcan from './../../svg/CanvasTrashcan';
import SvgCanvasRotate from './../../svg/CanvasRotate';
import SvgCheck2 from './../../svg/Check2';

const ImagePreview = ({ files, onRemove, onRotate }) => {

    return files.map(file => !file.deleted &&
        <div className="preview" key={file.id}>
            <div className="preview-container">
                {file.loading
                    ? (<div className="preview-loader element--is-loading-after"></div>)
                    : (
                        <>
                            {!file.isuploading && !file.isuploaded && <div className="preview-overlay">
                                <button id="buttonRemove" onClick={event => onRemove(file, event)} className="button button--icon-only button--background-green">
                                    <SvgCanvasTrashcan />
                                </button>
                                <button id="buttonRotate" onClick={event => onRotate(file, event)} className="button button--icon-only button--background-green">
                                    <SvgCanvasRotate />
                                </button>
                            </div>}
                            {file.isuploading && <div className="preview-uploading-overlay element--is-loading-after"></div>}
                            {file.isuploaded && <div className="preview-uploaded-overlay">
                                <SvgCheck2 className="image-selector__button image-selector__editbutton" />
                            </div>}
                            <img className="preview-image" src={`data:${file.type};base64,${file.body}`} />
                        </>
                    )
                }
            </div>
        </div>
    )
};

export default ImagePreview;