import React from "react";

const SvgButtonBarClose = ({ title, size = "26px", fill = "#F8F8F8" }) => (
    <svg width={ size } height={ size } viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{title}</title>
        <g id="Icons/icon_close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M10,0 C15.52,0 20,4.48 20,10 C20,15.52 15.52,20 10,20 C4.48,20 0,15.52 0,10 C0,4.48 4.48,0 10,0 Z M15.5,3.08578644 L10,8.585 L4.5,3.08578644 L3.08578644,4.5 L8.586,10 L3.08578644,15.5 L4.5,16.9142136 L10,11.414 L15.5,16.9142136 L16.9142136,15.5 L11.415,10 L16.9142136,4.5 L15.5,3.08578644 Z" id="Combined-Shape" fill={ fill }></path>
        </g>
    </svg>
);

export default SvgButtonBarClose;
