import React from "react";

const SvgButtonBarDuplicate = ({ title, size = "26px" }) => (
    <svg width={ size } height={ size } viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{title}</title>
        <g id="icon_duplicate" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icons/Actions/Duplicate" transform="translate(-0.000000, 0.320896)" fill="#F8F8F8">
                <path d="M0.909090909,3.63636364 C1.40909091,3.63636364 1.81818182,4.04545455 1.81818182,4.54545455 L1.81818182,17.2727273 C1.81818182,17.7727273 2.22727273,18.1818182 2.72727273,18.1818182 L15.4545455,18.1818182 C15.9545455,18.1818182 16.3636364,18.5909091 16.3636364,19.0909091 C16.3636364,19.5909091 15.9545455,20 15.4545455,20 L1.81818182,20 C0.818181818,20 -3.41060513e-13,19.1818182 -3.41060513e-13,18.1818182 L-3.41060513e-13,4.54545455 C-3.41060513e-13,4.04545455 0.409090909,3.63636364 0.909090909,3.63636364 Z M18.1818182,1.13686838e-13 C19.1818182,1.13686838e-13 20,0.818181818 20,1.81818182 L20,14.5454545 C20,15.5454545 19.1818182,16.3636364 18.1818182,16.3636364 L5.45454545,16.3636364 C4.45454545,16.3636364 3.63636364,15.5454545 3.63636364,14.5454545 L3.63636364,1.81818182 C3.63636364,0.818181818 4.45454545,1.13686838e-13 5.45454545,1.13686838e-13 L18.1818182,1.13686838e-13 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
);

export default SvgButtonBarDuplicate;
