import { combineReducers } from "redux";
import ui from "redux/reducers/uiReducers";
import caseRelated from "redux/reducers/casesReducers";
import filters from "redux/reducers/filterReducers";
import saveComponentValue from "redux/reducers/saveComponentValueReducers";
import language from "redux/reducers/languageReducers";
import formTemplate from "redux/reducers/formTemplateReducers";
import isSaving from "redux/reducers/isSavingReducers";
import requiredList from "redux/reducers/requiredListReducers";
import connection from "redux/reducers/connectionReducers";
import swReducers from "redux/reducers/swReducers";
import isRailwayWheelsetBearing from "./isRailwayWheelsetBearingReducers";
import isConfidential from "./isConfidentialReducers";
import excludeISOAppendix from "./excludeISOAppendixReducers";
import pageBreakEachBearing from "./pageBreakEachBearingReducers";
import currentCase from "./currentCaseReducers";
import currentUser from "./currentUserReducers";
import showSummaryAtTop from "./showSummaryAtTopReducers";
import bearingsExpanded from "./isBearingsExpandedReducers";
import activeTab from "./activeTabReducers";
import currentCaseAccess from "./currentCaseAccessReducers";
import currentFormConfig from "./currentFormConfigReducers";

const rootReducer = combineReducers({
  ui,
  caseRelated,
  filters,
  saveComponentValue,
  language,
  requiredList,
  formTemplate,
  connection,
  isSaving,
  swReducers,
  isRailwayWheelsetBearing,
  isConfidential,
  excludeISOAppendix,
  pageBreakEachBearing,
  showSummaryAtTop,
  currentCase,
  currentUser,
  bearingsExpanded,
  activeTab,
  currentCaseAccess,
  currentFormConfig
});

export default rootReducer;
