import React from "react";

const SvgCircleCloseFilter = props => (
  <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={20} cy={20} r={20} />
      <path
        d="M19.523 18.11l7.15-7.15a1 1 0 011.414 1.414l-7.15 7.15 7.15 7.149a1 1 0 01-1.414 1.414l-7.15-7.15-7.15 7.15a1 1 0 11-1.413-1.414l7.15-7.15-7.15-7.15a1 1 0 111.414-1.413l7.15 7.15z"
        fill="#0F58D6"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgCircleCloseFilter;
