export function addUpdateComponent(components, payload) {
  let index = components.findIndex(component => component.name === payload.name || (component.id === payload.id && payload.id));
  if (index !== -1) {
    let updated = JSON.parse(JSON.stringify(components))
    updated[index].id = payload.id;
    updated[index].updatedAt = payload.updatedAt;
    updated[index].value = payload.value;
    updated[index].isError = payload.isError;
    updated[index].isSaving = payload.isSaving;
    updated[index].error = payload.error;
    updated[index].index = payload.index;
    if (payload.name.includes('bearingInvestigation')) {
      updated[index].name = payload.name
    }
    return updated;
  } else {
    return [...components, payload];
  }
}

export function clearComponentErrorById(components, id) {
  let index = components.findIndex(component => component.id === id);
  if (index !== -1) {
    let updated = JSON.parse(JSON.stringify(components))
    updated[index].isError = false;
    updated[index].isSaving = false;
    updated[index].error = undefined
    return updated;
  } else {
    return [...components];
  }
}