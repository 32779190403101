import React, { Component } from "react";
import getComponent from "../../_Case/ComponentsList";

class SkfCompanyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressValue: "",
      nameValue: ""
    };
  }

  componentDidMount() {
    let values = this.props.getValue(this.props.myName);
    if (values !== undefined) {
      this.setState({
        addressValue: values.addressValue,
        nameValue: values.nameValue
      });
    }
  }

  handleChange = (value, name) => {
    var parts = name.split(".");
    var resultName = parts[parts.length - 1];
    let objectToSave = {
      addressValue: this.state.addressValue,
      nameValue: this.state.nameValue
    };

    if (resultName === "skfCompanyName") {
      this.setState({
        nameValue: value
      });
      objectToSave.nameValue = value;
      this.props.setValue(objectToSave, this.props.myName);
    } else if (resultName === "skfCompanyAddress") {
      this.setState({
        addressValue: value
      });
      objectToSave.addressValue = value;
      this.props.setValue(objectToSave, this.props.myName);
    }
  };

  renderSkfCompany = (formComponent, index) => {
    if (formComponent !== undefined) {
      if (formComponent.key === "skfCompanyName") {
        return (
          <React.Fragment key={index}>
            {getComponent(
              formComponent,
              `${this.props.myName}.${formComponent.key}`,
              this.handleChange,
              this.state.nameValue
            )}
          </React.Fragment>
        );
      } else if (formComponent.key === "skfCompanyAddress") {
        return (
          <React.Fragment key={index}>
            {getComponent(
              formComponent,
              `${this.props.myName}.${formComponent.key}`,
              this.handleChange,
              this.state.addressValue
            )}
          </React.Fragment>
        );
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.formComponentData.args.map((formComponent, index) => {
          return this.renderSkfCompany(formComponent, index);
        })}
      </React.Fragment>
    );
  }
}

export default SkfCompanyComponent;
