export const addAnswerToSave = (answersToSave, answer) => { 
    const index = answersToSave.findIndex(a => a.answer.name === answer.name)
    if (index !== -1) {
        let updated = JSON.parse(JSON.stringify(answersToSave))
        updated[index].answer.value = answer.value;
        updated[index].touched = new Date();
        return updated;
    } else { 
        return [...answersToSave.map(a => ({...a})), { touched: new Date(), answer }]
    }
}

export const removeAnswerToSave = (answersToSave, answerName) => { 
    let index = answersToSave.findIndex(a => a.answer.name === answerName);
    if (index !== -1) { 
        const updated = JSON.parse(JSON.stringify(answersToSave))
        updated.splice(index, 1);
        return updated;
    }
    return answersToSave;
}

export const removeAnswersToSave = (answersToSave, answerNames) => { 
    return [...answersToSave.filter(a => !answerNames.includes(a.answer.name))];
}

export const triggerSaveOnAccordian = (answersToSave, accoridanName) => { 
    return answersToSave.map(a => { 
        if (!a.answer.value?.startsWith(accoridanName) || accoridanName === null) {
            return { ...a, saveNow: true };
        } else { 
            return a;
        }
    });
}