import React from 'react'
import { useIntl } from "react-intl"
import { useSelector } from "react-redux";
import { isNotNull, isValuePresent } from "../Shared/BIHelperFunctions";

const GbiReportInformation = (props) => {
  const intl = useIntl()

  const scrollTarget = useSelector(state => state.saveComponentValue.scrollTarget)
  const caseWasSaved = useSelector(state => state.saveComponentValue.caseWasSaved)

  const hasScrollTarget = key => {
    if (!scrollTarget) return false

    if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
      return (scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key)) && !caseWasSaved
    }
    return false
  }

  return (
    <div className="live-preview-report-information__container">
      <div className="live-preview-report-information__two-column">
        {isValuePresent(props.data['reportDetails.reportTitle']) || hasScrollTarget('reportDetails.reportTitle') ?
          <div className="live-preview-report-information__row" data-key-id="reportDetails.reportTitle">
            <div className="live-preview-report-information__key">
              {intl.formatMessage({ id: 'reportTitle' })}:
            </div>

            <div className="live-preview-report-information__value">
              {props.data['reportDetails.reportTitle']}
            </div>
          </div>
          : null
        }
        {(isNotNull(props.data['reportDetails.reportDate']) || hasScrollTarget('reportDetails.reportDate') && isValuePresent(props.data['reportDetails.reportDate'].date)) ?
          <div className="live-preview-report-information__row" data-key-id="reportDetails.reportDate">
            <div className="live-preview-report-information__key">
              {intl.formatMessage({ id: 'reportDate' })}:
            </div>
            <div className="live-preview-report-information__value">
              {props.data['reportDetails.reportDate'] ? props.data['reportDetails.reportDate'].date : ""}
            </div>
          </div>
          : null
        }
      </div>
      <div className="live-preview-report-information__two-column">
        {props.terOrAmfrNumber.number && props.terOrAmfrNumber.type ? (
          <div className="live-preview-report-information__row" data-key-id="reportDetails.searchText">
            <div className="live-preview-report-information__key">
              {intl.formatMessage({ id: 'searchText' })}:
            </div>
            <div className="live-preview-report-information__value">
              {props.terOrAmfrNumber.number}
            </div>
          </div>
        ) : null
        }
      </div>
    </div>
  )
}

export default GbiReportInformation