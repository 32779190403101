import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import LighthouseStatus from "components/common/LighthouseStatus";
import LockSvg from "components/svg/Lock";
import { FormattedMessage } from "react-intl";

const TableMobileRow = ({
  translationKey,
  value,
  isStatus,
  isConfidential,
  color,
  originalValue,
  intl
}) => {
  let rowValue;
  if (isStatus) {
    rowValue = <LighthouseStatus label={value} value={ originalValue } color={color} />;
  } else if (isConfidential) {
    rowValue = value ? (
      <React.Fragment>
        <LockSvg className="table__body-lock-svg" />
        {<FormattedMessage id="tableMobileRow.no" />}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <LockSvg className="table__body-lock-svg" />
        {<FormattedMessage id="tableMobileRow.yes" />}
      </React.Fragment>
    );
  } else if (
    !isConfidential &&
    translationKey === "table.header.confidential"
  ) {
    rowValue = (
      <React.Fragment>
        {" "}
        {<FormattedMessage id="tableMobileRow.no" />}
      </React.Fragment>
    );
  } else {
    rowValue = value;
  }

  return (
    <div className="table-mobile-accordion__key-value">
      <div className="table-mobile-accordion__key">
        {translationKey !== undefined &&
          intl.formatMessage({ id: translationKey })}
      </div>
      <div className="table-mobile-accordion__value">{rowValue}</div>
    </div>
  );
};

TableMobileRow.propTypes = {
  translationKey: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  isStatus: PropTypes.bool,
  isConfidential: PropTypes.bool,
  originalValue: PropTypes.string
};

export default injectIntl(TableMobileRow);
