import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getImageObjects,
  getImageLayoutObject,
} from "../Shared/BIHelperFunctions";
import _ from 'lodash';
import KeyValueTable from "../Shared/KeyValueTable";
import ImageContainer from "../Shared/ImageContainer";

const Summary = ({ header, config, data, showOnlyConclusionsAndRecommendations, sectionId }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [image, setImage] = useState({});

  const scrollTarget =  useSelector(state => state.saveComponentValue.scrollTarget)

  const part1 = ["summary.conclusions"];
  const part2 = ["summary.recommendations", "summary.recommendationsOptions", "summary.equipmentUsed"];
  const part2OnlyRecommendations = ["summary.recommendations"];
  const imageKey = "summary.conclusionImages";

  useEffect(() => {
    setSelectedValues(requiredFieldsAndFilledArray(config, data));
    setImage(data[imageKey]);
  }, [data]);

  const filterConfig = (config, filter) => {
    return config.filter(c => filter.find(f => f == c.key));
  }

  const hasScrollTarget = key => {
    if (scrollTarget) {
      if (scrollTarget.fieldTarget) {
        return scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key);
      }
    }
    return false;
  }

  const requiredFieldsAndFilledArray = (config, data) => {
    let hasFields = [];

    if (data !== undefined) {
      config.forEach((c) => {
        if (data[c.key] !== undefined && data[c.key] !== null && data[c.key] !== "" || hasScrollTarget(c.key)) {
          hasFields.push(c);
        }
      });
    }

    return hasFields;
  };

  const hasAnyValue = () => {
    if (selectedValues.length === 0) return false

    const hasNonEmptyValue = selectedValues.some(value => {
      const currentValue = data[value.key]
      if (!currentValue) return false

      if (typeof currentValue === 'object') {
        return _.some(currentValue, e => e?.length > 0)
      }
      return currentValue.length > 0
    })
    return hasNonEmptyValue
  }

  return (
    <>
      {hasAnyValue() && (
        <>
          <div className="live-preview-key-value" data-section-id={sectionId}>
            {header && <div className="live-preview-key-value__header">{header}</div>}
            <div className="live-preview-summary-conclusions">
              <KeyValueTable config={filterConfig(config, part1)} data={data} />
            </div>
            {image && !showOnlyConclusionsAndRecommendations && (
              <ImageContainer
                imageLayout={getImageLayoutObject(image)}
                imageObjects={getImageObjects("summary.conclusionImages", data)}
              />
            )}
            <KeyValueTable config={filterConfig(config, showOnlyConclusionsAndRecommendations ? part2OnlyRecommendations : part2)} data={data} />
          </div>

        </>
      )}
    </>
  )
}

export default Summary