import React, { Component,useEffect,useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import getComponent from "../../_Case/ComponentsList";
import SubHeaderComponent from "./../SubHeaderComponent";
import { addAnswer, updateAnswer } from "redux/thunks/answerThunk";
import { useIntl } from "react-intl";

const UserPickerComponent = ({ myName, getValue, setValue, formComponentData }) => {
  const dispatch = useDispatch();
  
  const [phoneAnswer, setPhoneAnswer] = useState();
  const [nameAnswer, setNameAnswer] = useState();
  const [emailAnswer, setEmailAnswer] = useState();
  
  const [phoneAnswerValue, setPhoneAnswerValue] = useState();
  const [nameAnswerValue, setNameAnswerValue] = useState();
  const [emailAnswerValue, setEmailAnswerValue] = useState();

  const currentCaseId = useSelector((state) => state.currentCase.case.id, shallowEqual);

  const nameAnswerFromState = useSelector(state => state.saveComponentValue.components.find(a => a.name === `${myName}.name`), shallowEqual);
  const phoneAnswerFromState = useSelector(state => state.saveComponentValue.components.find(a => a.name === `${myName}.phone`), shallowEqual);
  const emailAnswerFromState = useSelector(state => state.saveComponentValue.components.find(a => a.name === `${myName}.email`), shallowEqual);

  const nameAnswerFromStateToSave = useSelector(state => state.saveComponentValue.componentsToSave.find(a => a.answer.name === `${myName}.name`), shallowEqual);
  const phoneAnswerFromStateToSave = useSelector(state => state.saveComponentValue.componentsToSave.find(a => a.answer.name === `${myName}.phone`), shallowEqual);
  const emailAnswerFromStateToSave = useSelector(state => state.saveComponentValue.componentsToSave.find(a => a.answer.name === `${myName}.email`), shallowEqual);

  const user = useSelector(state => state.currentUser, shallowEqual);

  const intl = useIntl();

  useEffect(() => {
    if (nameAnswerFromStateToSave) {
      setNameAnswer(nameAnswerFromStateToSave.answer);
    } else if (nameAnswerFromState) {
      setNameAnswer(nameAnswerFromState);
    } 
   }, [nameAnswerFromState, nameAnswerFromStateToSave]);

   useEffect(() => {
    if (phoneAnswerFromStateToSave) {
      setPhoneAnswer(phoneAnswerFromStateToSave.answer);
    } else if(phoneAnswerFromState) { 
      setPhoneAnswer(phoneAnswerFromState);
    }
   }, [phoneAnswerFromState, phoneAnswerFromStateToSave]);
  
   useEffect(() => {
    if (emailAnswerFromStateToSave) {
      setEmailAnswer(emailAnswerFromStateToSave.answer);
    } else if(emailAnswerFromState){ 
      setEmailAnswer(emailAnswerFromState);
    }
   }, [emailAnswerFromState, nameAnswerFromStateToSave]);
  
  useEffect(() => { 
    if (phoneAnswer && phoneAnswer.value) { 
      setPhoneAnswerValue(JSON.parse(phoneAnswer.value));
    }
    if (emailAnswer && emailAnswer.value) { 
      setEmailAnswerValue(JSON.parse(emailAnswer.value));
    }
    if (nameAnswer && phoneAnswer && emailAnswer) {
      setNameAnswerValue({ name: JSON.parse(nameAnswer.value), phone: phoneAnswer.value ? JSON.parse(phoneAnswer.value) : "", email: emailAnswer.value ? JSON.parse(emailAnswer.value) : "" })
    }
  }, [phoneAnswer, nameAnswer, emailAnswer]);
  
  const handleChange = (value, name) => {
    var resultName = name.split(".").pop();
    if (resultName === "name") {
      if (value) {
        dispatchAnswer(nameAnswer, value.name, `${myName}.name`);
        dispatchAnswer(emailAnswer, value.email, `${myName}.email`);
        dispatchAnswer(phoneAnswer, value.phone, `${myName}.phone`);
      } else { 
        dispatchAnswer(nameAnswer, "", `${myName}.name`);
        dispatchAnswer(emailAnswer, "", `${myName}.email`);
        dispatchAnswer(phoneAnswer, "", `${myName}.phone`);
      }
    } else if (resultName === "email") {
      dispatchAnswer(emailAnswer, value, `${myName}.email`);
    } else if (resultName === "phone") { 
      dispatchAnswer(phoneAnswer, value, `${myName}.phone`);
    }
  }

  const dispatchAnswer = (answer, value, name) => { 
    if (!answer) {
      dispatch(addAnswer(currentCaseId, JSON.stringify(value), name, 0, intl))
    } else {
      dispatch(updateAnswer(currentCaseId, answer.id, JSON.stringify(value), name, answer.updatedAt, answer.index, answer, intl));
    }
  }

  const renderUserPicker = (formComponent, index) => {
    if (formComponent !== undefined) {
      if (formComponent.key === "name") {
        return (
          <React.Fragment key={index}>
            {getComponent(
              formComponent,
              `${myName}.${formComponent.key}`,
              handleChange,
              nameAnswerValue
            )}
          </React.Fragment>
        );
      } else if (formComponent.key === "email") {
        return (
          <React.Fragment key={index}>
            {getComponent(
              formComponent,
              `${myName}.${formComponent.key}`,
              handleChange,
              emailAnswerValue
            )}
          </React.Fragment>
        );
      } else if (formComponent.key === "phone") {
        return (
          <React.Fragment key={index}>
            {getComponent(
              formComponent,
              `${myName}.${formComponent.key}`,
              handleChange,
              phoneAnswerValue
            )}
          </React.Fragment>
        );
      }
    }
  }
  
   return (
    <>
      <SubHeaderComponent formComponentData={{ description: `${formComponentData.description}`, }}></SubHeaderComponent>
      {formComponentData.args.map((formComponent, index) => {
        return renderUserPicker(formComponent, index);
      })}
    </>
  );
};

export default UserPickerComponent;