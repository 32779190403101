export function getReportType(templateId) {
  const reportTypes = {
    // BDA
    56: 'BDA',
    44: 'BDA',
    20: 'BDA',
    // GBI
    133: 'GBI',
    32: 'GBI',
    46: 'GBI',
    // Inspection (BI)
    170: 'Inspection',
    87: 'Inspection',
    73: 'Inspection',
    // NAM (USInspection)
    218: 'NAM',
    131: 'NAM',
    128: 'NAM',
    // Wind
    200: 'Wind',
    99: 'Wind',
    101: 'Wind',
  }

  return reportTypes[templateId];
}
