import React from "react";

const SvgCanvasZoom = props => (
  <svg width="30px" height="30px" viewBox="0 0 20 20" style={{ background: "transparent" }}>
    <title>icon_zoom</title>
    <g id="icon_zoom" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="transparent" x="0" y="0" width="20" height="20"></rect>
      <g id="Group" fillRule="nonzero">
        <rect id="Rectangle" fill="#88C008" x="0" y="0" width="20" height="20" rx="5"></rect>
        <path d="M17.0872727,14.5168182 L14.4317273,11.8616364 C14.3118182,11.7418182 14.1493636,11.6752727 13.9789091,11.6752727 L13.5447273,11.6752727 C14.2799091,10.7351818 14.7167273,9.55281818 14.7167273,8.26650909 C14.7167273,5.20660909 12.237,2.72727273 9.17654545,2.72727273 C6.11612727,2.72727273 3.63636364,5.20660909 3.63636364,8.26650909 C3.63636364,11.3263636 6.11612727,13.8057273 9.17654545,13.8057273 C10.463,13.8057273 11.6456364,13.369 12.5859091,12.634 L12.5859091,13.0680909 C12.5859091,13.2384545 12.6524545,13.4009091 12.7723636,13.5208182 L15.4279091,16.1759091 C15.6782727,16.4261818 16.0830909,16.4261818 16.3308182,16.1759091 L17.0846364,15.4222727 C17.335,15.1719091 17.335,14.7670909 17.0872727,14.5168182 Z M9.17654545,11.6752727 C7.29341818,11.6752727 5.7672,10.152 5.7672,8.26650909 C5.7672,6.3837 7.29074545,4.85774545 9.17654545,4.85774545 C11.0596364,4.85774545 12.5859091,6.38103636 12.5859091,8.26650909 C12.5859091,10.1492727 11.0623636,11.6752727 9.17654545,11.6752727 Z" id="Shape" fill="#FFFFFF"></path>
      </g>
    </g>
  </svg>
);

export default SvgCanvasZoom;
