import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import classnames from "classnames";
import { shallowEqual, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

const AppendicesComponent = ({ myName, formComponentData }) => { 

  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isHovering, setIsHovering] = useState("");

  const connection = useSelector(state => state.connection, shallowEqual);
  const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);

  const listAppendices = async () => {
    // some changes due to upgrading aws-amplify
    try {
      const res = await Storage.list(`${currentCaseId}/appendix`, {
        level: 'public',
      })

      setFiles(res?.results?.map(x => x.key.split('/')[2]))
      setIsHovering('')
    } catch (e) {
      console.log('Error litsing appendices:', e)
    }
  }

  useEffect(() => { 
    if (currentCaseId && connection) { 
      listAppendices();
    }
  }, [connection, currentCaseId]);

  const addAppendix = () => {
    if (selectedFile) {
      setIsLoading(true);
      const file = selectedFile;
      const name = file.name;
      Storage.put(`${currentCaseId}/appendix/` + name, file, {
        level: "public"
      }).then(() => {
        //Hack to allow the user to upload the same image again
        const element = document.getElementById(myName);
        if (element) {
          element.value = '';
        }
        listAppendices();
        setIsLoading(false);
        setSelectedFile(null);
      });
    }
  };

  const deleteAppendix = (item) => {
    Storage.remove(`${currentCaseId}/appendix/${item}`)
    .then(result => {
      listAppendices();
    })
    .catch(err => console.log("Error is",err));
  }

  const onChangeHandler = event => {
    setSelectedFile(event.target.files[0]);
  };

  const renderItem = (item, i) => { 
    return <li
      className="input-files-list__item"
      key={i}
      onMouseEnter={() => setIsHovering(item)}
      onMouseLeave={() => setIsHovering(item)}
    >
      {i + 1}: {item}
      {
        isHovering === item ? <button
          type="button"
          onClick={() => deleteAppendix(item)}
          className={classnames("button button--background-green")}
        >
          <FormattedMessage id="formRenderer.delete" />
        </button> : null
      }
    </li>;
  }

  return <div className="input-shell">
    {formComponentData.description && (
      <label
        htmlFor={myName}
        className={classnames(
          "input-shell__label",
          "input-shell__label--dark",
          {
            "input-shell__label--required": formComponentData.required
          }
        )}
      >
        {formComponentData.description}
      </label>
    )}

    <div className="input-shell__container input-shell__container--image-container">
      <label
        htmlFor={myName}
        className={classnames("button button--background-gray", {
          "button--disabled": connection === false
        })}
      >
        <FormattedMessage id="formComponents.chooseAppendix" />
      </label>

      <input
        type="file"
        accept="application/pdf,image/*"
        id={myName}
        name={formComponentData.name}
        onChange={onChangeHandler}
        disabled={!connection}
      />

      <button
        type="button"
        onClick={addAppendix}
        disabled={!selectedFile}
        className={classnames("button button--background-green", {
          "element--is-loading element--is-loading-after": isLoading
        })}
      >
        <FormattedMessage id="formComponents.upload" />
      </button>
    </div>

    <ul className="input-files-list">
      {files && files.map((item, i) => renderItem(item, i))}
    </ul>
  </div>;
}

export default AppendicesComponent;