
export default function (value) { 
    if (!value)
        return false;
    if (value == null)
        return false;
    
    if (value.constructor === Object) {
        value = value.value;
    }
    
    let parsedValue = JSON.parse(value);
    if (parsedValue.hasOwnProperty("customer")) {
        parsedValue = parsedValue.customer;
    }
    if (!parsedValue.companyPath)
        return false;
    
    const parts = parsedValue.companyPath.split("/");
    
    if (parts.length != 5) { 
        return false;
    }   
    
    if (parts.filter(part => part != '').some(part => !part || part == '' || part == '-')) { 
        return false;
    }
    
    return true;
}