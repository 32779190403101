import config from "config";
import BartConfig from './../../../components/_LivePreview/Bart/BartConfig';
import GbiConfig from './../../../components/_LivePreview/Gbi/GbiConfig';
import InspectionConfig from './../../../components/_LivePreview/Inspection/InspectionConfig';
import UsInspectionConfig from './../../../components/_LivePreview/UsInspection/UsInspectionConfig';
import WindConfig from './../../../components/_LivePreview/Wind/WindConfig';


export default function getNameFromScrollTarget(scrollTargetId, formTemplateId, oldScrollTarget) {
    if (scrollTargetId.endsWith("]")) {
        return getBearingScrollTarget(scrollTargetId, oldScrollTarget);
    }

    const scrollTarget = getConfigId(scrollTargetId, formTemplateId);    

    if (scrollTarget === null) {
        return {
            scrollTarget: "",
            fieldTarget: "",
            oldScrollTarget: oldScrollTarget
        }
    }

    if (scrollTarget.fieldTarget == "reportAuthor") {
      return { ...scrollTarget, fieldTarget: "skfDetails.reportAuthor.personalInfo", oldScrollTarget: oldScrollTarget };
    }
    if (scrollTarget.fieldTarget == "coReportAuthor") {
      return { ...scrollTarget, fieldTarget: "skfDetails.coReportAuthor.personalInfo", oldScrollTarget: oldScrollTarget };
    }
    if (scrollTarget.fieldTarget == "coReportAuthorTwo") {
      return { ...scrollTarget, fieldTarget: "skfDetails.coReportAuthorTwo.personalInfo", oldScrollTarget: oldScrollTarget };
    }
    if (scrollTarget.fieldTarget == "coReportAuthorThree") {
      return { ...scrollTarget, fieldTarget: "skfDetails.coReportAuthorThree.personalInfo", oldScrollTarget: oldScrollTarget };
    }
    if (scrollTarget.fieldTarget == "coReportAuthorFour") {
      return { ...scrollTarget, fieldTarget: "skfDetails.coReportAuthorFour.personalInfo", oldScrollTarget: oldScrollTarget };
    }
    if(scrollTarget.scrollTarget == "summary.recommendationsOptions"){
      return { ...scrollTarget, fieldTarget: "summary.recommendations", oldScrollTarget: oldScrollTarget };
    }
    const lastTarget = getLastNameOfKey(scrollTarget.scrollTarget);
    const lastField = getLastNameOfKey(scrollTarget.fieldTarget);
    
    let count = 0;
    if (oldScrollTarget) {
        if (oldScrollTarget.scrollTarget == scrollTarget.scrollTarget) {
            count = oldScrollTarget.count + 1;;
        }
    }

    if (lastField === lastTarget) {
      return { ...scrollTarget, fieldTarget:scrollTarget.scrollTarget, oldScrollTarget: oldScrollTarget, count: count };
    } else {
      const parts = scrollTarget.scrollTarget.split(".");
        return {
            fieldTarget: parts.slice(0, parts.findIndex(p => p === lastField) + 1).join("."),
            scrollTarget: scrollTarget.scrollTarget,
            oldScrollTarget: oldScrollTarget,
            count: count
        };
    }
}

function getConfigId(id, formTemplateId) {
    const formattedId = formatIdForBearings(id);
    const config = resolveCurrentConfig(formTemplateId);
    const found = config?.find(c => formattedId.includes(c.key));
    if (formTemplateId == config?.GBI_TEMPLATE_ID) {
        if (id.includes("coReportAuthorTwo") || id.includes("coReportAuthorThree") || id.includes("coReportAuthorFour")) {
            return {
                scrollTarget: id,
                fieldTarget: id.split(".")[1]
            }
        }
    }
    if (found && isFunctionalAreaComponent(found?.key)) {
        return {
            scrollTarget: id,
            fieldTarget: id,
        }
    }
    if (found) {
        return {
            scrollTarget: id,
            fieldTarget: found.key
        }
    } else {
        return null;
    }
}

function isFunctionalAreaComponent(key) {
    return key === "assetDetails.assetTypeOrFunctionalAreaOrSystem.FunctionalAreaComponent"
}

function getBearingScrollTarget(scrollTargetId, oldScrollTarget) {
    return {
        scrollTarget: scrollTargetId,
        fieldTarget: scrollTargetId,
        oldScrollTarget: oldScrollTarget
    }
}

function formatIdForBearings(id) {
    return id
        .split(".")
        .map(p => p.includes("[") ? "" : p)
        .filter(p => p !== "")
        .join(".");
};

function resolveCurrentConfig(formTemplateId) {
    if (formTemplateId == config.BART_TEMPLATE_ID) {
        return BartConfig;
    } else if (formTemplateId == config.GBI_TEMPLATE_ID) {
        return GbiConfig;
    } else if (formTemplateId == config.INSPECTION_TEMPLATE_ID) {
        return InspectionConfig;
    } else if (formTemplateId == config.US_INSPECTION_TEMPLATE_ID) {
        return UsInspectionConfig;
    } else if ( formTemplateId == config.WIND_TEMPLATE_ID ) {
        return WindConfig;
    }
}

function getLastNameOfKey(key) {
    const parts = key.split(".");
    return parts[parts.length - 1];
}