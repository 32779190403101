import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import LinesEllipsis from 'react-lines-ellipsis'

const TableCellBasic = ({ cellValue, useTruncate, noWrapClass, isReportAuthor }) => (
  <td className="table__body-cell">
    <div
      className={classnames(
        'table__body-inset',
        { 'table__body-inset--no-wrap': noWrapClass },
        { 'table__body-inset--report_author': isReportAuthor }
      )}
    >
      {useTruncate ? <LinesEllipsis
        text={cellValue ? cellValue : ''}
        maxLine='2'
        ellipsis='...'
        trimRight
        basedOn='words'
      /> : cellValue}
    </div>
  </td>
)

TableCellBasic.propTypes = {
  cellValue: PropTypes.string,
  useTruncate: PropTypes.bool,
  noWrapClass: PropTypes.bool,
  isReportAuthor: PropTypes.bool,
}

export default TableCellBasic
