import React, { useState } from 'react';
import { getBearingData } from 'components/_LivePreview/Shared/GetAndRenderBearingDesignation';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setConvertToBDACaseId } from 'redux/actions/saveComponentValueAction';
import { isInternalUser, isUserAdmin } from 'components/formcomponents/utils/userUtils';
import { getBearingsForRemanAndReplacement } from 'components/formcomponents/utils/BearingUtils';
import { getCaseDataRepositoryWithoutOffline } from 'core/caseRepository/caseRepositoryFactory';
import { loadCaseAndData } from 'redux/thunks/caseThunks';
import usePreviewAnswers from 'customHooks/usePreviewAnswers';
import SvgButtonBarConvert from 'components/svg/ButtonBarConvert';
import ConvertToBDAModal from './ConvertToBDAModal';
import classnames from 'classnames';
import config from "../../../config";

const ButtonConvert = ({ buttonSize }) => {
  const [bearingsData, setBearingsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isAlreadyConverted, setIsAlreadyConverted] = useState(false);
  const [hasJustConverted, setHasJustConverted] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();
  const stateAnswers = usePreviewAnswers();
  const currentCaseDomain = useSelector(state => state.currentCase.case.domain, shallowEqual);
  const currentCase = useSelector(state => state.currentCase.case);
  const currentUser = useSelector(state => state.currentUser, shallowEqual);
  const connection = useSelector(state => state.connection);

  const getBearingsData = () => bearingsForRemanAndReplacement.reduce((acc, { index, value, name }) => {
    const { designation, manufacturer } = getBearingData(index, stateAnswers, false);

    if (!designation || !manufacturer) return acc;

    const { label } = JSON.parse(value);

    return [...acc, {
      index: index,
      name: name,
      designation: `${manufacturer} ${designation}`,
      recommendation: label,
      select: false,
    }];
  }, [])

  const handleOpenModal = () => {
    const realData = getBearingsData();
    setBearingsData(realData);
    setShowModal(true);
  }

  const handleCheckboxChange = index => setBearingsData(prevData =>
    prevData.map((item, i) => i === index ? { ...item, select: !item.select } : item)
  )

  const selectedBearings = bearingsData.filter(item => item.select);

  const bearingsForRemanAndReplacement = getBearingsForRemanAndReplacement(useSelector(state => state.saveComponentValue.components));

  const isCaseDraftOrApproved = currentCase.status === 'draft' || currentCase.status === 'approved';

  const isCaseInspectionOrNAM = (currentCaseDomain === 'Inspection' || currentCaseDomain === 'NAMInspection');

  const isUserCaseRelated = [currentCase.author, currentCase?.coauthor, currentCase?.approver].map(e => (e?.startsWith(';') || (e?.endsWith(';'))) ? e.replace(';', '') : e).includes(currentUser?.email);

  const isUserAuthorizedToConvert = isUserAdmin(currentUser['custom:administrator']) || isUserCaseRelated;

  const showConvertButton = isCaseInspectionOrNAM && isUserAuthorizedToConvert;

  if (!showConvertButton) return null;

  const handleConvert = async () => {
    if (!currentCaseDomain.includes('Inspection')) {
      alert('Only Inspection reports can be converted.');
      return;
    }
    setIsConverting(true);
    const repository = getCaseDataRepositoryWithoutOffline();

    try {
      const bearingsToMove = selectedBearings.map(bearing => bearing.index);

      const conversionResult = await repository.convertInspectionToBDA(
        currentCase.id,
        config.BART_TEMPLATE_ID,
        bearingsToMove
      );

      if (conversionResult.data?.convertedCaseId) {
        setHasJustConverted(true);
        dispatch(setConvertToBDACaseId(conversionResult.data.convertedCaseId));
      }
    } catch (e) {
      setIsError(true);
      console.log('There was an error converting to BDA.', e);
    } finally {
      setIsConverting(false);
      setIsAlreadyConverted(true);
      dispatch(loadCaseAndData(currentCase.id));
    }
  }

  const isDisabled = !isCaseDraftOrApproved || bearingsForRemanAndReplacement.length === 0 || !connection;

  return (
    <>
      <button
        id='buttonConvert'
        type='button'
        onClick={handleOpenModal}
        className={classnames('button', 'button--icon-only', { 'button--icon-disabled': isDisabled })}
        disabled={isDisabled}
      >
        <SvgButtonBarConvert size={buttonSize} title='Convert to BDA' />
      </button>
      {showModal && (
        <ConvertToBDAModal
          showModal={showModal}
          setShowModal={setShowModal}
          isConverting={isConverting}
          bearingsData={bearingsData}
          handleCheckboxChange={handleCheckboxChange}
          selectedBearingsCount={selectedBearings.length}
          handleConvert={handleConvert}
          setBearingsData={setBearingsData}
          isAlreadyConverted={isAlreadyConverted}
          setIsAlreadyConverted={setIsAlreadyConverted}
          hasJustConverted={hasJustConverted}
          setHasJustConverted={setHasJustConverted}
          correspondingBDAId={currentCase?.convertedBDAID}
          error={isError}
          setError={setIsError}
        />
      )}
    </>
  );
}

export default ButtonConvert;
