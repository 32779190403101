import React from "react";

const SvgButtonBarSave = ({ title, size = "26px" }) => (  
    <svg width={ size } height={ size } viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{title}</title>
        <g id="Icons/icon_save" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon_save" fill="#FFFFFF">
                <path d="M8.13953488,0 L6.86225403,0 C6.38640429,0 6,0.282597266 6,0.632229232 L6,4.36645636 C6,4.71608833 6.38640429,5 6.86225403,5 L8.13953488,5 C8.61538462,5 9,4.71608833 9,4.36645636 L9,0.632229232 C9,0.282597266 8.61538462,0 8.13953488,0" id="Fill-1"></path>
                <path d="M20,1.09409963 C20,0.489930515 19.5100406,0 18.9070137,0 L15.273541,0 C15.2970968,0.0777293605 15.3182969,0.157814156 15.3182969,0.24378754 L15.3182969,5.86503356 C15.3182969,6.31138853 14.986161,6.67294783 14.5751134,6.67294783 L5.42606442,6.67294783 C5.01501678,6.67294783 4.68170308,6.31138853 4.68170308,5.86503356 L4.68170308,0.24378754 C4.68170308,0.157814156 4.70408103,0.0777293605 4.72763677,0 L4.37547848,0 C3.93380837,0 3.51451622,0.199034272 3.23655851,0.541750088 L0.329780343,4.10434578 C0.123667629,4.35873278 0.00942229551,4.67436109 0.00942229551,5.00058886 L0,18.6997998 C0,19.4182075 0.581826748,20 1.30027678,20 L2.48041929,20 L2.48041929,9.41114121 L17.656204,9.41114121 L17.656204,20 L18.590189,20 C19.3687062,20 20,19.3687434 20,18.5902721 L20,1.09409963 Z" id="Fill-3"></path>
                <polygon id="Fill-6" points="4 20 16 20 16 11 4 11"></polygon>
            </g>
        </g>
    </svg>
);

export default SvgButtonBarSave;
