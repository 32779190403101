import React from "react";

import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import ImageRow from "components/_LivePreview/Shared/ImageRow";
import BearingTable from 'components/_LivePreview/Shared/BearingTable'
import PersonCell from "./Cells/PersonCell";
import LabelListCell from "./Cells/LabelListCell";
import ReportDateInspectionDate from './Cells/ReportDateInspectionDate';
import TestEquipmentTable from './Cells/TestEquipmentTable';

export const cellComponents = {
    labellist:LabelListCell,
    person: PersonCell,
    'reportdate-inspectiondate': ReportDateInspectionDate,
    'equipmentTable': TestEquipmentTable
}

const NewKeyValueTable = (props) => {
    const intl = useIntl()
    const scrollTarget = useSelector(state => state.saveComponentValue.scrollTarget)
    const caseWasSaved = useSelector(state => state.saveComponentValue.caseWasSaved)

    let data = props.data ? props.data : {}
    
    const hasScrollTarget = key => {
        if (scrollTarget) {
            if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
                return (scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key)) && !caseWasSaved
            }
        }
        return false;
    }

    const renderHeader = () => {
        if (props.header) {
            for (let c of props.config) {
                if (data[c.key]) {
                    if (data[c.key].imageUrls) {
                        if (data[c.key].imageUrls.length > 0) {
                            return <div className="live-preview-key-value__header">{props.header}</div>
                        }
                    } else {
                        return <div className="live-preview-key-value__header">{props.header}</div>
                    }
                } else if (hasScrollTarget(c.key)) {
                    return <div className="live-preview-key-value__header">{props.header}</div>
                }
            }
            if (props.bearingTableObject) {
                if (props.bearingTableObject.isVisible) {
                    return <div className="live-preview-key-value__header">{props.header}</div>
                }
            }
        } else {
            return null;
        }
    }  

    const renderItem = item => {
        if (item.config.cellType) {
            const Component = cellComponents[item.config.cellType];
            if (Component) {
                if (item.config.isContainer) {
                    return <Component config={item.config} data={item.data} />;
                } else { 
                    return <div className="live-preview-key-value__value">
                    <Component config={item.config} data={item.data} />
                </div>
                }
                
            }
        }
        return <div className="live-preview-key-value__value" dangerouslySetInnerHTML={{ __html: item.data }}></div>
    }


    const renderTextRow = (cell, index) => {
        if (cell.length === 1 && cell[0].config.isContainer) {
            return renderItem(cell[0]);
        } else {
            return (
                <div className="live-preview-key-value__row" key={index}>
                    {cell.map((item) => {
                        if (item.data || hasScrollTarget(item.config.key)) {
                            return (
                                <div data-key-id={item.config.key} key={item.config.key} className="live-preview-key-value__cell">
                                    <div className="live-preview-key-value__key">
                                        {item.config.key ? intl.formatMessage({ id: item.config.key.split('.').pop() }) : ""}
                                    </div>
                                    {renderItem(item)}
                                </div>
                            )
                        }
                    })}
                </div>
            )
        }
    }

    const createLivePreviewData = () => {
        var livePreviewData = []
        const configs = props.config
        var rowCells = []
        var numberOfCellsInRow = 2

        configs && configs.map((config) => {
            if (config.isFullWidth) {
                if (data[config.key] || hasScrollTarget(config.key)) {
                    if (rowCells.length > 0) {
                        livePreviewData.push(rowCells)
                        rowCells = []
                    }
                    rowCells.push({ data: data[config.key] ? data[config.key] : "", config: config })
                    livePreviewData.push(rowCells)
                    rowCells = [] 
                }
            } else if (config.textKey) {
                if (data[config.key]) {
                    if (data[config.key].imageUrls.length > 0) {
                        if (rowCells.length > 0) {
                            livePreviewData.push(rowCells)
                            rowCells = []
                        }
                        rowCells.push({imageData: data[config.key] ? data[config.key] : {}, key: config.key, textKey: config.textKey})
                        livePreviewData.push(rowCells)
                        rowCells = []
                    } else if (hasScrollTarget(config.key) || hasScrollTarget(config.textKey) || data[config.textKey]) {
                        if (rowCells.length > 0) {
                            livePreviewData.push(rowCells)
                            rowCells = []
                        }
                        rowCells.push({imageData: data[config.key] ? data[config.key] : {}, key: config.key, textKey: config.textKey})
                        livePreviewData.push(rowCells)
                        rowCells = []
                    }
                } else if (hasScrollTarget(config.key) || hasScrollTarget(config.textKey) || data[config.textKey]) {
                    if (rowCells.length > 0) {
                        livePreviewData.push(rowCells)
                        rowCells = []
                    }
                    rowCells.push({imageData: data[config.key] ? data[config.key] : {}, key: config.key, textKey: config.textKey})
                    livePreviewData.push(rowCells)
                    rowCells = []
                }
            } else if (!config.imageKey) {
                if (data[config.key] || hasScrollTarget(config.key)) {
                    rowCells.push({ data: data[config.key] ? data[config.key] : "", config: config })
                    if (rowCells.length === numberOfCellsInRow) {
                        livePreviewData.push(rowCells)
                        rowCells = []
                    } 
                }  
            }

        })
        if (rowCells.length > 0) {
            livePreviewData.push(rowCells)
            rowCells = []
        }
        return livePreviewData
    }   

    return (
        <div>
            <div className="live-preview-report-information">
                <div className="live-preview-key-value" data-section-id={props.sectionId}>
                    {renderHeader()}
                    {createLivePreviewData().map((cell, index) => {
                        if (cell[0].imageData || cell[0].textKey) {
                            return (
                                <ImageRow key={index}
                                    data={props.data ? props.data : {}}
                                    figureKey={cell[0].key}
                                    figureTextKey={cell[0].textKey}
                                />
                            )
                        }
                        return renderTextRow(cell, index)
                    })}
                    {props.bearingTableObject && props.bearingTableObject.isVisible && (
                        <div className="live-preview-key-value__row">
                            <BearingTable
                                data={props.bearingTableObject.data}
                                numberOfBearings={props.bearingTableObject.data["bearingInvestigations.count"]}
                                isGBI={props.bearingTableObject.isGBI}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NewKeyValueTable