import React, { useCallback, useEffect, useState } from "react";
import FormRenderer from "./FormRenderer";
import LivePreview from "./LivePreview";
import ScrollOnFocus from "./ScrollOnFocus";
import { MEDIUM } from "core/ui/breakpoints";
import classnames from "classnames";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { loadCaseAndData, resetLoadedCase } from "redux/thunks/caseThunks";
import ReactModal from "react-modal";
import BaseButton from "components/common/BaseButton";
import repository from 'core/repository';
import { pageSize } from 'components/common/Pagination';

import { saveAnswers } from "redux/thunks/answerThunk";
import { saveCase } from "redux/thunks/caseThunks";
import { useIntl } from "react-intl";

const Case = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [userHasAccess, setUserHasAccess] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const currentCaseExist = useSelector((state) => state.currentCase.case, shallowEqual);
  const currentCaseIsNotFoundError = useSelector((state) => state.currentCase.case ? state.currentCase.case.isNotFoundError : false, shallowEqual);
  const currentCaseIsFormtemplateId = useSelector((state) => state.currentCase.case ? state.currentCase.case.formtemplateId : null, shallowEqual);
  const currentCase = useSelector((state) => state.currentCase.case ? state.currentCase.case : null, shallowEqual);
  const currentUser = useSelector(state => state.currentUser);
  const activeTab = useSelector((state) => state.activeTab, shallowEqual);

  useEffect(() => {
    dispatch(loadCaseAndData(id));
  }, [id]);

  useEffect(() => {
    if (currentCase && currentCaseExist) {
      setIsLoading(true)

      let params = { limit: pageSize, order: '-updated' }
      params = {
        ...params,
        q: id
      }

      repository.getCaseSearch(params)
        .then(response => {
          if (response.data.total < 1) {
            if (currentUser?.email === currentCase?.author) {
              setUserHasAccess(true);
            } else if (currentCase.domain === 'NAMInspection' && currentUser && currentCase?.customer) {
              const currentCaseCustomer = currentCase.customer;
              const currentUserCompany = currentUser['custom:usercompany'];
              const isNAMUser = currentUser['custom:region']?.toUpperCase() === 'NAM';
              const hasAccess = currentCaseCustomer?.toLowerCase().includes(currentUserCompany?.toLowerCase()) && isNAMUser;
              setUserHasAccess(hasAccess);
            } else {
              setUserHasAccess(false);
            }
          } else {
            setUserHasAccess(true);
          }
        })
        .catch(error => {
          console.error('Could not fetch reports succesfully', error)
        })
        .finally(() => setIsLoading(false));
    }
  }, [currentCase?.id]);

  const getViewClassName = () => {
    if (activeTab === 2) {
      //Forms view 
      return "page-layout__form-wide"
    }
    else if (activeTab === 1) {
      //Wide view 
      return "page-layout__preview-wide"
    } else {
      //Normal view
      return "";
    }
  }

  const isMedium = () => {
    const mq = window.matchMedia(MEDIUM);
    return mq.matches;
  }

  const closeNotFound = () => {
    dispatch(resetLoadedCase(() => {
      history.go(-1);
    }));
  }

  const goToHomePage = () => {
    history.push("/dashboard")
  }

  const getNotFoundModal = () => {
    return <ReactModal
      isOpen={true}
      className="react-modal approveai-container"
      overlayClassName="react-modal-overlay">
      <div className="react-modal__header">

      </div>
      <div className="react-modal__body update-modal__body">
        <h2>{intl.formatMessage({ id: 'reportNotFound' }, { id })}</h2>
      </div>
      <div className="react-modal__footer">
        <BaseButton handleSubmit={() => closeNotFound()} text={intl.formatMessage({ id: 'formRenderer.close' })} />
      </div>
    </ReactModal>
  };

  const GetNotAuthorizedModal = () => {
    return <ReactModal
      isOpen={!userHasAccess}
      className="react-modal approveai-container"
      overlayClassName="react-modal-overlay">
      <div className="react-modal__header" style={{ justifyContent: "center" }}>
        <h2 className="react-modal__text">{intl.formatMessage({ id: 'error' })}</h2>
      </div>
      <div className="react-modal__body update-modal__body">
        <h2>{intl.formatMessage({ id: 'autorizationError' }, { id })}</h2>
      </div>
      <div className="react-modal__footer">
        <BaseButton handleSubmit={() => goToHomePage()} text={intl.formatMessage({ id: 'goBack' })} />
      </div>
    </ReactModal>
  };

  if (!userHasAccess) {
    return <GetNotAuthorizedModal />
  }

  if (currentCaseExist && currentCaseIsNotFoundError) {
    return getNotFoundModal()
  }

  if (!isLoading && currentCaseExist) {
    return (
      <div className="page-layout__container page-layout__container--live-preview">
        <div className="page-layout__main-container">
          <div className={classnames("page-layout__main-container-content", getViewClassName())}>
            <div className={classnames("page-layout__filter page-layout__filter--live-preview", "page-layout__toggle-view")}>
              <FormRenderer />
            </div>
            <LivePreview
              classNames={classnames({
                "page-layout__toggle-view":
                  activeTab === 1 || isMedium(),
              })}
            />
            {currentCaseIsFormtemplateId && <ScrollOnFocus currentFormTemplateID={currentCaseIsFormtemplateId} />}
          </div>
        </div>
        <AnswerSaver />
        <CaseError />
        <CaseSaverQueue />
      </div>
    )
  }

  return null
}


const CaseError = () => {
  const currentCaseIsError = useSelector((state) => state.currentCase.case.isError, shallowEqual);
  const currentCaseError = useSelector((state) => state.currentCase.case.error, shallowEqual);
  const intl = useIntl();

  const [showModel, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");

  useEffect(() => {
    if (currentCaseIsError) {
      setShowModal(true);
      if (currentCaseError.errorType === "ConcurrencyError") {
        setModalError(intl.formatMessage({ id: 'saveError.reportConcurrency' }));
      } else {
        setModalError(intl.formatMessage({ id: 'saveError.reportUnknown' }));
      }
    }
  }, [currentCaseIsError, currentCaseError])

  const onReload = () => {
    window.location.reload();
  }

  return <ReactModal
    isOpen={showModel}
    className="react-modal approveai-container"
    overlayClassName="react-modal-overlay">
    <div className="react-modal__header">

    </div>
    <div className="react-modal__body update-modal__body">
      <h2>{intl.formatMessage({ id: 'saveError.reportHeader' })}</h2>
      <p>{modalError}</p>
    </div>
    <div className="react-modal__footer">
      <BaseButton handleSubmit={() => onReload()} text="Reload" />
    </div>
  </ReactModal>
}

const CaseSaverQueue = () => {
  const casesToSave = useSelector(state => state.currentCase.updates, shallowEqual);
  const dispatch = useDispatch();
  const [running, setRunning] = useState(false);

  const saveToDatabase = useCallback(() => {
    const caseToSave = [...casesToSave].shift();
    setRunning(true);
    dispatch(saveCase(caseToSave, () => {
      setRunning(false);
    }))
  })
  useEffect(() => {
    if (casesToSave.length > 0 && !running) {
      saveToDatabase();
    }
  }, [casesToSave, running]);

  return null;
}

const AnswerSaver = () => {

  const componentsToSave = useSelector(state => state.saveComponentValue.componentsToSave);
  const currentCaseId = useSelector((state) => state.currentCase.case.id, shallowEqual);
  const connection = useSelector((state) => state.connection, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (connection) {

      const toSave = componentsToSave.filter(a => a.saveNow);
      if (toSave.length > 0) {
        dispatch(saveAnswers(currentCaseId, toSave.map(a => a.answer)));
      }

      const interval = setInterval(() => {
        const oneMinuteAgo = new Date(new Date().getTime() - 60 * 1000);
        const isAllDataOld = componentsToSave.every(a => {
          return Date.parse(a.touched) < oneMinuteAgo.getTime()
        });
        if (isAllDataOld && componentsToSave.length > 0) {
          dispatch(saveAnswers(currentCaseId, componentsToSave.map(a => a.answer)));
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      }
    } else {
      const interval = setInterval(() => {
        dispatch(saveAnswers(currentCaseId, componentsToSave.map(a => a.answer)));
      }, 1000);
      return () => {
        clearInterval(interval);
      }
    }
  }, [componentsToSave, connection]);

  return null;
};

export default Case;
