import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import ReactModal from 'react-modal'
import BaseButton from 'components/common/BaseButton'
import LicenseAgreementText from 'components/_LicenseAgreement/LicenseAgreementText'

const LicenseAgreementModal = ({ showModal }) => {
  const [isOpen, setIsOpen] = useState(showModal)
  const [isRejected, setIsRejected] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function handleAccept() {
    try {
      setIsLoading(true)
      const user = await Auth.currentAuthenticatedUser()
      const updateUserResponse = await Auth.updateUserAttributes(user, { 'custom:LA_accepted': 'true' })
      if (updateUserResponse !== 'SUCCESS') {
        setHasError(true)
        return
      }
      setIsOpen(false)
    } catch (e) {
      setHasError(true)
      console.log('Error updating user attribute', e)
    } finally {
      setIsLoading(false)
    }
  }

  const renderLADialog = () => (
    <>
      <LicenseAgreementText renderedOn='modal' />
      <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
        <BaseButton
          handleSubmit={handleAccept}
          text='I Accept'
          backgroundColor='green'
          className={isLoading ? 'button--wide button--disabled' : 'button--wide'}
        />
        <BaseButton
          handleSubmit={() => setIsRejected(true)}
          text='Decline'
          backgroundColor='red'
          className={isLoading ? 'button--wide button--disabled' : 'button--wide'}
        />
      </div>
    </>
  )

  const renderRejectedLADialog = () => (
    <>
      <h3 style={{ marginTop: '0' }}>
        You cannot access BART without reading and accepting the License Agreement.
      </h3>
      <BaseButton
        handleSubmit={() => setIsRejected(false)}
        text='I understand'
        backgroundColor='green'
        className='button--wide'
      />
    </>
  )

  const renderErrorDialog = () => (
    <>
      <h3 style={{ marginTop: '0' }}>
        There was an error updating your account. Please try again later.
      </h3>
      <BaseButton
        handleSubmit={() => setHasError(false)}
        text='Close'
        backgroundColor='green'
        className='button--wide'
      />
    </>
  )

  const className = (isRejected || hasError) ? 'modal__license-agreement__rejected' : 'modal__license-agreement'

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName='react-modal-overlay'
      className={className}
    >
      <div className='react-modal__body'>
        <div className="modal-license_agreement_body">
          {hasError && isOpen && renderErrorDialog()}
          {isRejected && !hasError && isOpen && renderRejectedLADialog()}
          {!isRejected && !hasError && isOpen && renderLADialog()}
        </div>
      </div>
    </ReactModal>
  )
}

export default LicenseAgreementModal
