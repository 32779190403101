import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

const usePreviewAnswers = () => {
  const answers = useSelector(state => state.saveComponentValue.components, shallowEqual);
  const answersToSave = useSelector(state => state.saveComponentValue.componentsToSave, shallowEqual);

  const [previewAnswers, setPreviewAnswers] = useState({});

  useEffect(() => {
    const convertedAnswers = answers.reduce((acc, answer) => {
      try {
        const answerToSave = answersToSave.find(a => a.answer.name === answer.name);
        if (answerToSave && answerToSave.answer.value) {
          acc[answer.name] = JSON.parse(answerToSave.answer.value);
        } else {
          if (answer.value) {
            acc[answer.name] = JSON.parse(answer.value);
          }
        }
      } catch (error) {
        console.log(answer);
        console.log(error);
      }
      return acc;
    }, {});

    answersToSave.forEach(answerToSave => {
      const existingAnswer = Object.keys(convertedAnswers).find(key => key === answerToSave.answer.name);
      if (!existingAnswer) {
        if (answerToSave.answer.value) {
          convertedAnswers[answerToSave.answer.name] = JSON.parse(answerToSave.answer.value);
        }
      }
    });

    setPreviewAnswers(convertedAnswers);
  }, [answers, answersToSave]);

  return previewAnswers;
};

export default usePreviewAnswers;
