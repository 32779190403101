import React, { Component } from "react";
import httpService from "core/http-service";
import Select from "react-select";
import classnames from "classnames";
import _ from "lodash";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

class SectorSegmentIndustryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSectorLoading: false,
      isSegmentLoading: false,
      isIndustryLoading: false,
      sectors: [],
      segments: [],
      industries: [],
      sectorValue: "",
      segmentValue: "",
      industryValue: "",
      sectorDescription: "",
      segmentDescription: "",
      industryDescription: ""
    };

    this.codesArray = ["-", "-", "-"]; // Array containing the codes for Sector, Segment and Industry-dropdown.
    this.sectorsData = []; // Array containing the data from API to populate sectors dropdown.
    this.segmentsData = []; // Array containing the data from API to populate segments dropdown.
    this.industryData = []; // Array containing the data from API to populate industries dropdown.
  }

  getAPIRequest = (apiPath, name, params) => {
    switch (name) {
      case "sector":
        this.setState({
          isSectorLoading: true,
          isSegmentLoading: true,
          isIndustryLoading: true
        });
        break;
      case "segment":
        this.setState({ isSegmentLoading: true, isIndustryLoading: true });
        break;
      case "industry":
        this.setState({ isIndustryLoading: true });
        break;
      default:
        return null;
    }
    if (apiPath !== undefined) {
      return httpService
        .get(apiPath, params)
        .then(response => {
          switch (name) {
            case "sector":
              this.setState({
                isSectorLoading: false,
                isSegmentLoading: false,
                isIndustryLoading: false
              });
              break;
            case "segment":
              this.setState({
                isSegmentLoading: false,
                isIndustryLoading: false
              });
              break;
            case "industry":
              this.setState({ isIndustryLoading: false });
              break;
            default:
              return null;
          }
          return response;
        })
        .catch(error => {
          switch (name) {
            case "sector":
              this.setState({
                isSectorLoading: false,
                isSegmentLoading: false,
                isIndustryLoading: false
              });
              break;
            case "segment":
              this.setState({
                isSectorLoading: false,
                isSegmentLoading: false,
                isIndustryLoading: false
              });
              break;
            case "industry":
              this.setState({
                isSectorLoading: false,
                isSegmentLoading: false,
                isIndustryLoading: false
              });
              break;
            default:
              return null;
          }
          return error;
        });
    }
  };

  componentDidMount() {
    let preDefinedValues = this.findValueInArray(
      this.props.formComponentData.args,
      "preDefinedValues"
    );

    let apiPath = this.findValueInArray(
      this.props.formComponentData.args,
      "apiPath"
    );
    let valueLabelParams = this.findValueInArray(
      this.props.formComponentData.args,
      "valueLabelParams"
    );

    apiPath = apiPath.apiPath;
    valueLabelParams = valueLabelParams.valueLabelParams;

    this.getAPIRequest(apiPath, "sector", { "lang": this.props.language }).then(response => {
      if (response.data !== undefined) {
        let sectorOptions = this.mapResponseData(
          valueLabelParams.value,
          valueLabelParams.label,
          response.data
        );
        this.sectorsData = response.data;
        this.setState({
          sectors: sectorOptions
        });

        this.codesArray = this.getCodesFromPath(this.props.parentSetValue);

        if (preDefinedValues !== undefined) {
          this.codesArray[0] = preDefinedValues.preDefinedValues.sectorValue;
        }

        if (this.codesArray !== null) {
          const sectorId = this.getObjectByCodeArray(this.sectorsData, 0);
          this.setState({ currentPath: this.props.parentSetValue });

          if (sectorId !== null && sectorId !== undefined) {
            this.handleChange(sectorId.id, "sector", sectorId.description);
          }
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.parentSetValue, this.props.parentSetValue)) {
      if (
        this.props.parentSetValue !== undefined &&
        this.props.parentSetValue !== ""
      ) {
        this.codesArray = this.getCodesFromPath(this.props.parentSetValue);
        const sectorId = this.getObjectByCodeArray(this.sectorsData, 0);
        this.setState({ currentPath: this.props.parentSetValue });
        if (sectorId !== null && sectorId !== undefined) {
          this.handleChange(sectorId.id, "sector", sectorId.description);
        }
      }
    }
  }

  findValueInArray = (array, value) => {
    if (array !== undefined && value !== undefined) {
      return _.find(array, value);
    }
  };

  getObjectByCodeArray = (array, codeIndex) => {
    if (array !== undefined && codeIndex !== undefined) {
      return array.find(obj => {
        return obj.code === this.codesArray[codeIndex];
      });
    } else {
      return null;
    }
  };

  getObjectById = (array, id) => {
    if (array !== undefined && id !== undefined) {
      return array.find(obj => {
        return obj.id === id;
      });
    } else {
      return null;
    }
  };

  /*
   * Generates a path used by the Sector-, segment- and industries-dropdowns. Ex ("X/Y/Z"). X = Sectorcode, Y = Segmentcode and Z = industrycode.
   */
  generateCurrentPath = (index, code) => {
    let tempCodeArray = this.codesArray;
    tempCodeArray[index] = code;
    let tempPath = tempCodeArray.join("/");
    tempPath = "/" + tempPath + "/";

    this.setState({
      currentPath: tempPath
    });
    return tempPath;
  };

  handleChange = (eventValue, name, description, skipSave) => {
    let valueName = name.concat("Value");
    let valueDecription = name.concat("Description");

    this.setState({
      [valueName]: eventValue,
      [valueDecription]: description
    });

    let objectToSave = {
      sector: this.state.sectorDescription,
      segment: this.state.segmentDescription,
      industry: this.state.industryDescription,
      customPath: this.state.currentPath
    };
    let tempCode;
    let tempPath;

    switch (name) {
      case "sector":
        tempCode = this.getObjectById(this.sectorsData, eventValue);
        if (tempCode !== undefined) {
          tempPath = this.generateCurrentPath(0, tempCode.code);
        } else {
          tempPath = this.generateCurrentPath(0, "-");
        }

        objectToSave.customPath = tempPath;

        if (eventValue !== null && description !== null) {
          this.getAPIRequest("lists/taxonomy/segments", "segment", {
            selected: eventValue,
            lang: this.props.language
          }).then(response => {
            if (response.data !== undefined) {
              let result = this.mapResponseData(
                "id",
                "description",
                response.data
              );

              const segmentId = this.getObjectByCodeArray(response.data, 1);
              this.segmentsData = response.data;
              if (segmentId !== null && segmentId !== undefined) {
                this.handleChange(
                  segmentId.id,
                  "segment",
                  segmentId.description
                );
              } else {
                this.handleChange(eventValue, "segment");
              }
              this.setState({ segments: result });
            }
          });
        } else {
          this.setState({ segments: [], industries: [] });
        }
        break;
      case "segment":
        tempCode = this.getObjectById(this.segmentsData, eventValue);
        if (tempCode !== undefined) {
          tempPath = this.generateCurrentPath(1, tempCode.code);
        } else {
          tempPath = this.generateCurrentPath(1, "-");
        }
        objectToSave.customPath = tempPath;

        if (eventValue !== null && description !== null) {
          this.getAPIRequest("lists/taxonomy/industries", "industry", {
            selected: eventValue,
            lang: this.props.language
          }).then(response => {
            if (response.data !== undefined) {
              let result = this.mapResponseData(
                "id",
                "description",
                response.data
              );
              const industryId = this.getObjectByCodeArray(response.data, 2);
              this.industryData = response.data;
              if (industryId !== null && industryId !== undefined) {
                this.handleChange(
                  industryId.id,
                  "industry",
                  industryId.description,
                  true
                );
              } else {
                this.handleChange(eventValue, "industry", undefined, true);
              }
              this.setState({ industries: result });
            }
          });
        } else {
          this.setState({ industries: [] });
        }
        break;
      case "industry":
        if (eventValue !== null && description !== null) {
          tempCode = this.getObjectById(this.industryData, eventValue);
          if (tempCode !== undefined) {
            tempPath = this.generateCurrentPath(2, tempCode.code);
          } else {
            tempPath = this.generateCurrentPath(2, "-");
          }
          objectToSave.customPath = tempPath;
          if (!skipSave) { 
            this.props.parentHandleSave(objectToSave, this.props.myName);
          }
          
        }
        break;
      default:
        return null;
    }
  };

  mapResponseData = (value, label, array) => {
    if (value !== undefined && label !== undefined && array !== undefined) {
      return array.map(option => {
        return {
          value: option[value],
          label: option.code + ": " + option[label]
        };
      });
    }
  };

  /**
   * Takes a customerPath or companyPath and makes into a "X/Y/Z"-string.
   */
  getCodesFromPath = path => {
    if (path !== undefined && path !== "") {
      let correctPath = path.split("/");
      return _.compact(correctPath);
    } else return ["-", "-", "-"];
  };

  /**
   * // Helper function for the react-select dropdown to get current value.
   */
  getCurrentValue = (value, options, isLoading) => {
    if (!isLoading) {
      let currentValue = options.filter(option => option.value === value);
      return currentValue;
    } else if (isLoading) {
      return {
        value: this.props.intl.formatMessage({
          id: "customComponents.loading"
        }),
        label: this.props.intl.formatMessage({ id: "customComponents.loading" })
      };
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className={classnames("input-shell", {"invalid": !this.props.isvalid})}>
          <label className="input-shell__label input-shell__label--dark input-shell__label--required">
            <FormattedMessage id="customComponents.sector" />
          </label>

          <div className="input-shell__container">
            <Select
              onChange={event => {
                if (event !== undefined && event !== null) {
                  this.handleChange(event.value, "sector", event.label);
                } else {
                  this.handleChange(null, "sector", null);
                }
              }}
              value={this.getCurrentValue(
                this.state.sectorValue,
                this.state.sectors,
                this.state.isLoading
              )}
              className="select__element"
              options={this.state.sectors}
              isDisabled={this.state.isSectorLoading || !this.props.connection}
              placeholder={
                this.props.connection
                  ? this.state.isSectorLoading
                    ? this.props.intl.formatMessage({
                        id: "customComponents.loading"
                      })
                    : this.props.intl.formatMessage({
                        id: "customComponents.select"
                      })
                  : this.props.intl.formatMessage({
                      id: "customComponents.disabled"
                    })
              }
              styles={ReactSelectStyles}
              isClearable={true}
            />
            <div className="input-shell__message">
              <div className="input-shell__message-hint">
                {this.props.intl.formatMessage({ id: 'formComponents.required' })}  
              </div>
            </div>
          </div>
        </div>

        <div className={classnames("input-shell", {"invalid": !this.props.isvalid})}>
          <label className="input-shell__label input-shell__label--dark input-shell__label--required">
            <FormattedMessage id="customComponents.segment" />
          </label>

          <div className="input-shell__container">
            <Select
              onChange={event => {
                if (event !== undefined && event !== null) {
                  this.handleChange(event.value, "segment", event.label);
                } else {
                  this.handleChange(null, "segment", null);
                }
              }}
              value={this.getCurrentValue(
                this.state.segmentValue,
                this.state.segments,
                this.state.isLoading
              )}
              className="select__element"
              options={this.state.segments}
              isDisabled={this.state.isSegmentLoading || !this.props.connection}
              placeholder={
                this.props.connection
                  ? this.state.isSegmentLoading
                    ? this.props.intl.formatMessage({
                        id: "customComponents.loading"
                      })
                    : this.props.intl.formatMessage({
                        id: "customComponents.select"
                      })
                  : this.props.intl.formatMessage({
                      id: "customComponents.disabled"
                    })
              }
              styles={ReactSelectStyles}
              isClearable={true}
            />
            <div className="input-shell__message">
              <div className="input-shell__message-hint">
                {this.props.intl.formatMessage({ id: 'formComponents.required' })}  
              </div>
            </div>
          </div>
          <div className="input-shell__message">
            <div className="input-shell__message-hint">
              - {"B (OEM) / U (End user) / D (Distributor)"}
            </div>
          </div>
        </div>

        <div className={classnames("input-shell", {"invalid": !this.props.isvalid})}>
          <label className="input-shell__label input-shell__label--dark input-shell__label--required">
            <FormattedMessage id="customComponents.industry" />
          </label>

          <div className="input-shell__container">
            <Select
              onChange={event => {
                if (event !== undefined && event !== null) {
                  this.handleChange(event.value, "industry", event.label);
                } else {
                  this.handleChange(null, "industry", null);
                }
              }}
              value={this.getCurrentValue(
                this.state.industryValue,
                this.state.industries,
                this.state.isLoading
              )}
              options={this.state.industries}
              className="select__element"
              isDisabled={
                this.state.isIndustryLoading || !this.props.connection
              }
              placeholder={
                this.props.connection
                  ? this.state.isIndustryLoading
                    ? this.props.intl.formatMessage({
                        id: "customComponents.loading"
                      })
                    : this.props.intl.formatMessage({
                        id: "customComponents.select"
                      })
                  : this.props.intl.formatMessage({
                      id: "customComponents.disabled"
                    })
              }
              styles={ReactSelectStyles}
              isClearable={true}
            />
            <div className="input-shell__message">
              <div className="input-shell__message-hint">
                {this.props.intl.formatMessage({ id: 'formComponents.required' })}  
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    connection: state.connection,
    language: state.language
  };
}
export default injectIntl(
  connect(mapStateToProps)(SectorSegmentIndustryComponent)
);
