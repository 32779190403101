import React from "react";

const SvgDownloadPdf = (props) => (

    <svg width="26px" height="26px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
        <title>{props.title}</title>
        <g id="Icons/icon_pdf" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icons/Actions/PDF" fill={ props.color }>
                <path d="M18,2 C19.1,2 20,2.9 20,4 L20,4 L20,16 C20,17.1 19.1,18 18,18 L18,18 L2,18 C0.9,18 0,17.1 0,16 L0,16 L0,4 C0,2.9 0.9,2 2,2 L2,2 L18,2 Z M5.38461538,11.5 L2.5,11.5 L2.5,16.5 L4.23076923,16.5 L4.23076923,14.8333333 L5.38461538,14.8333333 C6.34230769,14.8333333 7.11538462,14.275 7.11538462,13.5833333 L7.11538462,13.5833333 L7.11538462,12.75 C7.11538462,12.0583333 6.34230769,11.5 5.38461538,11.5 L5.38461538,11.5 Z M11.1538462,11.5 L8.26923077,11.5 L8.26923077,16.5 L11.1538462,16.5 C12.1115385,16.5 12.8846154,15.9416667 12.8846154,15.25 L12.8846154,15.25 L12.8846154,12.75 C12.8846154,12.0583333 12.1115385,11.5 11.1538462,11.5 L11.1538462,11.5 Z M17.5,11.5 L14.0384615,11.5 L14.0384615,16.5 L15.7692308,16.5 L15.7692308,14.8333333 L17.5,14.8333333 L17.5,13.5833333 L15.7692308,13.5833333 L15.7692308,12.75 L17.5,12.75 L17.5,11.5 Z M11.1538462,12.75 L11.1538462,15.25 L10,15.25 L10,12.75 L11.1538462,12.75 Z M5.38461538,12.75 L5.38461538,13.5833333 L4.23076923,13.5833333 L4.23076923,12.75 L5.38461538,12.75 Z M2.88125,4.12940479 C2.275625,3.77940479 1.5,4.17940479 1.5,4.84940479 C1.5,5.13940479 1.659375,5.40940479 1.925,5.56940479 L1.925,5.56940479 L8.87375,9.65940479 C9.564375,10.0694048 10.435625,10.0694048 11.12625,9.65940479 L11.12625,9.65940479 L18.075,5.56940479 C18.340625,5.40940479 18.5,5.13940479 18.5,4.84940479 C18.5,4.17940479 17.724375,3.77940479 17.11875,4.12940479 L17.11875,4.12940479 L10,8.31940479 L2.88125,4.12940479 Z" id="Combined-Shape"></path>
            </g>
        </g>
    </svg>
);

export default SvgDownloadPdf;
