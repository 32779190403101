import config from "config";
import { setCurrentCaseStatus } from "redux/actions/currentCaseActions";
import { isIOS, isIPad13, isMobileSafari } from "react-device-detect";
import { rejectCase as rejectCaseThunk } from "redux/thunks/caseThunks";

export default function rejectCase(caseId, answers, currentFormTemplateID, intl, dispatchF) {
    return new Promise((resolve, reject) => {
        const authorAnswer = getAuthor(answers, currentFormTemplateID);
        if (authorAnswer) {
            dispatchF(rejectCaseThunk(caseId, (response) => {
                console.log(response);
                dispatchF(setCurrentCaseStatus(response.data));
                resolve({ noApprover: false, caseStatus: response.data });
                generateEmail(authorAnswer, caseId, intl);
            }));
        } else {
            resolve({ noAuthor: true, caseStatus: "" });
        }
    });
}

function generateEmail(authorAnswer, caseId, intl) {
    const authorEmail = JSON.parse(authorAnswer.value);
    const subject = `${intl.formatMessage({ id: "formRenderer.bartReportNumber" })}:${caseId} ${intl.formatMessage({ id: "formRenderer.hasBeenRejected" })}`;
    const body = `${intl.formatMessage({ id: "formRenderer.rejectedAvailableReport" })} ${window.location.origin.toString()}/case/${caseId}
        %0DLINK ${intl.formatMessage({ id: "formRenderer.mustBeOpenedInChrome" })} 
        %0D${intl.formatMessage({ id: "formRenderer.noEditsWereMadeInTheReport" })} 
        %0D${intl.formatMessage({ id: "formRenderer.rejectedReasons" })}`;
    
    if (isIOS || isIPad13 || isMobileSafari) {
        window.location.href = `ms-outlook://compose?to=${authorEmail}&subject=${subject}&body=${body}`;
    } else {
        window.location.href = `mailto:${authorEmail}?subject=${subject}&body=${body}`;
    }
}

function getAuthor(answers, currentFormTemplateID) {
    if (currentFormTemplateID === config.US_INSPECTION_TEMPLATE_ID) {
        return answers.find(a => a.name === "reportDetails.inspectionMadeBy.email");
    } else {
        return answers.find(a => a.name === "skfDetails.reportAuthor.email");    
    }
}