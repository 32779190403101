import React from 'react';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';
import BaseButton from './BaseButton';
import CircleCloseFilterSvg from 'components/svg/CircleCloseFilter';
import PropTypes from "prop-types";

const DuplicateCaseModal = ({
  showModal,
  setShowModal,
  duplicatedCaseId,
  formatURL,
}) => {
  const intl = useIntl();

  return (
    <ReactModal
      isOpen={showModal}
      overlayClassName='react-modal-overlay'
      onRequestClose={() => setShowModal(false)}
      className='react-modal-duplicate-case'
    >
      <div className='react-modal__header'>
        <button className='react-modal__close-button' onClick={() => setShowModal(false)}>
          <CircleCloseFilterSvg />
        </button>
      </div>
      <div className='react-modal__body'>
        <div className='convertion__successful__dialog'>
          <h3 style={{ marginTop: '0' }}>
            {intl.formatMessage({ id: 'formRenderer.caseDuplicated' }) + duplicatedCaseId}
          </h3>
          <BaseButton
            handleSubmit={() => {
              window.open(formatURL(document.URL, duplicatedCaseId), '_blank', 'noopener,noreferrer');
              setShowModal(false);
            }}
            text='Open'
            backgroundColor='green'
            className='react-modal__action-button'
          />
        </div>
      </div>
    </ReactModal>
  );
}

DuplicateCaseModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  duplicatedCaseId: PropTypes.string,
  formatUrl: PropTypes.func,
};

export default DuplicateCaseModal;
