
var detection = null;

const activateOnlineOfflineDetection = (setIsConnected, hasBeenOffline) => {
    const NOT_SET = 0;
    const ONLINE = 1;
    const OFFLINE = 2;
    if (!detection) { 
        detection = {
            previousCheckWasOnline: true,
            connectionStatus: NOT_SET // used to update state only when needed
        }

        setInterval(() => {
            fetch("https://www.skf.com", {
                method: 'HEAD',
                mode: "no-cors",
                cache: "no-cache"
            }).then(() => {
                if (detection.previousCheckWasOnline && detection.connectionStatus != ONLINE) {
                    detection.connectionStatus = ONLINE;
                    setIsConnected(true);
                }else if (!detection.previousCheckWasOnline) {
                    hasBeenOffline(true);
                }
                detection.previousCheckWasOnline = true;
            }).catch(() => {
                if (!detection.previousCheckWasOnline && detection.connectionStatus != OFFLINE) {
                    detection.connectionStatus = OFFLINE;
                    setIsConnected(false);
                }
                detection.previousCheckWasOnline = false;
            });
        }, 2000);
    }
};

export default activateOnlineOfflineDetection;