
import _ from "lodash";
import config from "config";
import repository from "core/repository";
import { Storage } from "aws-amplify";

const EXCLUDED_FAILURE_CODES =
          new Set([
              "m000", // No failure
              "m001"  // Could not be determined
          ]);

export default function generatePdf(caseId, revisionNumber, status, currentFormTemplateID, excludeISOAppendix, language, answers, openPdf, previewHtml) {
    return new Promise((resolve, reject) => {
        if (previewHtml) {
            const requestUrl = createPdfRequestUrl(caseId, revisionNumber, status, currentFormTemplateID, excludeISOAppendix, language, answers);
            const html = addHtmlWrapper(previewHtml);
            
            repository.postPdf(html, requestUrl)
                .then(response => {
                    if (openPdf) {
                        openPdfWindow(response).then(() => resolve());
                    } else {
                        resolve(response);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        } else {
            resolve();
        }
    });
}

export function createPdfRequestUrl(caseId, revisionNumber, status, currentFormTemplateID, excludeISOAppendix, language, answers) {
    const failureCodes = !excludeISOAppendix ? getFailureCodes(currentFormTemplateID, answers) : [];
    const countryCompanyLocation = getCountryCompanyLocation(answers);
    let requestUrl = "";
    if (failureCodes.length > 0 && !excludeISOAppendix) {
        const failureCodeString = getFailurecodesString(failureCodes);
        requestUrl = `?appendices=${failureCodeString}&lang=${language}&caseid=${caseId}&revision=${revisionNumber}&status=${status}&address=${countryCompanyLocation}`;
    } else {
        requestUrl = `?caseid=${caseId}&revision=${revisionNumber}&status=${status}&address=${countryCompanyLocation}`;
    }
    return requestUrl;
}

function openPdfWindow(response) {
    return new Promise(resolve => {
        Storage
            .get(response.data.filename, { bucket: config.BUCKET_PDFS })
            .then(result => {
                window.location = result;
                resolve();
            });
    });
}

function getCountryCompanyLocation(answers) {
    const countryCompanyLocation = answers.find(a => a.name === "skfDetails.countryCompanyLocation");
    if (countryCompanyLocation) {
        const value = JSON.parse(countryCompanyLocation.value);
        return `${value.company.label ? value.company.label : ""}</br>${value.location.label ? value.location.label : ""}`;
    } else {
        return "";
    }
}

function getFailurecodesString(failureCodes) {
    return failureCodes
        .map((failureCode) => failureCode ? failureCode.toLowerCase() : null)
        .filter(failureCode => failureCodes != null && !EXCLUDED_FAILURE_CODES.has(failureCode))
        .join(",");
}

function getFailureCodes(currentFormTemplateID, answers) {
    if (currentFormTemplateID === config.INSPECTION_TEMPLATE_ID || currentFormTemplateID === config.US_INSPECTION_TEMPLATE_ID) {
        return ["i01"];
    }

    let failureCodes = [];
    addAppendixWind(failureCodes, currentFormTemplateID);

    const numberOfInvestigations = answers.find(a => a.name == "bearingInvestigations.count");
    if (numberOfInvestigations) {
        for (let index = 0; index < numberOfInvestigations.value; index++) {
            const key = `bearingInvestigations.bearingInvestigation[${index}].analysis.primaryFailureAndCause`;
            const primaryFailureAndCause = answers.find(a => a.name == key)
            if (primaryFailureAndCause) {
                const value = JSON.parse(primaryFailureAndCause.value);
                if (value) {
                    failureCodes.push(value.failureCode.value);
                }
            }
        }
        failureCodes = _.uniq(failureCodes);
    }

    return failureCodes;
}

function addAppendixWind(failureCodes, currentFormTemplateID) {
    if (currentFormTemplateID == config.WIND_TEMPLATE_ID) {
        failureCodes.push("w01");
    }
}

export function addHtmlWrapper(html) {
    const links = getHtmlLinks();
    return `<!DOCTYPE html><html><head>${links}</head><body style="min-height:0">${html}</body></html>`
}

function getHtmlLinks() {
    if (process.env.NODE_ENV === "development") {
        const styleTags = document.getElementsByTagName("style");

        var styleTagsContent = _.chain(styleTags)
            .filter((style) => {
                return /\S/.test(style.innerHTML) && !style.dataset.emotion;
            })
            .map("innerHTML")
            .join("")
            .value();
        
        return `<style>${styleTagsContent}</style>`;
    } else {
        const linkTags = document.querySelectorAll('link[href*="static/css"]');

        let links = "";
        linkTags.forEach((link) => {
          links += `<link rel="stylesheet" href=${link.href}>`;
        });

        return links;
    }
}