import { restoreCase } from "redux/thunks/caseThunks";

export default async function restoreDeletedCase(caseId, dispatchF) { 
    await restoreCaseAsPromise(caseId, dispatchF);
}

const restoreCaseAsPromise = (caseId,dispatchF) => { 
    return new Promise(resolve => { 
        dispatchF(restoreCase(caseId, () => { 
            resolve();
        }))
    });
}