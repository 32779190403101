import React, {  useEffect, useState } from "react";
import getComponent from "../../_Case/ComponentsList";
import { useSelector, shallowEqual } from "react-redux"

const TERInvestigationDetailsComponent = ({ myName, getValue, setValue, formComponentData }) => { 
  const defaultState = {
    localReferenceNumber: "",
    customerReference: "",
    customerName: "",
    customerIDReference: "",
    customerRequestedDate: "",
    investigationDetailsText: "",
    investigationDetailsFigure: "",
    X1Coordinator: "",
    productLine: "",
    GBICenter: "",
    GBIDistributionList: "",
    peopleInvolved: "",
    quantity: "",
  };

  const [state, setState] = useState(defaultState);
  const [isTER, setIsTER] = useState(false);
  const gbiTypeAnswer = useSelector(state => state.saveComponentValue.components.find(a => a.name === "TERorAMFRorOther"), shallowEqual);

  useEffect(() => { 
    if (gbiTypeAnswer !== undefined) {
      let parsedResult = JSON.parse(gbiTypeAnswer.value)
      if (parsedResult.GBIReportType.value === "TER") {
        setIsTER(true);
      } else {
        setIsTER(false);
      }
    } else {
      setIsTER(false);
    }
  }, [gbiTypeAnswer]);

  useEffect(() => { 
    let result = getValue(myName);
    if (result) {
      result = {
        ...result,
        investigationDetailsText: result.investigationDetailsText ? result.investigationDetailsText : "",
        investigationDetailsFigure: result.investigationDetailsFigure ? result.investigationDetailsFigure : "",
        peopleInvolved :result.peopleInvolved ? result.peopleInvolved : "",
      }
      setState(result);
    } else { 
      setState(defaultState);
    }
  }, [getValue, myName]);

  const handleChange = (value, name) => { 
    let resultName = name.split(".").pop();
    let objectToSave = { ...state };
    objectToSave[resultName] = value;
    setState(objectToSave);
    setValue(objectToSave, myName);
  }

  const renderOther = (formComponent, index) => { 
    return <React.Fragment key={index}>{getComponent(
      formComponent,
      `${myName}.${formComponent.key}`,
      handleChange,
      state[formComponent.key]
    )}</React.Fragment>
  }

  return (
    isTER ? 
    <>
      {formComponentData.args.map((formComponent, index) => {
        return renderOther(formComponent, index);
      })}
    </>
    : null
  );

}

export default TERInvestigationDetailsComponent;