import React from 'react'
import Account from 'components/svg/Account'
import { withRouter} from 'react-router-dom'
import { Auth } from "aws-amplify"
import { Amplify } from "aws-amplify"
import config from "config"
import PropTypes from 'prop-types'
import { FormattedMessage } from "react-intl";
import { setCurrentUser } from "./../../redux/actions/currentUserActions";
import { connect } from "react-redux";

const TheUserBar = (props) => {
  // config
  Amplify.configure({
    Auth: config.COGNITO_AUTH
  })

  // methods
  const signOut = async () => {
    await Auth.signOut()
    props.dispatch(setCurrentUser({}));
    props.childProps.userHasAuthenticated(false)
    props.history.push("/login");
  }

  return (
    <React.Fragment>
      {props.childProps.isAuthenticated && (<div className="user-bar">
        <div className="user-bar__container">
          <button className="user-bar__sign-out" onClick={signOut}>
            <Account className="user-bar__sign-out-icon" />
            <FormattedMessage id="theUserBar.signout" />
          </button>
        </div>
      </div>)}
    </React.Fragment>
  )
}

TheUserBar.propTypes = {
  childProps: PropTypes.object
}

export default withRouter(connect()(TheUserBar))
