import axios from "axios";
import { Auth } from "aws-amplify";
import config from "config";

const defaultApiKey = config.API_KEY;
const defaultBaseURL = config.API_URL;
const formdataApiKey = config.FORMDATA_API_KEY;
const formdataBaseURL = config.FORMDATA_API_URL;

class HttpRepository { 
    constructor(apiUrl, apiKey) { 
        this.apiUrl = apiUrl;
        this.apiKey = apiKey;
    }
    get(endpoint, params) {
        return this.xhr("get", `${this.apiUrl}/${endpoint}`, { params });
    }
      
    post(endpoint, data) {
        return this.xhr("post", `${this.apiUrl}/${endpoint}`, { data });
    }
    
    put(endpoint, data, params = {}) {
        return this.xhr("put", `${this.apiUrl}/${endpoint}`, { data, params });
    }

    delete(endpoint, params) { 
        return this.xhr("delete", `${this.apiUrl}/${endpoint}`, { params });
    }
        
    async xhr(method, endpoint, settings = {}) {
        var user = await Auth.currentAuthenticatedUser();
        const defaultSettings = {
            headers: {
                Authorization: user.signInUserSession.idToken.jwtToken,
                "x-api-key": this.apiKey,
            },
            method,
            url: endpoint
        };
        const requestConfig = { ...defaultSettings, ...settings };
        return await axios(requestConfig);
    }
}

export default function getHttpRepository(formdata = false) { 
    if (formdata) {
        return new HttpRepository(formdataBaseURL, formdataApiKey);
    } else { 
        return new HttpRepository(defaultBaseURL, defaultApiKey);
    }
}

