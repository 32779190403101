import React, { lazy, Suspense } from "react"

const FigureModal = lazy(() => import("./FigureModal"))

const FigureModalWrapper = ({
  onChange,
  onDeleteImage,
  imageItems,
  caseId,
  currentIndex,
  onModalClose,
  showModal,
  myName,
}) => {
  // This is a workaround to prevent the modal from rendering when the user is not editing the image.
  if (!showModal) return null

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <FigureModal
        onChange={onChange}
        onModalClose={onModalClose}
        onDeleteImage={onDeleteImage}
        showModal={showModal}
        imageItems={imageItems}
        currentIndex={currentIndex}
        caseId={caseId}
        myName={myName}
      />
    </Suspense>
  )
}

export default FigureModalWrapper
