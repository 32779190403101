import React, { useEffect, useState, useRef } from "react";
import { useIntl, injectIntl } from 'react-intl';
import classnames from "classnames";
import SvgPlus from "./../../svg/Ellipsis";
import useOuterClick from "./../../formcomponents/utils/useOuterClick";

const SectionMenu = ({ sections, onAddSection }) => {
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const innerRef = useOuterClick(() => {
        setIsOpen(false)
    })

    const onClick = value => {
        onAddSection(value);
        return false;
    }

    return (
        sections.length > 0 && <>
            <div className="accordion-menu">
                <button type="button" className={classnames("button", "bearing-add-button", { "is-open": isOpen })} onClick={event => setIsOpen(isOpen ? false : true)} ref={innerRef}>
                    <SvgPlus />
                </button>
            </div>
            {isOpen && (
                <div className="accordion-menu-content navigation__container">
                    {sections.map((s, index) =>
                        <a key={ index } className={classnames("navigation__action")} onClick={event => onClick(s.value)}>{s.label}</a>
                    )}
                </div>
            )}
        </>
    );
}

export default injectIntl(SectionMenu);