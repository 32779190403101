import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import MainContainer from 'components/common/MainContainer'
import PageHeader from 'components/common/PageHeader'
import Filter from 'components/common/Filter'
import CasesTable from 'components/common/CasesTable'
import { FormattedMessage } from 'react-intl'
import queryString from 'query-string'
import CaseSearchBox from 'components/common/CaseSearchBox';
import ExportMessage from "../common/ExportMessage";

const AllReports = ({ loading, history, isUserExternal }) => {
  const [showSearch, setShowSearch] = useState(false)

  const searchParams = queryString.parse(history.location.search)

  useEffect(() => {
    if (searchParams && searchParams.q) {
      setShowSearch(true)
    }
  }, [searchParams])

  return (
      <MainContainer
      filter={<Filter loading={loading} className={'page-layout__filter'} isUserExternal={isUserExternal} />}
        pageTitle={(
          <>
            <PageHeader
              className="page-layout__heading-title"
              header={<FormattedMessage id="pageName.allReports"/>}
            />
            {showSearch && (
              <div className="all-reports__title-search-container">
                <CaseSearchBox />
              </div>
            )}
          </>
        )}
      >
      <CasesTable loading={loading} />
      <ExportMessage />
      </MainContainer>
  )
}

AllReports.propTypes = {
  loading: PropTypes.bool
}

const mapStateToProps = state => ({
  loading: state.caseRelated.loading
})

export default connect(mapStateToProps)(AllReports)