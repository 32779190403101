import React, { useEffect, useState } from 'react'
import ArrowDown from "components/svg/ArrowDown"
import { useMediaQuery } from 'react-responsive'
import { TO_MEDIUM } from 'core/ui/breakpoints'

const TheToTop = () => {
  const [windowHeight, setWindowHeight] = useState(0)
  const [inPosition, setInPosition] = useState(false)

  const toMedium = useMediaQuery({
    query: TO_MEDIUM
  })

  const scrollAction = () => {
    const htmlElement = document.querySelector('html')
    
    htmlElement.scrollTo({
      top: 0
    })
  }

  const scrollListen = () => {
    const scrollPosition = window.scrollY
    
    if (scrollPosition > windowHeight) {
      setInPosition(true)
    } else {
      setInPosition(false)
    }
  }
  
  useEffect(() => {
    setWindowHeight(window.innerHeight / 2)
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', scrollListen)

    return () => {
      document.removeEventListener('scroll', scrollListen)
    }
  })

  return (
    <React.Fragment>
      {(toMedium && inPosition) && <button className="to-top" onClick={() => scrollAction()}>
        <ArrowDown className="to-top__action-svg-arrow" />
      </button>}
    </React.Fragment>
  )
}

export default TheToTop
