import React from "react";

const SvgButtonBarApproveWithEdits = ({ title, size = "26px" }) => (
    <svg width={ size } height={ size } viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{title}</title>
        <g id="Icons/icon_approve_edit" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M4.5,0 C2.016,0 0,2.016 0,4.5 C0,6.984 2.016,9 4.5,9 C6.984,9 9,6.984 9,4.5 C9,2.016 6.984,0 4.5,0 Z M3.6,6.75 L1.35,4.5 L1.9845,3.8655 L3.6,5.4765 L7.0155,2.061 L7.65,2.7 L3.6,6.75 Z" id="-↳Color" fill="#F8F8F8"></path>
            <path d="M2,16.4625 L2,19.5025 C2,19.7825 2.22,20.0025 2.5,20.0025 L5.54,20.0025 C5.67,20.0025 5.8,19.9525 5.89,19.8525 L16.81,8.9425 L13.06,5.1925 L2.15,16.1025 C2.05,16.2025 2,16.3225 2,16.4625 Z M19.71,6.0425 C20.1,5.6525 20.1,5.0225 19.71,4.6325 L17.37,2.2925 C16.98,1.9025 16.35,1.9025 15.96,2.2925 L14.13,4.1225 L17.88,7.8725 L19.71,6.0425 Z" id="Shape-Copy" fill="#F8F8F8"></path>
        </g>
    </svg>
);

export default SvgButtonBarApproveWithEdits;
