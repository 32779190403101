import { saveAllAnswersAndValidate } from "redux/thunks/answerThunk";
import { callBackThunkAsPromise } from "redux/thunks/caseThunks";
import { getCaseDataRepositoryWithoutOffline } from "core/caseRepository/caseRepositoryFactory";

export default async function duplicateCase(caseId, caseStatus, canUserEdit, dispatchF, intl) {

    if (caseStatus === "draft") { 
        if (canUserEdit) { 
            await callBackThunkAsPromise(dispatchF, saveAllAnswersAndValidate);
        }
    }
    
    const repository = getCaseDataRepositoryWithoutOffline();
    const cloneCaseResponse = await repository.cloneCase(caseId);

    return cloneCaseResponse.data.id;
}
