import React,{ useEffect, useState }  from "react";
import { useIntl } from 'react-intl';
import BaseInput from '../common/BaseInput'

const TextComponent = (props) => {
  const intl = useIntl()
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    const newValue = props.formComponentData.key === "competitorDesignationAndMarkings" ? event.target.value.toUpperCase() : event.target.value 
    setValue(newValue)
  }

  const _onBlur = () => {
    if (props.parentSetValue !== undefined) {
      props.parentHandleSave(value, props.myName)
    } else {
      props.setValue(value, props.myName)
    }
  }

  useEffect(() => {
    if( props.parentSetValue !== undefined) {
      setValue(props.parentSetValue)
    } else {
      setValue(props.getValue(props.myName))
    }
  },[props, props.parentSetValue] )

  return (
    <BaseInput 
      onBlur={_onBlur}
      type={props.formComponentData.isNumeric ? 'number' : 'text'}
      id={props.formComponentData.name}
      placeholder={props.formComponentData.placeholder ? props.formComponentData.placeholder : ""}
      labelText={props.formComponentData.description}
      labelTextDark={true}
      hintMessage={props.formComponentData.required && intl.formatMessage({ id: 'formComponents.required' })}
      isTextArea={props.formComponentData.isTextArea ? props.formComponentData.isTextArea : false}
      handleChange={handleChange}
      isRequired={props.formComponentData.required}
      isDisabled={props.formComponentData.isDisabled ? props.formComponentData.isDisabled : false}
      value={value ? value : ''} 
      myName={props.myName}
    />
  )
}

TextComponent.defaultProps = {
  type: 'text'
}

export default TextComponent