
export default function (value) {
    if (!value)
        return false;
    if (value == null)
        return false;
    
    if (value.constructor === Object) {
        value = value.value;
    }

    let parsedValue = JSON.parse(value);
    
    if (!parsedValue.endUser) {
        return false;
    }

    if (!parsedValue.distributor) {
        return false;
    } else if(parsedValue.distributor.isCustom) {
        if (!parsedValue.distributor.value || parsedValue.distributor.value.length < 1) {
            return false;
        }
    }

    return true;
}