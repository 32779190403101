import { SET_EXCLUDE_ISO_APPENDIX } from "redux/types";
import initialState from "redux/initialState";

export default function excludeISOAppendixReducer(
  state = initialState.excludeISOAppendix,
  action
) {
  switch (action.type) {
    case SET_EXCLUDE_ISO_APPENDIX:
      return action.payload;
    default:
      return state;
  }
}
