import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import config from "../../../config";
import AccordionTrigger from "components/_Case/Accordion/AccordionTrigger";
import FormComponent from "components/_Case/FormComponent";
import BearingInvestigationMenu from "./BearingInvestigationMenu";

const OriginalBearingInvestigationComponent = ({ formConfig, index, myName, toggleAccordion, openAccordions }) => {
  const intl = useIntl();
  const currentUser = useSelector(state => state.currentUser, shallowEqual);
  const templateId = useSelector(state => state.currentCase.case.formtemplateId)

  const isWindReportType = () => config.WIND_TEMPLATE_ID === templateId

  const getFormConfigForUnToggledBI = (formConfig) => {
    const result = []

    const analysisConfig = formConfig?.find(fc => fc.key === 'analysis')
    result.push(analysisConfig)

    if (isWindReportType()) {
      const bearingTypeConfig = formConfig?.find(fc => fc.key === 'bearingType')

      if (!bearingTypeConfig) return result

      result.push({
        ...bearingTypeConfig,
        components: bearingTypeConfig?.components?.filter(c => c.key === 'windFunctionalLocation'),
      })

      return result
    }
    return result
  }

  return <div className="accordion-menu-container" data-parent-id={`bearingInvestigation[${index}]`}>
    <div className="accordion accordion--bearing">
      <div className="accordion-trigger-container">
        <AccordionTrigger
          isRoot={false}
          isOpened={openAccordions.includes(`bearingInvestigation[${index}]`)}
          isExpanded={false}
          isLast={false}
          isFirst={false}
          isPreviousExpanded={false}
          isPreviousOpened={openAccordions.includes(`bearingInvestigation[${index - 1}]`)}
          toggleAccordion={() => toggleAccordion(`bearingInvestigation[${index}]`, index)}
          label={`${intl.formatMessage({ id: "bearingInvestigations.bearingNumber" })} ${index + 1}`}
          SecondaryLabel={<BearingLabel index={index} />}
          fieldName={myName}
        />
        <BearingInvestigationMenu bearingIndex={index} />
      </div>
      <div
        className={classnames("accordion__content",
          { "accordion__content--is-open": openAccordions.includes(`bearingInvestigation[${index}]`) }
        )}
      >
        {openAccordions.includes(`bearingInvestigation[${index}]`) ?
          <FormComponent
            formConfig={formConfig}
            parentName={myName}
            user={currentUser}
          /> :
          <FormComponent
            formConfig={getFormConfigForUnToggledBI(formConfig)}
            parentName={myName}
            user={currentUser}
          />
        }

        {/* Doesn't work because of validation since each formcomponent add required fields
        {openAccordions.includes(`bearingInvestigation[${index}]`) ?
          <FormComponent
            formConfig={formConfig}
            parentName={myName}
            user={currentUser}
          />
        : null} */}
      </div>
    </div>
  </div>;
}

const BearingLabel = ({ index }) => {
  const currentFormTemplateID = useSelector(state => state.currentCase.case.formtemplateId, shallowEqual);
  return config.GBI_TEMPLATE_ID === currentFormTemplateID ? <BearingLabelGBI index={index} /> : <BearingLabelDesignation index={index} />;
}

const BearingLabelDesignation = ({ index }) => {
  const bearingDesignationName = `bearingInvestigations.bearingInvestigation[${index}].bearingType.BearingDesignationComponent`;

  const extractManufacturerAndDesignation = (answerValue) => {
    const value = JSON.parse(answerValue.value);
    if (value.skfOrCompetitor.value == "skf") {
      return {
        manufacturer: value.SKFManufacturer.label ? value.SKFManufacturer.label : "",
        designation: value.skfBearingDesignation.label ? value.skfBearingDesignation.label : ""
      };
    } else if (value.skfOrCompetitor.value == "competitor") {
      const designation = typeof value.competitorDesignationAndMarkings === "string" 
        ? value.competitorDesignationAndMarkings 
        : typeof value.competitorDesignationAndMarkings === "object"
        ? value.competitorDesignationAndMarkings.label
        : "";
      return {
        manufacturer: value.manufacturer.label ? value.manufacturer.label : "",
        designation,
      };
    }
  };

  return <BearingLabelDesignationBase bearingDesignationName={bearingDesignationName} extractFunction={extractManufacturerAndDesignation} />
}

const BearingLabelGBI = ({ index }) => {
  const tERorAMFRorOtherAnswer = useSelector(state => state.saveComponentValue.components.find(a => a.name === "TERorAMFRorOther"), shallowEqual);

  const [typeValue, setTypeValue] = useState("");

  useEffect(() => {
    if (tERorAMFRorOtherAnswer) {
      const typeValue = JSON.parse(tERorAMFRorOtherAnswer.value);
      setTypeValue(typeValue.GBIReportType.value);
    }
  }, [tERorAMFRorOtherAnswer]);

  return typeValue === "Other" ? <BearingLabelGBIOther index={index} /> : typeValue === "AMFR" ? <BearingLabelGBIAMFR index={index} /> : null;
}

const BearingLabelGBIOther = ({ index }) => {
  const otherBearingInvestigationName = `bearingInvestigations.bearingInvestigation[${index}].otherBearingInvestigation`;

  const extractManufacturerAndDesignation = (answerValue) => {
    const value = JSON.parse(answerValue.value);
    if (value.bearingDesignation) {
      if (value.bearingDesignation.skfOrCompetitor.value == "skf") {
        return {
          manufacturer: value.bearingDesignation.SKFManufacturer ? value.bearingDesignation.SKFManufacturer.label : "",
          designation: value.bearingDesignation.skfBearingDesignation ? value.bearingDesignation.skfBearingDesignation.label : ""
        };
      } else if (value.bearingDesignation.skfOrCompetitor.value == "competitor") {
        return {
          manufacturer: value.bearingDesignation.manufacturer ? value.bearingDesignation.manufacturer.label : "",
          designation: value.bearingDesignation.competitorDesignationAndMarkings
        };
      }
    }

  };

  return <BearingLabelDesignationBase bearingDesignationName={otherBearingInvestigationName} extractFunction={extractManufacturerAndDesignation} />

}

const BearingLabelGBIAMFR = ({ index }) => {
  const amfrBearingInvestigationName = `bearingInvestigations.bearingInvestigation[${index}].amfrBearingInvestigation`;

  const extractManufacturerAndDesignation = (answerValue) => {
    const value = JSON.parse(answerValue.value);
    if (value && value.skfBearingDesignation) {
      return {
        designation: value.skfBearingDesignation
      };
    }
  }

  return <BearingLabelDesignationBase bearingDesignationName={amfrBearingInvestigationName} extractFunction={extractManufacturerAndDesignation} />
}

const BearingLabelDesignationBase = ({ bearingDesignationName, extractFunction }) => {
  const bearingDesignationAnswer = useSelector(state => state.saveComponentValue.components.find(a => a.name === bearingDesignationName), shallowEqual);
  const bearingDesignationToSaveAnswer = useSelector(state => state.saveComponentValue.componentsToSave.find(a => a.answer.name === bearingDesignationName), shallowEqual);

  const [bearingManufacturer, setBearingManufacturer] = useState("");
  const [bearingDesignation, setBearingDesignation] = useState("");

  useEffect(() => {
    let result;
    if (bearingDesignationToSaveAnswer) {
      result = extractFunction(bearingDesignationToSaveAnswer.answer);
    } else if (bearingDesignationAnswer) {
      result = extractFunction(bearingDesignationAnswer);
    } else {
      // reset the values when both are undefined - prevents duplicate labels when you delete a bearing and all bearings move up
      setBearingManufacturer("");
      setBearingDesignation("");
    }

    if (result) {
      setBearingManufacturer(result.manufacturer);
      setBearingDesignation(result.designation);
    }

  }, [bearingDesignationAnswer, bearingDesignationToSaveAnswer, extractFunction]);

  return <span>{[bearingManufacturer, bearingDesignation].filter(s => s).join(" ")}</span>
}


const BearingInvestigationComponent = React.memo(OriginalBearingInvestigationComponent)
export default BearingInvestigationComponent;
