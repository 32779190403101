import React from "react";
import classnames from "classnames";
import { shallowEqual, useSelector } from "react-redux";

import {
    SvgTreeEditEndExpanded,
    SvgTreeInward,
    SvgTreeOutward,
    SvgTreeRootBranch,
    SvgTreeRootBranchExpandedEnd,
    SvgTreeRootBranchExpandedStart,
    SvgTreeRootEnd,
    SvgTreeRootFirst
} from "./TreeIcons";

const AccordionTrigger = ({
    isRoot,
    isOpened,
    isExpanded,
    isLast,
    isFirst,
    isPreviousExpanded,
    isPreviousOpened,
    isPreviousOrphaned,
    isOrphaned,
    toggleAccordion,
    label,
    SecondaryLabel,
    fieldName
    }) => {

    const isInvalid = useSelector(state => !state.saveComponentValue.validationErrors.filter(ve => ve.key.startsWith(fieldName)).every(ve => ve.valid), shallowEqual);
    const isError = useSelector((state) => state.saveComponentValue.components.filter(c => c.name.startsWith(fieldName)).some(c => c.isError), shallowEqual);

    const getIcon = () => {
        if (isOrphaned) {
            if (isPreviousExpanded) {
                return <SvgTreeRootBranchExpandedEnd rotate={true} />;
            } else {
                return <SvgTreeRootEnd />;    
            }
            
        }
        if (isPreviousOrphaned) {
            if (isRoot && isLast) {
                return <SvgTreeRootBranchExpandedEnd rotate={true} />;
            } else {
                return <SvgTreeRootFirst />;
            }
        }
        if (isExpanded) {
            if (isRoot && isFirst) {
                return <SvgTreeRootBranchExpandedEnd />;
            } else {
                return <SvgTreeRootBranchExpandedStart />;
            }
            
        }
        if (isOpened) {
            return <SvgTreeInward />;
        }

        if (isPreviousExpanded) {
            if (isLast) {
                if (isRoot) {
                    return <SvgTreeRootBranchExpandedEnd rotate={true} />;
                } else {
                    return <SvgTreeRootBranchExpandedStart rotate={true} />;
                }
            } else {
                return <SvgTreeRootBranchExpandedStart rotate={true} />;
            }
        }

        if (isPreviousOpened) {
            if (isRoot && isLast) {
                return <SvgTreeRootBranchExpandedEnd rotate={true} />;
            } else {
                return <SvgTreeOutward />;
            }
        }

        if (isFirst) {
            if (isRoot) {
                return <SvgTreeRootFirst />;
            } else {
                return <SvgTreeRootBranch />;
            }
        }

        if (isLast) {
            if (isRoot) {
                return <SvgTreeRootEnd />;
            } else {
                return <SvgTreeRootBranch />;
            }
        }

        return <SvgTreeRootBranch />;
    }

    
    return <div className={classnames("accordion__trigger", { "accordion__trigger-selected": isExpanded || isOpened })}>
        <div className={classnames("accordion__trigger-icon")}>{ getIcon() }</div>
        <button
            onClick={() => toggleAccordion()}>
            {label}{isInvalid || isError ? <div className="is-invalid"></div> : null}
            {SecondaryLabel && SecondaryLabel }
        </button>
    </div>;
}


export default AccordionTrigger;