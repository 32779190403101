const createPerson = (name, email, phone) => {
    return { name, email, phone }
};

export const transformUsInspectionData = data => { 
    if (data["skfDetails.endUser"]) {
        const endUserRoot = data["skfDetails.endUser"];

        if (endUserRoot.endUser) {
            const endUser = endUserRoot.endUser;
            data["skfDetails.endUser.endUser_contact"] = createPerson(endUser.contact_name, endUser.email ? endUser.email.join(",") : "", endUser.phone);
            
            if (endUser.industrySpecialist) {
                data["skfDetails.endUser.industrySpecialist_contact"] = createPerson(endUser.industrySpecialist.name, endUser.industrySpecialist.email);
            }
            if (endUser.territoryManager) {
                data["skfDetails.endUser.territoryManager_contact"] = createPerson(endUser.territoryManager.name, endUser.territoryManager.email);
            }
            data["skfDetails.endUser.endUser_name"] = endUser.name;
            data["skfDetails.endUser.endUser_cityState"] = `${endUser.city}, ${endUser.state}`;
            data["skfDetails.endUser.endUser_address"] = endUser.address;
            data["skfDetails.endUser.endUser_zip"] = endUser.zip;
        }
        if (endUserRoot.distributor) {
            const distributor = endUserRoot.distributor;
            
            if (distributor.isCustom) {
                data["skfDetails.endUser.distributor_custom"] = distributor.value;
            } else {
                data["skfDetails.endUser.distributor_name"] = distributor.name;
                data["skfDetails.endUser.distributor_contact"] = createPerson(distributor.contact_name, distributor.email ? distributor.email.join(",") : "", distributor.phone);
                data["skfDetails.endUser.distributor_cityState"] = `${distributor.city}, ${distributor.state}`;
                data["skfDetails.endUser.distributor_address"] = distributor.address;
                data["skfDetails.endUser.distributor_zip"] = distributor.zip;    
            }
        }

        if (endUserRoot.vendor && endUserRoot.vendor.label) {
            const vendor = endUserRoot.vendor.value;
            data["skfDetails.endUser.vendor_name"] = vendor.name;
            data["skfDetails.endUser.vendor_contact"] = createPerson(vendor.contact_name, vendor.email ? vendor.email.join(",") : "", vendor.phone);
            data["skfDetails.endUser.vendor_cityState"] = `${vendor.city}, ${vendor.state}`;
            data["skfDetails.endUser.vendor_address"] = vendor.address;
            data["skfDetails.endUser.vendor_zip"] = vendor.zip;
        }
    }
    if (data['reportDetails.reportDate'] || data['reportDetails.inspectionDate']) { 
        data['reportDetails.reportDateInspectionDate'] = {
            reportDate: data['reportDetails.reportDate'],
            inspectionDate: data['reportDetails.inspectionDate']
        }
    }
    return data;
}