import React, { useState, useRef, useEffect } from "react"
import SettingsSvg from "components/svg/Settings"
import GlobeSvg from "components/svg/Globe"
import ArrowDown from "components/svg/ArrowDown"
import classnames from "classnames"
import { setCurrentLanguage } from "redux/actions/languageActions"
import { connect } from "react-redux"
import { FormattedMessage } from 'react-intl'

const TheHeaderSettings = props => {
  const headerSearchWrapperRef = useRef(null)

  // state
  const [isSettingsOpen, toggleIsettingsOpen] = useState(false)
  const [isAccordionOpen, toggleIsAccordionOpen] = useState(false)

  let propsLang
  switch (props.currentLanguage) {
    case "en": propsLang = "English"
      break
    case "pt": propsLang = "Português"
      break
    case "zh": propsLang = "中國"
      break
    case "it": propsLang = "Italiano"
      break
    case "fr": propsLang = "Français"
      break
    case "es": propsLang = "Español"
      break
    case "se": propsLang = "Svenska"
      break
    case "de": propsLang = "Deutsch"
      break
    case "cs": propsLang = "Czech"
      break
    case "zh-HANT": propsLang = "繁體中文"
      break
    case "pl": propsLang = "Polski"
      break
    case "fi": propsLang = "Suomi"
      break
    case "tr": propsLang = "Türkçe"
      break
    case "vi": propsLang = "Tiếng Việt"
      break
    case "ru": propsLang = "Pусский"
      break
    case "nl": propsLang = "Nederlands"
      break
    case "ko": propsLang = "한국어"
      break
    case "jp": propsLang = "日本"
      break
    case "hu": propsLang = "Magyar"
      break
    default:
      break
  }


  const [currentLanguage, setCurrentLang] = useState(propsLang)

  // methods
  const toggleSettings = () => toggleIsettingsOpen(!isSettingsOpen)
  const toggleAccordion = () => toggleIsAccordionOpen(!isAccordionOpen)

  function setLang(languageCode, languageName) {
    localStorage.setItem("currentLang", languageCode);
    props.setCurrentLanguage(languageCode)
    setCurrentLang(languageName)
  }

  const handleClickOutside = (event) => {
    if (headerSearchWrapperRef.current && !headerSearchWrapperRef.current.contains(event.target)) {
      toggleIsAccordionOpen(false)
      toggleIsettingsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  return (
    <div className="header-settings" ref={headerSearchWrapperRef}>
      <button
        className={classnames("header-settings__toggler", {
          "header-settings__toggler--is-active": isSettingsOpen
        })}
        onClick={toggleSettings}
      >
        <SettingsSvg className="header-settings__svg" />
      </button>

      {isSettingsOpen && (
        <div className="header-settings__actions-container">
          <div className="header-settings__langugage-header">
            <FormattedMessage id="headerSettings.language" />
          </div>

          <div
            className={classnames("header-settings-language", {
              "header-settings-language--is-open": isAccordionOpen
            })}
          >
            <button
              className="header-settings-language__toggler"
              onClick={toggleAccordion}
            >
              <GlobeSvg className="header-settings-language__globe-svg" />
              {currentLanguage.charAt(0).toUpperCase() + currentLanguage.slice(1)}
              <ArrowDown className="header-settings-language__action-svg-arrow" />
            </button>

            <div className="header-settings-language__content">
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('de', 'Deutsch')}
              >
                Deutsch
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('en', 'English')}
              >
                English
              </button>

              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('es', 'Español')}
              >
                Español
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('fr', 'Français')}
              >
                Français
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('it', 'Italiano')}
              >
                Italiano
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('pt', 'Português')}
              >
                Português
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('se', 'Svenska')}
              >
                Svenska
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('zh', '中國')}
              >
                中國
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('cs', 'Czech')}
              >
                Czech
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('zh-HANT', '繁體中文')}
              >
                繁體中文
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('pl', 'Polski')}
              >
                Polski
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('fi', 'Suomi')}
              >
                Suomi
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('tr', 'Türkçe')}
              >
                Türkçe
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('vi', 'Tiếng Việt')}
              >
                Tiếng Việt
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('ru', 'Pусский')}
              >
                Pусский
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('nl', 'Nederlands')}
              >
                Nederlands
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('ko', '한국어')}
              >
                한국어
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('jp', '日本')}
              >
                日本
              </button>
              <button
                className="header-settings-language__change-action"
                onClick={() => setLang('hu', 'Magyar')}
              >
                Magyar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = { setCurrentLanguage }
function mapStateToProps(state) {
  return {
    currentLanguage: state.language
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TheHeaderSettings)
