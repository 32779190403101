import { OfflineTrackingRepository } from "core/caseRepository/OfflineTrackingRepository";
import { getCaseDataRepositoryWithoutOffline } from "core/caseRepository/caseRepositoryFactory";
import { findAndUploadOfflineImages } from "./offlineImages";
import localforage from "localforage";


export async function clearEvents() {
    const repository = new OfflineTrackingRepository();
    await repository.clearEvents();
}

export async function syncCaseGroup(caseGroup, attemptsToSync) {
    let resultEvents = [...await handleAddCaseEvent(caseGroup)];
    try {
        const imageurls = await findAndUploadOfflineImages(caseGroup.events, caseGroup.caseId);
        resultEvents = [...resultEvents, ...await syncAll(caseGroup.caseId, caseGroup.events, imageurls, attemptsToSync)];
    } catch (error) {
        handleError(error, resultEvents, {}, caseGroup.caseId);
    }
    return resultEvents;
}

export async function clearSyncedCase(caseId) {
    const repository = new OfflineTrackingRepository()
    await repository.clearSyncedCaseFromEvents(caseId)
}

export async function isFailedCase(caseId) {
    const repository = new OfflineTrackingRepository()
    return repository.isFailedToSyncCase(caseId)
}

export async function removeAllImages() {
    const repository = new OfflineTrackingRepository()
    await repository.removeOfflineImages()
}

async function handleAddCaseEvent(caseGroup) {
    const resultEvents = [];

    if (caseGroup.addCaseEvent) {
        try {
            const addResult = await handleAddCase(caseGroup.addCaseEvent);
            resultEvents.push(addResult);

            caseGroup.caseId = addResult.result.id;
            caseGroup.events = caseGroup.events.filter(e => e.type !== "addCase");

            handleCaseForward(caseGroup.events, addResult);
        } catch (error) {
            handleError(error, resultEvents, caseGroup.addCaseEvent, caseGroup.caseId);
        }
    }

    return resultEvents;
}

async function handleError(error, resultEvents, event, caseId) {
    if (error && resultEvents.some(e => e.type === 'addCase')) {
        resultEvents.push({ ...event, error })
        return
    }

    const repository = new OfflineTrackingRepository()
    await repository.addFailedToSyncCase(caseId)
    if (error.response && error.response.data) {
        console.log("CONCURRENCY ERROR", error.response.data);
        resultEvents.push({ ...event, error: error.response.data });
    } else {
        console.error("SYNC ERROR", error);
    }
}

async function syncAll(caseId, events, imageurls, attemptsToSync) {
    const attempts = await calculateAttemptsToSync(caseId, attemptsToSync);
    const repository = getCaseDataRepositoryWithoutOffline();
    const result = await repository.syncCase(caseId, events, imageurls);

    const res = result.data.results.reduce((allresult, item) => {
        if (item.type === "addAnswers" || item.type === "updateAnswers") {
            return [
                ...allresult,
                ...item.payload.answers.map(({ payload, date, type }) =>
                ({
                    type,
                    payload,
                    date,
                    syncAttempts: attempts,
                    result: item.result.find(r => r.answer.name === payload.answer.name),
                    error: item.result.find(r => r.answer.name === payload.answer.name).error
                }))]
        } else {
            return [...allresult, item];
        }
    }, []);

    return res
}

async function calculateAttemptsToSync(caseId, attemptsToSync) {
    const trackingRepository = new OfflineTrackingRepository()

    let attempts = 1
    if (attemptsToSync) {
        await trackingRepository.incrementSyncAttempts(caseId)
        attempts = await trackingRepository.getAttemptsToSync(caseId)
    } else {
        await trackingRepository.resetSyncAttempts(caseId)
    }

    return attempts
}

export async function getEvents() {
    const repository = new OfflineTrackingRepository();
    return repository.getEvents();
}

export async function getEventsGroupedByCase() {
    const repository = new OfflineTrackingRepository();
    return repository.getEventsGroupedByCaseId();
}

export async function getCasesFromCaseEvents() {
    let cases = []
    const caseEvents = await getEventsGroupedByCase();
    for (const caseEvent of caseEvents) {
        let caseData = null;

        const storedCase = await localforage.getItem(`case/${caseEvent.caseId}`);
        if (storedCase) {
            caseData = storedCase.data;
        }
        if (!caseData) {
            if (caseEvent.addCaseEvent) {
                caseData = caseEvent.addCaseEvent.payload.caseData;
            }
        }
        if (!caseData) {
            const lastAddUpdate = [...caseEvent.events].reverse().find(e => e.type === "addCase" || e.type === "updateCase");
            if (lastAddUpdate) {
                caseData = lastAddUpdate.payload.caseData;
            }
        }

        if (caseData) {
            const reportDate = new Date(caseData.updatedAt)
            let updated = 'N/A'
            if (typeof reportDate.getMonth === 'function') {
                const date = ('0' + reportDate.getDate()).slice(-2); // Double digit date instead of single digit
                const month = ('0' + (reportDate.getMonth() + 1)).slice(-2); // Since getMonth() returns month from 0-11 not 1-12
                const year = reportDate.getFullYear();
                updated = `${year}-${month}-${date}`
            }
            cases = [...cases, formatCaseData(caseData)];
        }
    }

    return cases;
}

export function formatCaseData(caseData) {
    const reportDate = new Date(caseData.updatedAt)
    let updated = 'N/A'
    if (typeof reportDate.getMonth === 'function') {
        const date = ('0' + reportDate.getDate()).slice(-2); // Double digit date instead of single digit
        const month = ('0' + (reportDate.getMonth() + 1)).slice(-2); // Since getMonth() returns month from 0-11 not 1-12
        const year = reportDate.getFullYear();
        updated = `${year}-${month}-${date}`
    }
    return { ...caseData, updated, report_number: caseData.id };
}

export function getAnswerErrors(resultEvents) {
    return resultEvents
        .filter(e => e.error)
        .filter(e => (e.error.errorType === "ConcurrencyError" || e.error.errorType === "BadRequestError") ||
            (e.error.error?.errorType === "ConcurrencyError" || e.error.error?.errorType === "BadRequestError"))
        .filter(e => e.type === "addAnswer" || e.type === "updateAnswer")
        .map(e => ({ originalAnswer: e.payload.answer, errorData: { error: e.error.error ? e.error.error : e.error } }));
}

export function getCaseErrors(resultEvents) {
    return resultEvents
        .filter(e => e.error)
        .filter(e => (e.error.errorType === "ConcurrencyError" || e.error.errorType === "BadRequestError") ||
            (e.error.error?.errorType === "ConcurrencyError" || e.error.error?.errorType === "BadRequestError"))
        .filter(e => e.type === "addCase" || e.type === "updateCase")
        .map(e => ({ originalAnswer: e.payload.answer, errorData: { error: e.error.error ? e.error.error : e.error } }));
}

async function handleAddCase(event) {
    const repository = getCaseDataRepositoryWithoutOffline();
    const result = await repository.addCase(
        event.payload.caseData.template,
        [],
        event.payload.caseData.confidential,
        event.payload.caseData.language,
    )
    return { ...event, result: result.data };
}


function handleCaseForward(events, eventWithResult) {
    for (const event of events) {
        if (event.payload.caseId === eventWithResult.payload.caseId) {
            event.payload.caseId = eventWithResult.result.id;
            if (event.payload.caseData) {
                mergeCases(event.payload.caseData, eventWithResult.result);
            }
        }
    }
}

function mergeCases(caseTarget, caseSource) {
    Object.keys(caseSource).forEach(key => {
        if (caseSource[key]) {
            caseTarget[key] = caseSource[key];
        }
    });
}


