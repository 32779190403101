import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  transformBearingInvestigationData,
  extractConfigFor,
  imageSectionNames,
  imageAnalysisNames,
  getImageObjects,
  getImageLayoutObject,
  fixBearingConfig,
  isImageEmpty,
  isValuePresent
} from "../Shared/BIHelperFunctions";
import { formatRollingElementsHeader } from "../Shared/LivepreviewHelperFunctions"
import { renderBearingDesignationHeaderGBI } from "../Shared/GetAndRenderBearingDesignation";

import _ from "lodash";
import classnames from "classnames";

import PropTypes from "prop-types";
import KeyValueTable from "components/_LivePreview/Shared/KeyValueTable";
import NewKeyValueTable from "components/_LivePreview/Shared/NewKeyValueTable";
import ImageContainer from "components/_LivePreview/Shared/ImageContainer";
import ObservationsAndFailuremode from "components/_LivePreview/Shared/ObservationsAndFailuremode";

const GbiBearingInvestigation = (props) => {
  const intl = useIntl()
  const [bearingsConfig, setBearingConfig] = useState([]);

  const pageBreakEachBearing = useSelector(state => state.pageBreakEachBearing)
  const scrollTarget = useSelector(state => state.saveComponentValue.scrollTarget)
  const caseWasSaved = useSelector(state => state.saveComponentValue.caseWasSaved)

  const extractConfigsFor = (values, config) => {
    return config.filter(item => { return values.some(v => item.key.includes(v)) });
  }

  const filterConfig = (values, keys) => {
    return values.filter(v => !keys.some(key => v.key.endsWith(key)));
  }

  const hasScrollTarget = key => {
    if (!scrollTarget) return false

    if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
      return (scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key)) && !caseWasSaved
    }
    return false
  }

  const transformTERAMFROtherData = (i) => {
    let answerObject = {};
    let config;
    let answer;
    let terOramfrOrOther = props.data["TERorAMFRorOther"];
    if (terOramfrOrOther !== undefined) {
      terOramfrOrOther = terOramfrOrOther.GBIReportType.value;
      if (terOramfrOrOther === "TER") {
        config = extractConfigFor("terBearingInvestigation", i, bearingsConfig);
        answer =
          props.data[
          `bearingInvestigations.bearingInvestigation[${i}].terBearingInvestigation`
          ];
      } else if (terOramfrOrOther === "AMFR") {
        config = extractConfigFor(
          "amfrBearingInvestigation",
          i,
          bearingsConfig
        );
        answer =
          props.data[
          `bearingInvestigations.bearingInvestigation[${i}].amfrBearingInvestigation`
          ];
      } else if (terOramfrOrOther === "Other") {
        config = extractConfigFor(
          "otherBearingInvestigation",
          i,
          bearingsConfig
        );
        answer =
          props.data[
          `bearingInvestigations.bearingInvestigation[${i}].otherBearingInvestigation`
          ];
      }
      if (config !== undefined && answer !== undefined) {
        config.forEach((element) => {
          let nameParts = element.key.split(".");
          let name = nameParts[nameParts.length - 1];
          if (terOramfrOrOther === "TER") {
            if (name === "productServiceDesignation") {
              answerObject[`${element.key}`] = answer.productServiceDesignation;
            } else if (name === "quantity") {
              answerObject[`${element.key}`] = answer.quantity;
            } else if (name === "application") {
              answerObject[`${element.key}`] = answer.application;
            } else if (name === "applicationDetails") {
              answerObject[`${element.key}`] = answer.applicationDetails;
            } else if (name === "typeOfReturn") {
              answerObject[`${element.key}`] = answer.typeOfReturn;
            } else if (name === "customerReferencePartNumber") {
              answerObject[`${element.key}`] =
                answer.customerReferencePartNumber;
            } else if (name === "problemDescription") {
              answerObject[`${element.key}`] = answer.problemDescription;
            } else if (name === "problemDetail") {
              answerObject[`${element.key}`] = answer.problemDetail;
            }
          } else if (terOramfrOrOther === "AMFR") {
            if (name === "productDesignation") {
              answerObject[`${element.key}`] = answer.productDesignation;
            } else if (name === "quantity") {
              answerObject[`${element.key}`] = answer.quantity;
            } else if (name === "skfBearingDesignation") {
              answerObject[`${element.key}`] = answer.skfBearingDesignation;
            } else if (name === "customerIdRefrenceNumber") {
              answerObject[`${element.key}`] = answer.customerIdRefrenceNumber;
            } else if (name === "competitorBearing") {
              answerObject[`${element.key}`] = answer.competitorBearing;
            } else if (name === "application") {
              answerObject[`${element.key}`] = answer.application;
            } else if (name === "vehicleNumber") {
              answerObject[`${element.key}`] = answer.vehicleNumber;
            } else if (name === "carSalesDate") {
              if (
                answer.carSalesDate !== undefined &&
                answer.carSalesDate !== ""
              )
                answerObject[`${element.key}`] = answer.carSalesDate;
            } else if (name === "carRepairDate") {
              if (
                answer.carRepairDate !== undefined &&
                answer.carRepairDate !== ""
              )
                answerObject[`${element.key}`] = answer.carRepairDate;
            } else if (name === "carType") {
              answerObject[`${element.key}`] = answer.carType;
            } else if (name === "duration") {
              answerObject[`${element.key}`] = answer.duration;
            } else if (name === "problemDetails") {
              answerObject[`${element.key}`] = answer.problemDetails;
            } else if (name === "problemDescription") {
              answerObject[`${element.key}`] = answer.problemDescription;
            }
          } else if (terOramfrOrOther === "Other") {
            if (name === "backgroundInformationText") {
              answerObject[`${element.key}`] = answer.backgroundInformationText;
            } else if (name === "recurringFailure") {
              answerObject[`${element.key}`] = answer.recurringFailure;
            } else if (name === "lengthOfBearingOperated") {
              if (
                answer.lengthOfBearingOperated.value &&
                answer.lengthOfBearingOperated.unitSelected !== undefined && answer.lengthOfBearingOperated.unitSelected !== ""
              ) {
                answerObject[
                  element.key
                ] = `${answer.lengthOfBearingOperated.value} ${answer.lengthOfBearingOperated.unitSelected !== "" ? answer.lengthOfBearingOperated.unitSelected.label : ""}`;
              }
            } else if (name === "expectedLife") {
              if (
                answer.expectedLife.value &&
                answer.expectedLife.unitSelected !== undefined && answer.expectedLife.unitSelected !== ""
              ) {
                answerObject[
                  element.key
                ] = `${answer.expectedLife.value} ${answer.expectedLife.unitSelected !== "" ? answer.expectedLife.unitSelected.label : ""}`;
              }
            } else if (name === "costOfFailure") {
              if (
                answer.costOfFailure.value &&
                answer.costOfFailure.unitSelected !== undefined && answer.costOfFailure.unitSelected !== ""
              ) {
                answerObject[
                  element.key
                ] = `${answer.costOfFailure.value} ${answer.costOfFailure.unitSelected !== "" ? answer.costOfFailure.unitSelected.label : ""}`;
              }
            } else if (name === "reasonWasRemoved") {
              if (answer.reasonWasRemoved.hasOwnProperty("label")) {
                answerObject[`${element.key}`] = answer.reasonWasRemoved.label;
              }
            } else if (name === "machineOrAssetCode") {
              if (answer.machineOrAssetCode.hasOwnProperty("label")) {
                answerObject[`${element.key}`] =
                  answer.machineOrAssetCode.label;
              }
            } else if (name === "functionalAreaName") {
              answerObject[`${element.key}`] = answer.functionalAreaName;
            } else if (name === "systemNameWhereAssetIsUsed") {
              answerObject[`${element.key}`] =
                answer.systemNameWhereAssetIsUsed;
            } else if (name === "purposeOfAsset") {
              answerObject[`${element.key}`] = answer.purposeOfAsset;
            } else if (name === "rotationalSpeed") {
              answerObject[`${element.key}`] = answer.rotationalSpeed;
            } else if (name === "temperature") {
              if (
                answer.temperature.value &&
                answer.temperature.unitSelected !== undefined && answer.temperature.unitSelected !== ""
              ) {
                answerObject[
                  element.key
                ] = `${answer.temperature.value} ${answer.temperature.unitSelected !== "" ? answer.temperature.unitSelected.label : ""}`;
              }
            } else if (name === "bearingDesignation") {
              if (
                answer.bearingDesignation.manufacturer !== undefined &&
                answer.bearingDesignation.manufacturer.hasOwnProperty("label")
              ) {
                answerObject[`${element.key}.manufacturer`] =
                  answer.bearingDesignation.manufacturer.label;
              }
              if (
                answer.bearingDesignation.SKFManufacturer !== undefined &&
                answer.bearingDesignation.SKFManufacturer.hasOwnProperty(
                  "label"
                )
              ) {
                answerObject[`${element.key}.SKFManufacturer`] =
                  answer.bearingDesignation.SKFManufacturer.label;
              }
              if (
                answer.bearingDesignation.skfBearingDesignation !== undefined &&
                answer.bearingDesignation.skfBearingDesignation.hasOwnProperty(
                  "label"
                )
              ) {
                answerObject[`${element.key}.skfBearingDesignation`] =
                  answer.bearingDesignation.skfBearingDesignation.label;
              }
              if (
                answer.bearingDesignation.competitorDesignationAndMarkings !==
                undefined
              ) {
                answerObject[
                  `${element.key}.competitorDesignationAndMarkings`
                ] = answer.bearingDesignation.competitorDesignationAndMarkings;
              }
            } else if (name === "continuousOrIntermittent") {
              if (
                answer.operation.continuousOrIntermittent !== undefined &&
                answer.operation.continuousOrIntermittent.hasOwnProperty(
                  "label"
                )
              ) {
                answerObject[element.key] =
                  answer.operation.continuousOrIntermittent.label;
              }
            } else if (name === "lubricationType") {
              if (
                answer.lubricationType.greaseOrOil !== undefined &&
                answer.lubricationType.greaseOrOil.hasOwnProperty("label")
              ) {
                answerObject[`${element.key}`] =
                  answer.lubricationType.greaseOrOil.label;
              }
              if (
                answer.lubricationType.lubricationBrand !== undefined
              ) {
                answerObject[`${element.key}.lubricationBrand`] =
                  answer.lubricationType.lubricationBrand;
              }
              if (
                answer.lubricationType.oilType !== undefined &&
                answer.lubricationType.oilType.hasOwnProperty("label")
              ) {
                answerObject[`${element.key}.oilType`] =
                  answer.lubricationType.oilType.label;
              }
              if (
                answer.lubricationType.greaseType !== undefined &&
                answer.lubricationType.greaseType.hasOwnProperty("label")
              ) {
                answerObject[`${element.key}.greaseType`] =
                  answer.lubricationType.greaseType.label;
              }
              if (answer.lubricationType.greaseTypeGrams !== undefined) {
                let greaseTypeGrams = answer.lubricationType.greaseTypeGrams;
                if (
                  greaseTypeGrams.unitSelected &&
                  greaseTypeGrams.value
                ) {
                  answerObject[
                    `${element.key}.greaseTypeGrams`
                  ] = `${greaseTypeGrams.value} ${greaseTypeGrams.unitSelected.label !== "" ? greaseTypeGrams.unitSelected.label : ""}`;
                }
              }
              if (answer.lubricationType.oilBathBelowShaft !== undefined) {
                let oilBathBelowShaft =
                  answer.lubricationType.oilBathBelowShaft;
                if (
                  oilBathBelowShaft.unitSelected !== undefined &&
                  oilBathBelowShaft.value !== undefined && oilBathBelowShaft.unitSelected !== ""
                ) {
                  answerObject[
                    `${element.key}.oilBathBelowShaft`
                  ] = `${oilBathBelowShaft.value} ${oilBathBelowShaft.unitSelected !== "" ? oilBathBelowShaft.unitSelected.label : ""}`;
                }
              }
              if (answer.lubricationType.circulatingOil !== undefined) {
                let circulatingOil = answer.lubricationType.circulatingOil;
                if (
                  circulatingOil.unitSelected &&
                  circulatingOil.value
                ) {
                  answerObject[
                    `${element.key}.circulatingOilFlowRate`
                  ] = `${circulatingOil.value} ${circulatingOil.unitSelected !== "" ? circulatingOil.unitSelected.label : ""}`;
                }
              }
            }
          }
        });
        return answerObject;
      }
    } else {
      return answerObject;
    }
  };

  useEffect(() => {
    const configs = fixBearingConfig(props.config, props.bearingsCount);
    setBearingConfig(configs);
  }, [props.data]);

  const renderComponentPartInspection = (i, bearingsConfig) => {
    let currentName = "";
    return <div data-section-id={`bearingInvestigations.bearingInvestigation[${i}].componentPartsInvestigation`}>{
      imageSectionNames(i, bearingsConfig).map((name) => {
        let key = name.key;
        let partsOfName = key.split(".");
        let scrollHook = "";
        if (currentName !== partsOfName[3]) {
          currentName = partsOfName[3];
          scrollHook = `${partsOfName[0]}.${partsOfName[1]}.${partsOfName[2]}.${currentName}`;
        }
        let headerName = intl.formatMessage({ id: `${partsOfName[4]}` });
        if (key.includes("innerRing") || key.includes("outerRing")) {
          headerName = `${intl.formatMessage({
            id: `${partsOfName[3]}`,
          })} - ${intl.formatMessage({ id: `${partsOfName[4]}` })}`;
        }

        if (isImageEmpty(props.data[key])) {
          if (!hasScrollTarget(key)) {
            return;
          }
        }

        headerName = formatRollingElementsHeader(props.data, i, headerName, scrollTarget)

        const shouldRenderCPI = props.data[key] || hasScrollTarget(key)

        return shouldRenderCPI ? (
          <div key={key + ".whole"} data-section-id={scrollHook}>
            <div className="live-preview-images" data-key-id={key}>
              <h6 className="live-preview-images__headline">{headerName}</h6>
              <ObservationsAndFailuremode
                answer={props.data[key]}
                configKey={key}
              />
              <ImageContainer
                imageObjects={getImageObjects(key, props.data)}
                imageLayout={getImageLayoutObject(props.data[key])}
              />
            </div>
          </div>
        ) : null
      })}
    </div>
  }

  const renderAnalysis = (i, bearingsConfig) => {
    const renderedFailures = renderFailureImages(i, bearingsConfig);
    const renderedAnalysis = renderAnalysisImages(i);

    return (renderedAnalysis != null || renderedFailures.some(f => f !== null) ?
      (<div className="live-preview-list-structure" data-section-id={`bearingInvestigations.bearingInvestigation[${i}].analysis`}>
        <div className="live-preview-key-value">
          <div className="live-preview-key-value__header__without_background_color">{intl.formatMessage({ id: "analysis" })}</div>
        </div>
        {renderedAnalysis}
        {renderedFailures}
      </div>) : null
    );
  };

  const renderAnalysisImages = i => {
    const summaryKey = "bearingInvestigations.bearingInvestigation[" + i + "].analysis.findings";
    const analysisSummary = props.data[summaryKey];

    return (
      isValuePresent(analysisSummary) || hasScrollTarget(summaryKey) ?
        (<div className="live-preview-key-value">
          <div className="live-preview-images">
            <div data-key-id={summaryKey} key={"bearingInvestigations.bearingInvestigation[" + i + "].analysis.findings"} className="live-preview-key-value__row">
              <div className="live-preview-key-value__cell">
                <div className="live-preview-key-value__key">
                  {intl.formatMessage({ id: "findings" })}
                </div>
                <div className="live-preview-key-value__value" dangerouslySetInnerHTML={{ __html: analysisSummary }}></div>
              </div>
            </div>
          </div>
        </div>) : null)
  };

  const showPredictionImage = data => {
    if (data && data.prediction && data.prediction.showBoundryBox && data.prediction.boundryBoxImage) {
      return true;
    } else {
      return false;
    }
  }

  const showAnalysis = data => {
    if (data) {
      if (data.cause || !_.isEmpty(data.failureCode) || data.imageLayout || (data.imageUrls && data.imageUrls.length > 0) || data.observations || showPredictionImage(data)) {
        return true;
      }
    }
    return false;
  }


  const renderFailureImages = (i, bearingsConfig) => {
    return imageAnalysisNames(i, bearingsConfig).map((name) => {
      let key = name.key;
      return showAnalysis(props.data[key]) || hasScrollTarget(key) ? (
        <div key={key + ".whole"}>
          <div className="live-preview-images">
            <ObservationsAndFailuremode
              answer={props.data[key]}
              configKey={key}
            />
            {showPredictionImage(props.data[key]) && (
              <ImageContainer
                datakey={name.key + ".predictionImage"}
                imageObjects={[props.data[key].prediction.boundryBoxImage]}
                imageLayout={{ value: "live-preview-images__container--one-figure" }} />)}
          </div>
        </div>
      ) : null;
    });
  };

  const newKeyValueTableConfig = row => filterConfig(extractConfigsFor(
    [
      "terBearingInvestigation",
      "amfrBearingInvestigation",
      "otherBearingInvestigation",
      "testEquipment"
    ],
    row
  ),
    [
      "amfrBearingInvestigation",
      "terBearingInvestigation",
      "otherBearingInvestigation",
      "bearingDesignation"
    ]
  )

  return (
    <div className="live-preview-key-value live-preview-key-value--bearing" data-section-id="bearingInvestigations">
      {bearingsConfig.map((row, i) => (
        <div key={`something-${i}`} className="live-preview-list-structure">
          <h3 className="live-preview-list-structure__header" data-section-id={`bearingInvestigation[${i}]`}>
            <div className={classnames("live-preview-key-value__header live-preview-key-value__bearingheader",
              { "live-preview-key-value-with-page-break-if-print__header": pageBreakEachBearing })}>
              {renderBearingDesignationHeaderGBI(intl.formatMessage({ id: "bearingNumber" }), intl.formatMessage({ id: "customerReferenceNumber.header" }), i, props.data)}
            </div>
          </h3>
          <KeyValueTable
            config={filterConfig(extractConfigsFor(["skfBearingTypeGBI", "bearingSkfCustomerDescription", "dateOfReciept"], row), ["BearingDesignationComponent"])}
            data={transformBearingInvestigationData(row, ["skfBearingTypeGBI", "bearingSkfCustomerDescription", "dateOfReciept"], props.data)}
          />
          <NewKeyValueTable
            config={newKeyValueTableConfig(row)}
            data={{ ...props.data, ...transformTERAMFROtherData(i) }}
          />

          <KeyValueTable
            config={extractConfigsFor(["skfCompany", "manufacturingDateCode"], row)}
            data={transformBearingInvestigationData(
              row,
              ["skfCompany", "manufacturingDateCode"],
              props.data,
            )}
          />

          {/* {row.find(c => c.key.includes("testEquipment")) && <div>
            <TestEquipmentTable datakey={row.find(c => c.key.includes("testEquipment")).key} data={ props.data } />
          </div>} */}

          {renderComponentPartInspection(i, bearingsConfig)}

          <KeyValueTable
            config={extractConfigsFor(["allPartsAvailable"], row)}
            data={transformBearingInvestigationData(
              row,
              ["allPartsAvailable"],
              props.data
            )}
          />
          {renderAnalysis(i, bearingsConfig)}
          <KeyValueTable
            header={intl.formatMessage({ id: "GBI-ForgingDieCode" })}
            config={extractConfigsFor(["GBI-ForgingDieCode"], row)}
            data={transformBearingInvestigationData(
              row,
              ["GBI-ForgingDieCode"],
              props.data
            )}
          />
        </div>
      ))}
    </div>
  );
};

GbiBearingInvestigation.propTypes = {
  header: PropTypes.string,
  config: PropTypes.array,
  extract: PropTypes.func,
}

export default GbiBearingInvestigation
